const checkBoxOptions = (text) => {
  return {
    text,
  };
};

const dateBoxOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
  openOnFieldClick: true,
  showClearButton: true,
  displayFormat: 'yyyy-MM-dd',
};

const dateBoxOptionsReadOnly = {
  ...dateBoxOptions,
  readOnly: true,
};

const FileEditorOptions = (setContratStatus) => {
  return {
    stylingMode: 'filled',
    labelMode: 'floating',
    selectButtonText: 'Importer le contrat',
    accept: '.pdf',
    uploadMode: 'useForm',
    labelText: 'ou déposez le fichier ici',
    readyToUploadMessage: 'Prêt à télécharger',
    onValueChanged: (e) => {
      setContratStatus((state) => !e.value.length);
    },
  };
};

const ImageWithEventEditorOptions = (setImageStatus) => {
  return {
    stylingMode: 'filled',
    labelMode: 'floating',
    selectButtonText: 'sélectionner la photo',
    accept: 'image/*',
    uploadMode: 'useForm',
    labelText: 'ou déposez le fichier ici',
    readyToUploadMessage: 'Prêt à télécharger',
    onValueChanged: (e) => {
      setImageStatus(!e.value?.length);
    },
  };
};

export { checkBoxOptions, dateBoxOptions, FileEditorOptions, dateBoxOptionsReadOnly, ImageWithEventEditorOptions };
