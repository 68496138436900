import React from 'react';
import PropTypes from 'prop-types';
import { originImgs } from '../../utils';

function DataCell({ data }) {
  const { appointmentData } = data;
  return (
    <div className="containerCell">
      <img alt="" src={originImgs[appointmentData.origin]} width="35px" height="35px" />
      <div className="cellwrapper">
        {appointmentData.text && <div>{appointmentData.text}</div>}
        {appointmentData.booked_by && <div>{appointmentData.booked_by}</div>}
      </div>
    </div>
  );
}

export default DataCell;
DataCell.propTypes = {
  data: PropTypes.object.isRequired,
};
