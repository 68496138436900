/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
  EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import EditorInput from '../../editor-input/editor-input';
import { create, get, getAll, update } from '../../../api';
import { emailEditorOptions, phoneCaEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { Toast } from '../../../utils';
import useGetMergTags from '../../../hooks/useGetTags';

export default function AgencyForm() {
  const history = useHistory();
  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [valueContent, setValueContent] = useState('');
  const dataTags = useGetMergTags();

  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const notes = valueContent;
      const values = { ...items, notes };
      setLoading(true);
      return isAddMode ? addVille(values) : updateVilleById(id, values);
    },
    [items, valueContent]
  );

  const addVille = (values) => {
    create('agencies', values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/agences');
        res.data && Toast("L'agence de ménage' a été bien ajoutée");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateVilleById = (villeId, values) => {
    update('agencies', villeId, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/agences');
        res.data && Toast("L'agence de ménage' a été bien modifiée");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isAddMode) {
      get('agencies', id).then((response) => {
        const { data } = response.data;
        const { notes } = data;
        setItems(data);
        setValueContent(notes ?? '');
      });
    }
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Agences de ménage"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/agences')}
      />
      <h2 className="content-block">{isAddMode ? 'Ajouter une agence' : 'Modifier une agence'}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Général">
                  <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom est requis" />
                    <Label text="Nom" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="manager" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le responsable est requis" />
                    <Label text="responsable" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="email" editorType="dxTextBox" editorOptions={emailEditorOptions}>
                    <RequiredRule message="Le courriel est requis" />
                    <EmailRule message="Le courriel est invalide" />
                    <Label text="Courriel" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="phone_number" editorType="dxTextBox" editorOptions={phoneCaEditorOptions}>
                    <RequiredRule message="Le téléphone est requis" />
                    <Label text="Téléphone" visible={false} />
                  </SimpleItem>
                </Tab>
                <Tab title="Notes">
                  <EditorInput dataSource={dataTags} valueContent={valueContent} valueChanged={setValueContent} />
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/agences')}>
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
