import React from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';

export default function WidgetImage({ t, src }) {
  return (
    <div className="dx-card responsive-paddings-widget widget">
      <div className="widget_item_title">
        <i className="fa fa-image" />
        {t('residance.main_image')}
      </div>
      <img
        style={{
          width: '100%',
          height: 'auto',
        }}
        alt="problème de connexion"
        src={src}
      />
    </div>
  );
}

WidgetImage.propTypes = {
  t: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
};
