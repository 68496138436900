import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DataGrid, {
  Column,
  Pager,
  Paging,
  LoadPanel,
  Sorting,
  Editing,
  Button,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NotePopup from '../../../notePopup/note_popup';
import { ModePaiments, priceColumn_customizeText, typeListData } from '../../../../utils';

export default function PaymentListing({ customDataSource, reservationId, showModal }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [noteMsg, setNoteMsg] = useState('');

  const formatedData = () => {
    const array = customDataSource?.map((el) => {
      return {
        id: el.id,
        is_refunded: el.is_refunded === 1,
        type: `${typeListData[el.type]} \r\n ${ModePaiments[el.payment_mode]} ${
          // eslint-disable-next-line no-nested-ternary
          el.type_cc === 'visa' ? '(V)' : el.type_cc === 'mastercard' ? '(MC)' : ''
        } \r\n`,
        date: `Fait: \r\n ${moment(el.created_at).format('ll')} \r\n Reçu: \r\n ${moment(el.payment_date).format(
          'll'
        )} \r\n ${el.refund_date ? `Remb: \r\n ${moment(el.refund_date).format('ll')} \r\n` : ''}`,
        amount: el.amount,
        service_cost: el.service_cost,
        total: el.amount + el.service_cost,
        note: el.note,
      };
    });
    return array;
  };

  const isVisibleBtn = (e) => {
    return e.row.data.note && e.row.data.note !== '';
  };

  const showNote = (note) => {
    setIsVisible(true);
    setNoteMsg(note);
  };

  const onHiding = () => {
    setIsVisible(false);
  };

  return (
    <>
      <DataGrid
        className="dx-card wide-card"
        dataSource={formatedData()}
        showBorders
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="is_refunded" caption={t('reservation.remb').toUpperCase()} />
        <Column dataField="type" caption={t('reservation.type').toUpperCase()} hidingPriority={7} />
        <Column dataField="date" caption={t('reservation.date').toUpperCase()} />
        <Column
          dataField="amount"
          caption={t('reservation.rising').toUpperCase()}
          alignment="left"
          customizeText={priceColumn_customizeText}
        />
        <Column
          dataField="service_cost"
          caption={t('reservation.serveice_fees').toUpperCase()}
          alignment="left"
          customizeText={priceColumn_customizeText}
        />
        <Column
          dataField="total"
          caption={t('reservation.sum').toUpperCase()}
          alignment="left"
          customizeText={priceColumn_customizeText}
        />
        <Column type="buttons">
          <Button
            hint="Modifier"
            icon="edit"
            onClick={(e) => history.push(`/reservation/paiement/modifier/${reservationId}/${e.row.data.id}`)}
          />
          <Button icon="trash" hint="Supprimer" onClick={showModal} />
          <Button text="Note" visible={(e) => isVisibleBtn(e)} onClick={(e) => showNote(e.row.data.note)} />
        </Column>
        <Summary>
          <TotalItem column="date" displayFormat="Total:" />
          <TotalItem column="amount" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
          <TotalItem column="service_cost" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
          <TotalItem column="total" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        </Summary>
      </DataGrid>
      <NotePopup isVisible={isVisible} onHiding={onHiding} title="Note de paiement" msg={noteMsg} width={800} />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];

PaymentListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
  reservationId: PropTypes.number.isRequired,
  showModal: PropTypes.func.isRequired,
};
