/* eslint-disable prettier/prettier */
import { React, useState } from 'react';
import Form, {
    SimpleItem,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    GroupItem,
    TabbedItem,
    TabPanelOptions,
    Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import useSubmitForm from '../../../hooks/useSubmitForm/useSubmitForm';
import { textEditorOptions } from '../../../utils/editorOptions';
import EditorInput from '../../editor-input/editor-input';

export default function PageForm() {
    const history = useHistory();
    const { t } = useTranslation();
    const [items, loading, onSubmit] = useSubmitForm('pages', 'pages', 'La page');
    const { id } = useParams();
    const isAddMode = !id;

    const title = isAddMode ? 'Ajouter une page' : 'Modifier une page';
    const [textsValues, setTextsValues] = useState([
        {
            language: 'fr',
            type: 'name',
            value: '',
        },
        {
            language: 'en',
            type: 'name',
            value: '',
        },
        {
            language: 'fr',
            type: 'name_title',
            value: '',
        },
        {
            language: 'en',
            type: 'name_title',
            value: '',
        },
        {
            language: 'fr',
            type: 'text',
            value: '',
        },
        {
            language: 'en',
            type: 'text',
            value: '',
        },
        {
            language: 'fr',
            type: 'permalink',
            value: '',
        },
        {
            language: 'en',
            type: 'permalink',
            value: '',
        },
    ]);
    const setChangeTexts = (data, index) => {
        textsValues[index].value = data;
        setTextsValues(textsValues);
    };
    /** ***** Seo editor states ****** */
    const [seoRef, setSeoRef] = useState([]);
    const setChangeSeo = (data, index) => {
        seoRef[index] = data;
        setSeoRef(seoRef);
    };

    const formatDataBeforeSubmit = () => {
        let with_error = false;
        const { name_fr, name_en, seo_fr, seo_en, ...rest } = items;
        if (textsValues[3].value === '' || textsValues[2].value === '' || textsValues[4].value === '' || textsValues[5].value === '') {
            with_error = true;
            return with_error;
            // eslint-disable-next-line no-else-return
        } else {
            textsValues[0].value = name_fr;
            textsValues[1].value = name_en;
            const seo =
                seo_fr || seo_en
                    ? [
                        { ...seo_fr, language: 'fr' },
                        { ...seo_en, permalink: null, language: 'en' },
                    ]
                    : [];
            const values = {
                ...rest,
                texts: JSON.stringify(textsValues),
                seo: JSON.stringify(seo),
            };
            const formData = new FormData();
            Object.keys(values).map((key) => values[key] != null && formData.append(key, values[key]));
            return formData;
        }
    };



    return (
        <>
            <Button
                className="goback-btn"
                icon="chevronprev"
                text="Retour au liste des pages"
                type="default"
                stylingMode="text"
                onClick={() => history.push('/pages')}
            />
            <h2 className="content-block">{title}</h2>
            <div className="content-block">
                <div className="dx-card responsive-paddings">
                    <form onSubmit={onSubmit}>
                        <Form formData={items} disabled={loading} showValidationSummary>
                            <TabbedItem>
                                <TabPanelOptions deferRendering={false} />
                                <Tab title="Français">
                                    <SimpleItem dataField="name_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                        <RequiredRule message="Le nom est requis" />
                                        <Label text="Nom" visible={false} />
                                    </SimpleItem>
                                    <SimpleItem dataField="permalink_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                        <Label text="Permalien" visible={false} />
                                    </SimpleItem>
                                    <SimpleItem dataField="name_title_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                        <RequiredRule message="Titre pour le menu est requis" />
                                        <Label text="Titre pour le menu" visible={false} />
                                    </SimpleItem>
                                    <GroupItem>
                                        <div className="dx-field-label">{t('activity.text')}*</div>
                                        <div className="dx-field-value editor_width">
                                            <RequiredRule message="la description de la page est requise" />
                                            <EditorInput valueContent={textsValues[4].value}
                                                valueChanged={(data) => setChangeTexts(data, 4)} />
                                        </div>
                                    </GroupItem>
                                </Tab>
                                <Tab title="Englais">
                                    <SimpleItem dataField="name_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                        <RequiredRule message="Le nom est requis" />
                                        <Label text="Nom" visible={false} />
                                    </SimpleItem>
                                    <SimpleItem dataField="permalink_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                        <Label text="Permalien" visible={false} />
                                    </SimpleItem>
                                    <SimpleItem dataField="name_title_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                        <RequiredRule message="Titre pour le menu est requis" />
                                        <Label text="Titre pour le menu" visible={false} />
                                    </SimpleItem>
                                    <GroupItem>
                                        <div className="dx-field-label">{t('activity.text')}*</div>
                                        <div className="dx-field-value editor_width">
                                            <RequiredRule message="la description de la page est requise" />
                                            <EditorInput valueContent={textsValues[5].value}
                                                valueChanged={(data) => setChangeTexts(data, 5)} />
                                        </div>
                                    </GroupItem>
                                </Tab>
                                <Tab title="Seo">
                                    <GroupItem cssClass="group-item">
                                        <GroupItem>
                                            <SimpleItem dataField="seo_fr.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                                <Label text={t('activity.title_fr')} visible={false} />
                                            </SimpleItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <SimpleItem dataField="seo_fr.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                                <Label text={t('activity.desc_fr')} visible={false} />
                                            </SimpleItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <SimpleItem dataField="seo_en.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                                <Label text={t('activity.title_en')} visible={false} />
                                            </SimpleItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <SimpleItem dataField="seo_en.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                                <Label text={t('activity.desc_en')} visible={false} />
                                            </SimpleItem>
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                            </TabbedItem>
                            <GroupItem colCount={2}>
                                <ButtonItem>
                                    <ButtonOptions width="100%" type="default" useSubmitBehavior>
                                        <span className="dx-button-text">
                                            {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                                        </span>
                                    </ButtonOptions>
                                </ButtonItem>
                                <ButtonItem>
                                    <ButtonOptions width="100%" type="normal" onClick={() => history.push('/pages')}>
                                        <span className="dx-button-text">Annuler</span>
                                    </ButtonOptions>
                                </ButtonItem>
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </div>
        </>
    );
}
