/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import Form, { ButtonItem, ButtonOptions, GroupItem, TabbedItem, TabPanelOptions, Tab, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';
import DataSource from 'devextreme/data/data_source';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './activity-create-form.scss';
import { LoadPanel } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { CommonSeriesSettingsHoverStyle } from 'devextreme-react/chart';
import { ImageEditorOptions, textEditorOptions, selectEditorOptions } from '../../../utils/editorOptions';
import { get, updateWithMedia, create, getAll } from '../../../api';
import { saisonList, Toast, categList } from '../../../utils';
import AgencyFormPopup from '../agency-form-popup/agency-form-popup';
import MemberFormPopup from '../member-form-popup/member-form-popup';
import EditorInput from '../../editor-input/editor-input';

const AddPopup = React.lazy(() => import('../../add-popup/add-popup'));
const GeneralInformation = React.lazy(() => import('./tabs/generalInformation'));
const FrenchTab = React.lazy(() => import('./tabs/french'));
const EnglishTab = React.lazy(() => import('./tabs/english'));
const Seo = React.lazy(() => import('./tabs/seo'));

export default function ActivityCreateForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const [loadPanelVisible, setLoadPanelVisible] = useState(true);

  const [loading, setLoading] = useState(false);
  /** ********** Popup Form create ********** */
  const [groupCateg, setGroupCateg] = useState(false);


  const [items, setItems] = useState({});
  const [errors, setErrors] = useState([]);

  /** ** select dropdown data *** */

  const refFormActivity = useRef('ActivityForm');

  /** ***** texts editor states ****** */
  const [textsValues, setTextsValues] = useState([
    {
      language: 'fr',
      type: 'name',
      value: '',
    },
    {
      language: 'en',
      type: 'name',
      value: '',
    },
    {
      language: 'fr',
      type: 'text',
      value: '',
    },
    {
      language: 'en',
      type: 'text',
      value: '',
    },
    {
      language: 'fr',
      type: 'useful_links',
      value: '',
    },
    {
      language: 'en',
      type: 'useful_links',
      value: '',
    },
  ]);
  const setChangeTexts = (data, index) => {
    textsValues[index].value = data;
    setTextsValues(textsValues);
  };
  /** ***** Seo editor states ****** */
  const [seoRef, setSeoRef] = useState([]);
  const setChangeSeo = (data, index) => {
    seoRef[index] = data;
    setSeoRef(seoRef);
  };

  /** ***** Categories List states ****** */

  /** ********* format data before submit ******** */
  const formatDataBeforeSubmit = () => {
    let with_error = false;
    const { name_fr, name_en, main_photo, cover_photo, seo_fr, seo_en, activity_category_id, ...rest } = items;
    if (textsValues[3].value === '' || textsValues[2].value === '' || textsValues[4].value === '' || textsValues[5].value === '') {
      with_error = true;
      return with_error;
      // eslint-disable-next-line no-else-return
    } else {
      textsValues[0].value = name_fr;
      textsValues[1].value = name_en;
      const seo =
        seo_fr || seo_en
          ? [
            { ...seo_fr, language: 'fr' },
            { ...seo_en, permalink: null, language: 'en' },
          ]
          : [];
      const values = {
        ...rest,
        texts: JSON.stringify(textsValues),
        seo: JSON.stringify(seo),
        activity_category_id,
      };
      if (main_photo?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        values.main_photo = main_photo[0];
      }
      if (cover_photo?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        values.cover_photo = cover_photo[0];
      }
      const formData = new FormData();
      Object.keys(values).map((key) => values[key] != null && formData.append(key, values[key]));
      return formData;
    }
  };

  /** ******* Submit data ********* */
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const dataToSend = formatDataBeforeSubmit();
      if (dataToSend === true) { return notify('Tous les champs français et anglais sont obligatoires', 'error', 4000); }
      create('activities', dataToSend).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          Toast(t('activity.successAddActivity'));
          history.push('/activites')
        }
      });
      return items;

    },
    [items, errors]
  );
  /** ******** useEffect ********* */
  useEffect(() => {
    getAll('activity_categories').then((response) => {
      setGroupCateg(response.data.data);
      setLoadPanelVisible(false);
    });
  }, []);

  return (
    <Suspense
      fallback={
        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible showIndicator shading showPane hideOnOutsideClick={false} />
      }
    >
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('activity.goback')}
        type="default"
        stylingMode="text"
        onClick={() => history.push('/activites')}
      />
      <h2 className="content-block">{t('activity.add_activity')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form
              formData={items}
              ref={refFormActivity}
              labelLocation="left"
              disabled={loading}
              showValidationSummary
              className="residence_form"
            >
              <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                onHiding={() => setLoadPanelVisible(false)}
                visible={loadPanelVisible}
                showIndicator
                shading
                showPane
                hideOnOutsideClick={false}
              />
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Informations de base">
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="activity_category_id"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(groupCateg, 'name_fr')}
                    >
                      <RequiredRule message={t('activity.categ_error_msg')} />
                      <Label text={t('activity.categ')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" caption={t('activity.main_img')}>
                    <SimpleItem dataField="main_photo" editorType="dxFileUploader" editorOptions={ImageEditorOptions}>
                      <RequiredRule message="la photo principale est requise" />
                      <Label text="Photo principale" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" caption={t('activity.cover_photo')}>
                    <SimpleItem dataField="cover_photo" editorType="dxFileUploader" editorOptions={ImageEditorOptions}>
                      <RequiredRule message="la photo de couverture est requise" />
                      <Label text="Photo de couverture" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                </Tab>
                <Tab title="Français">
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem dataField="name_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message={t('activity.activity_name_fr_requise')} />
                      <Label text={t('activity.activity_name')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <GroupItem>
                      <div className="dx-field-label">{t('activity.text')}*</div>
                      <div className="dx-field-value editor_width">
                        <RequiredRule message="la description de l'activité est requise" />
                        <EditorInput valueContent={textsValues[2].value} valueChanged={(data) => setChangeTexts(data, 2)} />
                      </div>
                    </GroupItem>
                    <GroupItem>
                      <div className="dx-field-label">{t('activity.link_utils')}*</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput valueContent={textsValues[4].value} valueChanged={(data) => setChangeTexts(data, 4)} />
                      </div>
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title="Anglais">
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem dataField="name_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message={t('activity.activity_name_en_requise')} />
                      <Label text={t('activity.activity_name')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <GroupItem>
                      <div className="dx-field-label">{t('activity.text')}*</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput valueContent={textsValues[3].value} valueChanged={(data) => setChangeTexts(data, 3)} />
                      </div>
                    </GroupItem>
                    <GroupItem>
                      <div className="dx-field-label">{t('activity.link_utils')}*</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput valueContent={textsValues[5].value} valueChanged={(data) => setChangeTexts(data, 5)} />
                      </div>
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title="Seo">
                  <GroupItem cssClass="group-item">
                    <GroupItem>
                      <SimpleItem dataField="seo_fr.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                        <Label text={t('activity.title_fr')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem dataField="seo_fr.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                        <Label text={t('activity.desc_fr')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem dataField="seo_en.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                        <Label text={t('activity.title_en')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem dataField="seo_en.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                        <Label text={t('activity.desc_en')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/activites')}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
          <div className="errors">
            {
              errors.forEach((err) => {
                <div> {err} </div>
              })
            }
          </div>
        </div>
      </div>
    </Suspense>
  );
}
