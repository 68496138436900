import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAll } from '../../../api';
import CompteGeneralForm from './compteGeneralForm';
import FactureAddListing from './factureAddListing';
import PaymentListing from './paymentListing/PaymentListing';
import ResidenceListing from './residenceListing';
import './compteGeneral.scss';

const facturesAdd = [
  {
    id: 1,
    name: 'Sommets du Saint-Laurent',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
  },
  {
    id: 2,
    name: 'Sommets du Saint-Laurent',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
  },
  {
    id: 3,
    name: 'Sommets du Saint-Laurent',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
  },
  {
    id: 4,
    name: 'Sommets du Saint-Laurent',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
  },
  {
    id: 5,
    name: 'Sommets du Saint-Laurent',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
  },
];

const residences = [
  {
    id: 1,
    name: 'AAA-39',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
    tps: '0.00 $',
    tvq: '0.00 $',
  },
  {
    id: 2,
    name: 'AAA-39',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
    tps: '0.00 $',
    tvq: '0.00 $',
  },
  {
    id: 3,
    name: 'AAA-39',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
    tps: '0.00 $',
    tvq: '0.00 $',
  },
  {
    id: 4,
    name: 'AAA-39',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
    tps: '0.00 $',
    tvq: '0.00 $',
  },
  {
    id: 5,
    name: 'AAA-39',
    payments: '-5 741,85 $',
    depenses: '0.00 $',
    commission: '0.00 $',
    tx_hc: '0.00 $',
    service_fee: '0.00 $',
    prop_fee: '0.00 $',
    carte_fee: '0.00 $',
    other_fee: '0.00 $',
    montant: '-5 741,85 $',
    tps: '0.00 $',
    tvq: '0.00 $',
  },
];

const etatCompteGeneralData = [
  { title: '+ Total des paiements reçu', amount: 1291913.72 },
  { title: '+ Total des frais de service', amount: 86622.6 },
  { title: '+ Total des frais de carte de crédit', amount: 67.65 },
  { title: '- Total des dépenses', amount: 8185.59 },
  { title: '- Total des commission', amount: 10603.1 },
  { title: '- Total des frais au propriétaire', amount: 310.43 },
  { title: "- Total des taxes d'hébergement", amount: 2499.33 },
  { title: '- Total de TPS récoltée', amount: 0.0 },
  { title: '- Total de TVQ récoltée', amount: 0.0 },
  { title: '- Total des autres frais', amount: 6138.43 },
];

export default function CompteGeneral() {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [FactureAddData, setFactureAddData] = useState([]);
  const [ResidencesData, setResidencesData] = useState([]);
  const [searchObject, setSearchObject] = useState({});

  useEffect(() => {
    const formValues = {
      date: queryParams.get('date'),
    };
    const url = `${formValues.date ? `?date=${formValues.date}` : ''}`;

    setFactureAddData(facturesAdd);
    setResidencesData(residences);
    setCustomDataSource(etatCompteGeneralData);
    setSearchObject(formValues);
    // getAll(`etat_compte_general/${url}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    //   setSearchObject(formValues);
    // });
  }, []);

  const handleSearchValues = (values) => {
    const url = `${values.date ? `?date=${values.date}` : ''}`;

    history.push(`/etat_compte_general/${url}`);

    // getAll(`etat_compte_general/${url}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    //   setSearchObject(values);
    // });
  };

  return (
    <>
      <h2 className="content-block">État de compte général</h2>
      <div className="factures">
        <div className="factures_listing">
          <PaymentListing customDataSource={customDataSource} />
        </div>
        <div className="factures_search">
          <CompteGeneralForm handleSearchValues={handleSearchValues} formValues={searchObject} />
        </div>
      </div>
      <FactureAddListing customDataSource={FactureAddData} />
      <ResidenceListing customDataSource={ResidencesData} />
    </>
  );
}
