import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, LoadPanel, Sorting } from 'devextreme-react/data-grid';

export default function RapportTotalListing({ customDataSource }) {
  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      remoteOperations
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
    >
      <Sorting mode="none" />
      <LoadPanel enabled />

      <Column dataField="remb" caption="REMB." />
      <Column dataField="no_remb" caption="NON REMB." />
    </DataGrid>
  );
}

RapportTotalListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
