import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import { Button as ButtonBack } from 'devextreme-react';
import Hero from '../../../../components/hero/hero';
import { getAll, remove } from '../../../../api';
import DeletePopup from '../../../../components/delete-popup/delete-popup';
import { Toast } from '../../../../utils';
import './FacturesAddition.scss';

const factures = [
  { id: 1, facture_number: '123', type: 'Normale', date: '2022-08-18 09:10:35' },
  { id: 2, facture_number: '123', type: 'Normale', date: '2022-08-18 09:10:35' },
  { id: 3, facture_number: '123', type: 'Normale', date: '2022-08-18 09:10:35' },
  { id: 4, facture_number: '123', type: 'Normale', date: '2022-08-18 09:10:35' },
  { id: 5, facture_number: '123', type: 'Normale', date: '2022-08-18 09:10:35' },
];

export default function FacturesAddition() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [number, setNumber] = useState('');

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
    setNumber(e.row.data.facture_number);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteFacture = (idFacture) => {
    setCustomDataSource(customDataSource.filter((data) => data.id !== idFacture));
    hideModal();
    Toast(t('common.successDelete', { title: 'La facture' }));
    // remove('factures', idFacture).then(() => {
    //   setCustomDataSource(customDataSource.filter((data) => data.id !== idFacture));
    //   hideModal();
    //   Toast(t('common.successDelete', { title: 'La facture' }));
    // });
  };

  useEffect(() => {
    setCustomDataSource(factures);
    // getAll(`factures/${reservationId}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    // });
  }, []);

  return (
    <>
      <ButtonBack
        className="goback-btn"
        icon="chevronprev"
        text={t('reservation.go_back_reservation')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/reservation/${id}`)}
      />
      <Hero
        title={t('factures.additional_invoices')}
        btnText={t('factures.additional_invoices_add')}
        url={`/reservation/factures/ajouter/${id}`}
      />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="facture_number" caption="NUMÉRO DE FACTURE" />
        <Column dataField="type" caption="TYPE" />
        <Column dataField="date" caption="DATE" />
        <Column type="buttons" width={110}>
          <Button
            hint="Consulter"
            icon="fa fa-eye"
            onClick={(e) => history.push(`/reservation/factures/details/${id}/${e.row.data.id}`)}
          />
          <Button
            hint="Modifier"
            icon="edit"
            onClick={(e) => history.push(`/reservation/factures/modifier/${id}/${e.row.data.id}`)}
          />
          <Button icon="trash" hint="Supprimer" onClick={showModal} />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={`${t('factures.additional_invoices_delete')}${number}`}
        msg={t('factures.additional_invoices_delete_msg')}
        deleteActivity={() => deleteFacture(deleteID)}
        width={500}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
