import React, { useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form, { SimpleItem, Label, RequiredRule, GroupItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { LoadIndicator, Button } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ModePaimentdata, payment, Toast, type, typeCC } from '../../../utils';
import { create } from '../../../api';
import { NumberDecimalEditorOptions, selectEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { dateBoxOptions, checkBoxOptions } from '../residence-form/input-options';

export default function PaymentMethodeManuel() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [isType, setIsType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { refund_date, payment_date, additional_bill_id, type_cc, payment_mode, ...rest } = items;
      const dataForm = {
        ...rest,
        payment_mode,
        additional_bill_id: additional_bill_id === 0 ? null : additional_bill_id,
        payment_date: moment(payment_date).format('YYYY-MM-DD'),
        refund_date: moment(payment_date).format('YYYY-MM-DD'),
      };

      if (payment_mode === 'credit_card_agency') {
        dataForm.type_cc = type_cc;
      }

      return addPayment(dataForm);
    },
    [items]
  );

  const addPayment = (values) => {
    create(`/payments/${id}`, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push(`/reservation/${id}`);
        res.data && Toast(t('common.successAddM', { title: 'Le paiement manuel' }));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
    setIsType(data.payment_mode === 'credit_card_agency');
  };

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('reservation.go_back_reservations')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/reservation/${id}`)}
      />
      <h2 className="content-block">{t('reservation.save_manuel_payment')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} disabled={false} onFieldDataChanged={formFieldDataChanged} showValidationSummary>
              <GroupItem cssClass="group-item" colCount={1}>
                <SimpleItem
                  dataField="additional_bill_id"
                  editorType="dxSelectBox"
                  editorOptions={selectEditorOptions(payment)}
                >
                  <RequiredRule message={t('errors.required_Paiement')} />
                  <Label text={t('reservation.payment')} visible={false} />
                </SimpleItem>
                <SimpleItem dataField="type" editorType="dxSelectBox" editorOptions={selectEditorOptions(type)}>
                  <RequiredRule message={t('errors.required_Type')} />
                  <Label text={t('reservation.type')} visible={false} />
                </SimpleItem>
                <SimpleItem
                  dataField="payment_mode"
                  editorType="dxSelectBox"
                  editorOptions={selectEditorOptions(ModePaimentdata)}
                >
                  <RequiredRule message={t('errors.required_payment_method')} />
                  <Label text={t('reservation.payment_method')} visible={false} />
                </SimpleItem>
                {isType && (
                  <SimpleItem dataField="type_cc" editorType="dxSelectBox" editorOptions={selectEditorOptions(typeCC)}>
                    <RequiredRule message={t('errors.required_Type')} />
                    <Label text={t('reservation.type_cc')} visible={false} />
                  </SimpleItem>
                )}
                <SimpleItem dataField="amount" editorType="dxNumberBox" editorOptions={NumberDecimalEditorOptions}>
                  <RequiredRule message={t('errors.required_rising')} />
                  <Label text={`${t('reservation.rising')} $`} visible={false} />
                </SimpleItem>
                <SimpleItem
                  dataField="service_cost"
                  editorType="dxNumberBox"
                  editorOptions={NumberDecimalEditorOptions}
                >
                  <Label text={`${t('payment.service_cost')} $`} visible={false} />
                </SimpleItem>
                <SimpleItem dataField="payment_date" editorType="dxDateBox" editorOptions={dateBoxOptions}>
                  <RequiredRule message={t('errors.required_date')} />
                  <Label text={t('reservation.date')} visible={false} />
                </SimpleItem>
                <SimpleItem
                  dataField="is_refunded"
                  editorType="dxCheckBox"
                  editorOptions={checkBoxOptions('Remboursement fait')}
                >
                  <Label visible={false} />
                </SimpleItem>
                <SimpleItem dataField="refund_date" editorType="dxDateBox" editorOptions={dateBoxOptions}>
                  <Label text={t('payment.refund_date')} visible={false} />
                </SimpleItem>
                <SimpleItem dataField="note" editorType="dxTextArea" editorOptions={textEditorOptions}>
                  <Label text={t('reservation.note')} visible={false} />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push(`/reservation/${id}`)}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
