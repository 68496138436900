import React, { useState, useCallback, useEffect } from 'react';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import './residence-search-form.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  NumberEditorOptions,
  selectEditorOptions,
  tagEditorOptions,
  tagMemberEditorOptions,
  textEditorOptions,
} from '../../../utils/editorOptions';
import { useAuth } from '../../../contexts/auth';
import { getAll } from '../../../api';
import { statusChaletdata, getRole } from '../../../utils';
import { checkBoxOptions } from '../residence-form/input-options';

export default function ResidenceSearchForm({ handleSearchValues, formValues }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [villedata, setVilledata] = useState([]);
  const [optionsdata, setOptionsdata] = useState([]);
  const [categoriesdata, setCategoriesdata] = useState([]);
  const [unitiesdata, setUnitiesdata] = useState([]);
  const [startValue, setStartValue] = useState();
  const [endValue, setEndValue] = useState();
  const [memberValue, setMemberValue] = useState('');
  const [items, setItems] = useState({});
  const [Membersdata, setMembersdata] = useState([]);
  const [SelectedMembersdata, setSelectedMembersdata] = useState([]);
  const { auth } = useAuth();
  const owner_permission = getRole('residence_owner');

  const minChangeHandler = function (e) {
    setStartValue(e.value);
  };

  const maxChangeHandler = function (e) {
    setEndValue(e.value);
  };

  const vedetteType = [
    {
      name: 'En vedette',
      id: 'featured',
    },
    {
      name: 'Dernière minute',
      id: 'last_minute',
    },
  ];

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { arrive_date, departure_date, users, multi_unit, ...rest } = items;
      const dataForm = {
        ...rest,
        multi_unit: multi_unit ? 1 : 0,
      };
      if (owner_permission) dataForm.users = [auth.userId];
      if (users) {
        const usersIds = users.map((user) => {
          if (typeof user === 'number') {
            return user;
          }
          return user.id;
        });
        dataForm.users = usersIds;
      }
      if (arrive_date) {
        dataForm.arrive_date = moment(arrive_date).format('YYYY-MM-DD');
      }
      if (departure_date) {
        dataForm.departure_date = moment(departure_date).format('YYYY-MM-DD');
      }
      return handleSearchValues(dataForm);
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  const startDateBoxOptions = {
    stylingMode: 'filled',
    labelMode: 'floating',
    invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
    openOnFieldClick: true,
    showClearButton: true,
    value: startValue,
    onValueChanged: (e) => minChangeHandler(e),
    max: endValue,
  };

  const endDateBoxOptions = {
    stylingMode: 'filled',
    labelMode: 'floating',
    invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
    openOnFieldClick: true,
    showClearButton: true,
    value: endValue,
    onValueChanged: (e) => maxChangeHandler(e),
    min: startValue,
  };

  useEffect(() => {
    getAll('cities').then((response) => {
      setVilledata(response.data.data);
    });
    getAll('equipments').then((response) => {
      setOptionsdata(response.data.data);
    });
    getAll('categories').then((response) => {
      setCategoriesdata(response.data.data);
    });
    getAll('units').then((response) => {
      setUnitiesdata(response.data.data);
    });

    setItems(formValues);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (memberValue?.length >= 3) {
        getAll(`search/user/?filed=full_name&value=${memberValue}`).then((response) => {
          setMembersdata(response.data.data);
        });
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [memberValue]);

  return (
    <div className="dx-card responsive-paddings">
      <div className="client_item_title search-res">
        <i className="fa fa-search" />
        {t('common.search')}
      </div>
      <form onSubmit={onSubmit}>
        <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading} colCount={3}>
          <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
            <Label text="Nom de la résidence" visible={false} />
          </SimpleItem>
          {!owner_permission && (
            <SimpleItem
              dataField="users"
              cssClass="tag-box-input"
              editorType="dxTagBox"
              editorOptions={tagMemberEditorOptions(
                Membersdata,
                SelectedMembersdata,
                setMemberValue,
                setSelectedMembersdata
              )}
            >
              <Label text="Propriétaire" visible={false} />
            </SimpleItem>
          )}

          <SimpleItem
            dataField="cities"
            cssClass="tag-box-input"
            editorType="dxTagBox"
            editorOptions={tagEditorOptions(villedata, 'name', true)}
          >
            <Label text="Ville" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="max_nbr_people" editorType="dxNumberBox" editorOptions={NumberEditorOptions}>
            <Label text="Nombre de personnes" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="nbr_rooms" editorType="dxNumberBox" editorOptions={NumberEditorOptions}>
            <Label text="Nombre de chambres" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="status" editorType="dxSelectBox" editorOptions={selectEditorOptions(statusChaletdata)}>
            <Label text="Statut du chalet" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="arrive_date" editorType="dxDateBox" editorOptions={startDateBoxOptions}>
            <Label text="Date d'arrivée" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="departure_date" editorType="dxDateBox" editorOptions={endDateBoxOptions}>
            <Label text="Date de départ" visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField="options"
            cssClass="tag-box-input"
            editorType="dxTagBox"
            editorOptions={tagEditorOptions(optionsdata, 'name', true)}
          >
            <Label text="Options" visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField="categories"
            cssClass="tag-box-input"
            editorType="dxTagBox"
            editorOptions={tagEditorOptions(categoriesdata, 'name', true)}
          >
            <Label text="Catégories" visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField="unit_types"
            cssClass="tag-box-input"
            editorType="dxTagBox"
            editorOptions={tagEditorOptions(unitiesdata, 'name', true)}
          >
            <Label text="Type d'unités" visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField="featured_type"
            editorType="dxSelectBox"
            editorOptions={selectEditorOptions(vedetteType)}
          >
            <Label text="En vedette" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="multi_unit" editorType="dxCheckBox" editorOptions={checkBoxOptions('Multi-Unités')}>
            <Label visible={false} />
          </SimpleItem>
          <GroupItem cssClass="search-btn">
            <ButtonItem>
              <ButtonOptions width="100%" type="default" useSubmitBehavior>
                <span className="dx-button-text">
                  {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.search')}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
}

ResidenceSearchForm.propTypes = {
  handleSearchValues: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};
