/* eslint-disable no-nested-ternary */
import React from 'react';
import '../styles/style.scss';
import { Button } from 'devextreme-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import ClientInfo from '../../client-info/client-info';
import { getPermissionVisibility } from '../../../utils';

export default function WidgetClient({ clinetDetails }) {
  const history = useHistory();
  const { auth } = useAuth();
  const owner_permission = auth.role === 'residence_owner';
  return (
    <div className="dx-card responsive-paddings widget">
      <div className="widget_item_title">
        <i className="fa fa-user" />
        {clinetDetails?.gender === 'm' ? 'M. ' : clinetDetails?.gender === 'mme' ? 'Mme. ' : ''}
        {`${clinetDetails?.first_name} ${clinetDetails?.last_name}`}
      </div>
      <ClientInfo clientDetails={clinetDetails ?? {}} />
      {!owner_permission && (
        <Button
          text="Historique des réservations"
          onClick={() => history.push(`/reservations/?client_id=${clinetDetails?.id}`)}
        />
      )}
    </div>
  );
}
WidgetClient.propTypes = {
  clinetDetails: PropTypes.object.isRequired,
};
