import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import DeletePopup from '../../../../components/delete-popup/delete-popup';
import { getAll, remove } from '../../../../api';
import { Toast } from '../../../../utils';
import UnitePopupForm from '../../../../components/form/unite-popup-form/unite-popup-form';
import AddPopup from '../../../../components/add-popup/add-popup';

export default function UnitiesListing({ customDataSource, setCustomDataSource, isTrash, ShowTrash, residenceId }) {
  const { t } = useTranslation();
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupVisibleUnite, setPopupVisibleUnite] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [uniteId, setUniteId] = useState(null);

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const showModalUnite = (e) => {
    setPopupVisibleUnite(true);
    setUniteId(e.row.data.id);
  };

  const deleteUnity = (idUnity) => {
    remove(`residence_units/${residenceId}`, idUnity).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== idUnity));
      hideModal();
      Toast(t('units.delete_unit_msg'));
    });
  };

  const reactifUnity = (idUnity) => {
    getAll(`residence_units/${residenceId}/${idUnity}/restore`).then(() => {
      hideModal();
      ShowTrash();
      Toast(t('units.reactive_unit_msg'));
    });
  };

  useEffect(() => {
    getAll(`residence_units/${residenceId}`).then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, []);

  return (
    <>
      <DataGrid
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} />
        <Column dataField="code" caption={t('units.code_label')} />
        <Column type="buttons" width={110}>
          <Button visible={!isTrash} hint={t('common.update')} icon="edit" onClick={showModalUnite} />
          <Button
            icon={isTrash ? 'repeat' : 'trash'}
            hint={isTrash ? t('common.enable') : t('common.delete')}
            onClick={showModal}
          />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={isTrash ? t('units.reactivation') : t('units.deletion')}
        msg={isTrash ? t('units.reactivation_msg') : t('units.deletion_msg')}
        deleteActivity={() => (isTrash ? reactifUnity(deleteID) : deleteUnity(deleteID))}
        textBtn={isTrash ? t('common.enable') : t('common.delete')}
        typeBtn={isTrash ? 'success' : 'danger'}
      />
      <AddPopup
        popupVisible={popupVisibleUnite}
        hideModal={() => setPopupVisibleUnite(false)}
        title={t('units.update_unit')}
        component={
          <UnitePopupForm
            hideModal={() => setPopupVisibleUnite(false)}
            setUnitedata={(data) => {
              setCustomDataSource((state) =>
                state.map((item) => {
                  if (item.id === data.id) {
                    return data;
                  }
                  return item;
                })
              );
            }}
            uniteId={uniteId}
            residenceId={residenceId}
          />
        }
        width="60%"
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];

UnitiesListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
  setCustomDataSource: PropTypes.func.isRequired,
  isTrash: PropTypes.bool.isRequired,
  ShowTrash: PropTypes.func.isRequired,
  residenceId: PropTypes.number.isRequired,
};
