import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import Hero from '../../../components/hero/hero';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { getAll, remove } from '../../../api';
import { customizeDates, Toast } from '../../../utils';

export default function SpecialDays() {
  const history = useHistory();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
    setDeleteName(e.row.data.name);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteSpecialDay = (idDay) => {
    remove('holidays', idDay).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== idDay));
      hideModal();
      Toast('Le jour férié a été bien supprimé');
    });
  };

  useEffect(() => {
    getAll('holidays').then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, []);

  return (
    <>
      <Hero title="Jours fériés" btnText="Ajouter un Jour férié" url="/jour-special" />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} />
        <Column dataField="name" caption="NOM" />
        <Column dataField="ranges" caption="dates" customizeText={customizeDates} />

        <Column type="buttons" width={110}>
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/jour-special/${e.row.data.id}`)} />
          <Button icon="trash" hint="Supprimer" onClick={showModal} />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={"Suppression d'une journée spéciale"}
        msg={`Êtes-vous sûr de vouloir supprimer cette journée spéciale ${deleteName} ?`}
        deleteActivity={() => deleteSpecialDay(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
