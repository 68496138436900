/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import moment from 'moment';
import { Tab } from 'devextreme-react/diagram';

export default function PaymentReceivedForm() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startValue, setStartValue] = useState(new Date());
  const [endValue, setEndValue] = useState(new Date());
  const [items, setItems] = useState({ start_date: new Date(), end_date: new Date() });

  const minChangeHandler = function (e) {
    setStartValue(e.value);
  };

  const maxChangeHandler = function (e) {
    setEndValue(e.value);
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { start_date, end_date, ...rest } = items;
      const values = {
        ...rest,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
      };
      return addPeriode(values);
    },
    [items]
  );

  const addPeriode = (values) => {
    setLoading(false);
    history.push(`/rapport_paiements?debut=${values.start_date}&fin=${values.end_date}`);
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  const startDateBoxOptions = () => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: t('errors.date_format'),
      openOnFieldClick: true,
      showClearButton: true,
      value: startValue,
      onValueChanged: (e) => minChangeHandler(e),
      max: endValue,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  const endDateBoxOptions = () => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: t('errors.date_format'),
      openOnFieldClick: true,
      showClearButton: true,
      value: endValue,
      onValueChanged: (e) => maxChangeHandler(e),
      min: startValue,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  return (
    <>
      <h2 className="content-block">{t('payment.payment_rapport')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title={t('forms.labels.information_dates')}>
                  <GroupItem itemType="group">
                    <SimpleItem dataField="start_date" editorType="dxDateBox" editorOptions={startDateBoxOptions()}>
                      <Label text="Début de la période" visible={false} />
                      <RequiredRule message={t('errors.required_start_date')} />
                    </SimpleItem>
                    <SimpleItem dataField="end_date" editorType="dxDateBox" editorOptions={endDateBoxOptions()}>
                      <Label text="Fin de la période" visible={false} />
                      <RequiredRule message={t('errors.required_end_date')} />
                    </SimpleItem>
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.display')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() => {
                      setItems({ start_date: new Date(), end_date: new Date() });
                    }}
                  >
                    <span className="dx-button-text">{t('common.clear')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
