import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import './client.scss';
import { get, remove } from '../../api';
import ClientInfo from '../../components/client-info/client-info';
import DeletePopup from '../../components/delete-popup/delete-popup';
import { Toast, getRole } from '../../utils';

export default function Client() {
  const { id } = useParams();
  const history = useHistory();
  const [items, setItems] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  const owner_permission = getRole('residence_owner');
  const showModal = () => {
    setPopupVisible(true);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteClient = (idClient) => {
    remove('clients', idClient).then((res) => {
      hideModal();
      if (res.status === 204) {
        history.push('/clients');
        Toast('Le client a été bien supprimé');
      }
    });
  };

  useEffect(() => {
    get('clients', id).then((response) => {
      setItems(response.data.data);
    });
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Clients"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/clients')}
        visible={!owner_permission}
      />
      <h2 className="content-block">
        {items.first_name} {items.last_name} ({items.id})
      </h2>
      <div className="content-block client">
        {!owner_permission && (
          <div className="dx-card responsive-paddings client_item client_item-actions">
            <div className="client_item_title">
              <i className="fa fa-edit" />
              Actions
            </div>
            <div className="client_btn">
              <Button
                icon="clock"
                type="default"
                text="Voir son historique"
                onClick={() => {
                  history.push(`/reservations/?client_id=${items?.id}`);
                }}
                visible={!owner_permission}
              />
              <Button
                className="btn-edit"
                icon="edit"
                type="default"
                text="Editer les informations"
                onClick={() => {
                  history.push(`/client/${items.id}`);
                }}
                visible={!owner_permission}
              />
              <Button
                icon="fa fa-code-fork"
                type="normal"
                text="Fusion de clients"
                onClick={() => {
                  history.push(`/clients/fusion/${items.id}`);
                }}
                visible={!owner_permission}
              />
              <Button
                icon="trash"
                type="danger"
                text="Supprimer le client"
                onClick={showModal}
                visible={!owner_permission}
              />
            </div>
          </div>
        )}

        <div className="dx-card responsive-paddings client_item client_item-info">
          <div className="client_item_title">
            <i className="fa fa-info-circle" />
            Informations
          </div>
          <div className="client_item-notes_content">
            <h2 className="content-block">
              {items.first_name} {items.last_name}
            </h2>
            <ClientInfo clientDetails={items} />
          </div>
        </div>
        <div className="dx-card responsive-paddings client_item client_item-notes">
          <div className="client_item_title">
            <i className="fa fa-flag" />
            Remarques
          </div>
          {items.notes && (
            <div className="client_item-notes_content" dangerouslySetInnerHTML={{ __html: items.notes }} />
          )}
        </div>
      </div>

      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title="Suppression:"
        msg="Êtes-vous sûr de vouloir supprimer ce client?"
        deleteActivity={() => deleteClient(items.id)}
        visible={!owner_permission}
      />
    </>
  );
}
