import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting } from 'devextreme-react/data-grid';

export default function FacturesListing({ customDataSource }) {
  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      remoteOperations
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
    >
      <Sorting mode="none" />
      <LoadPanel enabled />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />

      <Column dataField="number" caption="NUMERO" />
      <Column dataField="residence" caption="RÉSIDENCE" />
      <Column dataField="description" caption="DESCRIPTION" />
      <Column dataField="date" caption="DATE" />
      <Column dataField="montant" caption="MONTANT" />
    </DataGrid>
  );
}

const allowedPageSizes = [5, 10, 20];

FacturesListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
