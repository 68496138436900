import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Position } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

export default function AddPopup({ popupVisible, hideModal, title, component, width, height }) {
  return (
    <Popup
      visible={popupVisible}
      onHiding={hideModal}
      dragEnabled={false}
      closeOnOutsideClick
      showCloseButton
      showTitle
      title={title}
      container=".dx-viewport"
      width={width}
      height={height}
    >
      <Position at="center" my="center" />
      <ScrollView height="100%" width="100%">
        {component}
      </ScrollView>
    </Popup>
  );
}

AddPopup.propTypes = {
  popupVisible: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

AddPopup.defaultProps = {
  popupVisible: false,
  width: 'auto',
  height: 'auto',
};
