import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
// import './promotion.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Hero from '../../../components/hero/hero';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { getAll, remove } from '../../../api';
import { Toast } from '../../../utils';
import PermissionComponent from '../../../permissions/permissionComponent';

export default function PromotionResidencePage({ data }) {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const [popupVisible, setPopupVisible] = useState(false);
  const [customDataSource, setCustomDataSource] = useState(data);
  const [deleteID, setDeleteID] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
    setDeleteName(e.row.data.title_fr);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deletePromotion = (idDiscount) => {
    remove('discounts', idDiscount).then(() => {
      setCustomDataSource(customDataSource.filter((el) => el.id !== idDiscount));
      hideModal();
      Toast('La promotion a été bien supprimée');
    });
  };

  useEffect(() => {
    getAll(`discounts/all/residence/${id}`).then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, [id]);

  return (
    <>
      <PermissionComponent
        scope="updateParameter"
        rightchildren={
          <Hero
            title={t('residance.promotions')}
            btnText={t('discount.add_promo')}
            url={`/residences/promotion/add/${id}`}
          />
        }
        nonrightchildren={<h2 className="content-block">{t('residance.promotions')}</h2>}
      />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} cssClass="column_ID_right" />
        <Column dataField="title_fr" caption="Titre" />
        <Column type="buttons" width={110}>
          <Button
            hint={t('common.update')}
            icon="edit"
            onClick={(e) => history.push(`/residences/promotion/update/${id}/${e.row.data.id}`)}
          />
          <Button icon="trash" hint={t('common.delete')} onClick={showModal} />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={t('discount.delete_promo')}
        msg={t('discount.delete_promo_msg', { value: deleteName })}
        deleteActivity={() => deletePromotion(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
PromotionResidencePage.propTypes = {
  data: PropTypes.array.isRequired,
};
