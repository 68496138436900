// @ts-nocheck
import axios from 'axios';
import { errorsHandler } from '../../data-layer/errors-handler';
import responseHandler from '../../data-layer/response-handler';
import { getTokenFromCookie } from '../../token/Auth';
import { config } from './http-config';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: config,
});

apiClient.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorsHandler(error)
);

apiClient.interceptors.request.use(
  (configHeader) => {
    if (!configHeader.headers.Authorization && configHeader.url?.search('/login') === -1) {
      // eslint-disable-next-line no-param-reassign
      configHeader.headers.Authorization = `Bearer ${getTokenFromCookie()}`;
    }
    return configHeader;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
