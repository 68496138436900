import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting } from 'devextreme-react/data-grid';

export default function RapportTotalListing({ customDataSource }) {
  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      remoteOperations
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
    >
      <Sorting mode="none" />
      <LoadPanel enabled />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />

      <Column dataField="visa" caption="VISA" />
      <Column dataField="mastercard" caption="MASTERCARD" />
      <Column dataField="visa_online" caption="VISA (EN LIGNE)" />
      <Column dataField="mastercard_online" caption="MASTERCARD (EN LIGNE)" />
      <Column dataField="certificat" caption="CERTIFICAT" />
      <Column dataField="frais_cc" caption="FRAIS CC" />
      <Column dataField="other" caption="AUTRE" />
      <Column dataField="total" caption="TOTAL" />
    </DataGrid>
  );
}

const allowedPageSizes = [5, 10, 20];

RapportTotalListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
