import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/style.scss';
import { useHistory, useParams } from 'react-router-dom';
import RenderHeaderPage from '../../../components/widgets/headerResidance';
import WidgetImage from '../../../components/widgets/mainImage';
import WidgetActions from '../../../components/widgets/actions';
import { getAll } from '../../../api';
import FormPrices from '../../../components/widgets/addPrice';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function AddAndUpdatePrice() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [dataList, setDataList] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                                   EVENTS                                   */
  /* -------------------------------------------------------------------------- */

  function navigateBack() {
    history.goBack();
  }

  function navigateBackToResidence() {
    history.goBack(-2);
  }

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */

  const listActions = [
    {
      id: 3,
      name: t('residance.backToPrices'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBack,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 4,
      name: t('residance.backToResidance'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBackToResidence,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
  ];
  /* -------------------------------------------------------------------------- */
  /*                                  USEFFECT                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getAll(`residence_photos/${id}`).then(({ data }) => {
      setDataList(data);
    });
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block ">
      <RenderHeaderPage t={t} id="" name={dataList?.name ?? ''} officialName={dataList?.official_name ?? ''} />
      <div className="mainContainer">
        <div className="side">
          <WidgetImage t={t} src={dataList?.main_photo ?? ''} />
          <WidgetActions ListAction={listActions} t={t} dviderIndexs={[]} />
        </div>
        <FormPrices t={t} />
      </div>
    </div>
  );
}
