/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { Trans } from 'react-i18next';
import { Button } from 'devextreme-react';
import AddPopup from '../../add-popup/add-popup';
import AssignPerson from '../../form/assign-form';

export default function RequestItem({ item, t }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [popupVisible, setPopupVisible] = useState(false);
  const [assignPerson, setAssignPerson] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTATNT                                 */
  /* -------------------------------------------------------------------------- */
  const fakeData = [{ id: 1, name: 'Amine kriaa' }];
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const showModal = () => {
    setPopupVisible(true);
  };
  const hideModal = () => {
    setPopupVisible(false);
  };
  return (
    <div className="card">
      <div className="request">
        <div className="headerItem">
          <div className="headerTitle">
            {item.time}
            <div onClick={showModal} className="assignName">
              &nbsp;{`[${fakeData?.filter((el) => el.id === assignPerson?.assign)[0]?.name ?? 'Assigner'}]`}
            </div>
          </div>
          <div>
            <Button hint="Consulter" icon="fa fa-eye" />
            <Button name="archive" icon="fa fa-archive" hint="Archivé" />
          </div>
        </div>
        <div>
          <p className="title">{item.title}</p>
        </div>
        <Trans i18nKey="home.description">
          <strong>{{ clinetName: item.client }}</strong>
          <a href="https://hebergement-charlevoix.com/fr/chalet-a-louer/info/voir/FOR-3-A">
            {{ residanceName: item.residanceName }}
          </a>
          <a href="https://hebergement-charlevoix.com/admin/residences/voir/132">
            {{ residanceSubName: item.residanceSubName }}
          </a>
          <strong>{{ numNights: item.nights }}</strong>
          <strong>{{ date: item.start_at }}</strong>.
        </Trans>
      </div>
      <AddPopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title="Assignation"
        component={<AssignPerson onHandleAssign={(value) => setAssignPerson(value)} hideModal={hideModal} t={t} />}
        width="60%"
      />
    </div>
  );
}
RequestItem.propTypes = {
  t: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
