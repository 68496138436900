import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { t } from 'i18next';
import { useAuth } from '../../contexts/auth';
import './user-panel.scss';
import { defaultUser } from '../../utils/default-user';
import { signOut } from '../../api/auth';
import { getUserAuth, getTokenFromCookie } from '../../token/Auth';

// eslint-disable-next-line react/prop-types
export default function UserPanel({ menuMode }) {
  const { auth, setAuth, deleteCookie } = useAuth();
  const history = useHistory();

  const logout = () => {
    signOut().then((res) => {
      if (res.data?.length === 0) {
        localStorage.removeItem('userAuth');
        deleteCookie();
        history.push('/login');
        setAuth({
          userId: getUserAuth().userId,
          userName: getUserAuth().userName,
          role: getUserAuth().role,
          permissions: getUserAuth().permissions,
          getAuth: !!getTokenFromCookie(),
        });
      }
    });
  };

  function navigateToProfile() {
    history.push(`/membres/${auth.userId}`);
  }
  const menuItems = useMemo(
    () => [
      {
        text: t('common.profil'),
        icon: 'user',
        onClick: navigateToProfile,
      },
      {
        text: t('common.logout'),
        icon: 'runner',
        onClick: logout,
      },
    ],
    [logout]
  );

  return (
    <div className="user-panel">
      <div className="user-info">
        <div className="image-container">
          <div
            style={{
              background: `url(${defaultUser.avatarUrl}) no-repeat #fff`,
              backgroundSize: 'cover',
            }}
            className="user-image"
          />
        </div>
        <div className="user-name">{auth.userName}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu items={menuItems} target=".user-button" showEvent="dxclick" width={210} cssClass="user-menu">
          <Position my="top center" at="bottom center" />
        </ContextMenu>
      )}
      {menuMode === 'list' && <List className="dx-toolbar-menu-action" items={menuItems} />}
    </div>
  );
}
