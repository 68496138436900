/* eslint-disable no-nested-ternary */
import React from 'react';

import PropTypes from 'prop-types';
import './prices-details.scss';

export default function priceDetails({ details, keysList, title }) {
  function renderComponent(item) {
    return (
      <div key={item.key} className="detail">
        <span className="label">{item.name}</span>
        <span className="value">
          {['allow_fill_holes', 'not_show_tariff', 'not_show_dates_tariff', 'day_delay_between_reservations'].includes(
            item.key
          )
            ? details[item.key] === 1
              ? 'oui'
              : 'non'
            : item.key === 'twin'
            ? details[item.key]?.name ?? '-'
            : details[item.key]}
        </span>
      </div>
    );
  }

  function InfoComponent(item) {
    return renderComponent(item);
  }

  return (
    <div className="priceDetailsItem">
      <div className="gallery_item_title">{title}</div>
      {keysList.map(InfoComponent)}
    </div>
  );
}

priceDetails.propTypes = {
  keysList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
