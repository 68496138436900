import React, { useState, useCallback, useEffect } from 'react';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, GroupItem, EmailRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  AutoCompleteSelectEditorOptions,
  emailEditorOptions,
  phoneCaEditorOptions,
  selectEditorOptions,
  textEditorOptions,
} from '../../../utils/editorOptions';
import { getAll } from '../../../api';
import { occupeProprioData } from '../../../utils';

export default function ReservationSearchForm({ handleSearchValues, formValues }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startValue, setStartValue] = useState();
  const [endValue, setEndValue] = useState();
  const [memberValue, setMemberValue] = useState('');
  const [residenceValue, setResidenceValue] = useState('');
  const [items, setItems] = useState({});

  const [Membersdata, setMembersdata] = useState([]);

  const [Residencesdata, setResidencesdata] = useState([]);

  const minChangeHandler = function (e) {
    setStartValue(e.value);
  };

  const maxChangeHandler = function (e) {
    setEndValue(e.value);
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { start_at, end_at, ...rest } = items;
      const dataForm = {
        ...rest,
      };
      if (start_at) {
        dataForm.start_at = moment(start_at).format('YYYY-MM-DD');
      }
      if (end_at) {
        dataForm.end_at = moment(end_at).format('YYYY-MM-DD');
      }
      return handleSearchValues(dataForm);
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  const startDateBoxOptions = {
    stylingMode: 'filled',
    labelMode: 'floating',
    invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
    openOnFieldClick: true,
    showClearButton: true,
    value: startValue,
    onValueChanged: (e) => minChangeHandler(e),
    max: endValue,
  };

  const endDateBoxOptions = {
    stylingMode: 'filled',
    labelMode: 'floating',
    invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
    openOnFieldClick: true,
    showClearButton: true,
    value: endValue,
    onValueChanged: (e) => maxChangeHandler(e),
    min: startValue,
  };

  useEffect(() => {
    const { residence_id, client_id, ...rest } = formValues;
    const dataList = { ...rest };
    if (client_id) {
      dataList.client_id = client_id[0]?.id;
      setMembersdata(client_id);
    }
    if (residence_id) {
      dataList.residence_id = residence_id[0]?.id;
      setResidencesdata(residence_id);
    }
    setItems(dataList);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (memberValue?.length >= 3) {
        getAll(`search/client/?filed=full_name&value=${memberValue}`).then((response) => {
          setMembersdata(response.data.data);
        });
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [memberValue]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (residenceValue?.length >= 3) {
        getAll(`search/residence/?filed=full_name&value=${residenceValue}`).then((response) => {
          setResidencesdata(response.data.data);
        });
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [residenceValue]);

  return (
    <div className="dx-card responsive-paddings">
      <div className="client_item_title search-res">
        <i className="fa fa-search" />
        {t('common.search')}
      </div>
      <form onSubmit={onSubmit}>
        <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading} colCount={3}>
          <SimpleItem dataField="reservation_number" editorType="dxTextBox" editorOptions={textEditorOptions}>
            <Label text="Numéro de réservation" visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField="residence_id"
            cssClass="tag-box-input"
            editorType="dxSelectBox"
            editorOptions={AutoCompleteSelectEditorOptions(Residencesdata, setResidenceValue)}
          >
            <Label text="Résidence" visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField="client_id"
            cssClass="tag-box-input"
            editorType="dxSelectBox"
            editorOptions={AutoCompleteSelectEditorOptions(Membersdata, setMemberValue)}
          >
            <Label text="Client" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="start_at" editorType="dxDateBox" editorOptions={startDateBoxOptions}>
            <Label text="Date d'arrivée" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="end_at" editorType="dxDateBox" editorOptions={endDateBoxOptions}>
            <Label text="Date de départ" visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField="origin"
            editorType="dxSelectBox"
            editorOptions={selectEditorOptions(occupeProprioData)}
          >
            <Label text="Provenance" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="email" editorType="dxTextBox" editorOptions={emailEditorOptions}>
            <EmailRule message="Le courriel client est invalide" />
            <Label text="Courriel client" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="phone" editorType="dxTextBox" editorOptions={phoneCaEditorOptions}>
            <Label text="Téléphone" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="last_name" editorType="dxTextBox" editorOptions={textEditorOptions}>
            <Label text="Nom" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="first_name" editorType="dxTextBox" editorOptions={textEditorOptions}>
            <Label text="Prénom" visible={false} />
          </SimpleItem>
          <SimpleItem dataField="company" editorType="dxTextBox" editorOptions={textEditorOptions}>
            <Label text="Compagnie" visible={false} />
          </SimpleItem>
          <GroupItem cssClass="search-btn">
            <ButtonItem>
              <ButtonOptions width="100%" type="default" useSubmitBehavior>
                <span className="dx-button-text">
                  {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.search')}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
}

ReservationSearchForm.propTypes = {
  handleSearchValues: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};
