import notify from 'devextreme/ui/notify';
import moment from 'moment';
import agencyImg from '../assets/origins/agency.svg';
import airbnbImg from '../assets/origins/airbnb.svg';
import lodgifyImg from '../assets/origins/lodgify.svg';
import occupied_by_ownerImg from '../assets/origins/occupied_by_owner.svg';
import other_provenanceImg from '../assets/origins/other_provenance.svg';
import quebec_location_de_chaletsImg from '../assets/origins/quebec_location_de_chalets.svg';
import renovationsImg from '../assets/origins/renovations.svg';
import rent_by_ownerImg from '../assets/origins/rent_by_owner.svg';
import reservitImg from '../assets/origins/reservit.svg';
import vrboImg from '../assets/origins/vrbo.svg';
import { useAuth } from '../contexts/auth';

export const parametresData = {
  'contrat-location': { name: 'Contrat de location', slug: 'rental-contract' },
  'contrat-service': { name: 'Contrat de service', slug: 'service-contract' },
  courriel: { name: 'Courriel', slug: 'email' },
  conditions: { name: 'Conditions spécifiques', slug: 'conditions' },
};

export const countriesList = [
  { id: 4, code: 'af', alpha3: 'afg', name: 'Afghanistan' },
  { id: 710, code: 'za', alpha3: 'zaf', name: 'Afrique du Sud' },
  { id: 248, code: 'ax', alpha3: 'ala', name: 'Îles Åland' },
  { id: 8, code: 'al', alpha3: 'alb', name: 'Albanie' },
  { id: 12, code: 'dz', alpha3: 'dza', name: 'Algérie' },
  { id: 276, code: 'de', alpha3: 'deu', name: 'Allemagne' },
  { id: 20, code: 'ad', alpha3: 'and', name: 'Andorre' },
  { id: 24, code: 'ao', alpha3: 'ago', name: 'Angola' },
  { id: 660, code: 'ai', alpha3: 'aia', name: 'Anguilla' },
  { id: 10, code: 'aq', alpha3: 'ata', name: 'Antarctique' },
  { id: 28, code: 'ag', alpha3: 'atg', name: 'Antigua-et-Barbuda' },
  { id: 682, code: 'sa', alpha3: 'sau', name: 'Arabie saoudite' },
  { id: 32, code: 'ar', alpha3: 'arg', name: 'Argentine' },
  { id: 51, code: 'am', alpha3: 'arm', name: 'Arménie' },
  { id: 533, code: 'aw', alpha3: 'abw', name: 'Aruba' },
  { id: 36, code: 'au', alpha3: 'aus', name: 'Australie' },
  { id: 40, code: 'at', alpha3: 'aut', name: 'Autriche' },
  { id: 31, code: 'az', alpha3: 'aze', name: 'Azerbaïdjan' },
  { id: 44, code: 'bs', alpha3: 'bhs', name: 'Bahamas' },
  { id: 48, code: 'bh', alpha3: 'bhr', name: 'Bahreïn' },
  { id: 50, code: 'bd', alpha3: 'bgd', name: 'Bangladesh' },
  { id: 52, code: 'bb', alpha3: 'brb', name: 'Barbade' },
  { id: 112, code: 'by', alpha3: 'blr', name: 'Biélorussie' },
  { id: 56, code: 'be', alpha3: 'bel', name: 'Belgique' },
  { id: 84, code: 'bz', alpha3: 'blz', name: 'Belize' },
  { id: 204, code: 'bj', alpha3: 'ben', name: 'Bénin' },
  { id: 60, code: 'bm', alpha3: 'bmu', name: 'Bermudes' },
  { id: 64, code: 'bt', alpha3: 'btn', name: 'Bhoutan' },
  { id: 68, code: 'bo', alpha3: 'bol', name: 'Bolivie' },
  { id: 535, code: 'bq', alpha3: 'bes', name: 'Pays-Bas caribéens' },
  { id: 70, code: 'ba', alpha3: 'bih', name: 'Bosnie-Herzégovine' },
  { id: 72, code: 'bw', alpha3: 'bwa', name: 'Botswana' },
  { id: 74, code: 'bv', alpha3: 'bvt', name: 'Île Bouvet' },
  { id: 76, code: 'br', alpha3: 'bra', name: 'Brésil' },
  { id: 96, code: 'bn', alpha3: 'brn', name: 'Brunei' },
  { id: 100, code: 'bg', alpha3: 'bgr', name: 'Bulgarie' },
  { id: 854, code: 'bf', alpha3: 'bfa', name: 'Burkina Faso' },
  { id: 108, code: 'bi', alpha3: 'bdi', name: 'Burundi' },
  { id: 136, code: 'ky', alpha3: 'cym', name: 'Îles Caïmans' },
  { id: 116, code: 'kh', alpha3: 'khm', name: 'Cambodge' },
  { id: 120, code: 'cm', alpha3: 'cmr', name: 'Cameroun' },
  { id: 124, code: 'ca', alpha3: 'can', name: 'Canada' },
  { id: 132, code: 'cv', alpha3: 'cpv', name: 'Cap-Vert' },
  { id: 140, code: 'cf', alpha3: 'caf', name: 'République centrafricaine' },
  { id: 152, code: 'cl', alpha3: 'chl', name: 'Chili' },
  { id: 156, code: 'cn', alpha3: 'chn', name: 'Chine' },
  { id: 162, code: 'cx', alpha3: 'cxr', name: 'Île Christmas' },
  { id: 196, code: 'cy', alpha3: 'cyp', name: 'Chypre' },
  { id: 166, code: 'cc', alpha3: 'cck', name: 'Îles Cocos' },
  { id: 170, code: 'co', alpha3: 'col', name: 'Colombie' },
  { id: 174, code: 'km', alpha3: 'com', name: 'Comores' },
  { id: 178, code: 'cg', alpha3: 'cog', name: 'République du Congo' },
  { id: 180, code: 'cd', alpha3: 'cod', name: 'République démocratique du Congo' },
  { id: 184, code: 'ck', alpha3: 'cok', name: 'Îles Cook' },
  { id: 410, code: 'kr', alpha3: 'kor', name: 'Corée du Sud' },
  { id: 408, code: 'kp', alpha3: 'prk', name: 'Corée du Nord' },
  { id: 188, code: 'cr', alpha3: 'cri', name: 'Costa Rica' },
  { id: 384, code: 'ci', alpha3: 'civ', name: "Côte d'Ivoire" },
  { id: 191, code: 'hr', alpha3: 'hrv', name: 'Croatie' },
  { id: 192, code: 'cu', alpha3: 'cub', name: 'Cuba' },
  { id: 531, code: 'cw', alpha3: 'cuw', name: 'Curaçao' },
  { id: 208, code: 'dk', alpha3: 'dnk', name: 'Danemark' },
  { id: 262, code: 'dj', alpha3: 'dji', name: 'Djibouti' },
  { id: 214, code: 'do', alpha3: 'dom', name: 'République dominicaine' },
  { id: 212, code: 'dm', alpha3: 'dma', name: 'Dominique' },
  { id: 818, code: 'eg', alpha3: 'egy', name: 'Égypte' },
  { id: 222, code: 'sv', alpha3: 'slv', name: 'Salvador' },
  { id: 784, code: 'ae', alpha3: 'are', name: 'Émirats arabes unis' },
  { id: 218, code: 'ec', alpha3: 'ecu', name: 'Équateur' },
  { id: 232, code: 'er', alpha3: 'eri', name: 'Érythrée' },
  { id: 724, code: 'es', alpha3: 'esp', name: 'Espagne' },
  { id: 233, code: 'ee', alpha3: 'est', name: 'Estonie' },
  { id: 840, code: 'us', alpha3: 'usa', name: 'États-Unis' },
  { id: 231, code: 'et', alpha3: 'eth', name: 'Éthiopie' },
  { id: 238, code: 'fk', alpha3: 'flk', name: 'Malouines' },
  { id: 234, code: 'fo', alpha3: 'fro', name: 'Îles Féroé' },
  { id: 242, code: 'fj', alpha3: 'fji', name: 'Fidji' },
  { id: 246, code: 'fi', alpha3: 'fin', name: 'Finlande' },
  { id: 250, code: 'fr', alpha3: 'fra', name: 'France' },
  { id: 266, code: 'ga', alpha3: 'gab', name: 'Gabon' },
  { id: 270, code: 'gm', alpha3: 'gmb', name: 'Gambie' },
  { id: 268, code: 'ge', alpha3: 'geo', name: 'Géorgie' },
  { id: 239, code: 'gs', alpha3: 'sgs', name: 'Géorgie du Sud-et-les îles Sandwich du Sud' },
  { id: 288, code: 'gh', alpha3: 'gha', name: 'Ghana' },
  { id: 292, code: 'gi', alpha3: 'gib', name: 'Gibraltar' },
  { id: 300, code: 'gr', alpha3: 'grc', name: 'Grèce' },
  { id: 308, code: 'gd', alpha3: 'grd', name: 'Grenade' },
  { id: 304, code: 'gl', alpha3: 'grl', name: 'Groenland' },
  { id: 312, code: 'gp', alpha3: 'glp', name: 'Guadeloupe' },
  { id: 316, code: 'gu', alpha3: 'gum', name: 'Guam' },
  { id: 320, code: 'gt', alpha3: 'gtm', name: 'Guatemala' },
  { id: 831, code: 'gg', alpha3: 'ggy', name: 'Guernesey' },
  { id: 324, code: 'gn', alpha3: 'gin', name: 'Guinée' },
  { id: 624, code: 'gw', alpha3: 'gnb', name: 'Guinée-Bissau' },
  { id: 226, code: 'gq', alpha3: 'gnq', name: 'Guinée équatoriale' },
  { id: 328, code: 'gy', alpha3: 'guy', name: 'Guyana' },
  { id: 254, code: 'gf', alpha3: 'guf', name: 'Guyane' },
  { id: 332, code: 'ht', alpha3: 'hti', name: 'Haïti' },
  { id: 334, code: 'hm', alpha3: 'hmd', name: 'Îles Heard-et-MacDonald' },
  { id: 340, code: 'hn', alpha3: 'hnd', name: 'Honduras' },
  { id: 344, code: 'hk', alpha3: 'hkg', name: 'Hong Kong' },
  { id: 348, code: 'hu', alpha3: 'hun', name: 'Hongrie' },
  { id: 833, code: 'im', alpha3: 'imn', name: 'Île de Man' },
  { id: 581, code: 'um', alpha3: 'umi', name: 'Îles mineures éloignées des États-Unis' },
  { id: 92, code: 'vg', alpha3: 'vgb', name: 'Îles Vierges britanniques' },
  { id: 850, code: 'vi', alpha3: 'vir', name: 'Îles Vierges des États-Unis' },
  { id: 356, code: 'in', alpha3: 'ind', name: 'Inde' },
  { id: 360, code: 'id', alpha3: 'idn', name: 'Indonésie' },
  { id: 364, code: 'ir', alpha3: 'irn', name: 'Iran' },
  { id: 368, code: 'iq', alpha3: 'irq', name: 'Irak' },
  { id: 372, code: 'ie', alpha3: 'irl', name: 'Irlande' },
  { id: 352, code: 'is', alpha3: 'isl', name: 'Islande' },
  { id: 376, code: 'il', alpha3: 'isr', name: 'Israël' },
  { id: 380, code: 'it', alpha3: 'ita', name: 'Italie' },
  { id: 388, code: 'jm', alpha3: 'jam', name: 'Jamaïque' },
  { id: 392, code: 'jp', alpha3: 'jpn', name: 'Japon' },
  { id: 832, code: 'je', alpha3: 'jey', name: 'Jersey' },
  { id: 400, code: 'jo', alpha3: 'jor', name: 'Jordanie' },
  { id: 398, code: 'kz', alpha3: 'kaz', name: 'Kazakhstan' },
  { id: 404, code: 'ke', alpha3: 'ken', name: 'Kenya' },
  { id: 417, code: 'kg', alpha3: 'kgz', name: 'Kirghizistan' },
  { id: 296, code: 'ki', alpha3: 'kir', name: 'Kiribati' },
  { id: 414, code: 'kw', alpha3: 'kwt', name: 'Koweït' },
  { id: 418, code: 'la', alpha3: 'lao', name: 'Laos' },
  { id: 426, code: 'ls', alpha3: 'lso', name: 'Lesotho' },
  { id: 428, code: 'lv', alpha3: 'lva', name: 'Lettonie' },
  { id: 422, code: 'lb', alpha3: 'lbn', name: 'Liban' },
  { id: 430, code: 'lr', alpha3: 'lbr', name: 'Liberia' },
  { id: 434, code: 'ly', alpha3: 'lby', name: 'Libye' },
  { id: 438, code: 'li', alpha3: 'lie', name: 'Liechtenstein' },
  { id: 440, code: 'lt', alpha3: 'ltu', name: 'Lituanie' },
  { id: 442, code: 'lu', alpha3: 'lux', name: 'Luxembourg' },
  { id: 446, code: 'mo', alpha3: 'mac', name: 'Macao' },
  { id: 807, code: 'mk', alpha3: 'mkd', name: 'Macédoine du Nord' },
  { id: 450, code: 'mg', alpha3: 'mdg', name: 'Madagascar' },
  { id: 458, code: 'my', alpha3: 'mys', name: 'Malaisie' },
  { id: 454, code: 'mw', alpha3: 'mwi', name: 'Malawi' },
  { id: 462, code: 'mv', alpha3: 'mdv', name: 'Maldives' },
  { id: 466, code: 'ml', alpha3: 'mli', name: 'Mali' },
  { id: 470, code: 'mt', alpha3: 'mlt', name: 'Malte' },
  { id: 580, code: 'mp', alpha3: 'mnp', name: 'Îles Mariannes du Nord' },
  { id: 504, code: 'ma', alpha3: 'mar', name: 'Maroc' },
  { id: 584, code: 'mh', alpha3: 'mhl', name: 'Îles Marshall' },
  { id: 474, code: 'mq', alpha3: 'mtq', name: 'Martinique' },
  { id: 480, code: 'mu', alpha3: 'mus', name: 'Maurice' },
  { id: 478, code: 'mr', alpha3: 'mrt', name: 'Mauritanie' },
  { id: 175, code: 'yt', alpha3: 'myt', name: 'Mayotte' },
  { id: 484, code: 'mx', alpha3: 'mex', name: 'Mexique' },
  { id: 583, code: 'fm', alpha3: 'fsm', name: 'États fédérés de Micronésie' },
  { id: 498, code: 'md', alpha3: 'mda', name: 'Moldavie' },
  { id: 492, code: 'mc', alpha3: 'mco', name: 'Monaco' },
  { id: 496, code: 'mn', alpha3: 'mng', name: 'Mongolie' },
  { id: 499, code: 'me', alpha3: 'mne', name: 'Monténégro' },
  { id: 500, code: 'ms', alpha3: 'msr', name: 'Montserrat' },
  { id: 508, code: 'mz', alpha3: 'moz', name: 'Mozambique' },
  { id: 104, code: 'mm', alpha3: 'mmr', name: 'Birmanie' },
  { id: 516, code: 'na', alpha3: 'nam', name: 'Namibie' },
  { id: 520, code: 'nr', alpha3: 'nru', name: 'Nauru' },
  { id: 524, code: 'np', alpha3: 'npl', name: 'Népal' },
  { id: 558, code: 'ni', alpha3: 'nic', name: 'Nicaragua' },
  { id: 562, code: 'ne', alpha3: 'ner', name: 'Niger' },
  { id: 566, code: 'ng', alpha3: 'nga', name: 'Nigeria' },
  { id: 570, code: 'nu', alpha3: 'niu', name: 'Niue' },
  { id: 574, code: 'nf', alpha3: 'nfk', name: 'Île Norfolk' },
  { id: 578, code: 'no', alpha3: 'nor', name: 'Norvège' },
  { id: 540, code: 'nc', alpha3: 'ncl', name: 'Nouvelle-Calédonie' },
  { id: 554, code: 'nz', alpha3: 'nzl', name: 'Nouvelle-Zélande' },
  { id: 86, code: 'io', alpha3: 'iot', name: "Territoire britannique de l'océan Indien" },
  { id: 512, code: 'om', alpha3: 'omn', name: 'Oman' },
  { id: 800, code: 'ug', alpha3: 'uga', name: 'Ouganda' },
  { id: 860, code: 'uz', alpha3: 'uzb', name: 'Ouzbékistan' },
  { id: 586, code: 'pk', alpha3: 'pak', name: 'Pakistan' },
  { id: 585, code: 'pw', alpha3: 'plw', name: 'Palaos' },
  { id: 275, code: 'ps', alpha3: 'pse', name: 'Palestine' },
  { id: 591, code: 'pa', alpha3: 'pan', name: 'Panama' },
  { id: 598, code: 'pg', alpha3: 'png', name: 'Papouasie-Nouvelle-Guinée' },
  { id: 600, code: 'py', alpha3: 'pry', name: 'Paraguay' },
  { id: 528, code: 'nl', alpha3: 'nld', name: 'Pays-Bas' },
  { id: 604, code: 'pe', alpha3: 'per', name: 'Pérou' },
  { id: 608, code: 'ph', alpha3: 'phl', name: 'Philippines' },
  { id: 612, code: 'pn', alpha3: 'pcn', name: 'Îles Pitcairn' },
  { id: 616, code: 'pl', alpha3: 'pol', name: 'Pologne' },
  { id: 258, code: 'pf', alpha3: 'pyf', name: 'Polynésie française' },
  { id: 630, code: 'pr', alpha3: 'pri', name: 'Porto Rico' },
  { id: 620, code: 'pt', alpha3: 'prt', name: 'Portugal' },
  { id: 634, code: 'qa', alpha3: 'qat', name: 'Qatar' },
  { id: 638, code: 're', alpha3: 'reu', name: 'La Réunion' },
  { id: 642, code: 'ro', alpha3: 'rou', name: 'Roumanie' },
  { id: 826, code: 'gb', alpha3: 'gbr', name: 'Royaume-Uni' },
  { id: 643, code: 'ru', alpha3: 'rus', name: 'Russie' },
  { id: 646, code: 'rw', alpha3: 'rwa', name: 'Rwanda' },
  { id: 732, code: 'eh', alpha3: 'esh', name: 'République arabe sahraouie démocratique' },
  { id: 652, code: 'bl', alpha3: 'blm', name: 'Saint-Barthélemy' },
  { id: 659, code: 'kn', alpha3: 'kna', name: 'Saint-Christophe-et-Niévès' },
  { id: 674, code: 'sm', alpha3: 'smr', name: 'Saint-Marin' },
  { id: 663, code: 'mf', alpha3: 'maf', name: 'Saint-Martin' },
  { id: 534, code: 'sx', alpha3: 'sxm', name: 'Saint-Martin' },
  { id: 666, code: 'pm', alpha3: 'spm', name: 'Saint-Pierre-et-Miquelon' },
  { id: 336, code: 'va', alpha3: 'vat', name: 'Saint-Siège (État de la Cité du Vatican)' },
  { id: 670, code: 'vc', alpha3: 'vct', name: 'Saint-Vincent-et-les-Grenadines' },
  { id: 654, code: 'sh', alpha3: 'shn', name: 'Sainte-Hélène, Ascension et Tristan da Cunha' },
  { id: 662, code: 'lc', alpha3: 'lca', name: 'Sainte-Lucie' },
  { id: 90, code: 'sb', alpha3: 'slb', name: 'Îles Salomon' },
  { id: 882, code: 'ws', alpha3: 'wsm', name: 'Samoa' },
  { id: 16, code: 'as', alpha3: 'asm', name: 'Samoa américaines' },
  { id: 678, code: 'st', alpha3: 'stp', name: 'Sao Tomé-et-Principe' },
  { id: 686, code: 'sn', alpha3: 'sen', name: 'Sénégal' },
  { id: 688, code: 'rs', alpha3: 'srb', name: 'Serbie' },
  { id: 690, code: 'sc', alpha3: 'syc', name: 'Seychelles' },
  { id: 694, code: 'sl', alpha3: 'sle', name: 'Sierra Leone' },
  { id: 702, code: 'sg', alpha3: 'sgp', name: 'Singapour' },
  { id: 703, code: 'sk', alpha3: 'svk', name: 'Slovaquie' },
  { id: 705, code: 'si', alpha3: 'svn', name: 'Slovénie' },
  { id: 706, code: 'so', alpha3: 'som', name: 'Somalie' },
  { id: 729, code: 'sd', alpha3: 'sdn', name: 'Soudan' },
  { id: 728, code: 'ss', alpha3: 'ssd', name: 'Soudan du Sud' },
  { id: 144, code: 'lk', alpha3: 'lka', name: 'Sri Lanka' },
  { id: 752, code: 'se', alpha3: 'swe', name: 'Suède' },
  { id: 756, code: 'ch', alpha3: 'che', name: 'Suisse' },
  { id: 740, code: 'sr', alpha3: 'sur', name: 'Suriname' },
  { id: 744, code: 'sj', alpha3: 'sjm', name: 'Svalbard et ile Jan Mayen' },
  { id: 748, code: 'sz', alpha3: 'swz', name: 'Eswatini' },
  { id: 760, code: 'sy', alpha3: 'syr', name: 'Syrie' },
  { id: 762, code: 'tj', alpha3: 'tjk', name: 'Tadjikistan' },
  { id: 158, code: 'tw', alpha3: 'twn', name: 'Taïwan / (République de Chine (Taïwan))' },
  { id: 834, code: 'tz', alpha3: 'tza', name: 'Tanzanie' },
  { id: 148, code: 'td', alpha3: 'tcd', name: 'Tchad' },
  { id: 203, code: 'cz', alpha3: 'cze', name: 'Tchéquie' },
  { id: 260, code: 'tf', alpha3: 'atf', name: 'Terres australes et antarctiques françaises' },
  { id: 764, code: 'th', alpha3: 'tha', name: 'Thaïlande' },
  { id: 626, code: 'tl', alpha3: 'tls', name: 'Timor oriental' },
  { id: 768, code: 'tg', alpha3: 'tgo', name: 'Togo' },
  { id: 772, code: 'tk', alpha3: 'tkl', name: 'Tokelau' },
  { id: 776, code: 'to', alpha3: 'ton', name: 'Tonga' },
  { id: 780, code: 'tt', alpha3: 'tto', name: 'Trinité-et-Tobago' },
  { id: 788, code: 'tn', alpha3: 'tun', name: 'Tunisie' },
  { id: 795, code: 'tm', alpha3: 'tkm', name: 'Turkménistan' },
  { id: 796, code: 'tc', alpha3: 'tca', name: 'Îles Turques-et-Caïques' },
  { id: 792, code: 'tr', alpha3: 'tur', name: 'Turquie' },
  { id: 798, code: 'tv', alpha3: 'tuv', name: 'Tuvalu' },
  { id: 804, code: 'ua', alpha3: 'ukr', name: 'Ukraine' },
  { id: 858, code: 'uy', alpha3: 'ury', name: 'Uruguay' },
  { id: 548, code: 'vu', alpha3: 'vut', name: 'Vanuatu' },
  { id: 862, code: 've', alpha3: 'ven', name: 'Venezuela' },
  { id: 704, code: 'vn', alpha3: 'vnm', name: 'Viêt Nam' },
  { id: 876, code: 'wf', alpha3: 'wlf', name: 'Wallis-et-Futuna' },
  { id: 887, code: 'ye', alpha3: 'yem', name: 'Yémen' },
  { id: 894, code: 'zm', alpha3: 'zmb', name: 'Zambie' },
  { id: 716, code: 'zw', alpha3: 'zwe', name: 'Zimbabwe' },
];

export const countriesListEN = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const canadaProvince = [
  'Alberta',
  'Colombie-Britannique',
  'Île-du-Prince-Édouard',
  'Manitoba',
  'Nouveau-Brunswick',
  'Nouvelle-Écosse',
  'Nunavut',
  'Ontario',
  'Québec',
  'Saskatchewan',
  'Terre-Neuve-et-Labrador',
  'Territoires du Nord-Ouest',
  'Yukon',
];

// to be deleted...
export const Memberdata = [
  { name: "L'Observatoire du Cap Inc", id: 1 },
  { name: 'Jean-François Gagnon', id: 2 },
  { name: 'Elisabeth Tremblay Benoit-Marie Tremblay', id: 3 },
  { name: 'Jean-Philippe Desbiens', id: 4 },
  { name: 'Annie Vaillancourt', id: 5 },
  { name: 'Georgette Guay', id: 6 },
];

export const bedList = [
  { label: 'Nombre de lits simple', id: 'nbr_single_beds' },
  { label: 'Nombre de lits double', id: 'nbr_double_beds' },
  { label: 'Nombre de lits queen', id: 'nbr_queen_beds' },
  { label: 'Nombre de lits king', id: 'nbr_king_beds' },
  { label: 'Nombre de lits simple superposé', id: 'nbr_single_bunk_beds' },
  { label: 'Nombre de lits double superposé', id: 'nbr_double_bunk_beds' },
  { label: 'Nombre de lits queen superposé', id: 'nbr_queen_bunk_beds' },
  { label: "Nombre de lits d'appoint", id: 'nbr_extra_beds' },
  { label: 'Nombre de divant-lits ou futons', id: 'nbr_sofa_beds' },
];

export const ModePaimentdata = [
  { name: 'Chèque', id: 'check' },
  { name: 'Carte de crédit (en ligne)', id: 'credit_card_online' },
  { name: "Carte de crédit (à l'agence)", id: 'credit_card_agency' },
  { name: 'Carte de crédit (au proprio)', id: 'credit_card_proprio' },
  { name: "Argent (à l'agence)", id: 'money_agency' },
  { name: 'Argent (au proprio)', id: 'money_proprio' },
  { name: 'Transfert interac', id: 'interac_transfer' },
  { name: 'Transfert', id: 'transfer' },
  { name: 'Certificat cadeau', id: 'gift_certificate' },
  { name: 'AirBNB', id: 'airbnb' },
];
export const ContactPreferenceData = [
  { name: 'Téléphone ', id: 'telephone ' },
  { name: 'Courriel', id: 'courriel' },
];

export const PositionPropriodata = [
  { name: 'Ne pas afficher', id: 'do_not_display' },
  { name: 'En premier', id: 'first' },
  { name: 'En deuxième', id: 'second' },
  { name: 'En troisième', id: 'third' },
];

export const statusChaletdata = [
  { name: 'activé', id: 'active' },
  { name: 'brouillon', id: 'draft' },
  { name: 'archivé', id: 'archived' },
];

export const Toast = (text) => {
  notify({ message: text, position: 'top center' }, 'success', 5000);
};

export const customizeDates = (cellInfo) => {
  let rangesDates = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const element of cellInfo.value) {
    rangesDates += `Du ${moment(element?.start_date).format('DD MMMM')} au ${moment(element?.end_date).format(
      'DD MMMM YYYY'
    )} \r\n`;
  }
  return rangesDates;
};

export const Roles = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  WEBMASTER: 'webmaster',
  RESIDENCE_OWNER: 'residence_owner',
  CLEANING_AGENCY: 'cleaning_agency',
};

export const RolesPermissions = {
  READ_USER: 'readUser',
  UPDATE_USER: 'updateUser',
  DELETE_USER: 'deleteUser',
  CREATE_USER: 'createUser',
  BROWSE_USER: 'browseUser',
  READ_AGENCY: 'readAgency',
  UPDATE_AGENCY: 'updateAgency',
  DELETE_AGENCY: 'deleteAgency',
  CREATE_AGENCY: 'createAgency',
  BROWSE_AGENCY: 'browseAgency',
  READ_CATEGORY: 'readCategory',
  UPDATE_CATEGORY: 'updateCategory',
  DELETE_CATEGORY: 'deleteCategory',
  CREATE_CATEGORY: 'createCategory',
  BROWSE_CATEGORY: 'browseCategory',
  READ_CITIESGROUP: 'readCitiesGroup',
  UPDATE_CITIESGROUP: 'updateCitiesGroup',
  DELETE_CITIESGROUP: 'deleteCitiesGroup',
  CREATE_CITIESGROUP: 'createCitiesGroup',
  BROWSE_CITIESGROUP: 'browseCitiesGroup',
  READ_CITY: 'readCity',
  UPDATE_CITY: 'updateCity',
  DELETE_CITY: 'deleteCity',
  CREATE_CITY: 'createCity',
  BROWSE_CITY: 'browseCity',
  READ_CLIENT: 'readClient',
  UPDATE_CLIENT: 'updateClient',
  DELETE_CLIENT: 'deleteClient',
  CREATE_CLIENT: 'createClient',
  BROWSE_CLIENT: 'browseClient',
  READ_EQUIPMENT: 'readEquipment',
  UPDATE_EQUIPMENT: 'updateEquipment',
  DELETE_EQUIPMENT: 'deleteEquipment',
  CREATE_EQUIPMENT: 'createEquipment',
  BROWSE_EQUIPMENT: 'browseEquipment',
  READ_HOLIDAY: 'readHoliday',
  UPDATE_HOLIDAY: 'updateHoliday',
  DELETE_HOLIDAY: 'deleteHoliday',
  CREATE_HOLIDAY: 'createHoliday',
  BROWSE_HOLIDAY: 'browseHoliday',
  READ_INVOICE: 'readInvoice',
  UPDATE_INVOICE: 'updateInvoice',
  DELETE_INVOICE: 'deleteInvoice',
  CREATE_INVOICE: 'createInvoice',
  BROWSE_INVOICE: 'browseInvoice',
  READ_PARAMETER: 'readParameter',
  UPDATE_PARAMETER: 'updateParameter',
  DELETE_PARAMETER: 'deleteParameter',
  CREATE_PARAMETER: 'createParameter',
  BROWSE_PARAMETER: 'browseParameter',
  READ_PERIOD: 'readPeriod',
  UPDATE_PERIOD: 'updatePeriod',
  DELETE_PERIOD: 'deletePeriod',
  CREATE_PERIOD: 'createPeriod',
  BROWSE_PERIOD: 'browsePeriod',
  READ_RESIDENCE: 'readResidence',
  UPDATE_RESIDENCE: 'updateResidence',
  DELETE_RESIDENCE: 'deleteResidence',
  CREATE_RESIDENCE: 'createResidence',
  BROWSE_RESIDENCE: 'browseResidence',
  READ_TAX: 'readTax',
  UPDATE_TAX: 'updateTax',
  DELETE_TAX: 'deleteTax',
  CREATE_TAX: 'createTax',
  BROWSE_TAX: 'browseTax',
  READ_TOKEN: 'readToken',
  UPDATE_TOKEN: 'updateToken',
  DELETE_TOKEN: 'deleteToken',
  CREATE_TOKEN: 'createToken',
  BROWSE_TOKEN: 'browseToken',
  READ_UNIT: 'readUnit',
  UPDATE_UNIT: 'updateUnit',
  DELETE_UNIT: 'deleteUnit',
  CREATE_UNIT: 'createUnit',
  BROWSE_UNIT: 'browseUnit',
  READ_PHOTODESCRIPTION: 'readPhotoDescription',
  UPDATE_PHOTODESCRIPTION: 'updatePhotoDescription',
  DELETE_PHOTODESCRIPTION: 'deletePhotoDescription',
  CREATE_PHOTODESCRIPTION: 'createPhotoDescription',
  BROWSE_PHOTODESCRIPTION: 'browsePhotoDescription',
  READ_UNLOCKING: 'readUnlocking',
  UPDATE_UNLOCKING: 'updateUnlocking',
  DELETE_UNLOCKING: 'deleteUnlocking',
  CREATE_UNLOCKING: 'createUnlocking',
  BROWSE_UNLOCKING: 'browseUnlocking',
  READ_SUPERADMIN: 'readSuperAdmin',
  UPDATE_SUPERADMIN: 'updateSuperAdmin',
  DELETE_SUPERADMIN: 'deleteSuperAdmin',
  CREATE_SUPERADMIN: 'createSuperAdmin',
  BROWSE_SUPERADMIN: 'browseSuperAdmin',
  READ_ADMIN: 'readAdmin',
  UPDATE_ADMIN: 'updateAdmin',
  DELETE_ADMIN: 'deleteAdmin',
  CREATE_ADMIN: 'createAdmin',
  BROWSE_ADMIN: 'browseAdmin',
  READ_WEBMASTER: 'readWebmaster',
  UPDATE_WEBMASTER: 'updateWebmaster',
  DELETE_WEBMASTER: 'deleteWebmaster',
  CREATE_WEBMASTER: 'createWebmaster',
  BROWSE_WEBMASTER: 'browseWebmaster',
  READ_RESIDENCEOWNER: 'readResidenceOwner',
  UPDATE_RESIDENCEOWNER: 'updateResidenceOwner',
  DELETE_RESIDENCEOWNER: 'deleteResidenceOwner',
  CREATE_RESIDENCEOWNER: 'createResidenceOwner',
  BROWSE_RESIDENCEOWNER: 'browseResidenceOwner',
  READ_CLEANINGAGENCY: 'readCleaningAgency',
  UPDATE_CLEANINGAGENCY: 'updateCleaningAgency',
  DELETE_CLEANINGAGENCY: 'deleteCleaningAgency',
  CREATE_CLEANINGAGENCY: 'createCleaningAgency',
  BROWSE_CLEANINGAGENCY: 'browseCleaningAgency',
  UPDATE_CAT_ACTIV: 'updateCatActiv',
  DELETE_CAT_ACTIV: 'deleteCatActiv',
  CREATE_CAT_ACTIV: 'createCatActiv',
};

export const permissions = (PermissionsList) => {
  const hasCreateSuperAdminPermission = PermissionsList.includes('createSuperAdmin');
  const list = [
    { name: 'Administrateur', id: 'admin' },
    { name: 'Webmestre', id: 'webmaster' },
    { name: 'Propriétaire de résidence', id: 'residence_owner' },
    { name: 'Agence de ménage', id: 'cleaning_agency' },
  ];
  if (hasCreateSuperAdminPermission) {
    list.unshift({ name: 'Super administrateur', id: 'super_admin' });
  }
  return list;
};

export const getPermissionVisibility = (PermissionsList, scope) => {
  return PermissionsList.includes(scope);
};

export const occupeProprioData = [
  { name: 'Loué par le propriétaire', id: 'rent_by_owner' },
  { name: 'Occupé par le propriétaire', id: 'occupied_by_owner' },
  { name: 'Occupé par amis ou famille', id: 'occupied_by_friend_or_family' },
  { name: 'Rénovations', id: 'renovations' },
  { name: 'Agence', id: 'agency' },
  { name: 'AirBNB', id: 'airbnb' },
  { name: 'Quebeclocationdechalets', id: 'quebec_location_de_chalets' },
  { name: 'Lodgify', id: 'lodgify' },
  { name: 'Reservit', id: 'reservit' },
  { name: 'VRBO', id: 'vrbo' },
  { name: 'Autre provenance', id: 'other_provenance' },
];

export const promotionType = [
  { id: 'percentage', name: 'Pourcentage de rabais' },
  { id: 'amount', name: 'Montant de rabais' },
  { id: 'free_nights', name: 'Nb nuit gratuite' },
];

export const weekDays = [
  {
    id: 'monday',
    name: 'Lundi',
  },
  {
    id: 'tuesday',
    name: 'Mardi',
  },
  {
    id: 'wednesday',
    name: 'Mercredi',
  },
  {
    id: 'thursday',
    name: 'Jeudi',
  },
  {
    id: 'friday',
    name: 'Vendredi',
  },
  {
    id: 'saturday',
    name: 'Samedi',
  },
  {
    id: 'sunday',
    name: 'Dimanche',
  },
];

export const getDaysArray = function (s, e) {
  // eslint-disable-next-line vars-on-top, no-var
  for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    a.push(new Date(d));
  }
  // eslint-disable-next-line block-scoped-var
  return a;
};

export const getDateTimeZone = (date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const d = new Date(date.getTime() + userTimezoneOffset);
  return new Date(d);
};

export const getReservationDates = (list) => {
  let resList = [];
  // eslint-disable-next-line array-callback-return
  list.map(({ start_at, end_at }) => {
    const dateStart = new Date(start_at);
    const dateEnd = new Date(end_at);
    const userTimezoneOffset = dateStart.getTimezoneOffset() * 60000;
    const d = new Date(dateStart.getTime() + userTimezoneOffset);
    const a = new Date(dateEnd.getTime() + userTimezoneOffset);
    resList = resList.concat(getDaysArray(d, a));
  });
  return resList;
};

export const getDaysArrayCalander = function (s, e) {
  // eslint-disable-next-line vars-on-top, no-var
  for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    a.push(moment(new Date(d)).format('DD-MM-YYYY'));
  }
  // eslint-disable-next-line block-scoped-var
  return a;
};

export const getReservationDatesCalander = (list) => {
  let resList = [];
  // eslint-disable-next-line array-callback-return
  list.map(({ start_at, end_at }) => {
    const dateStart = new Date(start_at);
    const dateEnd = new Date(end_at);
    const userTimezoneOffset = dateStart.getTimezoneOffset() * 60000;
    const d = new Date(dateStart.getTime() + userTimezoneOffset);
    const a = new Date(dateEnd.getTime() + userTimezoneOffset);
    resList = resList.concat(getDaysArrayCalander(d, a));
  });
  return resList;
};

export const ModePaiments = {
  check: 'Chèque',
  credit_card_online: 'Carte de crédit (en ligne)',
  credit_card_agency: "Carte de crédit (à l'agence)",
  credit_card_proprio: 'Carte de crédit (au proprio)',
  money_agency: "Argent (à l'agence)",
  money_proprio: 'Argent (au proprio)',
  interac_transfer: 'Transfert interac',
  transfer: 'Transfert',
  gift_certificate: 'Certificat cadeau',
  airbnb: 'AirBNB',
};

export const originList = {
  rent_by_owner: 'Loué par le propriétaire',
  occupied_by_owner: 'Occupé par le propriétaire',
  occupied_by_friend_or_family: 'Occupé par amis ou famille',
  renovations: 'Rénovations',
  agency: 'Agence',
  airbnb: 'AirBNB',
  lodgify: 'Lodgify',
  quebec_location_de_chalets: 'Quebeclocationdechalets',
  reservit: 'Reservit',
  vrbo: 'VRBO',
};

export const originImgs = {
  rent_by_owner: rent_by_ownerImg,
  occupied_by_owner: occupied_by_ownerImg,
  occupied_by_friend_or_family: occupied_by_ownerImg,
  renovations: renovationsImg,
  agency: agencyImg,
  airbnb: airbnbImg,
  lodgify: lodgifyImg,
  quebec_location_de_chalets: quebec_location_de_chaletsImg,
  reservit: reservitImg,
  vrbo: vrboImg,
  other_origin: other_provenanceImg,
};

export const payment = [{ name: 'Réservation', id: 0 }];

export const type = [
  { name: 'Dépôt', id: 'deposit' },
  { name: 'Balance', id: 'balanced' },
  { name: 'Total', id: 'total' },
  { name: 'Autre', id: 'other' },
  { name: 'Préautorisation', id: 'pre_authorization' },
];

export const typeCC = [
  { name: 'Visa', id: 'visa' },
  { name: 'Mastercard', id: 'mastercard' },
];

export const promotionTypes = [
  {
    name: 'Sommets du Saint-Laurent',
    id: 1,
  },
  {
    name: 'Train de Charlevoix',
    id: 2,
  },
  {
    name: 'Frais HC',
    id: 3,
  },
  {
    name: 'Certificat cadeau',
    id: 4,
  },
  {
    name: 'Remboursement avec perte de commission',
    id: 5,
  },
  {
    name: 'Remboursement avec une commission',
    id: 6,
  },
];

export const typeListData = {
  deposit: 'Dépôt',
  balanced: 'Balance',
  total: 'Total',
  other: 'Autre',
  pre_authorization: 'Préautorisation',
};

export const priceColumn_customizeText = (cellInfo) => {
  return cellInfo.value !== null && `${cellInfo.value} $`;
};

export const compteNumber = [
  { name: "2315 - Taxe d'hébergement", id: '2315' },
  { name: '5001 - Commissions', id: '5001' },
  { name: '5004 - Modification au site Web', id: '5004' },
  { name: '5020 - Inscription: Nouveaux clients', id: '5020' },
  { name: '5030 - Maison vedette', id: '5030' },
  { name: '5003 - Frais de gestion: carte de crédit 5%', id: '5003' },
  { name: '5007 - Frais de service', id: '5007' },
  { name: '5006 - Photo du site Web', id: '5006' },
  { name: '5922 - Commission reportée Covid-19', id: '5922' },
  { name: '5953 - Billets de ski', id: '5953' },
  { name: '5960 - Adhésion ATR', id: '5960' },
  { name: '5951 - Autres revenus', id: '5951' },
];

export const saisonList = [
  { id: 'all', name: 'Toutes les saisons' },
  { id: 'ete', name: 'Été' },
  { id: 'auto', name: 'Automne' },
  { id: 'hiv', name: 'Hiver' },
  { id: 'print', name: 'Printemps' },
];
export const categList = [
  { id: '1', name: 'À domicile' },
  { id: '2', name: "Les activités d'été à ne pas manquer" },
  { id: '3', name: "Les activités d'hiver à ne pas manquer" },
  { id: '4', name: 'Des activités pour tous' },
  { id: '5', name: 'Des attraits' },
  { id: '6', name: "Les activités d'automne à ne pas manquer" },
  { id: '7', name: 'Activités sur place' },
];
export const getRole = (role) => {
  const { auth } = useAuth();
  return auth.role === role;
};

export const getLengthData = (data) => {
  return data.length;
};
