import React from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import useSubmitForm from '../../../hooks/useSubmitForm/useSubmitForm';
import { textEditorOptions } from '../../../utils/editorOptions';

export default function CategoryForm() {
  const history = useHistory();
  const [items, loading, onSubmit] = useSubmitForm('categories', 'categories', 'La catégorie');
  const { id } = useParams();
  const isAddMode = !id;

  const title = isAddMode ? 'Ajouter une catégorie' : 'Modifier une catégorie';

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Catégories"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/categories')}
      />
      <h2 className="content-block">{title}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Général">
                  <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="La catégorie est requise" />
                    <Label text="Catégorie" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/categories')}>
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
