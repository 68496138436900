import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { create, get, getAll, update } from '../../../api';
import { tagMemberEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { Toast } from '../../../utils';

export default function JumelecomponentForm({ residenceId, jumeleId }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const isAddMode = !jumeleId;
  const [items, setItems] = useState({});
  const [memberValue, setMemberValue] = useState('');
  const [Membersdata, setMembersdata] = useState([]);
  const [SelectedMembersdata, setSelectedMembersdata] = useState([]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { residences, ...rest } = items;
      const values = {
        ...rest,
      };
      if (residences) {
        const residencesIds = residences.map((residence) => {
          if (typeof residence === 'number') {
            return residence;
          }
          return residence.id;
        });
        values.residences = JSON.stringify(residencesIds);
      }
      return isAddMode ? addJumele(values) : updateJumele(values);
    },
    [items]
  );

  const addJumele = (values) => {
    create(`twins/${residenceId}`, values)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Toast(t('jumele.add_msg'));
          history.push(`/residences/jumeles/${residenceId}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateJumele = (values) => {
    update(`twins/${residenceId}`, jumeleId, values)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Toast(t('jumele.edit_msg'));
          history.push(`/residences/jumeles/${residenceId}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setItems({});
    if (!isAddMode) {
      get(`twins/${residenceId}`, jumeleId).then((response) => {
        const { data } = response.data;
        setItems(data);
        setMembersdata(data.residences);
      });
    }
  }, [jumeleId]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (memberValue?.length >= 3) {
        getAll(`search_twins/${residenceId}/${memberValue}`).then((response) => {
          setMembersdata(response.data.data);
        });
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [memberValue]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Jumelés"
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/residences/jumeles/${residenceId}`)}
      />
      <h2 className="content-block">{isAddMode ? t('jumele.add_jumele') : t('jumele.edit_jumele')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title={t('common.general')}>
                  <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message={t('errors.required_name')} />
                    <Label text={t('forms.labels.last_name')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="residences"
                    cssClass="tag-box-input"
                    editorType="dxTagBox"
                    editorOptions={tagMemberEditorOptions(
                      Membersdata,
                      SelectedMembersdata,
                      setMemberValue,
                      setSelectedMembersdata
                    )}
                  >
                    <RequiredRule message={t('errors.required_residences')} />
                    <Label text={t('forms.labels.residences')} visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() => history.push(`/residences/jumeles/${residenceId}`)}
                  >
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}

JumelecomponentForm.propTypes = {
  residenceId: PropTypes.number,
  jumeleId: PropTypes.number,
};

JumelecomponentForm.defaultProps = {
  residenceId: null,
  jumeleId: null,
};
