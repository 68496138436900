import React, { useCallback, useEffect, useState } from 'react';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule, GroupItem } from 'devextreme-react/form';
import PropTypes from 'prop-types';
import { selectEditorOptions } from '../../../utils/editorOptions';

export default function AssignPerson({ t, onHandleAssign, hideModal }) {
  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onHandleAssign(items);
      hideModal();
    },
    [items]
  );

  useEffect(() => {
    setItems({});
  }, [hideModal]);

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  return (
    <div className="content-block">
      <div className="dx-card responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form formData={items} onFieldDataChanged={formFieldDataChanged}>
            <GroupItem cssClass="group-item" colCount={1}>
              <SimpleItem
                dataField="assign"
                editorType="dxSelectBox"
                editorOptions={selectEditorOptions([{ id: 1, name: 'test test' }])}
              >
                <RequiredRule message="test" />
                <Label text="Membre" visible={false} />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">{t('common.save')}</span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={hideModal}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}
AssignPerson.propTypes = {
  t: PropTypes.func.isRequired,
  onHandleAssign: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};
