import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel, Sorting } from 'devextreme-react/data-grid';
import './jumeles.scss';
import { useTranslation } from 'react-i18next';
import { Button as GoBackBtn } from 'devextreme-react/button';
import Hero from '../../../components/hero/hero';
import { getAll, remove } from '../../../api';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { Toast } from '../../../utils';

export default function Jumeles() {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [popupDeleteVisible, setPopupDeleteVisible] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [residenceName, setResidenceName] = useState('');

  const showDeleteModal = (e) => {
    setPopupDeleteVisible(true);
    setDeleteID(e.row.data.id);
  };
  const hideDeleteModal = () => {
    setPopupDeleteVisible(false);
  };

  const deleteJumele = (idJumele) => {
    remove(`twins/${id}`, idJumele).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== idJumele));
      hideDeleteModal();
      Toast(t('jumele.delete_msg'));
    });
  };

  useEffect(() => {
    getAll(`twins/${id}`).then((response) => {
      setCustomDataSource(response.data.data);
      setResidenceName(response.data.name);
    });
  }, []);

  return (
    <>
      <GoBackBtn
        className="goback-btn"
        icon="chevronprev"
        text={t('jumele.goback_residence')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/residences/details/${id}`)}
      />
      <Hero
        title={`${t('jumele.jumeles')}/ ${residenceName}`}
        btnText={t('jumele.add_jumele')}
        onClickBtn={() => history.push(`/residences/jumeles/ajouter/${id}`)}
        cssClassLabel
      />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="name" caption="Nom" />
        <Column dataField="residences" caption="Résidences" />
        <Column type="buttons" width={110}>
          <Button
            hint="Modifier"
            icon="edit"
            onClick={(e) => history.push(`/residences/jumeles/modifier/${id}/${e.row.data.id}`)}
          />
          <Button icon="trash" hint="Supprimer" onClick={showDeleteModal} />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupDeleteVisible}
        hideModal={hideDeleteModal}
        title={t('common.deletion')}
        msg={t('jumele.deletion_msg')}
        deleteActivity={() => deleteJumele(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
