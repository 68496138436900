import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import './groupe-ville.scss';
import Hero from '../../../components/hero/hero';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { getAll, remove } from '../../../api';
import { getPermissionVisibility, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';
import PermissionComponent from '../../../permissions/permissionComponent';

export default function GroupeVille() {
  const history = useHistory();

  const { auth } = useAuth();
  const PermissionsList = auth.permissions;

  const [popupVisible, setPopupVisible] = useState(false);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [deleteID, setDeleteID] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
    setDeleteName(e.row.data.name);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteGroupeVille = (idVille) => {
    remove('cities-groups', idVille).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== idVille));
      hideModal();
      Toast('Le groupe de ville a été bien supprimé');
    });
  };

  useEffect(() => {
    getAll('cities-groups').then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, []);

  return (
    <>
      <PermissionComponent
        scope="createCitiesGroup"
        rightchildren={<Hero title="Groupes de Ville" btnText="Ajouter un groupe de ville" url="/groupe-ville" />}
        nonrightchildren={<h2 className="content-block">Groupes de Ville</h2>}
      />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} cssClass="column_ID_right" />
        <Column dataField="name" caption="Nom" />
        <Column type="buttons" width={110}>
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/groupe-ville/${e.row.data.id}`)} />
          <Button
            icon="trash"
            hint="Supprimer"
            visible={getPermissionVisibility(PermissionsList, 'deleteCitiesGroup')}
            onClick={showModal}
          />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={"Suppression d'un groupe de ville"}
        msg={`Êtes-vous sûr de vouloir supprimer ce groupe de ville ${deleteName}?`}
        deleteActivity={() => deleteGroupeVille(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
