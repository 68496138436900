import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateBox } from 'devextreme-react';
import BalanceVerification from './balanceVerification';
import BalanceDetailsFees from './balanceDetailsFees';
import './styles.scss';

export default function BalanceScreen() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [date, setDate] = useState(new Date());
  const { t } = useTranslation();

  /* -------------------------------------------------------------------------- */
  /*                                  FAKEDATA                                  */
  /* -------------------------------------------------------------------------- */
  const FakeDataItems = [
    {
      periode: '2022-09-12',
      TPS: 11.25,
      TVQ: 50,
      basedTPS: 30,
      basedTVQ: 0,
      accommodationTax: 12,
      serviceCharge: 15,
      commission: 20,
      ownerFees: 10,
      otherFees: 19,
      cardFees: 10,
    },
  ];

  const FakeDataDetailsItems = [
    {
      residence: 'FEU-73',
      periode: '2022-09-12',
      TPS: 11.25,
      TVQ: 50,
      basedTPS: 30,
      basedTVQ: 0,
      accommodationTax: 12,
      serviceCharge: 15,
      commission: 20,
      ownerFees: 10,
      otherFees: 19,
      cardFees: 10,
    },
    {
      residence: 'FTU-15',
      periode: '2022-09-15',
      TPS: 11.25,
      TVQ: 50,
      basedTPS: 30,
      basedTVQ: 0,
      accommodationTax: 12,
      serviceCharge: 15,
      commission: 20,
      ownerFees: 10,
      otherFees: 19,
      cardFees: 10,
    },
    {
      residence: 'AEU-73',
      periode: '2022-09-14',
      TPS: 11.25,
      TVQ: 50,
      basedTPS: 30,
      basedTVQ: 0,
      accommodationTax: 12,
      serviceCharge: 15,
      commission: 20,
      ownerFees: 10,
      otherFees: 19,
      cardFees: 10,
    },
    {
      residence: 'MPM-60',
      periode: '2022-09-13',
      TPS: 11.25,
      TVQ: 50,
      basedTPS: 30,
      basedTVQ: 0,
      accommodationTax: 12,
      serviceCharge: 15,
      commission: 20,
      ownerFees: 10,
      otherFees: 19,
      cardFees: 10,
    },
  ];

  const FakeDataDetailsFeesItems = [
    { date: '2022-09-28', client: 'René Darchen', residence: 'LOR-14', card_fees: 54.29 },
    { date: '2022-09-23', client: 'Johanne Camirand', residence: 'FU-140', card_fees: 64.29 },
  ];
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div>
        <div className="bankHeader">
          <h6 className="content-block transaction-title">{t('balance.title')}</h6>
          <div className="bankHeader">
            <DateBox
              acceptCustomValue={false}
              value={date}
              onValueChanged={({ value }) => setDate(value)}
              calendarOptions={{ maxZoomLevel: 'year' }}
              displayFormat="MMMM yyyy"
            />
          </div>
        </div>
        <BalanceVerification dataSource={FakeDataItems} />
      </div>
      <div>
        <h6 className="content-block transaction-title">{t('balance.details_balance')}</h6>
        <div className="detailsSection">
          {FakeDataDetailsItems.map((el) => (
            <BalanceVerification dataSource={[el]} />
          ))}
        </div>
      </div>
      <div>
        <h6 className="content-block transaction-title">{t('balance.details_fees')}</h6>
        <BalanceDetailsFees dataSource={FakeDataDetailsFeesItems} />
      </div>
    </>
  );
}
