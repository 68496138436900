import React, { useEffect, useState } from 'react';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import { useHistory, useParams } from 'react-router-dom';
import { Button as GoBackBtn } from 'devextreme-react/button';
import Hero from '../../../components/hero/hero';
import './parametres.scss';
import { getAll, remove } from '../../../api';
import { parametresData, Toast } from '../../../utils';
import DeletePopup from '../../../components/delete-popup/delete-popup';

export default function Parametres() {
  const history = useHistory();
  const { type } = useParams();
  const [popupVisible, setPopupVisible] = useState(false);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [deleteID, setDeleteID] = useState('');
  const [deleteName, setDeleteName] = useState('');

  // eslint-disable-next-line react/no-unstable-nested-components
  function ParametresHeader() {
    switch (type) {
      case 'conditions':
        return (
          <>
            <GoBackBtn
              className="goback-btn"
              icon="chevronprev"
              text="Retour contrat de location"
              type="default"
              stylingMode="text"
              onClick={() => history.push('/parametres/contrat-location')}
            />
            <Hero title="Conditions spécifiques" btnText="Ajouter un paramètre" url={`/parametre/${type}`} />
          </>
        );
      case 'contrat-location':
        return (
          <>
            <Hero title={parametresData[type].name} btnText="Ajouter un contrat" url={`/parametre/${type}`} />
            <div className="btn_ajout">
              <Hero title="" btnText="Conditions spécifiques" url="/parametres/conditions" type="default" icon="" />
            </div>
          </>
        );
      case 'contrat-service':
        return <Hero title={parametresData[type].name} btnText="Ajouter un contrat" url={`/parametre/${type}`} />;
      default:
        return <h2 className="content-block">{parametresData[type].name}</h2>;
    }
  }

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
    setDeleteName(e.row.data.label);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteType = (idType) => {
    remove('parameters', idType).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== idType));
      hideModal();
      Toast('Le paramètre a été bien supprimé');
    });
  };

  useEffect(() => {
    if (customDataSource.length) {
      setCustomDataSource([]);
    }
    getAll(`parameters/${parametresData[type].slug}`).then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, [type]);

  return (
    <>
      {ParametresHeader()}
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} />
        <Column dataField="label" caption="Étiquette" />
        <Column type="buttons" width={110}>
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/parametre/${type}/${e.row.data.id}`)} />
          {type !== 'courriel' && <Button icon="trash" hint="Supprimer" onClick={showModal} />}
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title="Suppression de paramètre"
        msg={`Êtes-vous sûr de vouloir supprimer ce paramètre ${deleteName}?`}
        deleteActivity={() => deleteType(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
