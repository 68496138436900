/* eslint-disable react/destructuring-assignment */
import DataSource from 'devextreme/data/data_source';
import { countriesList } from '.';
import { handleDetails } from './autocomplete';

const textEditorOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  inputAttr: {
    autocomplete: 'new-text',
  },
};

const textAreaEditorOptions = {
  ...textEditorOptions,
  minHeight: 100,
  maxLength: 60,
};

const textAreaEditorOptionsWithParam = (maxLength) => {
  return {
    ...textEditorOptions,
    minHeight: 100,
    maxLength,
  };
};

const DateBoxEditorOptions = {
  defaultValue: new Date(),
};
const passwordEditorOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  mode: 'password',
  inputAttr: {
    autocomplete: 'new-password',
  },
};

const AutoCompleteSelectEditorOptionsWithAddBtn = (data, setMemberValue, onClick) => {
  return {
    dataSource: data,
    displayExpr: 'full_name',
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    labelMode: 'floating',
    noDataText: 'Tapez au moins 3 caracteres',
    deferRendering: false,
    valueChangeEvent: 'input',
    onOptionChanged: (e) => {
      if (e.name === 'text') {
        setMemberValue(e.value);
      }
    },
    itemTemplate: (itemData, itemIndex, itemElement) => {
      itemElement.append(`${itemData.full_name}`);
    },
    buttons: [
      {
        name: 'clear',
      },
      {
        name: 'add',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'add',
          onClick,
        },
      },
    ],
  };
};
const AutoCompleteEditorOptions = (setValue, suggestions, refFormResidence) => {
  return {
    stylingMode: 'filled',
    labelMode: 'floating',
    dataSource: suggestions.data,
    valueExpr: 'description',
    onValueChanged: ({ value }) => setValue(value),
    onSelectionChanged: () => handleDetails(refFormResidence),
  };
};
const permalinkEditorOptions = (refForm, field) => {
  return {
    onValueChanged: (data) => {
      const updatedString = data.value
        ?.replace(/\s+/g, '-')
        .replace(/[^A-Za-z0-9-]+/g, '')
        .replace(/-+/g, '-')
        .replace(/^-?|-?$/g, '');
      refForm.current.instance.updateData(field ?? 'seo_fr.permalink', updatedString);
    },
  };
};
const NumberEditorOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  step: 0,
  min: 0,
};

export function FnDecimalNumberEditorOptions(numberEditorOptions, disabled = false) {
  return { ...numberEditorOptions, disabled };
}

export function FnNumberEditorOptions(disabled = false) {
  return { ...NumberEditorOptions, disabled };
}
function NumberIntEditorOptions(object) {
  return {
    stylingMode: 'filled',
    labelMode: 'floating',
    step: 1,
    min: object?.min ?? 0,
    max: object?.max ?? null,
    format: '#0',
    showSpinButtons: true,
  };
}
const NumberDecimalEditorOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  step: 1,
  min: 0,
  showSpinButtons: true,
};

const longEditorOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  step: 0,
  min: -180,
  max: 180,
};

const latEditorOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  step: 0,
  min: -90,
  max: 90,
};

const emailEditorOptions = {
  stylingMode: 'filled',
  mode: 'email',
  labelMode: 'floating',
  inputAttr: {
    autocomplete: 'new-address',
  },
};

const phoneCaEditorOptions = {
  mask: '0 (000) 000-0000',
  maskInvalidMessage: 'Le téléphone doit avoir un format de téléphone CA correct',
  labelMode: 'floating',
};

const selectEditorOptions = (data, displayExpr = 'name', disabled = false) => {
  return {
    dataSource: new DataSource({
      store: {
        data,
        type: 'array',
        key: 'id',
      },
    }),
    displayExpr,
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    labelMode: 'floating',
    disabled,
  };
};
const startDateBoxOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
  openOnFieldClick: true,
  showClearButton: true,
};
const selectPaymentEditorOptions = (data, displayExpr = 'name') => {
  return {
    dataSource: new DataSource({
      store: {
        data,
        type: 'array',
        key: 'id',
      },
    }),
    displayExpr,
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: false,
    labelMode: 'floating',
  };
};

const selectEditorOptionsWithAddBtn = (data, onClick) => {
  return {
    dataSource: new DataSource({
      store: {
        data,
        type: 'array',
        key: 'id',
      },
    }),
    displayExpr: 'name',
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    labelMode: 'floating',
    buttons: [
      {
        name: 'add',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'add',
          onClick,
        },
      },
    ],
  };
};

const ImageEditorOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  selectButtonText: 'sélectionner la photo',
  accept: 'image/*',
  uploadMode: 'useForm',
  labelText: 'ou déposez le fichier ici',
  readyToUploadMessage: 'Prêt à télécharger',
};

const tagEditorOptions = (data, displayExpr = 'name', multiline = false, disabled = false) => {
  return {
    dataSource: new DataSource({
      store: {
        data,
        type: 'array',
        key: 'id',
      },
    }),
    displayExpr,
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    showSelectionControls: true,
    showDropDownButton: true,
    multiline,
    labelMode: 'floating',
    disabled,
  };
};

const tagMemberEditorOptions = (data, selectedMembersdata, setMemberValue, setSelectedMembersdata) => {
  const arr = [...data, ...selectedMembersdata];
  const ids = arr.map((o) => o.id);
  const filteredArray = arr.filter(({ id }, index) => !ids.includes(id, index + 1));
  return {
    dataSource: new DataSource({
      store: {
        data: filteredArray,
        type: 'array',
        key: 'id',
      },
    }),
    displayExpr: 'full_name',
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    showSelectionControls: true,
    showDropDownButton: true,
    multiline: true,
    labelMode: 'floating',
    onOptionChanged: (e) => {
      if (e.name === 'text') {
        setMemberValue(e.value);
      }
    },
    onValueChanged: ({ value }) => {
      const selectedItems = data.filter((el) => value.includes(el.id));
      setSelectedMembersdata((state) => [...state, ...selectedItems]);
    },
  };
};

const tagEditorOptionsWithAddBtn = (data, onClick) => {
  return {
    dataSource: new DataSource({
      store: {
        data,
        type: 'array',
        key: 'id',
      },
    }),
    displayExpr: 'name',
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    showSelectionControls: true,
    showDropDownButton: true,
    multiline: false,
    labelMode: 'floating',
    buttons: [
      {
        name: 'add',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'add',
          onClick,
        },
      },
    ],
  };
};

const salutationEditorOptions = {
  dataSource: new DataSource({
    store: {
      data: [
        { Name: 'Aucune', ID: 'not_applicable' },
        { Name: 'Monsieur', ID: 'm' },
        { Name: 'Madame', ID: 'mme' },
      ],
      type: 'array',
      key: 'ID',
    },
  }),
  displayExpr: 'Name',
  valueExpr: 'ID',
  labelMode: 'floating',
};
const langueEditorOptions = {
  dataSource: new DataSource({
    store: {
      data: [
        { Name: 'Français', ID: 'fr' },
        { Name: 'Anglais', ID: 'en' },
      ],
      type: 'array',
      key: 'ID',
    },
  }),
  displayExpr: 'Name',
  valueExpr: 'ID',
  labelMode: 'floating',
};

const countryEditorOptions = {
  dataSource: new DataSource({
    store: {
      data: countriesList,
      type: 'array',
      key: 'code',
    },
  }),
  displayExpr: 'name',
  valueExpr: 'name',
  showClearButton: true,
  searchEnabled: true,
  labelMode: 'floating',
};

const AutoCompleteSelectEditorOptions = (data, setMemberValue) => {
  return {
    dataSource: data,
    displayExpr: 'full_name',
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    labelMode: 'floating',
    noDataText: 'Tapez au moins 3 caracteres',
    deferRendering: false,
    valueChangeEvent: 'input',
    onOptionChanged: (e) => {
      if (e.name === 'text') {
        setMemberValue(e.value);
      }
    },
    itemTemplate: (itemData, itemIndex, itemElement) => {
      itemElement.append(`${itemData.full_name}`);
    },
  };
};

const AutoCompleteTagEditorOptions = (data, setMemberValue) => {
  return {
    dataSource: data,
    displayExpr: 'full_name',
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    showSelectionControls: true,
    showDropDownButton: true,
    multiline: true,
    labelMode: 'floating',
    noDataText: 'Tapez au moins 3 caracteres',
    deferRendering: false,
    valueChangeEvent: 'input',
    onOptionChanged: (e) => {
      if (e.name === 'text') {
        setMemberValue(e.value);
      }
    },
    itemTemplate: (itemData, itemIndex, itemElement) => {
      itemElement.append(`${itemData.full_name}`);
    },
  };
};

export {
  textEditorOptions,
  textAreaEditorOptions,
  emailEditorOptions,
  phoneCaEditorOptions,
  selectEditorOptions,
  selectEditorOptionsWithAddBtn,
  ImageEditorOptions,
  longEditorOptions,
  latEditorOptions,
  NumberEditorOptions,
  tagEditorOptions,
  permalinkEditorOptions,
  AutoCompleteEditorOptions,
  passwordEditorOptions,
  AutoCompleteSelectEditorOptionsWithAddBtn,
  NumberIntEditorOptions,
  NumberDecimalEditorOptions,
  tagMemberEditorOptions,
  tagEditorOptionsWithAddBtn,
  selectPaymentEditorOptions,
  DateBoxEditorOptions,
  startDateBoxOptions,
  salutationEditorOptions,
  langueEditorOptions,
  countryEditorOptions,
  AutoCompleteSelectEditorOptions,
  AutoCompleteTagEditorOptions,
  textAreaEditorOptionsWithParam,
};
