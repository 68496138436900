/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';
import Scheduler, { Resource, View, Scrolling, WeekView } from 'devextreme-react/scheduler';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import './residences-scheduler.scss';
import DataCell from './dataCell';
import { getDateTimeZone } from '../../utils';

const groups = ['ownerId'];
const currentDate = new Date();

const transformCss = (e) => {
  const transform = e.appointmentElement.style.webkitTransform;
  e.appointmentElement.style.transform = `${transform} `;
  e.appointmentElement.id = 'appointment-hover';
};

function ResourceCell(data, history) {
  const onNavigateToDetails = () => history.push(`/residences/details/${data.data.id}`);
  return (
    <a role="button" className="link" onClick={onNavigateToDetails} tabIndex={0}>
      {`${data.data.text}, ${data.data.data.official_name ?? ''}`}
    </a>
  );
}

export default function ResidencesScheduler({ dataSource, arrive_date, departure_date }) {
  const history = useHistory();

  const formatedDataToScheduler = () => {
    const array = dataSource?.map((el) => {
      return { id: el.id, text: el.name, official_name: el.official_name, booked_by: el.booked_by };
    });
    return array;
  };
  const formatedReservations = (residenceId, reservationList) => {
    const array = reservationList.map((el) => {
      return {
        id: el.id,
        ownerId: [residenceId],
        text: el.client_name,
        booked_by: el.booked_by,
        origin: el.origin,
        startDate: getDateTimeZone(new Date(el.start_at)),
        endDate: getDateTimeZone(new Date(el.end_at)),
      };
    });
    return array;
  };

  const formatedDataReservationList = () => {
    const array = [];
    dataSource?.map((el) => el.reservations.length && array.push(...formatedReservations(el.id, el.reservations)));
    return array;
  };

  const formatCurrentDate = () => {
    return arrive_date ? new Date(arrive_date) : departure_date ? new Date(departure_date) : new Date();
  };

  const onAppointmentClick = (e) => {
    e.cancel = true;
  };

  const onAppointmentDblClick = (e) => {
    e.cancel = true;
    history.push(`/reservation/${e.appointmentData.id}`);
  };

  const onAppointmentFormOpening = (e) => {
    e.cancel = true;
    history.push(`/reservation/${e.appointmentData.id}`);
  };

  return (
    <div>
      {dataSource && (
        <Scheduler
          dataSource={formatedDataReservationList()}
          height={dataSource.length > 11 ? 700 : ''}
          defaultCurrentView="timelineWeek"
          defaultCurrentDate={currentDate}
          firstDayOfWeek={new Date().getDay()}
          resourceCellComponent={(values) => ResourceCell(values, history)}
          startDayHour={8}
          endDayHour={20}
          cellDuration={1440}
          showAllDayPanel={false}
          groups={groups}
          onAppointmentRendered={(e) => transformCss(e)}
          maxAppointmentsPerCell="unlimited"
          appointmentComponent={DataCell}
          editing={{
            allowAdding: false,
            allowDeleting: false,
            allowResizing: false,
            allowDragging: false,
            allowUpdating: false,
          }}
          onAppointmentDblClick={onAppointmentDblClick}
          onAppointmentFormOpening={onAppointmentFormOpening}
          appointmentTooltipComponent={DataCell}
        >
          <View type="timelineWeek" intervalCount={3} groupOrientation="vertical" />
          <Resource
            fieldExpr="ownerId"
            allowMultiple
            dataSource={formatedDataToScheduler()}
            label="Owner"
            colorExpr="color"
            useColorAsDefault
          />
          <Scrolling mode="virtual" />
        </Scheduler>
      )}
    </div>
  );
}

ResidencesScheduler.propTypes = {
  dataSource: PropTypes.array.isRequired,
  arrive_date: PropTypes.string.isRequired,
  departure_date: PropTypes.string.isRequired,
};
