/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';
import List from 'devextreme-react/list';
import { Button } from 'devextreme-react';
import DeletePopup from '../../delete-popup/delete-popup';
import AddPopup from '../../add-popup/add-popup';
import { getAll, remove } from '../../../api';
import { Toast } from '../../../utils';
import MediaVideoFormPopup from '../../popupVideoMedia';

function VideoInfo({ id, link_fr, caption_fr, caption_en, link_en, residence_id, showModal, showUpdateModal }) {
  const match = link_fr.indexOf('v=');
  const videoID = match > 0 ? link_fr?.substring(match + 2) : '';
  return (
    <div className="row">
      <iframe
        title={caption_fr}
        width="200px"
        height="150px"
        src={`https://www.youtube.com/embed/${videoID}?autoplay=0&mute=1`}
      />

      <div className="price">{caption_fr}</div>
      <div>
        <Button
          icon="edit"
          hint="Edit"
          onClick={() => showUpdateModal({ id, link_fr, caption_fr, caption_en, link_en, residence_id })}
        />
        <Button icon="trash" hint="Supprimer" onClick={() => showModal({ id, link_fr })} />
      </div>
    </div>
  );
}

/* -------------------------------------------------------------------------- */
/*                                RENDER HELPER                               */
/* -------------------------------------------------------------------------- */
export default function PricesList({ t, childFunc, dataList, idResidance }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [dataImages, setData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupAddVisible, setPopupAddVisible] = useState(false);
  const [selectedVideo, setselectedVideo] = useState({});
  const ref = useRef(null);

  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const showModal = (value) => {
    setPopupVisible(true);
    setselectedVideo(value);
  };
  const showAddModal = () => {
    setselectedVideo({});
    setPopupAddVisible(true);
  };

  const hideModal = () => {
    setPopupVisible(false);
    setselectedVideo({});
    getAll(`residence_videos/${idResidance}`).then(({ data }) => {
      setData(data.data);
    });
  };
  const hideAddModal = (value) => {
    setPopupAddVisible(false);
    setselectedVideo({});
    if (value && value === 'update') {
      getAll(`residence_videos/${idResidance}`).then(({ data }) => {
        setData(data.data);
      });
    }
  };

  const showUpdateModal = (value) => {
    setPopupAddVisible(true);
    setselectedVideo(value);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  const handleDelete = () => {
    remove(`residence_videos/${idResidance}`, selectedVideo.id).then((response) => {
      hideModal();
      response.status === 204 && Toast(t('common.successDelete', { title: 'La vidéo' }));
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    childFunc.current = showAddModal;
  }, []);

  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="dx-card responsive-paddings-widget fullWidth">
      <div className="widget_item_title">
        <i className="fa fa-video-camera" />
        {t('residance.videoGallery')}
      </div>
      <div className="titleList">
        <p className="titleListGrid">{t('residance.video').toUpperCase()}</p>
        <p className="titleListGrid">{t('residance.legend').toUpperCase()}</p>
        <div />
      </div>
      <List
        ref={ref}
        keyExpr="id"
        dataSource={dataImages ?? []}
        height="100%"
        focusStateEnabled={false}
        itemRender={(props) => VideoInfo({ ...props, showModal, showUpdateModal })}
        repaintChangesOnly
        bounceEnabled={false}
        noDataText={t('errors.noData')}
      />
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={t('residance.deleteVideoTitlePopup')}
        msg={t('residance.deleteVideoMsg')}
        deleteActivity={() => {
          handleDelete();
        }}
        src={selectedVideo.image}
      />
      <AddPopup
        popupVisible={popupAddVisible}
        hideModal={hideAddModal}
        title={Object.keys(selectedVideo).length === 0 ? t('residance.addVideo') : t('residance.updateVideo')}
        width="60%"
        component={
          <MediaVideoFormPopup
            hideAddModal={hideAddModal}
            t={t}
            selectedVideo={selectedVideo}
            idResidance={idResidance}
          />
        }
      />
    </div>
  );
}

PricesList.propTypes = {
  t: PropTypes.func.isRequired,
  childFunc: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
  dataList: PropTypes.object.isRequired,
  idResidance: PropTypes.string.isRequired,
};

VideoInfo.propTypes = {
  showModal: PropTypes.func.isRequired,
  link_fr: PropTypes.string.isRequired,
  caption_fr: PropTypes.string.isRequired,
  caption_en: PropTypes.string.isRequired,
  link_en: PropTypes.string.isRequired,
  residence_id: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  showUpdateModal: PropTypes.func.isRequired,
};
