import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useTranslation } from 'react-i18next';
import { create, get, update } from '../../../api';
import { textEditorOptions } from '../../../utils/editorOptions';
import { Toast } from '../../../utils';

export default function UnitePopupForm({ hideModal, setUnitedata, residenceId, uniteId }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const isAddMode = !uniteId;
  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return isAddMode ? addUnite(items) : updateUnite(items);
    },
    [items]
  );

  const addUnite = (values) => {
    create(`residence_units/${residenceId}`, values)
      .then((res) => {
        setLoading(false);
        res.data.data && setUnitedata(res.data.data);
        res.data && hideModal();
        res.data && Toast(t('units.add_unit_msg'));
        res.data && setItems({});
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateUnite = (values) => {
    update(`residence_units/${residenceId}`, uniteId, values)
      .then((res) => {
        setLoading(false);
        res.data.data && setUnitedata(res.data.data);
        res.data && hideModal();
        res.data && Toast(t('units.update_unit_msg'));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isAddMode) {
      get(`residence_units/${residenceId}`, uniteId).then((response) => {
        const { data } = response.data;
        setItems(data);
      });
    }
  }, [uniteId]);

  return (
    <div className="content-block">
      <div className="dx-card responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form formData={items} disabled={loading}>
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              <Tab title={t('common.informations')}>
                <SimpleItem dataField="code" editorType="dxTextBox" editorOptions={textEditorOptions}>
                  <RequiredRule message={t('units.required_code')} />
                  <Label text={t('units.code_label')} visible={false} />
                </SimpleItem>
              </Tab>
            </TabbedItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={() => hideModal()}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

UnitePopupForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
  setUnitedata: PropTypes.func.isRequired,
  residenceId: PropTypes.number.isRequired,
  uniteId: PropTypes.number,
};

UnitePopupForm.defaultProps = {
  uniteId: null,
};
