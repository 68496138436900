import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';
import './index.scss';
import { getAll } from '../../../api';
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function WidgetPromtions({ t, residenceId }) {
  const [promotionList, setPromotionList] = useState([]);

  useEffect(() => {
    getAll(`discounts/residence/current/${residenceId}`).then(({ data }) => {
      const { data: dataPromotion, ...rest } = data;
      setPromotionList(dataPromotion);
    });
  }, [residenceId]);

  return (
    <div className="dx-card responsive-paddings-widget widget">
      <div className="widget_item_title promo_title_widget">
        <i className="fa fa-trophy" />
        {t('residance.promotions')}
      </div>
      <div className="widget_promotions">
        {promotionList.map((e) => {
          return (
            <div className="propmo_item">
              <h5 className="titlePromo">{e.title_fr}</h5>
              <div className="propmo_content">
                <div className="imagePromo_container">
                  <img className="imagePromo" alt="probleme de connection" src={e.icon} />
                </div>
                <div className="textPromo" dangerouslySetInnerHTML={{ __html: e.description_fr }} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

WidgetPromtions.propTypes = {
  t: PropTypes.func.isRequired,
  residenceId: PropTypes.number.isRequired,
};
