import React, { useCallback, useEffect, useState, useRef } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import Button from 'devextreme-react/button';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useTranslation } from 'react-i18next';
import EditorInput from '../../editor-input/editor-input';
import { create, getAll } from '../../../api';
import {
  textEditorOptions,
  selectEditorOptions,
  ImageEditorOptions,
  latEditorOptions,
  longEditorOptions,
  AutoCompleteEditorOptions,
  AutoCompleteSelectEditorOptionsWithAddBtn,
  tagEditorOptions,
} from '../../../utils/editorOptions';
import './residence-create-form.scss';
import AddPopup from '../../add-popup/add-popup';
import { Toast } from '../../../utils';
import MemberFormPopup from '../member-form-popup/member-form-popup';
import useGetMergTags from '../../../hooks/useGetTags';

export default function ResidenceCreateForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [valueContentFR, setValueContentFR] = useState('');
  const [valueContentEN, setValueContentEN] = useState('');
  const [villedata, setVilledata] = useState([]);
  const [categorydata, setCategorydata] = useState([]);
  const [unitiesdata, setUnitiesdata] = useState([]);
  const [Membersdata, setMembersdata] = useState([]);
  const [items, setItems] = useState({});
  const [memberValue, setMemberValue] = useState('');
  const dataTags = useGetMergTags();

  const { suggestions, setValue } = usePlacesAutocomplete({
    cacheKey: 'upa-country-restricted',
    requestOptions: {
      componentRestrictions: { country: 'ca' },
    },
  });
  const refFormResidence = useRef('ResidenceForm');

  const showModal = () => {
    setPopupVisible(true);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (valueContentFR === '' || valueContentEN === '') {
        return notify(t('errors.required_field_fr_en'), 'error', 4000);
      }
      const { main_photo, units, ...rest } = items;
      const values = {
        ...rest,
        introduction: JSON.stringify([
          {
            type: 'introduction',
            language: 'fr',
            value: valueContentFR,
          },
          {
            type: 'introduction',
            language: 'en',
            value: valueContentEN,
          },
        ]),
        units: JSON.stringify(units),
        main_photo: main_photo[0],
      };
      const formData = new FormData();
      Object.keys(values).map((key) => formData.append(key, values[key]));

      return addResidence(formData);
    },
    [items, valueContentEN, valueContentFR, villedata, categorydata, isDraft, unitiesdata]
  );
  const addResidence = (values) => {
    create('residences', values, { 'Content-Type': 'multipart/form-data' })
      .then((res) => {
        setLoading(false);
        res.data && Toast(t('common.successAdd', { title: 'La résidence' }));
        return (
          res.data && (isDraft ? history.push('/residences') : history.push(`/residence/modifier/${res.data.data.id}`))
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAll('cities').then((response) => {
      setVilledata(response.data.data);
    });
    getAll('categories').then((response) => {
      setCategorydata(response.data.data);
    });
    getAll('units').then((response) => {
      setUnitiesdata(response.data.data);
    });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (memberValue?.length >= 3) {
        getAll(`search/user/?filed=full_name&value=${memberValue}`).then((response) => {
          setMembersdata(response.data.data);
        });
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [memberValue]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Résidences"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/residences')}
      />
      <h2 className="content-block">{t('residance.add_residence')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form ref={refFormResidence} formData={items} labelLocation="left" disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Général">
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message="Le nom de la résidence est requis" />
                      <Label text="Nom de la résidence" visible={false} />
                    </SimpleItem>
                    <SimpleItem dataField="official_name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message="Nom officiel (code de résidence) est requis" />
                      <Label text="Nom officiel (code de résidence)" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" caption="Photo principale">
                    <SimpleItem dataField="main_photo" editorType="dxFileUploader" editorOptions={ImageEditorOptions}>
                      <RequiredRule message="la photo principale est requise" />
                      <Label text="Photo principale" visible={false} />
                    </SimpleItem>
                  </GroupItem>

                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="user_id"
                      editorType="dxSelectBox"
                      editorOptions={AutoCompleteSelectEditorOptionsWithAddBtn(Membersdata, setMemberValue, showModal)}
                    >
                      <RequiredRule message="Le propriétaire est requis" />
                      <Label text="Propriétaire" visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="city_id"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(villedata)}
                    >
                      <RequiredRule message="La ville est requise" />
                      <Label text="Ville" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="address"
                      editorType="dxAutocomplete"
                      editorOptions={AutoCompleteEditorOptions(setValue, suggestions, refFormResidence)}
                    >
                      <RequiredRule message="L'adresse' est requise" />
                      <Label text="Adresse" visible={false} />
                    </SimpleItem>
                    <SimpleItem dataField="postal_code" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message="Le code postal est requis" />
                      <Label text="Code postal" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem dataField="latitude" editorType="dxNumberBox" editorOptions={latEditorOptions}>
                      <RequiredRule message="Latitude est requis" />
                      <Label text="Latitude" visible={false} />
                    </SimpleItem>
                    <SimpleItem dataField="longitude" editorType="dxNumberBox" editorOptions={longEditorOptions}>
                      <RequiredRule message="Longitude est requis" />
                      <Label text="Longitude" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="units"
                      cssClass="tag-box-input"
                      editorType="dxTagBox"
                      editorOptions={tagEditorOptions(unitiesdata)}
                    >
                      <RequiredRule message="Le Type d'unités est requis" />
                      <Label text="Type d'unités" visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="category_id"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(categorydata)}
                    >
                      <RequiredRule message="La Catégorie est requise" />
                      <Label text="Catégorie" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <GroupItem>
                      <div className="dx-field-label">Introduction (Fr)</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput
                          dataSource={dataTags}
                          valueContent={valueContentFR}
                          valueChanged={setValueContentFR}
                        />
                      </div>
                    </GroupItem>
                    <GroupItem>
                      <div className="dx-field-label">Introduction (En)</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput
                          dataSource={dataTags}
                          valueContent={valueContentEN}
                          valueChanged={setValueContentEN}
                        />
                      </div>
                    </GroupItem>
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={3}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior onClick={() => setIsDraft(true)}>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.draft_save')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior onClick={() => setIsDraft(false)}>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_continue')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/residences')}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
      <AddPopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={t('members.add_member')}
        component={
          <MemberFormPopup
            hideModal={() => {
              setPopupVisible(false);
            }}
            residence
          />
        }
        width="60%"
        height="90%"
      />
    </>
  );
}
