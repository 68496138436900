import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Form, {
  SimpleItem,
  Label,
  RequiredRule,
  GroupItem,
  ButtonItem,
  ButtonOptions,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form';
import { Button, LoadIndicator } from 'devextreme-react';
import { Tab } from 'devextreme-react/diagram';
import moment from 'moment';
import { Toast } from '../../../utils';
import { create, getAll, update } from '../../../api';
import {
  NumberDecimalEditorOptions,
  NumberIntEditorOptions,
  tagEditorOptions,
  textEditorOptions,
  selectEditorOptions,
} from '../../../utils/editorOptions';
import { checkBoxOptions } from '../residence-form/input-options';
import CustomDatesRender from '../../widgets/addPrice/helper';
import AddPopup from '../../add-popup/add-popup';
import './specialPeriod.scss';
import SelectMultipleDates from '../select-multiple-dates';

export default function AddSpecialPeriod({ hideAddModal, t, isTwin, idPrice, initialVal, handleAdd, handleUpdate }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({ periods: [] });
  const [popupVisible, setPopupVisible] = useState(false);
  const [listCustomDate, setListCustomDates] = useState([]);
  const [dataTwins, setDataTwins] = useState([]);
  const [periodesList, setPeriodesList] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { id } = useParams();
  const formRef = useRef('formRefAddSpecialPeriod');

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { not_show_dates_tariff, not_show_tariff, periods, twin_id, notes_fr, notes_en, ...rest } = items;
      let values = {
        ...rest,
        not_show_tariff: not_show_tariff ? 1 : 0,
        not_show_dates_tariff: not_show_dates_tariff ? 1 : 0,
        ranges: JSON.stringify(listCustomDate),
        periods: JSON.stringify(periods),
        type: 'special_holiday',
      };
      if (twin_id) {
        values = { ...values, twin_id };
      }
      const dataToSend = Object.fromEntries(
        Object.entries(values)
          .filter(([_, v]) => v != null)
          .filter(([_, v]) => v !== '')
      );
      if (!idPrice) {
        create(`tariffs/${id}`, dataToSend).then((res) => {
          setLoading(false);
          if (res.status === 201) {
            hideModal();
            handleAdd(res.data.data);
            Toast(t('common.successAddM', { title: 'Le tarif période spéciale' }));
          }
        });
      } else {
        update(`tariffs`, `${id}/${idPrice}`, dataToSend).then((res) => {
          setLoading(false);
          if (res.status === 200) {
            hideModal();
            handleUpdate(res.data.data);
            Toast(t('common.successUpdateM', { title: 'Le tarif période spéciale' }));
          }
        });
      }
    },
    [items, listCustomDate]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
    forceUpdate();
  };

  const showModal = () => {
    setPopupVisible(true);
  };

  const resetForm = () => {
    formRef.current.instance.resetValues();
    setItems({ min_nbr_nights: 2, max_nbr_nights: 7 });
    setListCustomDates([]);
  };

  const hideModal = () => {
    resetForm();
    hideAddModal(false);
  };

  const hideDateModal = () => {
    setPopupVisible(false);
  };

  const onDeleteDate = (selectedDate) => {
    const array = listCustomDate;
    const filtredArray = array.filter(
      (el) => !(el.start_date === selectedDate.start_date && el.end_date === selectedDate.end_date)
    );
    setListCustomDates(filtredArray);
  };

  const onHandleCustomDate = (date) => {
    setListCustomDates(date);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEFFECT                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    setItems({});
    setListCustomDates([]);
    if (idPrice) {
      getAll(`tariffs/${idPrice}`).then((res) => {
        setListCustomDates(res.data.data.ranges);
        const { twin, ...rest } = res.data.data;
        const values = { twin_id: twin?.id, ...rest };
        setItems(values);
      });
    } else {
      setItems({ min_nbr_nights: 2, max_nbr_nights: 7 });
    }
    if (initialVal) {
      resetForm();
    }
  }, [idPrice, initialVal]);

  useEffect(() => {
    getAll('periods').then((response) => {
      setPeriodesList(response.data.data);
    });
    if (JSON.parse(isTwin)) {
      getAll(`twins/${id}`).then((res) => {
        setDataTwins(res.data.data);
      });
    }
  }, [JSON.parse(isTwin)]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block scrolled">
      <div className="responsive-paddings">
        <form className="scrolled" onSubmit={onSubmit}>
          <Form
            ref={formRef}
            formData={items}
            disabled={false}
            onFieldDataChanged={formFieldDataChanged}
            showValidationSummary
          >
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              {/** pricesAllTimes */}
              <Tab title={t('price.specialPeriodsPrices')}>
                {isTwin && (
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="twin_id"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(dataTwins)}
                    >
                      <Label text={t('jumele.jumeles')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                )}
                <GroupItem cssClass="group-item">
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem dataField="title_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message={t('errors.required_titleFr')} />
                      <Label text={t('price.TitleFr')} visible={false} />
                    </SimpleItem>
                    <SimpleItem dataField="title_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message={t('errors.required_titleEn')} />
                      <Label text={t('price.TitleEn')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="periods"
                      cssClass="tag-box-input"
                      editorType="dxTagBox"
                      editorOptions={tagEditorOptions(periodesList, 'label', true)}
                    >
                      <Label text={t('price.periods')} visible={false} />
                    </SimpleItem>
                    <GroupItem>
                      <div className="customDates">
                        <Button text="Ajouter une date" hint="Add" icon="plus" onClick={showModal} />
                        {listCustomDate.map((el, index) => (
                          <CustomDatesRender key={index?.toString()} dates={el} onDeleteDate={onDeleteDate} />
                        ))}
                      </div>
                    </GroupItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={3}>
                    <SimpleItem
                      dataField="min_nbr_nights"
                      editorType="dxNumberBox"
                      editorOptions={NumberIntEditorOptions({ max: items?.max_nbr_nights })}
                    >
                      <RequiredRule message={t('errors.required_minReservation')} />
                      <Label text={t('price.minReservation')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="max_nbr_nights"
                      editorType="dxNumberBox"
                      editorOptions={NumberIntEditorOptions({ min: items?.max_nbr_nights })}
                    >
                      <RequiredRule message={t('errors.required_maxReservation')} />
                      <Label text={t('price.maxReservation')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="price_basic_night"
                      editorType="dxNumberBox"
                      editorOptions={NumberDecimalEditorOptions}
                    >
                      <RequiredRule message={t('errors.required_priceByNigth')} />
                      <Label text={t('price.priceByNigthLabel')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="price_fds_night"
                      editorType="dxNumberBox"
                      editorOptions={NumberDecimalEditorOptions}
                    >
                      <Label text={t('price.priceFdsByNigthLabel')} visible={false} />
                      <RequiredRule message={t('errors.required_priceFdsByNigth')} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="price_week_more"
                      editorType="dxNumberBox"
                      editorOptions={NumberDecimalEditorOptions}
                    >
                      <Label text={t('price.weekPriceLabel')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="price_month_more"
                      editorType="dxNumberBox"
                      editorOptions={NumberDecimalEditorOptions}
                    >
                      <Label text={t('price.mounthPriceLabel')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="price_night_additional_person"
                      editorType="dxNumberBox"
                      editorOptions={NumberDecimalEditorOptions}
                    >
                      <Label text={t('price.priceExtraPersonLabel')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="price_holiday"
                      editorType="dxNumberBox"
                      editorOptions={NumberDecimalEditorOptions}
                    >
                      <Label text={t('price.priceHolidayLabel')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="price_animal"
                      editorType="dxNumberBox"
                      editorOptions={NumberDecimalEditorOptions}
                    >
                      <Label text={t('price.priceAnimalLabel')} visible={false} />
                    </SimpleItem>
                    <SimpleItem dataField="notes_fr" editorType="dxTextArea" editorOptions={textEditorOptions}>
                      <Label visible={false} />
                    </SimpleItem>
                    <SimpleItem dataField="notes_en" editorType="dxTextArea" editorOptions={textEditorOptions}>
                      <Label visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="not_show_tariff"
                      editorType="dxCheckBox"
                      editorOptions={checkBoxOptions(t('price.dontShowPrice'))}
                    >
                      <Label visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="not_show_dates_tariff"
                      editorType="dxCheckBox"
                      editorOptions={checkBoxOptions(t('price.dontShowDates'))}
                    >
                      <Label visible={false} />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
              </Tab>
            </TabbedItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.saveBtn')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={hideModal}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
        <AddPopup
          popupVisible={popupVisible}
          hideModal={hideDateModal}
          title={t('discount.add_special_date')}
          component={
            <SelectMultipleDates
              listCustomDate={listCustomDate}
              onHandleCustomDate={onHandleCustomDate}
              hideModal={hideDateModal}
              t={t}
            />
          }
          width="60%"
        />
      </div>
    </div>
  );
}

AddSpecialPeriod.propTypes = {
  t: PropTypes.func.isRequired,
  hideAddModal: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isTwin: PropTypes.bool.isRequired,
  idPrice: PropTypes.number,
  initialVal: PropTypes.bool,
};
AddSpecialPeriod.defaultProps = {
  idPrice: null,
  initialVal: false,
};
