import React, { useCallback, useEffect, useState } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { create, get, getAll, update } from '../../../api';
import { NumberEditorOptions, selectEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { ModePaimentdata, Toast } from '../../../utils';
import { checkBoxOptions } from '../residence-form/input-options';

export default function FactureForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: reservationId, itemId: factureId } = useParams();
  const [loading, setLoading] = useState(false);
  const isAddMode = !factureId;
  const [items, setItems] = useState({});
  const [factureTypes, setFactureTypes] = useState([]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return isAddMode ? addFacture(items) : updateFacture(items);
    },
    [items]
  );

  const addFacture = (values) => {
    create(`factures/${reservationId}`, values)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Toast(t('common.successAdd', { title: 'La facture' }));
          history.push(`/reservation/factures/${reservationId}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateFacture = (values) => {
    update(`factures/${reservationId}`, factureId, values)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Toast(t('common.successUpdate', { title: 'La facture' }));
          history.push(`/reservation/factures/details/${reservationId}/${factureId}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAll('invoices').then((response) => {
      setFactureTypes(response.data.data);
    });

    if (!isAddMode) {
      // get(`factures/${reservationId}`, factureId).then((response) => {
      //   const { data } = response.data;
      //   setItems(data);
      // });
    }
  }, [factureId]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('factures.go_back_factures')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/reservation/factures/${reservationId}`)}
      />
      <h2 className="content-block">
        {isAddMode ? t('factures.additional_invoices_add') : t('factures.additional_invoices_edit')}
      </h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Détails">
                  <SimpleItem dataField="number" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="le numéro de facture est requis" />
                    <Label text="Numéro de facture" visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="payment_method"
                    editorType="dxSelectBox"
                    editorOptions={selectEditorOptions(ModePaimentdata)}
                  >
                    <RequiredRule message="le mode de paiement est requis" />
                    <Label text="Mode de paiement" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="service_fee" editorType="dxNumberBox" editorOptions={NumberEditorOptions}>
                    <RequiredRule message="le frais de service est requis" />
                    <Label text="Frais de service $" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="credit_fee" editorType="dxNumberBox" editorOptions={NumberEditorOptions}>
                    <RequiredRule message="le frais carte de crédit est requis" />
                    <Label text="Frais carte de crédit $" visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="facture_type"
                    editorType="dxSelectBox"
                    editorOptions={selectEditorOptions(factureTypes)}
                  >
                    <RequiredRule message="le type de facture additionnelle est requis" />
                    <Label text="Type de facture additionnelle" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="taxable" editorType="dxCheckBox" editorOptions={checkBoxOptions('Taxable')}>
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="add_taxe"
                    editorType="dxCheckBox"
                    editorOptions={checkBoxOptions("Ajouter la taxe d'hébergement")}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() =>
                      isAddMode
                        ? history.push(`/reservation/factures/${reservationId}`)
                        : history.push(`/reservation/factures/details/${reservationId}/${factureId}`)
                    }
                  >
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
