/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, LoadIndicator } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  SimpleItem,
  TabbedItem,
  TabPanelOptions,
  GroupItem,
  RequiredRule,
} from 'devextreme-react/form';
import { Tab } from 'devextreme-react/diagram';
import { Label } from 'devextreme-react/bar-gauge';
import { frCA } from 'date-fns/locale';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { addDays, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  NumberEditorOptions,
  selectEditorOptions,
  textAreaEditorOptions,
  textEditorOptions,
  startDateBoxOptions,
} from '../../../utils/editorOptions';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import EditorInput from '../../editor-input/editor-input';
import useGetMergTags from '../../../hooks/useGetTags';
import { checkBoxOptions } from '../residence-form/input-options';
import { getDateTimeZone, getReservationDates, ModePaimentdata, occupeProprioData, Toast } from '../../../utils';
import { get, getAll, update } from '../../../api';
import './styles.scss';
/* -------------------------------------------------------------------------- */
/*                                RENDER HELPER                               */
/* -------------------------------------------------------------------------- */

export default function UpdateFormReservation() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */

  const [items, setItems] = useState({ client: [], child: [] });
  const [loading, setLoading] = useState(false);
  const [valueContent, setValueContent] = useState('');
  const [nbrBabies, setNbrBabies] = useState([]);
  const [units, setUnits] = useState([]);
  const [isAnimal, setIsAnimal] = useState(false);
  const [isOtherProvenance, setIsOtherProvenance] = useState(false);
  const [isAnimalList, setIsAnimalList] = useState({
    dog: false,
    cat: false,
    other: false,
  });

  const [reservationPeriods, setReservationPeriods] = useState([]);
  const [statePeriode, setStatePeriode] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const dataTags = useGetMergTags();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */

  const addAgeButtonOptions = {
    icon: 'add',
    text: 'Ajouter un enfant',
    onClick: () => {
      setItems((state) => {
        const { child, ...rest } = state;
        return { ...rest, child: [...child, null] };
      });
      setNbrBabies([...nbrBabies, '']);
    },
  };
  const generateAgesBabiesOptions = (index) => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      step: 0,
      min: 0,
      buttons: [
        {
          name: 'trash',
          location: 'after',
          options: {
            stylingMode: 'text',
            icon: 'trash',
            onClick: () => {
              setItems((state) => {
                const { child, ...rest } = state;
                child.splice(index, 1);
                return { ...rest, child };
              });
              setNbrBabies([...nbrBabies.filter((_, i) => i !== index)]);
            },
          },
        },
      ],
    };
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { reservation_number, ...rest } = items;
      const values = dataBeforeSubmit({ ...rest });
      return update('reservations', id, values)
        .then((res) => {
          setLoading(false);
          res.data && Toast(t('common.successUpdate', { title: 'La réservation' }));
          res.data && history.goBack();
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [items, valueContent, statePeriode]
  );

  const formFieldDataChanged = (e) => {
    const dataForm = e.component.option('formData');
    setItems({ ...items, ...dataForm });
    setIsAnimal(dataForm.animals);
    setIsOtherProvenance(dataForm.origins === 'other_provenance');
    setIsAnimalList({
      dog: dataForm.dog,
      cat: dataForm.cat,
      other: dataForm.other,
    });
  };

  const dataBeforeSubmit = (data) => {
    const { dog, cat, other, dogs, cats, others, client, smoker, animals, origins, ...rest } = data;
    const animal = [];
    if (dog) {
      animal.push({
        type: 'dog',
        ...dogs,
      });
    }
    if (cat) {
      animal.push({
        type: 'cat',
        ...cats,
      });
    }
    if (other) {
      animal.push({
        type: 'other',
        ...others,
      });
    }
    const values = {
      ...rest,
      client_id: client.id,
      note: valueContent,
      occupied_by_owner: origins === 'occupied_by_owner' ? 1 : 0,
      occupied_by_friend_or_family: origins === 'occupied_by_friend_or_family' ? 1 : 0,
      renovations: origins === 'renovations' ? 1 : 0,
      animal,
      agency: origins === 'agency' ? 1 : 0,
      airbnb: origins === 'airbnb' ? 1 : 0,
      lodgify: origins === 'lodgify' ? 1 : 0,
      quebec_location_de_chalets: origins === 'quebec_location_de_chalets' ? 1 : 0,
      rent_by_owner: origins === 'rent_by_owner' ? 1 : 0,
      reservit: origins === 'reservit' ? 1 : 0,
      smoker: smoker ? 1 : 0,
      vrbo: origins === 'vrbo' ? 1 : 0,
      start_at: moment(statePeriode[0].startDate).format('YYYY-MM-DD'),
      end_at: moment(statePeriode[0].endDate).format('YYYY-MM-DD'),
    };
    delete values.cleaning_fee;
    delete values.service_cost;
    return values;
  };

  const checkReservationDates = async (date, unitUrl = '') => {
    const pickerDate = date;
    const res = await getAll(
      `residences/${items?.residence_id}/reservation-date?${unitUrl}start_at=${moment(subDays(pickerDate, 30)).format(
        'YYYY-MM-DD'
      )}&end_at=${moment(addDays(pickerDate, 60)).format('YYYY-MM-DD')}`
    );
    if (res.data && res.data.day_delay_between_reservations === 0) {
      /* eslint-disable no-param-reassign */
      res.data?.data.forEach((item) => {
        const new_date = moment(item.end_at).subtract(1, 'd').format('YYYY-MM-DD');
        item.end_at = new_date;
      });
    }
    const reservationsDates = res.data?.data?.filter((el) => {
      return (
        moment(getDateTimeZone(new Date(el.start_at))).format('YYYY-MM-DD') !==
        moment(new Date(statePeriode[0]?.startDate)).format('YYYY-MM-DD')
      );
    });

    const dataPeriodsFormat = getReservationDates(reservationsDates ?? []);
    setReservationPeriods(dataPeriodsFormat);
  };

  const showDateChange = (DateRange, Calendar) => {
    let unitUrl = '';
    if (items.unit_id) {
      unitUrl = `unit_id=${items.unit_id}&`;
    }
    checkReservationDates(DateRange, unitUrl);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (items?.residence_id) {
      getAll(`residence_units/${items.residence_id}`).then((response) => {
        setUnits(response.data.data);
      });
    }
  }, [items?.residence_id]);

  useEffect(() => {
    get('reservations', id).then(({ data }) => {
      const { start_at, end_at, child, note, animals, origin, ...rest } = data.data;
      const childList = child?.map((el) => el.age);
      if (childList?.length > 1) {
        setNbrBabies([...childList]);
      }
      const periode = {
        startDate: getDateTimeZone(new Date(start_at)),
        endDate: getDateTimeZone(new Date(end_at)),
        key: 'selection',
      };
      setStatePeriode([periode]);
      const entries = Object.entries(origin);
      const defaultOrigin = entries.filter((el) => el[1] === 1);
      const dogsIndex = animals?.findIndex((el) => el.type === 'dog');
      const catsIndex = animals?.findIndex((el) => el.type === 'cat');
      const otherIndex = animals?.findIndex((el) => el.type === 'other');

      const animalList = {
        dog: dogsIndex !== -1,
        cat: catsIndex !== -1,
        other: otherIndex !== -1,
      };
      const dog = {
        breed: animals[dogsIndex]?.breed ?? '',
        number: animals[dogsIndex]?.number ?? '',
        weight: animals[dogsIndex]?.weight ?? '',
        age: animals[dogsIndex]?.age ?? '',
      };
      const cat = {
        breed: animals[catsIndex]?.breed ?? '',
        number: animals[catsIndex]?.number ?? '',
        weight: animals[catsIndex]?.weight ?? '',
        age: animals[catsIndex]?.age ?? '',
      };
      const others = {
        breed: animals[otherIndex]?.breed ?? '',
        number: animals[otherIndex]?.number ?? '',
        weight: animals[otherIndex]?.weight ?? '',
        age: animals[otherIndex]?.age ?? '',
      };
      setIsAnimal(animals?.length !== 0);

      setIsAnimalList(animalList);
      setValueContent(note ?? '');
      setItems({
        ...rest,
        child: childList,
        origins: defaultOrigin[0][0] ?? '',
        dogs: dog,
        cats: cat,
        others,
        animals: animals?.length !== 0,
        other: otherIndex !== -1,
        dog: dogsIndex !== -1,
        cat: catsIndex !== -1,
      });
    });
  }, []);

  useEffect(() => {
    if (items?.unit_id) {
      const unitUrl = `unit_id=${items.unit_id}&`;
      checkReservationDates(new Date(), unitUrl);
    } else if (items?.residence_id) {
      checkReservationDates(new Date());
    }
  }, [items?.unit_id, items?.residence_id]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('reservation.go_back_reservations')}
        type="default"
        stylingMode="text"
        onClick={() => history.push('/reservations')}
      />
      <h2 className="content-block">{t('reservation.reservation_edit')}</h2>
      <div className="content-block dx-card responsive-paddings">
        {items.reservations_notes && (
          <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: items.reservations_notes }} />
        )}
        <div>
          <form onSubmit={onSubmit}>
            <Form formData={items} showValidationSummary onFieldDataChanged={formFieldDataChanged}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title={t('residance.arrival_departure')}>
                  {!!items.multi_unit && (
                    <SimpleItem
                      dataField="unit_id"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(units, 'code')}
                    >
                      <RequiredRule message="L'unité est requise" />
                      <Label text={t('units.units')} visible={false} />
                    </SimpleItem>
                  )}
                  <GroupItem cssClass="group-item center" className="center" colCount={1}>
                    <DateRangePicker
                      onChange={(item) => {
                        setStatePeriode([item.selection]);
                      }}
                      onShownDateChange={showDateChange}
                      showSelectionPreview={false}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={statePeriode}
                      direction="horizontal"
                      locale={frCA}
                      date={statePeriode?.startDate}
                      staticRanges={[]}
                      inputRanges={[]}
                      disabledDates={reservationPeriods}
                    />
                  </GroupItem>
                </Tab>
                <Tab title={t('residance.nbr_people')}>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <GroupItem cssClass="group-item">
                      <SimpleItem dataField="adult" editorType="dxNumberBox" editorOptions={NumberEditorOptions}>
                        <RequiredRule message="Le nombre d'adultes est requis" />
                        <Label text="Nombre d'adultes" visible={false} />
                      </SimpleItem>
                      <GroupItem caption="Enfants" name="enfants-container">
                        <GroupItem name="enfants">
                          {nbrBabies?.map((_, index) => (
                            <SimpleItem
                              key={`child${index}`}
                              dataField={`child[${index}]`}
                              editorType="dxNumberBox"
                              editorOptions={generateAgesBabiesOptions(index)}
                            >
                              <RequiredRule message={`L'age d'enfant ${index + 1} est requis`} />
                              <Label text={`Age d'enfant ${index + 1}`} visible={false} />
                            </SimpleItem>
                          ))}
                        </GroupItem>
                        <SimpleItem
                          itemType="button"
                          horizontalAlignment="left"
                          cssClass="add-button"
                          buttonOptions={addAgeButtonOptions}
                        />
                      </GroupItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item">
                      <SimpleItem
                        dataField="smoker"
                        editorType="dxCheckBox"
                        editorOptions={checkBoxOptions('Présence de fumeur')}
                      >
                        <Label visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        dataField="animals"
                        editorType="dxCheckBox"
                        editorOptions={checkBoxOptions("Présence d'animaux")}
                      >
                        <Label visible={false} />
                      </SimpleItem>
                      {isAnimal && (
                        <GroupItem cssClass="group-item">
                          <GroupItem cssClass="group-item" colCount={2}>
                            <SimpleItem
                              dataField="dog"
                              editorType="dxCheckBox"
                              editorOptions={checkBoxOptions('Chien')}
                            >
                              <Label visible={false} />
                            </SimpleItem>
                            {isAnimalList?.dog && (
                              <GroupItem cssClass="group-item">
                                <SimpleItem
                                  dataField="dogs.breed"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Râce" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="dogs.number"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Nombre" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="dogs.weight"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Poids approximatif" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="dogs.age"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Âge approximatif" visible={false} />
                                </SimpleItem>
                              </GroupItem>
                            )}
                          </GroupItem>
                          <GroupItem cssClass="group-item" colCount={2}>
                            <SimpleItem dataField="cat" editorType="dxCheckBox" editorOptions={checkBoxOptions('Chat')}>
                              <Label visible={false} />
                            </SimpleItem>
                            {isAnimalList?.cat && (
                              <GroupItem cssClass="group-item">
                                <SimpleItem
                                  dataField="cats.breed"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Râce" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="cats.number"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Nombre" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="cats.weight"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Poids approximatif" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="cats.age"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Âge approximatif" visible={false} />
                                </SimpleItem>
                              </GroupItem>
                            )}
                          </GroupItem>
                          <GroupItem cssClass="group-item" colCount={2}>
                            <SimpleItem
                              dataField="other"
                              editorType="dxCheckBox"
                              editorOptions={checkBoxOptions('Autre')}
                            >
                              <Label visible={false} />
                            </SimpleItem>
                            {isAnimalList?.other && (
                              <GroupItem cssClass="group-item">
                                <SimpleItem
                                  dataField="others.breed"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Râce" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="others.number"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Nombre" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="others.weight"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Poids approximatif" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="others.age"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Âge approximatif" visible={false} />
                                </SimpleItem>
                              </GroupItem>
                            )}
                          </GroupItem>
                        </GroupItem>
                      )}
                    </GroupItem>
                  </GroupItem>
                </Tab>

                <Tab title={t('residance.prix')}>
                  <GroupItem cssClass="group-item" colCount={1}>
                    <SimpleItem
                      dataField="payment_method"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(ModePaimentdata)}
                    >
                      <RequiredRule message="Le mode de paiement est requis" />
                      <Label text="Mode de paiement" visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="confirm_mails"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions([{ id: 'default', name: 'Défaut' }])}
                    >
                      <Label text="Courriel de confirmation" visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="price_pre_authorization'"
                      editorType="dxNumberBox"
                      editorOptions={NumberEditorOptions}
                    >
                      <Label text={t('reservation.amount_pre_authorization_label')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="notes_of_paymant"
                      editorType="dxTextArea"
                      editorOptions={textAreaEditorOptions}
                    >
                      <Label text={t('reservation.notes_of_paymant')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                </Tab>
                <Tab title={t('residance.proprio')}>
                  <GroupItem cssClass="group-item">
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="origins"
                        editorType="dxSelectBox"
                        editorOptions={selectEditorOptions(occupeProprioData)}
                      >
                        <RequiredRule message="Le choix du propriétaire est requis" />
                        <Label text="Choix du propriétaire" visible={false} />
                      </SimpleItem>
                      {isOtherProvenance && (
                        <SimpleItem dataField="other_origin" editorType="dxTextBox" editorOptions={textEditorOptions}>
                          <RequiredRule message="Le provenance est requis" />
                          <Label text="Provenance" visible={false} />
                        </SimpleItem>
                      )}
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title={t('residance.note')}>
                  <GroupItem>
                    <div className="dx-field-label">{t('reservation.supp_note')}</div>
                    <div className="dx-field-value editor_width">
                      <EditorInput dataSource={dataTags} valueContent={valueContent} valueChanged={setValueContent} />
                    </div>
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.saveBtn')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.goBack()}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
