import React from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';
import { useTranslation } from 'react-i18next';

export default function WidgetInvoice({ details }) {
  const { t } = useTranslation();
  const data = {
    price: `${details.price_ht} $`,
    housework: `${details.cleaning_fee} $`,
    th: `${details.lodging_cost} $`,
    service: `${details.service_cost} $`,
    tps: `${details.tps_cost} $`,
    tvq: `${details.tvq_cost} $`,
    sum: `${details.total_price} $`,
  };
  return (
    <div className="dx-card responsive-paddings-widget widget">
      <div className="widget_item_title">
        <i className="fa fa-tags" />
        {t('reservation.inovice')}
      </div>
      <div>
        {/* <h2 className="noMargin">{t('reservation.reservation_not_payed')}</h2> */}
        <table>
          {Object.keys(data).map((el) => {
            return (
              <tr>
                <td className="celltd">{t(`reservation.${el}`)}</td>
                <td className="celltd">{data[el]}</td>
              </tr>
            );
          })}
        </table>
        <span>{t('reservation.inovice_info')}</span>
      </div>
    </div>
  );
}

WidgetInvoice.propTypes = {
  details: PropTypes.object.isRequired,
};
