import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { create, get, update, updateWithMedia } from '../../api';
import { Toast } from '../../utils';

export default function useSubmitForm(
  resourceName,
  redirectionUrl,
  messageTitle,
  formatDataBeforeSubmit,
  formatDataForUpdate,
  headers,
  withMedia
) {
  const history = useHistory();
  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState({});
  const [icon, setIcon] = useState('');

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let formatData = { ...items };
      if (formatDataBeforeSubmit) {
        formatData = formatDataBeforeSubmit(items);
      }
      setLoading(true);
      return isAddMode ? add(formatData) : updateById(id, formatData);
    },
    [items]
  );

  const add = (values) => {
    create(resourceName, values, headers)
      .then((res) => {
        setLoading(false);
        res.data && history.push(`/${redirectionUrl}`);
        res.data && Toast(`${messageTitle} a été bien ajouté`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateById = (agenceId, values) => {
    // eslint-disable-next-line no-unused-expressions
    withMedia
      ? updateWithMedia(resourceName, agenceId, values, headers)
          .then((res) => {
            setLoading(false);
            res.data && history.push(`/${redirectionUrl}`);
            res.data && Toast(`${messageTitle} a été bien modifié`);
          })
          .catch(() => {
            setLoading(false);
          })
      : update(resourceName, agenceId, values, headers)
          .then((res) => {
            setLoading(false);
            res.data && history.push(`/${redirectionUrl}`);
            res.data && Toast(`${messageTitle} a été bien modifié`);
          })
          .catch(() => {
            setLoading(false);
          });
  };

  useEffect(() => {
    if (!isAddMode) {
      get(resourceName, id).then((response) => {
        if (formatDataForUpdate) {
          const formatData = formatDataForUpdate(response.data.data);
          setItems(formatData.items);
          setIcon(formatData.icon);
        } else {
          setItems(response.data.data);
        }
      });
    }
  }, [id]);

  return [items, loading, onSubmit, icon];
}
