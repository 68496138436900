/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel, RowDragging } from 'devextreme-react/data-grid';
import Hero from '../../../components/hero/hero';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { getAll, remove, create } from '../../../api';
import { getPermissionVisibility, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';
import PermissionComponent from '../../../permissions/permissionComponent';

export default function HeaderImages() {
    const history = useHistory();
    const { t } = useTranslation();
    const { auth } = useAuth();
    const PermissionsList = auth.permissions;

    const [popupVisible, setPopupVisible] = useState(false);
    const [customDataSource, setCustomDataSource] = useState([]);
    const [deleteID, setDeleteID] = useState('');
    const [deleteName, setDeleteName] = useState('');
    const ref = useRef(null);

    const showModal = (e) => {
        setPopupVisible(true);
        setDeleteID(e.row.data.id);
        setDeleteName(e.row.data.title);
    };

    const hideModal = () => {
        setPopupVisible(false);
    };

    const deletePhoto = (idPhoto) => {
        remove('header_pictures', idPhoto).then(() => {
            setCustomDataSource(customDataSource.filter((data) => data.id !== idPhoto));
            hideModal();
            Toast(t('headerImg.successDeletePage'));
        });
    };
    useEffect(() => {
        getAll('header_pictures').then((response) => {
            setCustomDataSource(response.data.data);
        });
    }, []);
    /* -------------------------------------------------------------------------- */
    /*                            DRAG AND DROP METHODS                           */
    /* -------------------------------------------------------------------------- */
    const onDragStart = (e) => {
        const tasks = customDataSource;
        e.itemData = tasks[e.fromIndex];
    };
    const onAdd = (e) => {
        setCustomDataSource((state) => [...state.slice(0, e.toIndex), e.itemData, ...state.slice(e.toIndex)]);
        handleUpdateOrder();
    };

    const onRemove = (e) => {
        setCustomDataSource((state) => [...state.slice(0, e.fromIndex), ...state.slice(e.fromIndex + 1)]);
    };

    const onReorder = (e) => {
        onRemove(e);
        onAdd(e);
    };
    const handleUpdateOrder = () => {
        const newOrderdList = ref.current?.props?.dataSource;
        const odredData = newOrderdList.map((el, index) => {
            return { id: el.id, order: index + 1 };
        });
        const formData = new FormData();
        formData.append('list', JSON.stringify(odredData));
        formData.append('type', 'headerPicture');
        create('/order', formData);
    };

    return (
        <>
            <PermissionComponent
                scope="createHeaderPicture"
                rightchildren={<Hero title="Entête" btnText={t('headerImg.entete_title')} url="/entetes/entete" />}
                nonrightchildren={<h2 className="content-block">Entête</h2>}
            />
            <DataGrid
                className="dx-card wide-card"
                dataSource={customDataSource}
                keyExpr="id"
                showBorders
                remoteOperations
                defaultFocusedRowIndex={0}
                columnAutoWidth
                columnHidingEnabled
                allowSorting
                ref={ref}
            >
                <RowDragging
                    allowReordering
                    keyExpr="id"
                    onDragStart={onDragStart}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onReorder={onReorder}
                    group="data"
                    data="customDataSource"
                    allowSorting
                />
                <LoadPanel enabled />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
                <Editing mode="row" useIcons allowSorting />

                <Column dataField="id" caption="ID" width={90} allowSorting />
                <Column dataField="title" caption="Nom" />
                <Column type="buttons" width={110}>
                    <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/entetes/entete/${e.row.data.id}`)} />
                    <Button
                        icon="trash"
                        hint="Supprimer"
                        onClick={showModal}
                    />
                </Column>
            </DataGrid>
            <DeletePopup
                popupVisible={popupVisible}
                hideModal={hideModal}
                title={"Suppression d'une image d'entête"}
                msg={`Êtes-vous sûr de vouloir supprimer cette image d'entête"${deleteName && !!deleteName ? deleteName : ''}"?`}
                deleteActivity={() => deletePhoto(deleteID)}
            />
        </>
    );
}

const allowedPageSizes = [5, 10, 20];
