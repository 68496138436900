import React, { useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'react-pagination-bar';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Hero from '../hero/hero';
import ResidencesListing from '../residences-listing/residences-listing';
import ResidencesScheduler from '../residences-scheduler/residences-scheduler';
import './index.scss';
import ResidenceSearchForm from '../form/residence-search-form/residence-search-form';
import ResidencesActions from '../residences-actions/residences-actions';
import { getAll } from '../../api';
import 'react-pagination-bar/dist/index.css';
import PermissionComponent from '../../permissions/permissionComponent';
import { useAuth } from '../../contexts/auth';
import { getPermissionVisibility, Toast } from '../../utils';

export default function ResidencesComponent({ isTrashed }) {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [isListing, setIsListing] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchObject, setSearchObject] = useState({});
  const { t } = useTranslation();
  const { auth } = useAuth();
  const PermissionsList = auth.permissions;

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  const getArrayListFromStrings = (string) => {
    let stringList = '';
    stringList = string
      ?.replace('[', '')
      ?.replace(']', '')
      ?.split(',')
      ?.map((e) => parseInt(e, 10));
    return string ? stringList : [];
  };

  const generateURLPath = (searchValues) => {
    const archivePram = isTrashed ? 'archive=1&' : '';
    let url = `?${archivePram}page=${currentPage}`;
    Object.keys(searchValues).forEach(function (key) {
      if (searchValues[key] === null || searchValues[key].length === 0) {
        url += '';
      } else if (typeof searchValues[key] === 'object') {
        url += `&${key}=[${searchValues[key]}]`;
      } else {
        url += `&${key}=${searchValues[key]}`;
      }
    });
    return url;
  };

  const exportCsvResidences = () => {
    getAll('residences/csv', { 'Content-Type': 'text/csv' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'residences.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      res.data && Toast(t('residance.export_res_msg'));
    });
  };

  useEffect(() => {
    const formValues = {
      name: queryParams.get('name'),
      users: getArrayListFromStrings(queryParams.get('users')),
      cities: getArrayListFromStrings(queryParams.get('cities')),
      max_nbr_people: queryParams.get('max_nbr_people') ? parseInt(queryParams.get('max_nbr_people'), 10) : null,
      nbr_rooms: queryParams.get('nbr_rooms') ? parseInt(queryParams.get('nbr_rooms'), 10) : null,
      status: queryParams.get('status') ? queryParams.get('status') : null,
      options: getArrayListFromStrings(queryParams.get('options')),
      categories: getArrayListFromStrings(queryParams.get('categories')),
      unit_types: getArrayListFromStrings(queryParams.get('unit_types')),
      arrive_date: queryParams.get('arrive_date'),
      departure_date: queryParams.get('departure_date'),
      multi_unit: +queryParams.get('multi_unit'),
    };

    const url = generateURLPath(formValues);

    getAll(`residences/${url}`).then((response) => {
      setPaginationMeta(response.data.meta);
      setCustomDataSource(response.data.data);
    });
    if (formValues.users.length > 0) {
      getAll(`search/user/?filed=id&value=${formValues.users}`).then((response) => {
        setSearchObject({ ...formValues, users: response.data.data });
      });
    } else {
      const { users, ...rest } = formValues;
      setSearchObject(rest);
    }
  }, [currentPage]);

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const handleSearchValues = (values) => {
    const url = generateURLPath(values);
    const archiveUrl = isTrashed ? '/corbeille' : '';

    history.push(`${archiveUrl}/residences/${url}`);

    getAll(`residences/${url}`).then((response) => {
      setPaginationMeta(response.data.meta);
      setCustomDataSource(response.data.data);
    });
    setSearchObject(values);
  };
  /* -------------------------------------------------------------------------- */
  /*                                   RANDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <PermissionComponent
        scope="createResidence"
        rightchildren={
          <Hero
            title={isTrashed ? 'Résidences - Corbeille' : 'Résidences'}
            btnText={t('residance.add_residence')}
            url="/residence/ajouter"
          />
        }
        nonrightchildren={<h2 className="content-block">{isTrashed ? 'Résidences - Corbeille' : 'Résidences'}</h2>}
      />
      <div className="btn_ajout">
        <div className="dx-field">
          <div className="dx-field-label" />
          <div className="dx-field-value content-block">
            <Button className="btn-icon" hint="Rechercher" icon="find" onClick={() => setIsSearch(!isSearch)} />
            <Button
              hint={isListing ? t('common.calendar') : t('common.list')}
              className="btn-icon"
              icon={isListing ? 'event' : 'detailslayout'}
              onClick={() => setIsListing(!isListing)}
            />
            <Button
              icon="download"
              className="btn-icon"
              type="normal"
              visible={getPermissionVisibility(PermissionsList, 'createResidence')}
              onClick={() => {
                exportCsvResidences();
              }}
            />
          </div>
        </div>
      </div>
      {isSearch && <ResidenceSearchForm handleSearchValues={handleSearchValues} formValues={searchObject} />}
      {isListing ? (
        <ResidencesListing customDataSource={customDataSource} />
      ) : (
        <ResidencesScheduler
          dataSource={customDataSource}
          arrive_date={searchObject?.arrive_date}
          departure_date={searchObject?.departure_date}
        />
      )}
      {paginationMeta?.total > 9 && (
        <div className="containerPginationBar">
          <Pagination
            initialPage={1}
            itemsPerPage={paginationMeta?.per_page ?? 1}
            onPageСhange={(pageNumber) => setCurrentPage(pageNumber)}
            totalItems={paginationMeta?.total ?? 1}
            nextLabel={<i className="fa fa-solid fa-chevron-right" />}
            prevLabel={<i className="fa fa-solid fa-chevron-left" />}
            endLabel={<i className="fa fa-sharp fa-solid fa-chevrons-left" />}
            startLabel={<i className="fa fa-sharp fa-solid fa-chevrons-right" />}
          />
        </div>
      )}
    </>
  );
}

ResidencesComponent.propTypes = {
  isTrashed: PropTypes.bool,
};

ResidencesComponent.defaultProps = {
  isTrashed: false,
};
