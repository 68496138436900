import React, { useCallback, useEffect, useState } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { create, get, update } from '../../../api';
import { textEditorOptions } from '../../../utils/editorOptions';
import { Toast } from '../../../utils';

export default function InvoicesTypeForm() {
  const history = useHistory();
  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const values = { ...items, refund: +items.refund };
      setLoading(true);
      return isAddMode ? addType(values) : updateTypeById(id, values);
    },
    [items]
  );

  const addType = (values) => {
    create('invoices', values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/types-factures');
        res.data && Toast('Le type de facture additionnelle a été bien ajouté');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateTypeById = (typeId, values) => {
    update('invoices', typeId, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/types-factures');
        res.data && Toast('Le type de facture additionnelle a été bien modifié');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  useEffect(() => {
    if (!isAddMode) {
      get('invoices', id).then((response) => {
        const { data } = response.data;
        data.refund = data.refund !== 0;
        setItems(data);
      });
    }
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Types de facture additionnelle"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/types-factures')}
      />
      <h2 className="content-block">{isAddMode ? 'Ajouter un type' : 'Modifier un type'}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Général">
                  <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom est requis" />
                    <Label text="Nom" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="refund" editorType="dxCheckBox" editorOptions={checkBoxOptions}>
                    <Label visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/types-factures')}>
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}

const checkBoxOptions = {
  text: 'Remboursé au propriétaire',
  value: false,
};
