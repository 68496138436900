export const getTokenFromCookie = () => {
  return document.cookie.trim().replace('authentification-cookie=', '');
};

export const getUserAuth = () => {
  const auth = localStorage.getItem('userAuth');
  const defaultAuth = {
    userId: '',
    userName: '',
    role: '',
    permissions: [],
  };
  return auth ? JSON.parse(auth) : defaultAuth;
};
