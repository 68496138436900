import React, { useEffect, useState } from 'react';
import '../styles/style.scss';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Button } from 'devextreme-react/data-grid';
import { Button as MainButton } from 'devextreme-react';
import PropTypes from 'prop-types';
import DeletePopup from '../../delete-popup/delete-popup';
import AddPopup from '../../add-popup/add-popup';
import AddSpecialPeriod from '../../form/addSpecialPeriod';
import { getAll, remove } from '../../../api';
import { Toast } from '../../../utils';
import './prices-details.scss';

export default function WidgetSpecilaPeriode({ idResidence, isTwin }) {
  const { t } = useTranslation();
  const [popupVisible, setPopupVisible] = useState({ status: false, idPrice: null });
  const [popupAddVisible, setPopupAddVisible] = useState({ status: false, idPrice: null });
  const [dataPricesSepcial, setDataPricesSepcial] = useState([]);

  const hideModal = () => {
    setPopupVisible({ status: false, idPrice: null, initial: false });
  };
  const hideAddModal = () => {
    setPopupAddVisible({ status: false, idPrice: null, initial: true });
  };
  const onDelete = () => {
    remove('tariffs', `${idResidence}/${popupVisible.idPrice}`).then((res) => {
      setDataPricesSepcial((state) => state.filter((el) => el.id !== popupVisible.idPrice));
      hideModal();
      Toast(t('common.successDeleteM', { title: 'Le tarif période spéciale' }));
    });
  };
  const onPopupAddVisible = (data) => setPopupAddVisible(data);

  useEffect(() => {
    getAll(`tariffs/${idResidence}/special_holiday`).then((res) => {
      if (res.data.data) {
        setDataPricesSepcial(res.data.data);
      }
    });
  }, []);

  const renderAddressHeader = (data) => {
    return <p className="caption">{data.column.caption}</p>;
  };

  const handleAdd = (data) => {
    setDataPricesSepcial((prevState) => [...prevState, data]);
  };
  const handleUpdate = (data) => {
    setDataPricesSepcial((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === data.id) {
          return data;
        }
        return obj;
      });
      return newState;
    });
  };

  return (
    <div className="dx-card responsive-paddings-widget widget">
      <div className="widget_item_title">{t('price.specialPeriodsPrices')}</div>
      <div>
        <MainButton
          text={t('price.specialPeriodsPrices')}
          type="success"
          className="btn_payment"
          onClick={() => onPopupAddVisible({ status: true, idPrice: null })}
        />
        <DataGrid
          className="dx-card wide-card"
          dataSource={dataPricesSepcial}
          showBorders
          focusedRowEnabled={false}
          keyExpr="id"
          noDataText={t('reservation.no_prices')}
        >
          <Column allowSorting={false} dataField="title_fr" caption={t('price.TitleFr').toUpperCase()} />
          <Column
            dataField="basic_nbr_people"
            caption={t('price.numPersons').toUpperCase()}
            allowSorting={false}
            hidingPriority={7}
          />
          <Column allowSorting={false} dataField="price_basic_night" caption={t('price.priceByNigth').toUpperCase()} />
          <Column allowSorting={false} dataField="price_fds_night" caption={t('price.priceFdsByNigth').toUpperCase()} />
          <Column
            allowSorting={false}
            dataField="price_night_additional_person"
            caption={t('price.priceExtraPerson').toUpperCase()}
            headerCellRender={renderAddressHeader}
          />
          <Column type="buttons">
            <Button
              hint="Modifier"
              icon="edit"
              onClick={(e) => onPopupAddVisible({ status: true, idPrice: e.row.data.id })}
            />
            <Button
              hint="Supprimer"
              icon="fa fa-trash"
              onClick={(e) => setPopupVisible({ status: true, idPrice: e.row.data.id })}
            />
          </Column>
        </DataGrid>
        <AddPopup
          popupVisible={popupAddVisible.status}
          hideModal={hideAddModal}
          height="80%"
          width="80%"
          title={t('price.save_special_period')}
          component={
            <AddSpecialPeriod
              idPrice={popupAddVisible.idPrice}
              isTwin={isTwin}
              hideAddModal={hideAddModal}
              initialVal={popupAddVisible.initial}
              t={t}
              handleAdd={handleAdd}
              handleUpdate={handleUpdate}
            />
          }
        />
        <DeletePopup
          popupVisible={popupVisible.status}
          hideModal={hideModal}
          title="Suppression de tarif période spéciale"
          msg="Êtes-vous sûr de vouloir supprimer ce tarif période spéciale ?"
          deleteActivity={onDelete}
        />
      </div>
    </div>
  );
}

WidgetSpecilaPeriode.propTypes = {
  idResidence: PropTypes.string.isRequired,
  isTwin: PropTypes.bool.isRequired,
};
