import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import './membre.scss';
import { useTranslation } from 'react-i18next';
import { create, get, remove } from '../../api';
import ClientInfo from '../../components/client-info/client-info';
import DeletePopup from '../../components/delete-popup/delete-popup';
import { Toast, getRole } from '../../utils';

export default function Membre() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [items, setItems] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  const owner_permission = getRole('residence_owner');

  const showModal = () => {
    setPopupVisible(true);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteMembre = (idMembre) => {
    remove('users', idMembre).then((res) => {
      hideModal();
      if (res.status === 204) {
        history.push('/membres');
        Toast(t('members.delete_member_msg'));
      }
    });
  };

  const reactifMembre = (idMembre) => {
    create(`users/${idMembre}/restore`).then((res) => {
      res.data && hideModal();
      res.data && history.push('/membres');
      res.data && Toast(t('members.reactive_member_msg'));
    });
  };

  useEffect(() => {
    get('users', id).then((response) => {
      setItems(response.data.data);
    });
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={items.is_trashed ? t('members.goback_members_trash') : t('members.goback_members')}
        type="default"
        stylingMode="text"
        onClick={() => (items.is_trashed ? history.push('/corbeille/membres') : history.push('/membres'))}
      />
      <h2 className="content-block">
        {items.first_name} {items.last_name}
      </h2>
      <div className="content-block client">
        <div className="dx-card responsive-paddings client_item client_item-actions">
          <div className="client_item_title">
            <i className="fa fa-edit" />
            {t('common.actions')}
          </div>
          <div className="client_btn">
            <Button
              icon="home"
              type="default"
              text={t('members.show_residences')}
              onClick={() => {
                return owner_permission ? history.push(`/residences/`) : history.push(`/residences/?users=[${id}]`);
              }}
            />
            <Button
              className="btn-edit"
              icon="edit"
              type="default"
              text={t('residance.edit_information')}
              onClick={() => {
                history.push(`/membre/${items.id}`);
              }}
              visible={!owner_permission}
            />
            <Button
              icon={items.is_trashed ? 'repeat' : 'trash'}
              type={items.is_trashed ? 'success' : 'danger'}
              text={items.is_trashed ? t('members.reactive_member') : t('members.delete_member')}
              onClick={showModal}
              visible={!owner_permission}
            />
          </div>
        </div>
        <div className="dx-card responsive-paddings client_item client_item-info">
          <div className="client_item_title">
            <i className="fa fa-info-circle" />
            {t('common.informations')}
          </div>
          <div className="client_item-notes_content">
            <h2 className="content-block">
              {items.first_name} {items.last_name}
            </h2>
            <ClientInfo clientDetails={items} />
          </div>
        </div>
        <div className="dx-card responsive-paddings client_item client_item-notes">
          <div className="client_item_title">
            <i className="fa fa-flag" />
            {t('common.notes')}
          </div>
          {items.notes && (
            <div className="client_item-notes_content" dangerouslySetInnerHTML={{ __html: items.notes }} />
          )}
        </div>
      </div>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={items.is_trashed ? t('common.reactivation') : t('common.deletion')}
        msg={items.is_trashed ? t('members.reactivation_msg') : t('members.deletion_msg')}
        deleteActivity={() => (items.is_trashed ? reactifMembre(items.id) : deleteMembre(items.id))}
        textBtn={items.is_trashed ? t('common.enable') : t('common.delete')}
        typeBtn={items.is_trashed ? 'success' : 'danger'}
      />
    </>
  );
}
