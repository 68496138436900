import React from 'react';
import ExtractsListing from '../../components/extracts';
import Hero from '../../components/hero/hero';
import './extraits.scss';

export default function () {
  return (
    <div className="container">
      <Hero title="Extraits" btnText="Ajouter un extrait" url="/extrait" />
      <ExtractsListing />
    </div>
  );
}
