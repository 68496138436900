import React, { useEffect, useState } from 'react';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { getAll } from '../../../api';

export default function Taxes() {
  const history = useHistory();
  const [customDataSource, setCustomDataSource] = useState([]);

  useEffect(() => {
    getAll('taxes').then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, []);

  return (
    <>
      <h2 className="content-block">Taxes</h2>
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} />
        <Column dataField="type" caption="Taxe" />
        <Column type="buttons" width={110}>
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/taxes/${e.row.data.id}`)} />
        </Column>
      </DataGrid>
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
