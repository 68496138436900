import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import './agencies.scss';
import Hero from '../../../components/hero/hero';
import { getAll, remove } from '../../../api';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { Toast } from '../../../utils';

export default function Agencies() {
  const history = useHistory();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteID, setDeleteID] = useState('');

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteAgency = (idAgency) => {
    remove('agencies', idAgency).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== idAgency));
      hideModal();
      Toast("L'agence de ménage a été bien supprimée");
    });
  };

  useEffect(() => {
    getAll('agencies').then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, []);

  return (
    <>
      <Hero title="Agences de ménage" btnText="Ajouter une agence" url="/agence" />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} defaultSortOrder="desc" />
        <Column dataField="name" caption="Nom" />
        <Column dataField="phone_number" caption="Téléphone" />
        <Column type="buttons" width={110}>
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/agence/${e.row.data.id}`)} />
          <Button icon="trash" hint="Supprimer" onClick={showModal} />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={"Suppression d'activité"}
        msg="Êtes-vous sûr de vouloir supprimer cette agence?"
        deleteActivity={() => deleteAgency(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
