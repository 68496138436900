/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import moment from 'moment';
import { Tab } from 'devextreme-react/diagram';
import { AutoCompleteTagEditorOptions } from '../../../utils/editorOptions';
import { getAll } from '../../../api';

export default function RapportForm() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startValue, setStartValue] = useState();
  const [endValue, setEndValue] = useState();
  const [items, setItems] = useState({});

  const [residenceValue, setResidenceValue] = useState('');
  const [Residencesdata, setResidencesdata] = useState([]);

  const minChangeHandler = function (e) {
    setStartValue(e.value);
  };

  const maxChangeHandler = function (e) {
    setEndValue(e.value);
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { start_date, end_date, ...rest } = items;
      const values = {
        ...rest,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
      };
      return addPeriode(values);
    },
    [items]
  );

  const addPeriode = (values) => {
    setLoading(false);
    history.push('/generer_facture');
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  const startDateBoxOptions = () => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: t('errors.date_format'),
      openOnFieldClick: true,
      showClearButton: true,
      value: startValue,
      onValueChanged: (e) => minChangeHandler(e),
      max: endValue,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  const endDateBoxOptions = () => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: t('errors.date_format'),
      openOnFieldClick: true,
      showClearButton: true,
      value: endValue,
      onValueChanged: (e) => maxChangeHandler(e),
      min: startValue,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (residenceValue?.length >= 3) {
        getAll(`search/residence/?filed=full_name&value=${residenceValue}`).then((response) => {
          setResidencesdata(response.data.data);
        });
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [residenceValue]);

  return (
    <>
      <h2 className="content-block">{t('rapports.title')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title={t('common.general')}>
                  <GroupItem itemType="group">
                    <SimpleItem dataField="start_date" editorType="dxDateBox" editorOptions={startDateBoxOptions()}>
                      <Label text="Début de la période" visible={false} />
                      <RequiredRule message={t('errors.required_start_date')} />
                    </SimpleItem>
                    <SimpleItem dataField="end_date" editorType="dxDateBox" editorOptions={endDateBoxOptions()}>
                      <Label text="Fin de la période" visible={false} />
                      <RequiredRule message={t('errors.required_end_date')} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="residence_ids"
                      cssClass="tag-box-input"
                      editorType="dxTagBox"
                      editorOptions={AutoCompleteTagEditorOptions(Residencesdata, setResidenceValue)}
                    >
                      <RequiredRule message={t('errors.required_residence')} />
                      <Label text="Résidences" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.gernerate')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() => {
                      setItems({});
                    }}
                  >
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
