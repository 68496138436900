import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles/style.scss';
import { useHistory, useParams } from 'react-router-dom';
import RenderHeaderPage from '../../components/widgets/headerResidance';
import WidgetImage from '../../components/widgets/mainImage';
import WidgetActions from '../../components/widgets/actions';
import { getAll } from '../../api';
import PricesList from '../../components/widgets/listPrices';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function UpdatePrice() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [dataList, setDataList] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                                   EVENTS                                   */
  /* -------------------------------------------------------------------------- */

  function navigateBack() {
    history.push(`/residences/details/${id}`);
  }

  function navigateToAddOrUpadate() {
    history.push({
      pathname: `/residences/prices/price/${id}`,
      search: `?${new URLSearchParams({ id: '', type: 0, isTwin: dataList.twin !== 0 }).toString()}`,
    });
  }

  function navigateToPromotion() {
    history.push({
      pathname: `/residences/promotions/${id}`,
    });
  }
  const handleDelete = () => {
    setDataList((prevstate) => {
      return { ...prevstate, data: [] };
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */

  const listActions = [
    {
      id: 1,
      name: t('residance.addPrice'),
      icon: 'fa fa-asterisk',
      onPress: navigateToAddOrUpadate,
      styles: 'greenBtnBackground actionBtn btnForWithBackGrounColor',
      display: dataList.twin === 0,
      disabled: !!(dataList?.data?.length > 0),
    },
    {
      id: 2,
      name: t('residance.managementPromotion'),
      icon: 'fa fa-trophy',
      onPress: navigateToPromotion,
      styles: 'orangeBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 3,
      name: t('residance.backToResidance'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBack,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
  ];
  /* -------------------------------------------------------------------------- */
  /*                                  USEFFECT                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getAll(`tariffs/${id}/basic`).then((res) => {
      setDataList(res.data);
    });
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block ">
      <RenderHeaderPage t={t} id="" name={dataList?.name ?? ''} officialName={dataList?.official_name ?? ''} />
      <div className="mainContainer">
        <div className="side">
          <WidgetImage t={t} src={dataList?.main_photo ?? ''} />
          <WidgetActions ListAction={listActions} t={t} dviderIndexs={[]} />
        </div>
        <PricesList
          t={t}
          dataBasePrice={dataList?.data ?? []}
          checkboxValue={{
            allow_fill_holes: dataList?.allow_fill_holes ?? 0,
            day_delay_between_reservations: dataList?.day_delay_between_reservations ?? 0,
          }}
          can_add_basic_tariff={dataList?.can_add_basic_tariff ?? 0}
          isTwin={dataList.twin !== 0}
          onDelete={handleDelete}
        />
      </div>
    </div>
  );
}
