import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Form, { SimpleItem, Label, RequiredRule, GroupItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { LoadIndicator, LoadPanel } from 'devextreme-react';
import { ImageEditorOptions, selectEditorOptions } from '../../utils/editorOptions';
import './styles/index.scss';
import { Toast } from '../../utils';
import { getAll, updateWithMedia } from '../../api';

export default function MediaUpdateFormPopup({ hideUpdateModal, t, selectedImage }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({});
  const [previewPohto, setpreviewPohto] = useState({});
  const [descriptionsDataSource, setDescriptionsDataSource] = useState([]);
  const { id } = useParams();

  const updatePicture = (values) => {
    const formDataPicture = new FormData();
    values.photo && formDataPicture.append('photo', values.photo[0]);
    formDataPicture.append('residence_id', id);
    formDataPicture.append('photo_description_id', values.descriptifs);
    formDataPicture.append('_method', 'PUT');
    updateWithMedia('residence_photos', `${id}/${selectedImage.id}`, formDataPicture, {
      'Content-Type': 'multipart/form-data',
    })
      .then((res) => {
        setLoading(false);
        hideUpdateModal();
        res.data && Toast(t('common.successUpdate', { title: 'La photo' }));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return updatePicture(items);
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setpreviewPohto(URL.createObjectURL(data.photo[0]));
    setItems(data);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAll('photo-descriptions').then((response) => {
      setDescriptionsDataSource(response.data.data);
    });
  }, []);

  useEffect(() => {
    if (selectedImage.photo_description) {
      setItems({
        descriptifs: selectedImage?.photo_description?.id,
      });
    }
    return () => setpreviewPohto(null);
  }, [selectedImage]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block" id="employee">
      <div className="responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form formData={items} disabled={false} onFieldDataChanged={formFieldDataChanged}>
            <GroupItem cssClass="group-item" colCount={1}>
              <SimpleItem dataField="photo" editorType="dxFileUploader" editorOptions={ImageEditorOptions}>
                {Object.keys(selectedImage).length === 0 && <RequiredRule message={t('errors.required_photo')} />}
                <Label text={t('common.photo')} visible={false} />
              </SimpleItem>
              <GroupItem cssClass="group-item" colCount={1}>
                {(previewPohto || selectedImage?.photo) && (
                  <img alt="" src={previewPohto ?? selectedImage.photo} width="150px" height="100px" />
                )}
              </GroupItem>
              <SimpleItem
                dataField="descriptifs"
                editorType="dxSelectBox"
                editorOptions={selectEditorOptions(descriptionsDataSource, 'description')}
              >
                <RequiredRule message={t('errors.required_descriptif')} />
                <Label text={t('residance.descriptifs')} visible={false} />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={hideUpdateModal}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

MediaUpdateFormPopup.propTypes = {
  t: PropTypes.func.isRequired,
  hideUpdateModal: PropTypes.func.isRequired,
  selectedImage: PropTypes.object.isRequired,
};
