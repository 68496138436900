import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, {
  Column,
  Pager,
  Paging,
  LoadPanel,
  Sorting,
  Editing,
  Button,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { priceColumn_customizeText } from '../../../../../utils';

export default function RapportListing({ customDataSource }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
    >
      <Sorting mode="none" />
      <LoadPanel enabled />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
      <Editing mode="row" useIcons />

      <Column dataField="remb" caption="REMB." />
      <Column dataField="residence" caption="RÉSIDENCE" />
      <Column dataField="client" caption="CLIENT" />
      <Column dataField="type" caption="TYPE" />
      <Column dataField="total" caption="TOTAL" customizeText={priceColumn_customizeText} />
      <Column dataField="tps" caption="TPS" customizeText={priceColumn_customizeText} />
      <Column dataField="tvq" caption="TVQ" customizeText={priceColumn_customizeText} />
      <Column dataField="th" caption="T.H." customizeText={priceColumn_customizeText} />
      <Column dataField="total_ttc" caption="TOTAL TTC" customizeText={priceColumn_customizeText} />
      <Column type="buttons">
        <Button
          hint={t('payment.payment_hint_3')}
          icon="fa fa-eye"
          onClick={(e) => history.push(`/reservation/${e.row.data.id}`)}
        />
      </Column>
      <Summary>
        <TotalItem column="type" displayFormat="Total:" />
        <TotalItem column="total" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        <TotalItem column="tps" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        <TotalItem column="tvq" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        <TotalItem column="th" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        <TotalItem column="total_ttc" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
      </Summary>
    </DataGrid>
  );
}

const allowedPageSizes = [5, 10, 20];

RapportListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
