import React, { useCallback, useEffect, useRef, useState } from 'react';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule, GroupItem } from 'devextreme-react/form';
import PropTypes from 'prop-types';
import moment from 'moment';

export default function SelectMultipleDates({ t, listCustomDate, onHandleCustomDate, hideModal }) {
  const [items, setItems] = useState({ ranges: {} });
  const formDate = useRef('formDate');
  const [periodesList, setPeriodesList] = useState(['']);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const addButtonOptions = {
    icon: 'add',
    text: 'Ajouter une date',
    onClick: () => {
      addPeriodeData();
      setPeriodesList([...periodesList, '']);
    },
  };

  const addPeriodeData = () => {
    const { ranges } = items;
    ranges[Object.keys(ranges).length] = { start_date: undefined, end_date: undefined };
    setItems({ ...items, ranges });
  };

  const deletePeriode = (index) => {
    const periodeData = deletePeriodeData(index);
    items.ranges = periodeData;
    setItems(items);
    setPeriodesList([...periodesList.filter((_, i) => i !== index)]);
  };

  const deletePeriodeData = (index) => {
    const data = items.ranges && Object.values(items.ranges).filter((_, i) => i !== index);
    return { ...data };
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { ranges, ...rest } = items;
      const periodes = Object.values(ranges).map((elt) => {
        return {
          start_date: moment(elt.start_date).format('l'),
          end_date: moment(elt.end_date).format('l'),
        };
      });
      onHandleCustomDate(periodes);
      hideModal();
    },
    [items, listCustomDate]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
    forceUpdate();
  };

  const startDateBoxOptions = (index) => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
      openOnFieldClick: true,
      showClearButton: true,
      max: items.ranges && items.ranges[index]?.end_date,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  const endDateBoxOptions = (index) => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
      openOnFieldClick: true,
      showClearButton: true,
      min: items.ranges && items.ranges[index]?.start_date,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  useEffect(() => {
    setPeriodesList(['']);
    setItems({ ranges: {} });
    if (listCustomDate.length > 0) {
      const data = Array(listCustomDate.length).fill('');
      setPeriodesList(data);
      const values = {
        ranges: { ...listCustomDate },
      };
      setItems(values);
    }
  }, [listCustomDate]);

  return (
    <div className="content-block">
      <div className="dx-card responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form ref={formDate} formData={items} onFieldDataChanged={formFieldDataChanged}>
            <GroupItem caption="Dates">
              <GroupItem>
                {periodesList.map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <GroupItem itemType="group" key={index} colCount={3}>
                    <SimpleItem
                      dataField={`ranges[${index}].start_date`}
                      editorType="dxDateBox"
                      editorOptions={startDateBoxOptions(index)}
                    >
                      <Label text="Date de début" visible={false} />
                      <RequiredRule message="La date de début est requise" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`ranges[${index}].end_date`}
                      editorType="dxDateBox"
                      editorOptions={endDateBoxOptions(index)}
                    >
                      <Label text="Date de fin" visible={false} />
                      <RequiredRule message="La date de fin est requise" />
                    </SimpleItem>
                    <ButtonItem>
                      <ButtonOptions
                        icon="trash"
                        type="normal"
                        hint="Supprimer"
                        onClick={() => {
                          deletePeriode(index);
                        }}
                      />
                    </ButtonItem>
                  </GroupItem>
                ))}
              </GroupItem>
              <SimpleItem
                itemType="button"
                cssClass="add-button"
                horizontalAlignment="left"
                buttonOptions={addButtonOptions}
              />
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">{t('common.save')}</span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={hideModal}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}
SelectMultipleDates.propTypes = {
  t: PropTypes.func.isRequired,
  onHandleCustomDate: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  listCustomDate: PropTypes.array.isRequired,
};
