import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAll } from '../../../api';
import FacturesListing from './facturesListing/facturesListing';
import FacturesSearchForm from './facturesSearchForm/facturesSearchForm';
import './searchFactures.scss';

const factures = [
  {
    id: 1,
    number: '20190307-123',
    residence: 'GER-65',
    description: 'Commission févr. 2019',
    date: '7 mars 2019',
    montant: '228.21 $',
  },
  {
    id: 2,
    number: '20190307-123',
    residence: 'GER-65',
    description: 'Commission févr. 2019',
    date: '7 mars 2019',
    montant: '228.21 $',
  },
  {
    id: 3,
    number: '20190307-123',
    residence: 'GER-65',
    description: 'Commission févr. 2019',
    date: '7 mars 2019',
    montant: '228.21 $',
  },
  {
    id: 4,
    number: '20190307-123',
    residence: 'GER-65',
    description: 'Commission févr. 2019',
    date: '7 mars 2019',
    montant: '228.21 $',
  },
  {
    id: 5,
    number: '20190307-123',
    residence: 'GER-65',
    description: 'Commission févr. 2019',
    date: '7 mars 2019',
    montant: '228.21 $',
  },
];

export default function SearchFactures() {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [searchObject, setSearchObject] = useState({});

  useEffect(() => {
    const formValues = {
      number: queryParams.get('number'),
    };
    const url = `?number=${formValues.number}`;

    setCustomDataSource(factures);
    setSearchObject(formValues);
    // getAll(`Factures/${url}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    //   setSearchObject(formValues);
    // });
  }, []);

  const handleSearchValues = (values) => {
    const url = values.number ? `?number=${values.number}` : '';

    history.push(`/factures_rechercher/${url}`);

    // getAll(`Factures/${url}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    //   setSearchObject(values);
    // });
  };

  return (
    <>
      <h2 className="content-block">Résultats</h2>
      <div className="factures">
        <div className="factures_listing">
          <FacturesListing customDataSource={customDataSource} />
        </div>
        <div className="factures_search">
          <FacturesSearchForm handleSearchValues={handleSearchValues} formValues={searchObject} />
        </div>
      </div>
    </>
  );
}
