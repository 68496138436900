import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateBox } from 'devextreme-react';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting } from 'devextreme-react/data-grid';
import Hero from '../../components/hero/hero';
import './styles.scss';

export default function BankAccount() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [date, setDate] = useState(new Date());
  const { t } = useTranslation();
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const renderGridCell = (data) => {
    const color = Number(data.value) >= 0 ? 'green' : 'red';
    return <div style={{ color }}>{data.value} $</div>;
  };
  const handleClick = () => {};
  const renderGridCellLink = (data) => {
    return (
      <div className="linkDetails" onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={0}>
        {data.value}
      </div>
    );
  };
  /* -------------------------------------------------------------------------- */
  /*                                  FAKEDATA                                  */
  /* -------------------------------------------------------------------------- */
  const FakeDAta = [
    {
      date: '1 avril 2018',
      type: 'Dépense',
      residence: 'residence 1',
      details: 'Adhésion au site Internet',
      montant: 1568,
    },
    {
      date: '2 avril 2018',
      type: 'Dépense',
      residence: 'residence 1',
      details: 'Adhésion au site Internet',
      montant: -100,
    },
  ];
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="bankHeader">
        <h6 className="content-block transaction-title">{t('bankAcount.title')}</h6>
        <div className="bankHeader">
          <Hero title="" btnText={t('common.export')} icon="download" onClickBtn={() => {}} />
          <DateBox
            acceptCustomValue={false}
            value={date}
            onValueChanged={({ value }) => setDate(value)}
            calendarOptions={{ maxZoomLevel: 'year' }}
            displayFormat="MMMM yyyy"
          />
        </div>
      </div>
      <DataGrid
        className="dx-card wide-card"
        dataSource={FakeDAta}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Column dataField="date" caption="DATE" />
        <Column dataField="type" caption="TYPE" />
        <Column dataField="residence" caption="RÉSIDENCE" />
        <Column dataField="details" caption="DÉTAILS" cellRender={renderGridCellLink} />
        <Column dataField="montant" caption="MONTANT" cellRender={renderGridCell} />
      </DataGrid>
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
