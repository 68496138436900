/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import DataSource from 'devextreme/data/data_source';
import EditorInput from '../../editor-input/editor-input';
import './create-ville-form.scss';
import { create, get, getAll, update } from '../../../api';
import { permalinkEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { getPermissionVisibility, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';
import useGetMergTags from '../../../hooks/useGetTags';

export default function CreateVilleForm() {
  const history = useHistory();

  const { auth } = useAuth();
  const { role } = auth;
  const dataTags = !getPermissionVisibility(['webmaster'], role) && useGetMergTags();
  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [valueContentFR, setValueContentFR] = useState('');
  const [valueContentEN, setValueContentEN] = useState('');
  const [groupeVilledata, setGroupeVilledata] = useState([]);
  const refForm = useRef('createVilleForm');
  const [items, setItems] = useState({});

  const groupeVilleEditorOptions = {
    dataSource: new DataSource({
      store: {
        data: groupeVilledata,
        type: 'array',
        key: 'id',
      },
    }),
    displayExpr: 'name',
    valueExpr: 'id',
    showClearButton: true,
    searchEnabled: true,
    showSelectionControls: true,
    showDropDownButton: true,
    multiline: false,
    labelMode: 'floating',
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { text_fr, text_en, seo_fr, seo_en, cities_groups, ...rest } = items;
      const seo =
        seo_fr || seo_en
          ? [
              { ...seo_fr, language: 'fr' },
              { ...seo_en, permalink: null, language: 'en' },
            ]
          : [];
      const values = {
        ...rest,
        texts: JSON.stringify([
          {
            language: 'fr',
            value: valueContentFR,
          },
          {
            language: 'en',
            value: valueContentEN,
          },
        ]),
        seo: JSON.stringify(seo),
        cities_groups: JSON.stringify(cities_groups ?? []),
      };
      setLoading(true);
      return isAddMode ? addVille(values) : updateVilleById(id, values);
    },
    [items, valueContentEN, valueContentFR, groupeVilledata]
  );

  const addVille = (values) => {
    create('cities', values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/villes');
        res.data && Toast('La ville a été bien ajoutée');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateVilleById = (villeId, values) => {
    update('cities', villeId, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/villes');
        res.data && Toast('La ville a été bien modifiée');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  useEffect(() => {
    getAll('cities-groups').then((response) => {
      setGroupeVilledata(response.data.data);
    });
    if (!isAddMode) {
      get('cities', id).then((response) => {
        const { data } = response.data;
        const { text_fr, text_en } = data;
        setItems(data);
        setValueContentFR(text_fr ?? '');
        setValueContentEN(text_en ?? '');
      });
    }
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Villes"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/villes')}
      />
      <h2 className="content-block">{isAddMode ? 'Ajouter une ville' : 'Modifier une ville'}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form ref={refForm} formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions
                  deferRendering={false}
                  selectedIndex={getPermissionVisibility(['webmaster'], role) ? 3 : 0}
                />
                <Tab title="Général" disabled={getPermissionVisibility(['webmaster'], role)}>
                  <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom est requis" />
                    <Label text="Nom" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="short_name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom court est requis" />
                    <Label text="Nom court" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="zone" editorType="dxSelectBox" editorOptions={secteurEditorOptions}>
                    <RequiredRule message="Le secteur est requis" />
                    <Label text="Secteur" visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="cities_groups"
                    cssClass="tag-box-input"
                    editorType="dxTagBox"
                    editorOptions={groupeVilleEditorOptions}
                  >
                    <Label text="Groupe de ville" visible={false} />
                  </SimpleItem>
                </Tab>
                <Tab title="Français" disabled={getPermissionVisibility(['webmaster'], role)}>
                  <EditorInput dataSource={dataTags} valueContent={valueContentFR} valueChanged={setValueContentFR} />
                </Tab>
                <Tab title="Anglais" disabled={getPermissionVisibility(['webmaster'], role)}>
                  <EditorInput dataSource={dataTags} valueContent={valueContentEN} valueChanged={setValueContentEN} />
                </Tab>
                <Tab title="SEO">
                  <SimpleItem
                    dataField="seo_fr.permalink"
                    editorType="dxTextBox"
                    editorOptions={Object.assign(permalinkEditorOptions(refForm), textEditorOptions)}
                  >
                    <Label text="Permalien" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_fr.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Titre Français" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_fr.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Description Français" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_en.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Titre Anglais" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_en.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Description Anglais" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/villes')}>
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}

const secteurEditorOptions = {
  dataSource: new DataSource({
    store: {
      data: [
        { Name: 'Baie-Saint-Paul et ses environs', ID: 'baie_saint_paul' },
        { Name: 'Route du fleuve', ID: 'river_road' },
        { Name: 'Route des montagnes', ID: 'mountain_road' },
        { Name: 'La Malbaie et ses environs', ID: 'malbaie' },
        { Name: 'Isle-aux-coudres', ID: 'isle_aux_coudres' },
      ],
      type: 'array',
      key: 'ID',
    },
  }),
  displayExpr: 'Name',
  valueExpr: 'ID',
  showClearButton: true,
  searchEnabled: true,
  labelMode: 'floating',
};
