import { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';

const stateKey = 'administrative_area_level_1';
const cityKey = 'locality';

const getStateOrCity = (result, key, useShortName) => {
  const foundState = result.address_components.find(({ types }) => types.includes(key));

  if (!foundState) return undefined;

  return useShortName ? foundState.short_name : foundState.long_name;
};

/**
 * @description google Places API to handle details from address input
 * @param {*} refFormResidence
 * @returns update fildes of form after pick address and out focus of input
 */
export const handleDetails = (refFormResidence) => {
  if (refFormResidence.current.props.formData?.address) {
    const parameter = {
      address: refFormResidence.current.props.formData.address,
    };

    getGeocode(parameter)
      .then((results) => {
        const state = getStateOrCity(results[0], stateKey);
        const city = getStateOrCity(results[0], cityKey);
        const { lat, lng } = getLatLng(results[0]);
        if (lat && lng) {
          refFormResidence.current.instance.updateData('longitude', lng);
          refFormResidence.current.instance.updateData('latitude', lat);
        }
        if (state) {
          refFormResidence.current.instance.updateData('province', state);
        }

        if (city) {
          refFormResidence.current.instance.updateData('city', city);
        }
        return getZipCode(results[0], false);
      })
      .then((zipCode) => {
        if (zipCode) {
          refFormResidence.current.instance.updateData('postal_code', zipCode);
        }
      })

      .catch((error) => {
        console.log('Error: ', error);
      });
  }
};
