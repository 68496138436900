import notify from 'devextreme/ui/notify';
import history from '../customHistory';

export function errorsHandler(error) {
  // the server responded with a status code
  let errors = [];
  let { message } = error;
  if (error.response) {
    const { status, data } = error.response;
    switch (status) {
      case 400:
        // TODO format errors
        errors = data;
        // TODO add translation
        message = 'Bad request error';
        break;
      // TODO handle 401 error
      case 401:
        document.cookie = 'authentification-cookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        localStorage.removeItem('userAuth');
        history.replace('/', {});
        window.location.reload();
        // eslint-disable-next-line no-return-assign
        break;
      // return (window.location = '/login');
      case 500:
        message = `Server error : ${error.response.data.message}`;
        break;
      default:
        message = `Unhandled error, status : ${status} message : ${error.response.data.message}`;
        break;
    }
  }
  // TODO Log errors system
  notify(error.response.data.message, 'error', 4000);
  return { message, errors };
}

export function errorsHandlerCustomStore(e) {
  return e;
}
