/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../styles/style.scss';
import moment from 'moment';
import { getDateTimeZone, ModePaiments, originList } from '../../../utils';

export default function ReservationInfo({ details }) {
  const { t } = useTranslation();
  const infoData = [
    { key: 'reservation_number', name: t('reservation.num_reservation') },
    { key: 'nights', name: t('reservation.num_nights') },
    { key: 'start_at', name: t('reservation.arrival_date') },
    { key: 'end_at', name: t('reservation.date_of_departure') },
    { key: 'adult', name: t('reservation.num_adults') },
    { key: 'kids_0_2', name: t('reservation.kids_0_2') },
    { key: 'kids_3_5', name: t('reservation.kids_3_5') },
    { key: 'kids_6_12', name: t('reservation.kids_6_12') },
    { key: 'animals', name: t('reservation.number_of_animals') },
    { key: 'smoker', name: t('reservation.presence_of_smoker') },
    { key: 'user', name: t('reservation.assigned_agent') },
    { key: 'created_at', name: t('reservation.reserved') },
    { key: 'content_parents', name: t('reservation.content_parents') },
    { key: 'heart_of_winters', name: t('reservation.heart_of_winters') },
    { key: 'free_kids', name: t('reservation.free_kids') },
    { key: 'commission_rate', name: t('reservation.commission_rate') },
    { key: 'booked_by', name: t('reservation.multi_unit_code') },
    { key: 'origin', name: t('reservation.owner') },
    { key: 'payment_method', name: t('reservation.payment_method') },
    { key: 'note', name: t('reservation.Note') },
  ];

  const getChildNumberPerAge = (min, max, list) => {
    const ages = list?.map((el) => {
      return el.age;
    });
    const agesFilter = ages?.filter((e) => min < e && e <= max);
    return agesFilter?.length;
  };

  const originFormat = (list) => {
    // eslint-disable-next-line consistent-return, array-callback-return
    const origins = Object.keys(list).map((origin) => {
      if (origin === 'other_origin') return list[origin];
      if (list[origin]) return originList[origin];
    });
    return origins
      .filter((element) => {
        return element !== undefined && element !== null;
      })
      .join(', ');
  };

  const getAnimalsNumber = (list) => {
    const numbers = list.map((animal) => {
      return animal.number;
    });
    const number = numbers.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    return number;
  };

  function renderComponent(item) {
    return (
      <div key={item.key} className="element">
        <span className={`label ${['note'].includes(item.key) && 'label-success'}`}>{item.name}</span>
        {['animals'].includes(item.key) ? (
          <span className="value">{getAnimalsNumber(details[item.key])}</span>
        ) : ['start_at', 'end_at'].includes(item.key) ? (
          <span className="value">{moment(getDateTimeZone(new Date(details[item.key]))).format('LL')}</span>
        ) : ['origin'].includes(item.key) ? (
          <span className="value">{originFormat(details[item.key])}</span>
        ) : ['note'].includes(item.key) ? (
          <span className="value" dangerouslySetInnerHTML={{ __html: details[item.key] }} />
        ) : ['smoker'].includes(item.key) ? (
          <span className="value">{details[item.key] > 0 ? 'Oui' : 'Non'}</span>
        ) : ['kids_0_2'].includes(item.key) ? (
          <span className="value">{getChildNumberPerAge(0, 2, details.child)} enfant(s)</span>
        ) : ['kids_3_5'].includes(item.key) ? (
          <span className="value">{getChildNumberPerAge(2, 5, details.child)} enfant(s)</span>
        ) : ['kids_6_12'].includes(item.key) ? (
          <span className="value">{getChildNumberPerAge(5, 12, details.child)} enfant(s)</span>
        ) : ['payment_method'].includes(item.key) ? (
          <span className="value">{ModePaiments[details[item.key]]}</span>
        ) : ['phone', 'second_phone'].includes(item.key) ? (
          <span className="value">
            {`${details[item.key].number}  ${details[item.key].extension ? `#${details[item.key].extension}` : ''}`}
          </span>
        ) : (
          <span className="value">
            {item.key === 'second_email' && typeof details[item.key] === 'object'
              ? details[item.key].email
              : details[item.key]}
          </span>
        )}
      </div>
    );
  }

  function InfoComponent(item) {
    // eslint-disable-next-line no-prototype-builtins
    return (
      (details.hasOwnProperty(item.key) || ['kids_0_2', 'kids_3_5', 'kids_6_12'].includes(item.key)) &&
      renderComponent(item)
    );
  }

  return (
    <div className="dx-card responsive-paddings-widget  ">
      <div className="widget_item_title">
        <i className="fa fa-asterisk" /> {t('reservation.title_reservation')}
      </div>
      {details.reservations_notes && (
        <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: details.reservations_notes }} />
      )}
      <div>{infoData.map(InfoComponent)}</div>
    </div>
  );
}

ReservationInfo.propTypes = {
  details: PropTypes.object.isRequired,
};
