import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Button } from 'devextreme-react/data-grid';
import './residences-listing.scss';
import { useAuth } from '../../contexts/auth';
import { getPermissionVisibility } from '../../utils';

export default function ResidencesListing({ customDataSource }) {
  const history = useHistory();
  const { auth } = useAuth();
  const PermissionsList = auth.permissions;

  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      remoteOperations
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
      loadPanel={{ enabled: true }}
    >
      <Editing mode="row" useIcons />
      <Column dataField="id" caption="ID" width={90} defaultSortOrder="desc" />
      <Column dataField="name" caption="Nom" />
      <Column dataField="official_name" caption="Code" />
      <Column dataField="member.full_name" caption="Propriétaire" />
      <Column dataField="city.name" caption="Ville" />
      <Column dataField="status" caption="Statut" />
      <Column type="buttons" width={110}>
        <Button
          hint="Consulter"
          icon="fa fa-eye"
          onClick={(e) => history.push(`/residences/details/${e.row.data.id}`)}
        />
        <Button
          hint="Modifier"
          icon="edit"
          visible={getPermissionVisibility(PermissionsList, 'updateResidence')}
          onClick={(e) => history.push(`/residence/modifier/${e.row.data.id}`)}
        />
        <Button
          icon="globe"
          onClick={(e) =>
            window.open(
              'https://staging.hebergement-charlevoix.com/fr/chalet-a-louer/petite-riviere-saint-francois/voir/AAA-39'
            )
          }
        />
      </Column>
    </DataGrid>
  );
}

ResidencesListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
