import React from 'react';
import './sauvegarde.scss';

export default function () {
  return (
    <>
      <h2 className="content-block">Sauvegarde</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">Put your content here</div>
      </div>
    </>
  );
}
