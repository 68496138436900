import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting } from 'devextreme-react/data-grid';

export default function FactureAddListing({ customDataSource }) {
  return (
    <div className="fact_list">
      <h2 className="content-block transaction-title">Liste détaillée par factures additionnelles</h2>
      <DataGrid
        className="dx-card wide-card listing-style"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />

        <Column dataField="name" caption="NOM" />
        <Column dataField="payments" caption="PAIEMENTS" />
        <Column dataField="depenses" caption="DÉPENSES" />
        <Column dataField="commission" caption="COMM." />
        <Column dataField="tx_hc" caption="TX. HÉB." />
        <Column dataField="service_fee" caption="FRAIS SERV." />
        <Column dataField="prop_fee" caption="FRAIS PROP." />
        <Column dataField="carte_fee" caption="FRAIS CARTE" />
        <Column dataField="other_fee" caption="AUTRE FRAIS" />
        <Column dataField="montant" caption="MONTANT DÛ" />
      </DataGrid>
    </div>
  );
}

const allowedPageSizes = [5, 10, 20];

FactureAddListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
