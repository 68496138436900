import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import ProtectedRoute from './protected-route';

export default function Content() {
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        {routes.map(({ roles, path, component: Component }) => (
          <Route
            exact
            key={path}
            path={path}
            // eslint-disable-next-line react/no-unstable-nested-components
            component={(props) => <ProtectedRoute roles={roles} routeChildren={<Component {...props} />} />}
          />
        ))}
        <Redirect to="/home" />
      </Switch>
      <Footer>
        Droits d&#39;auteur © {new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        Toutes les marques ou marques déposées sont la propriété de leurs propriétaires respectifs.
      </Footer>
    </SideNavBarLayout>
  );
}
