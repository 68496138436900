import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { get, remove } from '../../../api';
import WidgetImage from '../../../components/widgets/mainImage';
import AddPopup from '../../../components/add-popup/add-popup';
import FacturetionsListing from './facturationsListing';
import SendFacturePopupForm from '../../../components/form/send-facture';
import { Toast } from '../../../utils';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import AddFactureLignePopupForm from '../../../components/form/add-facture-ligne';

const FakeDAta = [
  {
    id: 1,
    reservationId: 4,
    origin: 'Proprio (Annnulé)',
    detail: 'Kristelle Paradis (1745$+tx) 2021-02-12 au 2021-02-17',
    fee: 120,
    service_fee: 0,
    tx_hc: 0,
    total: 120,
  },
  {
    id: 2,
    reservationId: 4,
    origin: 'Proprio (Annnulé)',
    detail: 'Kristelle Paradis (1745$+tx) 2021-02-12 au 2021-02-17',
    fee: 120,
    service_fee: 0,
    tx_hc: 0,
    total: 120,
  },
  {
    id: 3,
    reservationId: 4,
    origin: 'Proprio (Annnulé)',
    detail: 'Kristelle Paradis (1745$+tx) 2021-02-12 au 2021-02-17',
    fee: 120,
    service_fee: 0,
    tx_hc: 0,
    total: 120,
  },
  {
    id: 4,
    reservationId: 4,
    origin: 'Proprio (Annnulé)',
    detail: 'Kristelle Paradis (1745$+tx) 2021-02-12 au 2021-02-17',
    fee: 120,
    service_fee: 0,
    tx_hc: 0,
    total: 120,
  },
  {
    id: 5,
    reservationId: 4,
    origin: 'Proprio (Annnulé)',
    detail: 'Kristelle Paradis (1745$+tx) 2021-02-12 au 2021-02-17',
    fee: 120,
    service_fee: 0,
    tx_hc: 0,
    total: 120,
  },
  {
    id: 6,
    reservationId: 4,
    origin: 'Proprio (Annnulé)',
    detail: 'Kristelle Paradis (1745$+tx) 2021-02-12 au 2021-02-17',
    fee: 120,
    service_fee: 0,
    tx_hc: 0,
    total: 120,
  },
];

export default function FacturationDetails() {
  const { t } = useTranslation();
  const { id, itemId } = useParams();
  const history = useHistory();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [residenceDetails, setResidenceDetails] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);

  const [popupVisible1, setPopupVisible1] = useState(false);

  const [popupVisible2, setPopupVisible2] = useState(false);

  const deleteFacture = () => {
    history.push(`/residences/factures/${id}`);
    Toast(t('common.successDelete', { title: 'La facture' }));
    // remove('agencies', itemId).then((e) => {
    //   if (!e.message) {
    //     history.push(`/residences/factures/${id}`);
    //     Toast(t('common.successDelete', { title: 'La facture' }));
    //   }
    //   hideModal();
    // });
  };

  useEffect(() => {
    get('residences', id).then((response) => {
      setResidenceDetails(response.data.data);
    });
    // getAll(`facturations/${id}/${itemId}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    // });
    setCustomDataSource(FakeDAta);
  }, [id]);

  return (
    <>
      <div className="content-block client">
        <div className="client_item client_item-actions">
          <WidgetImage t={t} src={residenceDetails.main_photo} />
          <div className="dx-card responsive-paddings-widget widget">
            <div className="widget_item_title">
              <i className="fa fa-edit" />
              {t('common.actions')}
            </div>
            <div className="client_btn containerBtnsActions">
              <Button icon="export" type="normal" text={t('facturations.pdf_export')} />
              <Button
                icon="fa fa-plane"
                type="normal"
                text={t('facturations.facture_dispatch')}
                onClick={() => setPopupVisible(true)}
              />
              <hr className="barre" />
              <Button
                icon="add"
                type="success"
                text={t('facturations.add_facture')}
                onClick={() => setPopupVisible2(true)}
              />
              <hr className="barre" />
              <Button icon="trash" type="danger" text={t('common.delete')} onClick={() => setPopupVisible1(true)} />
              <hr className="barre" />
              <Button
                icon="chevronprev"
                type="default"
                text={t('facturations.go_back_factures')}
                onClick={() => history.push(`/residences/factures/${id}`)}
              />
            </div>
          </div>
        </div>
        <div className="dx-card responsive-paddings-widget widget client_item client_item-info">
          <div className="widget_item_title">
            <i className="fa fa-file" />
            {t('facturations.facture_title')}
          </div>
          <FacturetionsListing customDataSource={customDataSource} />
        </div>
      </div>
      <AddPopup
        popupVisible={popupVisible}
        hideModal={() => setPopupVisible(false)}
        title={t('facturations.send_facture')}
        component={<SendFacturePopupForm hideModal={() => setPopupVisible(false)} />}
        width="50%"
      />
      <DeletePopup
        popupVisible={popupVisible1}
        hideModal={() => setPopupVisible1(false)}
        title={t('facturations.delete_facture')}
        msg={t('facturations.delete_facture_msg')}
        deleteActivity={() => deleteFacture()}
      />
      <AddPopup
        popupVisible={popupVisible2}
        hideModal={() => setPopupVisible2(false)}
        title={t('facturations.add_facture_ligne')}
        component={<AddFactureLignePopupForm hideModal={() => setPopupVisible2(false)} />}
        width="60%"
      />
    </>
  );
}
