import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
  CompareRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { Button } from 'devextreme-react/button';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import caImg from '../../../assets/ca.gif';
import euImg from '../../../assets/eu.gif';
import { create, get, getAll, update } from '../../../api';
import EditorInput from '../../editor-input/editor-input';
import {
  textEditorOptions,
  emailEditorOptions,
  AutoCompleteEditorOptions,
  passwordEditorOptions,
  selectEditorOptions,
} from '../../../utils/editorOptions';
import { permissions, Roles, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';
import useGetMergTags from '../../../hooks/useGetTags';

export default function MemberFormComponent({ resultAddMember, closeFormAction, idMember, residence }) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANS                                  */
  /* -------------------------------------------------------------------------- */
  const phoneCaEditorOptions = {
    mask: '0 (000) 000-0000',
    maskInvalidMessage: 'Le téléphone doit avoir un format de téléphone CA correct',
    labelMode: 'floating',
  };

  const phoneEuEditorOptions = {
    mask: '00 0 00 00 00 00',
    maskInvalidMessage: 'Le téléphone doit avoir un format de téléphone EU correct',
    labelMode: 'floating',
  };
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const isAddMode = !idMember;

  const { auth } = useAuth();
  const PermissionsList = auth.permissions;

  const [valueContent, setValueContent] = useState('');
  const [phoneEditorOptions, setPhoneEditorOptions] = useState(phoneCaEditorOptions);
  const [phoneSecondEditorOptions, setPhoneSecondEditorOptions] = useState(phoneCaEditorOptions);
  const [items, setItems] = useState({});

  const dataTags = useGetMergTags();

  const [agencydata, setAgencydata] = useState([]);
  const [isAgency, setIsAgency] = useState(false);
  const residenceOwner =
    // eslint-disable-next-line no-nested-ternary
    auth.role === 'super_admin'
      ? ['super_admin', 'residence_owner', 'admin']
      : auth.role === 'admin'
      ? ['admin', 'residence_owner']
      : ['residence_owner'];

  const refFormAddMember = useRef('AddMemberForm');
  const { suggestions, setValue } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'ca',
      },
    },
  });
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const notes = valueContent;
      const { phone, second_phone, fax, second_email, cci_email, ...rest } = items;
      const values = {
        ...rest,
        notes,
        phone: JSON.stringify({ ...phone, type: 'main' }),
      };
      if (second_email && second_email.email) {
        values.second_email = JSON.stringify(second_email);
      }
      if (cci_email && cci_email.email) {
        values.cci_email = JSON.stringify(cci_email);
      }
      if (second_phone && second_phone.number) {
        values.second_phone = JSON.stringify({ ...second_phone, type: 'other' });
      }
      if (fax && fax.number) {
        values.fax = JSON.stringify({ ...fax, type: 'fax' });
      }
      setLoading(true);
      return isAddMode ? addMember(values) : updateMemberById(idMember, values);
    },
    [items, valueContent, agencydata]
  );

  const addMember = (values) => {
    create('users', values)
      .then((res) => {
        setLoading(false);
        resultAddMember(res.data.data);
        res.data && setItems({});
        res.data && Toast(t('members.add_member_msg'));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateMemberById = (memberId, values) => {
    update('users', memberId, values)
      .then((res) => {
        const { id: updateMemberId } = res.data.data;
        setLoading(false);
        res.data && history.push(`/membres/${updateMemberId}`);
        res.data && Toast(t('members.edit_member_msg'));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const passwordComparison = () => {
    return items.password;
  };

  /** Form Field Data changed */
  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    const { agency_id, ...rest } = data;
    const isAgencyValid = data.role === Roles.CLEANING_AGENCY;
    setIsAgency(isAgencyValid);
    setItems(isAgencyValid ? data : rest);
    if (isAgencyValid) {
      getAll('agencies').then((response) => {
        setAgencydata(response.data.data);
      });
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 USEEFFECTS                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (!isAddMode) {
      get('users', idMember).then((response) => {
        const { data } = response.data;
        const { notes } = data;
        setItems(data);
        setIsAgency(data.role === Roles.CLEANING_AGENCY);
        setValueContent(notes ?? '');
        getAll('agencies').then((res) => {
          setAgencydata(res.data.data);
        });
      });
    }
  }, [idMember]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block">
      <div className="dx-card responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form
            ref={refFormAddMember}
            formData={items}
            onFieldDataChanged={formFieldDataChanged}
            disabled={loading}
            showValidationSummary
          >
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              <Tab title="Informations de base">
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="first_name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le Prénom est requis" />
                    <Label text="Prénom" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="last_name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom est requis" />
                    <Label text="Nom" visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={3}>
                  <SimpleItem
                    dataField="email"
                    editorType="dxTextBox"
                    editorOptions={emailEditorOptions}
                    helpText="Cette adresse sera utilisé pour la connexion."
                  >
                    <RequiredRule message="Le courriel est requis" />
                    <EmailRule message="Le courriel est invalide" />
                    <Label text="Courriel" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="second_email.email" editorType="dxTextBox" editorOptions={emailEditorOptions}>
                    <EmailRule message="Le courriel est invalide" />
                    <Label text="Courriel (autre)" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="cci_email.email" editorType="dxTextBox" editorOptions={emailEditorOptions}>
                    <EmailRule message="Le courriel est invalide" />
                    <Label text="Courriel (cci)" visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="phone.number" editorType="dxTextBox" editorOptions={phoneEditorOptions}>
                    <RequiredRule message="Le téléphone est requis" />
                    <Label text="Téléphone" visible={false} />
                  </SimpleItem>
                  <GroupItem colCount={2}>
                    <SimpleItem dataField="phone.extension" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <Label text="Ext." visible={false} />
                    </SimpleItem>
                    <SimpleItem>
                      <Button
                        className="btn-icon"
                        icon={caImg}
                        onClick={() => setPhoneEditorOptions(phoneCaEditorOptions)}
                      />
                      <Button
                        className="btn-icon"
                        icon={euImg}
                        onClick={() => setPhoneEditorOptions(phoneEuEditorOptions)}
                      />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem
                    dataField="second_phone.number"
                    editorType="dxTextBox"
                    editorOptions={phoneSecondEditorOptions}
                  >
                    <Label text="Téléphone (autre)" visible={false} />
                  </SimpleItem>
                  <GroupItem colCount={2}>
                    <SimpleItem
                      dataField="second_phone.extension"
                      editorType="dxTextBox"
                      editorOptions={textEditorOptions}
                    >
                      <Label text="Ext. (autre)" visible={false} />
                    </SimpleItem>
                    <SimpleItem>
                      <Button
                        className="btn-icon"
                        icon={caImg}
                        onClick={() => setPhoneSecondEditorOptions(phoneCaEditorOptions)}
                      />
                      <Button
                        className="btn-icon"
                        icon={euImg}
                        onClick={() => setPhoneSecondEditorOptions(phoneEuEditorOptions)}
                      />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
                <SimpleItem dataField="fax.number" editorType="dxTextBox" editorOptions={phoneCaEditorOptions}>
                  <Label text="Fax" visible={false} />
                </SimpleItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem
                    dataField="address"
                    editorType="dxAutocomplete"
                    editorOptions={AutoCompleteEditorOptions(setValue, suggestions, refFormAddMember)}
                  >
                    <RequiredRule message="L'adresse est requise" />
                    <Label text="Adresse" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="province" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="La province est requise" />
                    <Label text="Province" visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="city" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="La ville est requise" />
                    <Label text="Ville" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="postal_code" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le code postal est requis" />
                    <Label text="Code postal" visible={false} />
                  </SimpleItem>
                </GroupItem>
              </Tab>
              <Tab title="Mot de passe et permission">
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="password" editorType="dxTextBox" editorOptions={passwordEditorOptions}>
                    {isAddMode && <RequiredRule message="Le mot de passe est requis" />}
                    <Label text="Mot de passe" visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="password_confirmation"
                    editorType="dxTextBox"
                    editorOptions={passwordEditorOptions}
                    helpText="Confirmation du nouveau mot de passe."
                  >
                    {isAddMode && <RequiredRule message="La confirmation est requise" />}
                    {isAddMode && (
                      <CompareRule
                        message="Le mot de passe et la confirmation du mot de passe ne correspondent pas"
                        comparisonTarget={passwordComparison}
                      />
                    )}
                    <Label text="Confirmation" visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem
                    dataField="role"
                    editorType="dxSelectBox"
                    editorOptions={selectEditorOptions(
                      !residence
                        ? permissions(PermissionsList)
                        : permissions(PermissionsList).filter((el) => residenceOwner.includes(el.id))
                    )}
                  >
                    <RequiredRule message="La permission est requise" />
                    <Label text="Permission" visible={false} />
                  </SimpleItem>
                  {isAgency && (
                    <SimpleItem
                      dataField="agency_id"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(agencydata)}
                    >
                      <RequiredRule message="L'agence de ménage est requise" />
                      <Label text="Agence de ménage" visible={false} />
                    </SimpleItem>
                  )}
                </GroupItem>
              </Tab>
              <Tab title="Note">
                <EditorInput dataSource={dataTags} valueContent={valueContent} valueChanged={setValueContent} />
              </Tab>
            </TabbedItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={closeFormAction}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

MemberFormComponent.propTypes = {
  resultAddMember: PropTypes.func.isRequired,
  closeFormAction: PropTypes.func.isRequired,
  idMember: PropTypes.number,
  residence: PropTypes.bool,
};

MemberFormComponent.defaultProps = {
  idMember: null,
  residence: false,
};
