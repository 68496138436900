import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './etats_compte.scss';
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  LoadPanel,
  Sorting,
  Summary,
  Button,
  TotalItem,
  Selection,
  FilterRow,
} from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import { Button as ButtonAction } from 'devextreme-react/button';
import { useAuth } from '../../../contexts/auth';
import RenderHeaderPage from '../../../components/widgets/headerResidance';
import CheckboxCustom from '../../../components/checkBox/checkBox';
import WidgetImage from '../../../components/widgets/mainImage';
import WidgetActions from '../../../components/widgets/actions';
import { getAll } from '../../../api';
import { getPermissionVisibility, priceColumn_customizeText } from '../../../utils';

export default function EtatsCompteGenerator() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { auth } = useAuth();
  const [dataList, setDataList] = useState({});
  const [totalPay, setTotalPay] = useState(0);
  const [checkBoxValue, setCheckBoxValue] = useState(0);

  const navigateBack = () => {
    // eslint-disable-next-line no-restricted-globals
    history.push(`/residences/details/${id}`);
  };

  const listActions = [
    {
      id: 1,
      name: t('common.cancel'),
      icon: 'fa fa-file',
      onPress: () => history.push(`/residences/etats_comptes/${id}`),
      styles: 'orangeBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 2,
      name: t('residance.backToResidance'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBack,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
  ];

  const customDataSource = [
    {
      id: 1,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: true,
      payee: false,
    },
    {
      id: 2,
      date: '2021-01-31',
      type: 'CR',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 3,
      date: '2021-01-31',
      type: 'CR',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 4,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 5,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 6,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 7,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 8,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 9,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 10,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
    {
      id: 11,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
      trans: false,
      payee: false,
    },
  ];

  const calculateSalesAmount = (rowData) => {
    return (parseFloat(rowData.fact.replace(',', '')) + parseFloat(rowData.credit.replace(',', ''))).toFixed(3);
  };
  function isCancelButtonVisible(e) {
    return !e.row.isEditing && !e.row.data.isCompleted;
  }

  const statusTransfer = (cell) => {
    if (cell.data.type === 'FA')
      return (
        <CheckBox
          defaultValue={cell.data.trans}
          onValueChanged={() => {
            console.log('transf');
          }}
          itemRender
        />
      );
    return false;
  };

  const calcTotal = useCallback((status, cell) => {
    if (status) {
      setTotalPay(totalPay + cell);
    } else {
      setTotalPay(totalPay - cell);
    }
  });

  const statusPayed = (cell) => {
    return <CheckboxCustom handelStatus={(status) => calcTotal(status, cell.data.mont)} />;
  };

  useEffect(() => {
    getAll(`tariffs/${id}/basic`).then((res) => {
      setDataList(res.data);
    });
  }, []);

  return (
    <div className="content-block ">
      <RenderHeaderPage
        t={t}
        id=""
        name={dataList ? `${t('residance.account_status')}, ${dataList.name}` : 'page'}
        officialName={dataList.official_name}
      />
      <div className="mainContainer">
        <div className="side">
          <WidgetImage t={t} src={dataList?.main_photo ?? ''} />

          <div className="dx-card responsive-paddings client_item client_item-actions">
            <div className="client_item_title">
              <i className="fa fa-edit" />
              {t('common.actions')}
            </div>
            <div className="client_btn">
              {listActions.map((el) => {
                return (
                  el.display && (
                    <div key={el.id} className="containerBtnsActions">
                      <ButtonAction
                        disabled={el.disabled}
                        className={el.styles}
                        text={el.name}
                        icon={el.icon}
                        onClick={() => el.onPress()}
                      />
                    </div>
                  )
                );
              })}
              {totalPay !== 0 && (
                <>
                  <hr className="solid" />
                  <div className="show_operation">
                    <div>Total Payées: {totalPay}</div>
                    <ButtonAction
                      className="greenBtnBackground actionBtn btnForWithBackGrounColor"
                      text={t('common.confirm')}
                      icon="fa fa-arrow-left"
                      onClick={() => console.log('confirm')}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <DataGrid
          className="dx-card wide-card"
          dataSource={customDataSource}
          showBorders
          defaultFocusedRowIndex={0}
          allowHeaderFiltering
          columnAutoWidth
        >
          <Sorting mode="none" />
          <LoadPanel enabled />
          <Paging defaultPageSize={10} />
          <Column caption="Payées" dataField="payee" cellRender={statusPayed} />
          <Column caption="TRANSFÉRÉ" dataField="transf" cellRender={statusTransfer} />
          <Column dataField="date" caption="Date" />
          <Column dataField="type" caption="TYPE" />
          <Column dataField="num" caption="Numéro" />
          <Column dataField="ref" caption="Réference" />
          <Column dataField="num" caption="Numéro" />
          <Column dataField="mont" caption="Montant" customizeText={priceColumn_customizeText} />
          <Column type="buttons">
            <Button
              hint="Voir"
              icon="fa fa-eye"
              onClick={(e) => history.push(`/${e.row.data.id}`)}
              className="actionBtn"
              visible={(e) => e.row.data.type === 'CR'}
            />
          </Column>
          <Summary width={200}>
            <TotalItem
              column="mont"
              summaryType="sum"
              valueFormat="decimal"
              width={200}
              displayFormat="Facture {0} $"
            />
            <TotalItem column="mont" summaryType="sum" valueFormat="decimal" displayFormat="Crédit {0} $" />
            <TotalItem column="mont" summaryType="sum" valueFormat="decimal" displayFormat="Total {0} $" />
          </Summary>
        </DataGrid>
      </div>
    </div>
  );
}
