import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  Button,
  LoadPanel,
  Sorting,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import './depenses.scss';
import { DateBox, Button as ButtonGoBack } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import Hero from '../../../components/hero/hero';
import { getAll, remove } from '../../../api';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { priceColumn_customizeText, Toast } from '../../../utils';

const FakeDAta = [
  {
    id: 1,
    remb: true,
    date: '1 avril 2018',
    code: '1253',
    category: 'Conciergerie Charlevoix',
    montant: 1568,
  },
  {
    id: 2,
    remb: true,
    date: '2 avril 2018',
    code: '596856',
    category: 'Remboursement des locations AIRBNB',
    montant: -100,
  },
  {
    id: 3,
    remb: true,
    date: '2 avril 2018',
    code: '596856',
    category: 'Remboursement des locations AIRBNB',
    montant: -100,
  },
  {
    id: 4,
    remb: true,
    date: '2 avril 2018',
    code: '596856',
    category: 'Remboursement des locations AIRBNB',
    montant: -100,
  },
  {
    id: 5,
    remb: false,
    date: '2 avril 2018',
    code: '596856',
    category: 'Remboursement des locations AIRBNB',
    montant: -100,
  },
  {
    id: 6,
    remb: false,
    date: '2 avril 2018',
    code: '596856',
    category: 'Remboursement des locations AIRBNB',
    montant: -100,
  },
];

export default function Depenses() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const [date, setDate] = useState(new Date());
  const [customDataSource, setCustomDataSource] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteID, setDeleteID] = useState('');

  const [popupRembVisible, setPopupRembVisible] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [rembData, setrembData] = useState({ id: null, remb: false });

  const popupDetailsList = [
    {
      title: t('depenses.depense_remb_title'),
      msg: t('depenses.depense_remb_msg'),
      deleteActivity: () => rembDepense(),
      textBtn: t('depenses.depense_remb_btn'),
      typeBtn: 'success',
    },
    {
      title: t('depenses.depense_imremb_title'),
      msg: t('depenses.depense_imremb_msg'),
      deleteActivity: () => rembDepense(),
      textBtn: t('depenses.depense_imremb_btn'),
      typeBtn: 'default',
    },
  ];

  const showRembModal = (e) => {
    if (e.rowType === 'data' && e.column.dataField === 'remb') {
      setPopupRembVisible(true);
      setrembData({ id: e.row.data.id, remb: e.row.data.remb });
      setPopupDetail(e.row.data.remb ? popupDetailsList[1] : popupDetailsList[0]);
    }
  };

  const rembDepense = () => {
    // @ts-ignore
    setCustomDataSource((state) =>
      state.map((item) => {
        if (item.id === rembData.id) {
          return { ...item, remb: !rembData.remb };
        }
        return item;
      })
    );
    setPopupRembVisible(false);
  };

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteDepense = (idDepense) => {
    setCustomDataSource(customDataSource.filter((data) => data.id !== idDepense));
    hideModal();
    Toast(t('common.successDelete', { title: 'La dépense' }));
    // remove('depenses', idDepense).then(() => {
    //   setCustomDataSource(customDataSource.filter((data) => data.id !== idDepense));
    //   hideModal();
    //   Toast(t('common.successDelete', { title: 'La dépense' }));
    // });
  };

  useEffect(() => {
    setCustomDataSource(FakeDAta);
    // getAll('depenses').then((response) => {
    //   setCustomDataSource(response.data.data);
    // });
  }, []);

  return (
    <>
      <ButtonGoBack
        className="goback-btn"
        icon="chevronprev"
        text={t('residance.backToResidance')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/residences/details/${id}`)}
      />
      <Hero
        title={t('depenses.title')}
        btnText={t('depenses.depense_add')}
        url={`/residences/depenses/ajouter/${id}`}
      />
      <div className="dx-field data_select">
        <div className="dx-field-label" />
        <div className="dx-field-value content-block">
          <DateBox
            acceptCustomValue={false}
            value={date}
            onValueChanged={({ value }) => setDate(value)}
            calendarOptions={{ maxZoomLevel: 'year' }}
            displayFormat="MMMM yyyy"
          />
        </div>
      </div>
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
        onCellClick={showRembModal}
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="remb" caption="REMBOURSÉE" />
        <Column dataField="date" caption="DATE" />
        <Column dataField="code" caption="CODE" />
        <Column dataField="category" caption="CATÉGORIE" />
        <Column dataField="montant" caption="MONTANT" alignment="left" customizeText={priceColumn_customizeText} />
        <Column type="buttons" width={110}>
          <Button
            hint="Modifier"
            icon="edit"
            onClick={(e) => history.push(`/residences/depenses/modifier/${id}/${e.row.data.id}`)}
          />
          <Button icon="trash" hint="Supprimer" onClick={showModal} />
        </Column>
        <Summary>
          <TotalItem column="montant" summaryType="sum" valueFormat="decimal" displayFormat="Total: {0} $" />
        </Summary>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={t('depenses.depense_delete_title')}
        msg={t('depenses.depense_delete_msg')}
        deleteActivity={() => deleteDepense(deleteID)}
      />
      <DeletePopup
        popupVisible={popupRembVisible}
        hideModal={() => setPopupRembVisible(false)}
        title={popupDetail.title}
        msg={popupDetail.msg}
        deleteActivity={popupDetail.deleteActivity}
        textBtn={popupDetail.textBtn}
        typeBtn={popupDetail.typeBtn}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
