import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  Button,
  LoadPanel,
  Sorting,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { Button as ButtonGoBack } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import Hero from '../../../components/hero/hero';
import { getAll, remove } from '../../../api';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { priceColumn_customizeText, Toast } from '../../../utils';

const FakeDAta = [
  {
    id: 1,
    app: true,
    date: '1 avril 2018',
    titre: 'Conciergerie Charlevoix',
    montant: 1568,
  },
  {
    id: 2,
    app: true,
    date: '1 avril 2018',
    titre: 'Conciergerie Charlevoix',
    montant: 1568,
  },
  {
    id: 3,
    app: false,
    date: '1 avril 2018',
    titre: 'Conciergerie Charlevoix',
    montant: 1568,
  },
  {
    id: 4,
    app: false,
    date: '1 avril 2018',
    titre: 'Conciergerie Charlevoix',
    montant: 1568,
  },
  {
    id: 5,
    app: false,
    date: '1 avril 2018',
    titre: 'Conciergerie Charlevoix',
    montant: 1568,
  },
  {
    id: 6,
    app: false,
    date: '1 avril 2018',
    titre: 'Conciergerie Charlevoix',
    montant: 1568,
  },
];

export default function Credits() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteID, setDeleteID] = useState('');

  const [popupAppVisible, setPopupAppVisible] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [appData, setappData] = useState({ id: null, app: false });

  const popupDetailsList = [
    {
      title: t('credits.credit_app_title'),
      msg: t('credits.credit_app_msg'),
      deleteActivity: () => applicredit(),
      textBtn: t('credits.credit_app_btn'),
      typeBtn: 'success',
    },
    {
      title: t('credits.credit_inapp_title'),
      msg: t('credits.credit_inapp_msg'),
      deleteActivity: () => applicredit(),
      textBtn: t('credits.credit_inapp_btn'),
      typeBtn: 'default',
    },
  ];

  const showAppModal = (e) => {
    if (e.rowType === 'data' && e.column.dataField === 'app') {
      setPopupAppVisible(true);
      setappData({ id: e.row.data.id, app: e.row.data.app });
      setPopupDetail(e.row.data.app ? popupDetailsList[1] : popupDetailsList[0]);
    }
  };

  const applicredit = () => {
    // @ts-ignore
    setCustomDataSource((state) =>
      state.map((item) => {
        if (item.id === appData.id) {
          return { ...item, app: !appData.app };
        }
        return item;
      })
    );
    setPopupAppVisible(false);
  };

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteCredit = (idCredit) => {
    setCustomDataSource(customDataSource.filter((data) => data.id !== idCredit));
    hideModal();
    Toast(t('common.successDeleteM', { title: 'Le crédit' }));
    // remove('credits', idCredit).then(() => {
    //   setCustomDataSource(customDataSource.filter((data) => data.id !== idCredit));
    //   hideModal();
    //   Toast(t('common.successDeleteM', { title: 'Le crédit' }));
    // });
  };

  useEffect(() => {
    setCustomDataSource(FakeDAta);
    // getAll('credits').then((response) => {
    //   setCustomDataSource(response.data.data);
    // });
  }, []);

  return (
    <>
      <ButtonGoBack
        className="goback-btn"
        icon="chevronprev"
        text={t('residance.backToResidance')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/residences/details/${id}`)}
      />
      <Hero title={t('credits.title')} btnText={t('credits.credit_add')} url={`/residences/credits/ajouter/${id}`} />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
        onCellClick={showAppModal}
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="app" caption="APPLIQUÉ" />
        <Column dataField="date" caption="DATE" />
        <Column dataField="titre" caption="TITRE" />
        <Column dataField="montant" caption="MONTANT" alignment="left" customizeText={priceColumn_customizeText} />
        <Column type="buttons" width={110}>
          <Button
            hint="Modifier"
            icon="edit"
            onClick={(e) => history.push(`/residences/credits/modifier/${id}/${e.row.data.id}`)}
          />
          <Button icon="trash" hint="Supprimer" onClick={showModal} />
        </Column>
        <Summary>
          <TotalItem column="montant" summaryType="sum" valueFormat="decimal" displayFormat="Total: {0} $" />
        </Summary>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={t('credits.credit_delete_title')}
        msg={t('credits.credit_delete_msg')}
        deleteActivity={() => deleteCredit(deleteID)}
      />
      <DeletePopup
        popupVisible={popupAppVisible}
        hideModal={() => setPopupAppVisible(false)}
        title={popupDetail.title}
        msg={popupDetail.msg}
        deleteActivity={popupDetail.deleteActivity}
        textBtn={popupDetail.textBtn}
        typeBtn={popupDetail.typeBtn}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
