import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../contexts/auth';

export default function PermissionComponent({ scope, rightchildren, nonrightchildren }) {
  const { auth } = useAuth();
  const PermissionsList = auth.permissions;
  const HasScope = PermissionsList.includes(scope);
  if (!HasScope) {
    return nonrightchildren;
  }
  return rightchildren;
}

PermissionComponent.propTypes = {
  scope: PropTypes.string.isRequired,
  rightchildren: PropTypes.element.isRequired,
  nonrightchildren: PropTypes.element.isRequired,
};
