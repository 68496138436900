import React, { useCallback, useEffect, useState } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { create, get, update } from '../../../api';
import { NumberEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { Toast } from '../../../utils';
import { dateBoxOptions } from '../residence-form/input-options';

export default function DepenseForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { itemId, id } = useParams();
  const [loading, setLoading] = useState(false);
  const isAddMode = !itemId;
  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return isAddMode ? addDepense(items) : updateDepense(items);
    },
    [items]
  );

  const addDepense = (values) => {
    create(`depenses/${id}`, values)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Toast(t('common.successAdd', { title: 'La dépense' }));
          history.push(`/residences/depenses/${id}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateDepense = (values) => {
    update(`depenses/${id}`, itemId, values)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Toast(t('common.successUpdate', { title: 'La dépense' }));
          history.push(`/residences/depenses/${id}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isAddMode) {
      // get(`depenses/${id}`, itemId).then((response) => {
      //   const { data } = response.data;
      //   setItems(data);
      // });
    }
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('depenses.go_back_depense')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/residences/depenses/${id}`)}
      />
      <h2 className="content-block">{isAddMode ? t('depenses.depense_add') : t('depenses.depense_edit')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Détails">
                  <SimpleItem dataField="date" editorType="dxDateBox" editorOptions={dateBoxOptions}>
                    <RequiredRule message="la date est requise" />
                    <Label text="Date" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="number_facture" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="le # de facture est requis" />
                    <Label text="# de facture" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="category" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="la catégorie est requise" />
                    <Label text="Catégorie" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="montant" editorType="dxNumberBox" editorOptions={NumberEditorOptions}>
                    <RequiredRule message="le montant est requis" />
                    <Label text="Montant" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="details" editorType="dxTextArea" editorOptions={textEditorOptions}>
                    <Label text="Détails" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push(`/residences/depenses/${id}`)}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
