// eslint-disable-next-line import/no-named-as-default
import ApiClient from './config/api-client';

export async function signIn(email, password) {
  const data = { email, password, token_name: 'bearer' };
  return await ApiClient.post('login', data);
}

export async function signOut() {
  return await ApiClient.post('logout');
}
