import React from 'react';
import { useHistory } from 'react-router-dom';
import './hero.scss';
import { Button as AddButton } from 'devextreme-react/button';
import PropTypes from 'prop-types';

export default function Hero(props) {
  const { title, btnText, url, type, icon, onClickBtn, cssClassLabel } = props;
  const history = useHistory();
  return (
    <div className="dx-field">
      <div className={`dx-field-label ${cssClassLabel && 'cssClassLabel'}`}>
        <h2 className="content-block">{title}</h2>
      </div>
      <div className="dx-field-value content-block">
        <AddButton
          icon={icon}
          type={type}
          text={btnText}
          onClick={() => {
            if (url) {
              history.push(url);
            } else {
              onClickBtn();
            }
          }}
        />
      </div>
    </div>
  );
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.oneOf(['success', 'default', 'normal']),
  icon: PropTypes.string,
  onClickBtn: PropTypes.func,
  cssClassLabel: PropTypes.bool,
};

Hero.defaultProps = {
  icon: 'add',
  type: 'success',
  url: null,
  onClickBtn: null,
  cssClassLabel: false,
};
