import { Button } from 'devextreme-react';
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';

export default function WidgetPrices({ t, textButton }) {
  return (
    <div className="dx-card responsive-paddings-widget widget">
      <div className="widget_item_title">
        <i className="fa fa-tags" />
        {t('residance.price_list')}
      </div>
      <Button text={textButton} className="priceBtn" />
    </div>
  );
}

WidgetPrices.propTypes = {
  textButton: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
