import React, { useCallback, useEffect, useState, useRef } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { create, get, update } from '../../../api';
import { textEditorOptions } from '../../../utils/editorOptions';
import './styles.scss';
import { Toast } from '../../../utils';
import EditorInput from '../../editor-input/editor-input';
import useGetMergTags from '../../../hooks/useGetTags';
import { checkBoxOptions } from '../residence-form/input-options';

export default function AddExtractForm() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const history = useHistory();
  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [valueContentFR, setValueContentFR] = useState('');
  const [valueContentEN, setValueContentEN] = useState('');
  const refForm = useRef('addExtraitForm');
  const [items, setItems] = useState({});
  const { t } = useTranslation();
  const dataTags = useGetMergTags();

  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const navigateToBack = () => {
    history.goBack();
  };
  const addExtract = (values) => {
    create('snippets', values)
      .then((res) => {
        setLoading(false);
        res.status === 200 && Toast(t('common.successAddExtact'));
        navigateToBack();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateExtract = (updateData) => {
    const { show_in_residence, show_in_checkout, ...rest } = updateData;
    const dataToSend = {
      ...rest,
      show_in_checkout: show_in_checkout ? 1 : 0,
      show_in_residence: show_in_residence ? 1 : 0,
    };
    update('snippets', id, dataToSend)
      .then((res) => {
        setLoading(false);
        res.status === 200 && Toast(t('common.successUpdateExtact'));
        navigateToBack();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (valueContentFR === '' || valueContentEN === '') {
        return notify('Les champs français et anglais sont requis', 'error', 4000);
      }
      const array = [
        {
          type: 'title',
          language: 'fr',
          value: items.title_fr,
        },
        {
          type: 'title',
          language: 'en',
          value: items.title_en,
        },
        {
          type: 'text',
          language: 'fr',
          value: valueContentFR,
        },
        {
          type: 'text',
          language: 'en',
          value: valueContentEN,
        },
      ];

      const dataToSend = {
        label: items.label,
        texts: JSON.stringify(array),
        show_in_checkout: items.show_in_checkout ? 1 : 0,
        show_in_residence: items.show_in_residence ? 1 : 0,
      };

      const formData = new FormData();
      Object.keys(dataToSend).map((key) => formData.append(key, dataToSend[key]));
      return isAddMode ? addExtract(formData) : updateExtract(dataToSend);
    },
    [valueContentEN, valueContentFR, items.title_en]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (!isAddMode) {
      get('snippets', id).then(({ data: { data } }) => {
        setItems(data);
        setValueContentFR(data.text_fr);
        setValueContentEN(data.text_en);
      });
    }
  }, [isAddMode]);
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */

  return (
    <div>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page extraits"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/extraits')}
      />
      <h2 className="content-block">{isAddMode ? 'Ajouter un extrait' : 'Modifier une extrait'}</h2>
      <div className=" content-block dx-card responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form
            showValidationSummary
            ref={refForm}
            formData={items}
            onFieldDataChanged={formFieldDataChanged}
            disabled={loading}
            validationGroup="formGroup"
          >
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              <Tab title="Français">
                <SimpleItem dataField="title_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                  <RequiredRule message={t('errors.required_titleFr')} />
                  <Label text={t('residance.title')} visible={false} />
                </SimpleItem>
                <GroupItem>
                  <div className="dx-field-value editor_width">
                    <EditorInput dataSource={dataTags} valueContent={valueContentFR} valueChanged={setValueContentFR} />
                  </div>
                </GroupItem>
              </Tab>
              <Tab title="Anglais">
                <SimpleItem dataField="title_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                  <RequiredRule message={t('errors.required_titleEn')} />
                  <Label text="Titre" visible={false} />
                </SimpleItem>
                <GroupItem>
                  <div className="dx-field-value editor_width">
                    <EditorInput dataSource={dataTags} valueContent={valueContentEN} valueChanged={setValueContentEN} />
                  </div>
                </GroupItem>
              </Tab>
              <Tab title={t('price.params')}>
                <SimpleItem dataField="label" editorType="dxTextBox" editorOptions={textEditorOptions}>
                  <RequiredRule message={t('errors.required_label')} />
                  <Label text="Étiquette" visible={false} />
                </SimpleItem>
                <SimpleItem
                  dataField="show_in_residence"
                  editorType="dxCheckBox"
                  editorOptions={checkBoxOptions(t('residance.display_on_website'))}
                >
                  <Label visible={false} />
                </SimpleItem>
                <SimpleItem
                  dataField="show_in_checkout"
                  editorType="dxCheckBox"
                  editorOptions={checkBoxOptions(t('residance.checkout'))}
                >
                  <Label visible={false} />
                </SimpleItem>
              </Tab>
            </TabbedItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={() => history.push('/extraits')}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}
