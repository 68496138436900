import { Button } from 'devextreme-react';
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function WidgetActions({ ListAction, dviderIndexs, t, title }) {
  const FacturesLength = '"5"';
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="dx-card responsive-paddings-widget widget">
      <div className="widget_item_title">
        <i className="fa fa-gear" /> {title || t('reservation.title_reservation')}
      </div>
      {ListAction.map((el) => {
        return (
          el.display && (
            <div key={el.id} className="containerBtnsActions">
              <Button
                disabled={el.disabled}
                className={el.styles}
                style={{ '--nbr': FacturesLength }}
                text={el.name}
                icon={el.icon}
                onClick={() => el.onPress()}
              />
              {dviderIndexs && dviderIndexs?.includes(el.id) && <hr className="solid" />}
            </div>
          )
        );
      })}
    </div>
  );
}

WidgetActions.propTypes = {
  ListAction: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  dviderIndexs: PropTypes.array,
  title: PropTypes.string,
};

WidgetActions.defaultProps = {
  dviderIndexs: [],
  title: '',
};
