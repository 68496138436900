import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './member-form.scss';
import { Button } from 'devextreme-react/button';
import MemberFormComponent from '../member-form-component/member-form-component';

export default function MemberForm() {
  const history = useHistory();
  const { id } = useParams();
  const isAddMode = !id;

  const resultAddMember = (data) => {
    data && history.push(`/membres/${data.id}`);
  };

  const closeFormAction = () => {
    return isAddMode ? history.push('/membres') : history.push(`/membres/${id}`);
  };

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Membres"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/membres')}
      />
      <h2 className="content-block">{isAddMode ? 'Ajouter un membre' : 'Modifier un membre'}</h2>
      <MemberFormComponent resultAddMember={resultAddMember} closeFormAction={closeFormAction} idMember={id} />
    </>
  );
}
