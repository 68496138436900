import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting, Summary, TotalItem } from 'devextreme-react/data-grid';
import { priceColumn_customizeText } from '../../../../utils';

export default function PaymentListing({ customDataSource }) {
  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
      showColumnHeaders={false}
    >
      <Sorting mode="none" />
      <LoadPanel enabled />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />

      <Column dataField="title" />
      <Column dataField="amount" alignment="left" customizeText={priceColumn_customizeText} />
      <Summary>
        <TotalItem column="title" displayFormat="Total" />
        <TotalItem column="amount" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
      </Summary>
    </DataGrid>
  );
}

const allowedPageSizes = [5, 10, 20];

PaymentListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
