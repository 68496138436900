/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import './holidays-form.scss';
import moment from 'moment';
import { Tab } from 'devextreme-react/diagram';
import Button from 'devextreme-react/button';
import { textEditorOptions } from '../../../utils/editorOptions';
import { create, get, update } from '../../../api';
import { Toast } from '../../../utils';

export default function HolidaysForm() {
  const history = useHistory();
  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [periodesList, setPeriodesList] = useState(['']);

  const [items, setItems] = useState({ ranges: {} });
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const addButtonOptions = {
    icon: 'add',
    text: 'Ajouter une date',
    onClick: () => {
      addPeriodeData();
      setPeriodesList([...periodesList, '']);
    },
  };

  const addPeriodeData = () => {
    const { ranges } = items;
    ranges[Object.keys(ranges).length] = { start_date: undefined, end_date: undefined };
    setItems({ ...items, ranges });
  };

  const deletePeriode = (index) => {
    const periodeData = deletePeriodeData(index);
    items.ranges = periodeData;
    setItems(items);
    setPeriodesList([...periodesList.filter((_, i) => i !== index)]);
  };

  const deletePeriodeData = (index) => {
    const data = items.ranges && Object.values(items.ranges).filter((_, i) => i !== index);
    return { ...data };
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { ranges, ...rest } = items;
      const periodes = Object.values(ranges).map((elt) => {
        return {
          start_date: moment(elt.start_date).format('YYYY-MM-DD'),
          end_date: moment(elt.end_date).format('YYYY-MM-DD'),
        };
      });
      const values = {
        ...rest,
        ranges: JSON.stringify(periodes),
      };
      return isAddMode ? addHoliday(values) : updateHolidayById(id, values);
    },
    [items]
  );

  const addHoliday = (values) => {
    create('holidays', values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/jours-special');
        res.data && Toast('Le jour férié a été bien ajouté');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateHolidayById = (periodeId, values) => {
    update('holidays', periodeId, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/jours-special');
        res.data && Toast('Le jour férié a été bien modifié');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
    forceUpdate();
  };

  const startDateBoxOptions = (index) => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
      openOnFieldClick: true,
      showClearButton: true,
      max: items.ranges && items.ranges[index]?.end_date,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  const endDateBoxOptions = (index) => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
      openOnFieldClick: true,
      showClearButton: true,
      min: items.ranges && items.ranges[index]?.start_date,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  useEffect(() => {
    if (!isAddMode) {
      get('holidays', id).then((response) => {
        const { ranges, ...rest } = response.data.data;
        const data = Array(ranges.length).fill('');
        setPeriodesList(data);
        const values = {
          ...rest,
          ranges: { ...ranges },
        };
        setItems(values);
      });
    }
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Jours fériés"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/jours-special')}
      />
      <h2 className="content-block">{isAddMode ? 'Ajouter un jour férié' : `Modifier un jour férié ${items?.name}`}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Informations et dates">
                  <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom est requis" />
                    <Label text="Nom" visible={false} />
                  </SimpleItem>
                  <GroupItem caption="Dates">
                    <GroupItem>
                      {periodesList.map((_, index) => (
                        <GroupItem itemType="group" key={index} colCount={3}>
                          <SimpleItem
                            dataField={`ranges[${index}].start_date`}
                            editorType="dxDateBox"
                            editorOptions={startDateBoxOptions(index)}
                          >
                            <Label text="Date de début" visible={false} />
                            <RequiredRule message="La date de début est requise" />
                          </SimpleItem>
                          <SimpleItem
                            dataField={`ranges[${index}].end_date`}
                            editorType="dxDateBox"
                            editorOptions={endDateBoxOptions(index)}
                          >
                            <Label text="Date de fin" visible={false} />
                            <RequiredRule message="La date de fin est requise" />
                          </SimpleItem>
                          <ButtonItem>
                            <ButtonOptions
                              icon="trash"
                              type="normal"
                              hint="Supprimer"
                              onClick={() => {
                                deletePeriode(index);
                              }}
                            />
                          </ButtonItem>
                        </GroupItem>
                      ))}
                    </GroupItem>
                    <SimpleItem
                      itemType="button"
                      cssClass="add-button"
                      horizontalAlignment="left"
                      buttonOptions={addButtonOptions}
                    />
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() => {
                      history.push('/jours-special');
                    }}
                  >
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
