import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Form, { SimpleItem, Label, RequiredRule, GroupItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { LoadIndicator } from 'devextreme-react';
import { textEditorOptions } from '../../utils/editorOptions';
import './styles/index.scss';
import { Toast } from '../../utils';

import { create, update } from '../../api';

export default function MediaVideoFormPopup({ hideAddModal, t, selectedVideo }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({});
  const { id } = useParams();

  const addVideo = (values) => {
    if (Object.keys(selectedVideo).length === 0) {
      const formDataVideo = { ...values, residence_id: id };
      create(`residence_videos/${id}`, formDataVideo)
        .then((res) => {
          setLoading(false);
          hideAddModal('update');
          res.data && Toast(t('common.successAdd', { title: 'La vidéo' }));
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      const formDataVideo = { ...values, residence_id: id };
      update('residence_videos', `${id}/${selectedVideo.id}`, formDataVideo)
        .then((res) => {
          setLoading(false);
          hideAddModal('update');
          res.data && Toast(t('common.successUpdate', { title: 'La vidéo' }));
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return addVideo(items);
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (selectedVideo) {
      setItems({ ...selectedVideo });
    }
  }, [selectedVideo]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block">
      <div className="responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form formData={items} disabled={false} onFieldDataChanged={formFieldDataChanged}>
            <GroupItem cssClass="group-item" colCount={1}>
              <SimpleItem dataField="caption_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                <RequiredRule message={t('errors.required_legend_Fr')} />
                <Label text={t('residance.legend_Fr')} visible={false} />
              </SimpleItem>
              <SimpleItem dataField="link_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                <RequiredRule message={t('errors.required_link_Fr')} />
                <Label text={t('residance.link_Fr')} visible={false} />
              </SimpleItem>
              <SimpleItem dataField="caption_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                <RequiredRule message={t('errors.required_legend_En')} />
                <Label text={t('residance.legend_En')} visible={false} />
              </SimpleItem>
              <SimpleItem dataField="link_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                <RequiredRule message={t('errors.required_link_En')} />
                <Label text={t('residance.link_En')} visible={false} />
              </SimpleItem>
            </GroupItem>

            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={hideAddModal}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

MediaVideoFormPopup.propTypes = {
  t: PropTypes.func.isRequired,
  hideAddModal: PropTypes.func.isRequired,
  selectedVideo: PropTypes.object.isRequired,
};
