import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';
import List, { ItemDragging } from 'devextreme-react/list';
import { Button } from 'devextreme-react';
import { useParams } from 'react-router-dom';
import DeletePopup from '../../delete-popup/delete-popup';
import AddPopup from '../../add-popup/add-popup';
import MediaFormPopup from '../../popupMedia';
import { create, getAll, remove } from '../../../api';
import { Toast } from '../../../utils';
import MediaUpdateFormPopup from '../../popupUpdateMedia';

function ImageInfo({ id, photo, photo_description, residence, showModal, showUpdateModal }) {
  return (
    <div className="row">
      <img alt="" src={photo} width="150px" height="100px" />

      <div className="price">{photo_description.description}</div>
      <div>
        <Button icon="edit" hint="Edit" onClick={() => showUpdateModal({ id, photo, photo_description, residence })} />
        <Button icon="trash" hint="Supprimer" onClick={() => showModal({ id, photo })} />
      </div>
    </div>
  );
}

/* -------------------------------------------------------------------------- */
/*                                RENDER HELPER                               */
/* -------------------------------------------------------------------------- */
export default function PhotosGallery({ t, childFunc, dataList }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [dataImages, setData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupUpdateVisible, setPopupUpdateVisible] = useState(false);

  const [popupAddVisible, setPopupAddVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const ref = useRef(null);
  const { id } = useParams();

  /* -------------------------------------------------------------------------- */
  /*                            DRAG AND DROP METHODS                           */
  /* -------------------------------------------------------------------------- */
  const onDragStart = (e) => {
    const tasks = dataImages;
    e.itemData = tasks[e.fromIndex];
  };
  const onAdd = (e) => {
    setData((state) => [...state.slice(0, e.toIndex), e.itemData, ...state.slice(e.toIndex)]);
    handleUpdateOrder();
  };

  const onRemove = (e) => {
    setData((state) => [...state.slice(0, e.fromIndex), ...state.slice(e.fromIndex + 1)]);
  };

  const onReorder = (e) => {
    onRemove(e);
    onAdd(e);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const showModal = (value) => {
    setPopupVisible(true);
    setSelectedImage(value);
  };
  const showAddModal = () => {
    setPopupAddVisible(true);
  };

  const hideUpdateModal = () => {
    setPopupUpdateVisible(false);
    setSelectedImage({});
    getAll(`residence_photos/${id}`).then(({ data }) => {
      setData(data.data);
    });
  };

  const hideModal = () => {
    setPopupVisible(false);
    getAll(`residence_photos/${id}`).then(({ data }) => {
      setData(data.data);
    });
  };
  const hideAddModal = (value) => {
    setPopupAddVisible(false);
    if (value && value === 'update') {
      getAll(`residence_photos/${id}`).then(({ data }) => {
        setData(data.data);
      });
    }
  };

  const showUpdateModal = (value) => {
    setPopupUpdateVisible(true);
    setSelectedImage(value);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTATNS                                 */
  /* -------------------------------------------------------------------------- */

  const handleDelete = () => {
    remove('residence_photos', `${id}/${selectedImage.id}`).then(() => {
      hideModal();
      Toast(t('common.successDelete', { title: 'La photo' }));
    });
  };

  const handleUpdateOrder = () => {
    const newOrderdList = ref.current?.props?.dataSource;
    const odredData = newOrderdList.map((el, index) => {
      return { id: el.id, order: index + 1 };
    });
    const formData = new FormData();
    formData.append('list', JSON.stringify(odredData));
    formData.append('type', 'residencePhoto');
    create('/order', formData);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 USEEFFECT                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    childFunc.current = showAddModal;
  }, []);

  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="dx-card responsive-paddings-widget fullWidth">
      <div className="widget_item_title">
        <i className="fa fa-image" />
        {t('residance.photosGallery')}
      </div>
      <div className="titleList">
        <p className="titleListGrid">{t('residance.photo').toUpperCase()}</p>
        <p className="titleListGrid">{t('residance.description').toUpperCase()}</p>
        <div />
      </div>
      <List
        ref={ref}
        keyExpr="id"
        dataSource={dataImages}
        height="100%"
        focusStateEnabled={false}
        itemRender={(props) => ImageInfo({ ...props, showModal, showUpdateModal })}
        repaintChangesOnly
        bounceEnabled={false}
        noDataText={t('errors.noData')}
      >
        <ItemDragging
          keyExpr="id"
          allowReordering
          group="data"
          data="dataImages"
          onDragStart={onDragStart}
          onAdd={onAdd}
          onRemove={onRemove}
          onReorder={onReorder}
        />
      </List>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={t('residance.deleteImageTitlePopup')}
        msg={t('residance.deleteImageMsg')}
        deleteActivity={() => {
          handleDelete();
        }}
        src={selectedImage.image}
      />
      <AddPopup
        popupVisible={popupAddVisible}
        hideModal={hideAddModal}
        title={Object.keys(selectedImage).length === 0 ? t('residance.addPhoto') : t('residance.updatePhoto')}
        width="60%"
        component={<MediaFormPopup hideAddModal={hideAddModal} t={t} restList={popupAddVisible} />}
      />
      <AddPopup
        popupVisible={popupUpdateVisible}
        hideModal={hideUpdateModal}
        title={t('residance.updatePhoto')}
        width="60%"
        component={<MediaUpdateFormPopup hideUpdateModal={hideUpdateModal} t={t} selectedImage={selectedImage} />}
      />
    </div>
  );
}

PhotosGallery.propTypes = {
  t: PropTypes.func.isRequired,
  childFunc: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
  dataList: PropTypes.object.isRequired,
};

ImageInfo.propTypes = {
  showModal: PropTypes.func.isRequired,
  photo: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  showUpdateModal: PropTypes.func.isRequired,
  photo_description: PropTypes.string.isRequired,
  residence: PropTypes.string.isRequired,
};
