import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles/style.scss';
import { useHistory, useParams } from 'react-router-dom';
import RenderHeaderPage from '../../components/widgets/headerResidance';
import WidgetImage from '../../components/widgets/mainImage';
import WidgetActions from '../../components/widgets/actions';
import { getAll } from '../../api';
import PromotionResidencePage from './listPromotions';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function ResidencePromotions() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [dataList, setDataList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                                   EVENTS                                   */
  /* -------------------------------------------------------------------------- */

  function navigateBackToPrices() {
    history.push(`/residences/prices/${id}`);
  }
  function navigateBackToResidences() {
    history.push(`/residences/details/${id}`);
  }
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */

  const listActions = [
    {
      id: 1,
      name: t('residance.backToPrice'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBackToPrices,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },

    {
      id: 2,
      name: t('residance.backToResidance'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBackToResidences,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
  ];
  /* -------------------------------------------------------------------------- */
  /*                                  USEFFECT                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getAll(`discounts/all/residence/${id}`).then(({ data }) => {
      const { data: dataPromotion, ...rest } = data;
      setDataList(rest);
      setPromotionList(dataPromotion);
    });
  }, [id]);
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block ">
      <RenderHeaderPage t={t} id="" name={dataList?.name ?? ''} officialName={dataList?.official_name ?? ''} />
      <div className="mainContainer">
        <div className="side">
          <WidgetImage t={t} src={dataList?.main_photo ?? ''} />
          <WidgetActions ListAction={listActions} t={t} dviderIndexs={[]} />
        </div>
        <div className="dx-card responsive-paddings-widget fullWidth">
          <PromotionResidencePage data={promotionList} />
        </div>
      </div>
    </div>
  );
}
