import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting, Editing, Button } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function RapportListing({ customDataSource }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      remoteOperations
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
    >
      <Sorting mode="none" />
      <LoadPanel enabled />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
      <Editing mode="row" useIcons />

      <Column dataField="date" caption="DATE" />
      <Column dataField="residence" caption="RÉSIDENCE" />
      <Column dataField="client" caption="CLIENT" />
      <Column dataField="sejour" caption="SÉJOUR" />
      <Column dataField="type" caption="TYPE" />
      <Column dataField="montant" caption="MONTANT" />
      <Column dataField="frais_cc" caption="FRAIS CC" />
      <Column dataField="total" caption="TOTAL" />
      <Column type="buttons" width={110}>
        <Button hint={t('payment.payment_hint_1')} icon="email" onClick={(e) => console.log('email')} />
        <Button hint={t('payment.payment_hint_2')} icon="home" onClick={(e) => console.log('home')} />
        <Button
          hint={t('payment.payment_hint_3')}
          icon="fa fa-eye"
          onClick={(e) => history.push(`/reservation/${e.row.data.id}`)}
        />
      </Column>
    </DataGrid>
  );
}

const allowedPageSizes = [5, 10, 20];

RapportListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
