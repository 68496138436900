import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting, Button, Editing } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { priceColumn_customizeText } from '../../../../utils';

export default function TransactionsListing({ customDataSource }) {
  const history = useHistory();

  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      remoteOperations
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
    >
      <Sorting mode="none" />
      <LoadPanel enabled />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
      <Editing mode="row" useIcons />

      <Column dataField="paye" caption="PAYÉ." />
      <Column dataField="date" caption="DATE" />
      <Column dataField="residence" caption="RÉSIDENCE" />
      <Column dataField="titre" caption="TITRE" />
      <Column dataField="montant" caption="MONTANT" customizeText={priceColumn_customizeText} />
      <Column type="buttons">
        <Button
          hint="Modifier"
          icon="edit"
          onClick={(e) => history.push(`/residences/depenses/modifier/${e.row.data.residenceId}/${e.row.data.id}`)}
        />
      </Column>
    </DataGrid>
  );
}

const allowedPageSizes = [5, 10, 20];

TransactionsListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
