import ApiClient from './config/api-client';

export async function getAll(resourceName, headers = null) {
  if (headers) {
    return await ApiClient.get(resourceName, headers);
  }
  return await ApiClient.get(resourceName);
}

export async function create(resourceName, values, headers = null) {
  if (headers) {
    return await ApiClient.post(resourceName, values, headers);
  }
  return await ApiClient.post(resourceName, values);
}

export async function update(resourceName, id, values, headers = null) {
  if (values) {
    const { created_at, updated_at, ...rest } = values;
    if (headers) {
      return await ApiClient.put(`${resourceName}/${id}`, values, headers);
    }
    return await ApiClient.put(`${resourceName}/${id}`, rest);
  }
  return await ApiClient.put(`${resourceName}/${id}`, values);
}

export async function updateWithMedia(resourceName, id, values, headers) {
  return await ApiClient.post(`${resourceName}/${id}`, values, headers);
}

export async function get(resourceName, id) {
  return await ApiClient.get(`${resourceName}/${id}`);
}

export async function remove(resourceName, id) {
  return await ApiClient.delete(`${resourceName}/${id}`);
}

export async function getWithPdf(resourceName, headers = null) {
  ApiClient.interceptors.request.use(
    (configReq) => {
      // eslint-disable-next-line no-param-reassign
      configReq.responseType = 'blob';
      return configReq;
    },
    (error) => Promise.reject(error)
  );
  if (headers) {
    return await ApiClient.get(resourceName, headers);
  }
  return await ApiClient.get(resourceName);
}

export const pathApi = {
  residences: 'residences',
};
