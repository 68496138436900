import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting } from 'devextreme-react/data-grid';

export default function TransactionsListing({ title, customDataSource }) {
  const renderGridCell = (data) => {
    const color = data.value === 'Dépôt' ? 'green' : 'red';
    return <div style={{ color }}>{data.value}</div>;
  };
  const priceColumn_customizeText = (cellInfo) => {
    return `${cellInfo.value} $`;
  };
  return (
    <>
      {title && <h2 className="content-block transaction-title">{title}</h2>}
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />

        <Column dataField="type" caption="TYPE" cellRender={renderGridCell} />
        <Column dataField="date" caption="DATE" />
        <Column dataField="description" caption="DESCRIPTION" />
        <Column dataField="montant" caption="MONTANT" customizeText={priceColumn_customizeText} />
        <Column dataField="frais" caption="FRAIS" customizeText={priceColumn_customizeText} />
        <Column dataField="total" caption="TOTAL" customizeText={priceColumn_customizeText} />
      </DataGrid>
    </>
  );
}

const allowedPageSizes = [5, 10, 20];

TransactionsListing.propTypes = {
  title: PropTypes.string,
  customDataSource: PropTypes.array.isRequired,
};

TransactionsListing.defaultProps = {
  title: null,
};
