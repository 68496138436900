import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../styles/style.scss';
import { useHistory, useParams } from 'react-router-dom';
import RenderHeaderPage from '../../../components/widgets/headerResidance';
import WidgetImage from '../../../components/widgets/mainImage';
import WidgetActions from '../../../components/widgets/actions';
import PhotosGallery from '../../../components/widgets/photosGallery';
import { getAll } from '../../../api';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function UpdateImages() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const childFunc = useRef(null);
  const [dataList, setDataList] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                                   EVENTS                                   */
  /* -------------------------------------------------------------------------- */

  function navigateBack() {
    history.goBack();
  }

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */

  const listActions = [
    {
      id: 1,
      name: t('residance.addImage'),
      icon: 'fa fa-plus',
      onPress: () => childFunc.current(),
      styles: 'greenBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 2,
      name: t('residance.backToResidance'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBack,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
  ];
  /* -------------------------------------------------------------------------- */
  /*                                  USEFFECT                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getAll(`residence_photos/${id}`).then(({ data }) => {
      setDataList(data);
    });
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block ">
      <RenderHeaderPage t={t} id="" name={dataList?.name ?? ''} officialName={dataList?.official_name ?? ''} />
      <div className="mainContainer">
        <div className="side">
          <WidgetImage t={t} src={dataList?.main_photo ?? ''} />
          <WidgetActions ListAction={listActions} t={t} dviderIndexs={[]} />
        </div>

        <PhotosGallery dataList={dataList.data} t={t} childFunc={childFunc} idResidance={id} />
      </div>
    </div>
  );
}
