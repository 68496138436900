import React, { useCallback, useEffect, useState } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import Button from 'devextreme-react/button';
import EditorInput from '../../editor-input/editor-input';
import { create, get, update } from '../../../api';
import { parametresData, Toast } from '../../../utils';
import { textEditorOptions } from '../../../utils/editorOptions';
import useGetMergTags from '../../../hooks/useGetTags';

export default function ParametreForm() {
  const history = useHistory();
  const { type, id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [valueContentFR, setValueContentFR] = useState('');
  const [valueContentEN, setValueContentEN] = useState('');
  const [items, setItems] = useState({});
  const dataTags = useGetMergTags();

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (valueContentFR === '' || valueContentEN === '') {
        return notify('Les champs français et anglais sont requis', 'error', 4000);
      }
      const { value_fr, value_en, ...rest } = items;
      const values = {
        ...rest,
        texts: JSON.stringify([
          {
            language: 'fr',
            value: valueContentFR,
          },
          {
            language: 'en',
            value: valueContentEN,
          },
        ]),
      };
      setLoading(true);
      return isAddMode ? addParameter(values) : updateParameterById(id, values);
    },
    [items, valueContentEN, valueContentFR]
  );

  const addParameter = (values) => {
    const data = { ...values, type: parametresData[type].slug };
    create('parameters', data)
      .then((res) => {
        setLoading(false);
        res.data && history.push(`/parametres/${type}`);
        res.data && Toast('Le paramètre a été bien ajouté');
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const updateParameterById = (parametreId, values) => {
    update('parameters', parametreId, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push(`/parametres/${type}`);
        res.data && Toast('Le paramètre a été bien modifié');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  useEffect(() => {
    if (!isAddMode) {
      get('parameters', id).then((response) => {
        const { data } = response.data;
        const { value_fr, value_en } = data;
        setItems(data);
        setValueContentFR(value_fr ?? '');
        setValueContentEN(value_en ?? '');
      });
    }
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={`Retour à la page ${parametresData[type].name}`}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/parametres/${type}`)}
      />
      <h2 className="content-block">{isAddMode ? 'Ajouter un paramètre' : 'Modifier un paramètre'}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Paramètres">
                  <SimpleItem dataField="label" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="L'étiquette est requise" />
                    <Label text="Étiquette" visible={false} />
                  </SimpleItem>
                </Tab>
                <Tab title="Français">
                  <EditorInput dataSource={dataTags} valueContent={valueContentFR} valueChanged={setValueContentFR} />
                </Tab>
                <Tab title="Anglais">
                  <EditorInput dataSource={dataTags} valueContent={valueContentEN} valueChanged={setValueContentEN} />
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push(`/parametres/${type}`)}>
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
