import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../../contexts/auth';
import WidgetActions from '../../../components/widgets/actions';
import ReservationInfo from '../../../components/widgets/reservation/reservation_info';
import WidgetClient from '../../../components/widgets/client';
import WidgetInvoice from '../../../components/widgets/invoice';
import WidgetPayment from '../../../components/widgets/payment';
import { create, get, remove, getAll, getWithPdf } from '../../../api';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { Toast, getPermissionVisibility } from '../../../utils';

export default function ReservationDetails() {
  const history = useHistory();
  const [popupVisible, setPopupVisible] = useState(false);
  const [generatedcontract, setGeneratedcontract] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [reservationDetails, setReseravationDetatils] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const { auth } = useAuth();
  const PermissionsList = auth.permissions;

  const popupDetailsList = [
    {
      title: `${t('common.deletion')} ${t('reservation.header', {
        residanceName: reservationDetails?.residence_name,
        client: `${reservationDetails?.client?.first_name} ${reservationDetails?.client?.last_name}`,
      })}`,
      msg: t('reservation.reservation_delete_msg'),
      deleteActivity: () => deleteReservation(id),
      textBtn: t('common.delete'),
      typeBtn: 'danger',
    },
    {
      title: `${t('common.reactivation')} ${t('reservation.header', {
        residanceName: reservationDetails?.residence_name,
        client: `${reservationDetails?.client?.first_name} ${reservationDetails?.client?.last_name}`,
      })}`,
      msg: t('reservation.reactivation_msg'),
      deleteActivity: () => reactifReservation(id),
      textBtn: t('common.enable'),
      typeBtn: 'success',
    },
  ];
  const showModal = (index) => {
    setPopupDetail(popupDetailsList[index]);
    setPopupVisible(true);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteReservation = (idRes) => {
    remove('reservations', idRes).then((e) => {
      if (!e.message) {
        history.push('/reservations');
        Toast(t('common.successDelete', { title: 'La réservation' }));
      }
      hideModal();
    });
  };

  const reactifReservation = (idRes) => {
    create(`reservations/${idRes}/restore`).then((e) => {
      if (!e.message) {
        hideModal();
        history.push('/reservations');
        Toast(t('reservation.reactive_reservation_msg'));
      }
      hideModal();
    });
  };

  const importContarct = () => {
    if (reservationDetails?.is_rental_contract_generated) {
      getWithPdf(`reservations/${id}/export`, { 'Content-Type': 'application/pdf' }).then((res) => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        res.data && Toast(t('reservation.export_contract_msg'));
      });
    } else {
      notify("Fichier introuvable! vous devez d'abord générer le fichier pdf.", 'error', 4000);
    }
  };

  const generateContarct = () => {
    create(`reservations/${id}/generate-contract`).then(() => {
      Toast('Le contrat a été bien généré');
      setGeneratedcontract(true);
    });
  };

  const listAccounting = [
    {
      id: 1,
      name: t('reservation.display_client'),
      icon: 'fa fa-user',
      onPress: () => {
        history.push({
          pathname: `/clients/${reservationDetails?.client?.id}`,
        });
      },
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 2,
      name: t('reservation.display_residence'),
      icon: 'fa fa-home',
      onPress: () => {
        history.push({
          pathname: `/residences/details/${reservationDetails?.residence_id}`,
        });
      },
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 3,
      name: t('reservation.contact_oprion'),
      icon: 'fa fa-gear',
      onPress: () => console.log(),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateReservation'),
    },
    {
      id: 4,
      name: t('reservation.generate_contract'),
      icon: 'fa fa-magic',
      onPress: () => generateContarct(),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateReservation'),
    },
    {
      id: 11,
      name: 'Télécharger le contrat',
      icon: 'fa fa-download',
      onPress: () => importContarct(),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateReservation'),
      disabled: !generatedcontract,
    },
    {
      id: 5,
      name: t('reservation.submit_confirmation'),
      icon: 'fa fa-check-square-o',
      onPress: () => console.log(),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateReservation'),
    },
    {
      id: 6,
      name: t('reservation.additional_invoices'),
      icon: 'fa fa-file',
      onPress: () => {
        history.push({
          pathname: `/reservation/factures/${reservationDetails?.id}`,
        });
      },
      styles: 'actionBtn before',
      display: getPermissionVisibility(PermissionsList, 'updateReservation'),
    },
    {
      id: 7,
      name: t('reservation.edit_reservation'),
      icon: 'fa fa-edit',
      onPress: () => {
        history.push(`/reservation/update/${id}`);
      },
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: getPermissionVisibility(PermissionsList, 'updateReservation'),
    },
    {
      id: 8,
      name: t('reservation.transfer_to_another_residence'),
      icon: 'fa fa-share',
      onPress: () => console.log(),
      styles: 'actionBtn',
      display: !reservationDetails?.is_trashed && getPermissionVisibility(PermissionsList, 'updateReservation'),
    },
    {
      id: 9,
      name: t('reservation.delete_reservation'),
      icon: 'fa fa-trash',
      onPress: () => showModal(0),
      styles: 'actionBtn btnForWithBackGrounColor redBtnBackground',
      display: !reservationDetails?.is_trashed && getPermissionVisibility(PermissionsList, 'updateReservation'),
    },
    {
      id: 10,
      name: t('reservation.reactive_reservation'),
      icon: 'repeat',
      onPress: () => showModal(1),
      styles: 'actionBtn btnForWithBackGrounColor greenBtnBackground hide',
      display: reservationDetails?.is_trashed && getPermissionVisibility(PermissionsList, 'updateReservation'),
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    get('reservations', id).then(({ data }) => {
      setReseravationDetatils(data?.data);
      setGeneratedcontract(reservationDetails?.is_rental_contract_generated);
    });
  }, [generatedcontract]);

  return (
    <div className="content-block">
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('reservation.go_back_reservations')}
        type="default"
        stylingMode="text"
        onClick={() => history.push('/reservations')}
      />
      <h2>
        {t('reservation.header', {
          residanceName: reservationDetails?.residence_name,
          client: `${reservationDetails?.client?.first_name} ${reservationDetails?.client?.last_name}`,
        })}
      </h2>
      <div className="containerDetails">
        <div>
          <WidgetActions
            title={t('common.actions')}
            ListAction={listAccounting}
            t={t}
            dviderIndexs={getPermissionVisibility(PermissionsList, 'updateReservation') ? [2, 6, 8] : []}
          />
        </div>
        <div className="detailsReservation">
          <ReservationInfo details={reservationDetails} />
        </div>
        <div className="leftSide">
          <WidgetClient clinetDetails={reservationDetails?.client} />
        </div>
      </div>
      {getPermissionVisibility(PermissionsList, 'updateReservation') && (
        <>
          <div className="containerDetails">
            <div className="invoice">
              <WidgetInvoice details={reservationDetails} />
            </div>
            <div>
              <WidgetPayment reservationId={id} />
            </div>
          </div>
          <DeletePopup
            popupVisible={popupVisible}
            hideModal={hideModal}
            title={popupDetail.title}
            msg={popupDetail.msg}
            deleteActivity={popupDetail.deleteActivity}
            textBtn={popupDetail.textBtn}
            typeBtn={popupDetail.typeBtn}
            width={900}
          />
        </>
      )}
    </div>
  );
}
