/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';
import { Form, LoadIndicator } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  SimpleItem,
  TabbedItem,
  TabPanelOptions,
  GroupItem,
  RequiredRule,
} from 'devextreme-react/form';
import { Tab } from 'devextreme-react/diagram';
import { Label } from 'devextreme-react/bar-gauge';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { Toast } from '../../../utils';
import {
  NumberDecimalEditorOptions,
  NumberIntEditorOptions,
  selectEditorOptions,
  textEditorOptions,
} from '../../../utils/editorOptions';
import { checkBoxOptions } from '../../form/residence-form/input-options';
import { create, getAll, update } from '../../../api';

import './style.scss';
/* -------------------------------------------------------------------------- */
/*                                RENDER HELPER                               */
/* -------------------------------------------------------------------------- */

export default function FormPrices({ t }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */

  const [items, setItems] = useState({
    max_nbr_nights: 7,
    min_nbr_nights: 2,
    price_basic_night: null,
    max_nbr_animals: null,
    basic_nbr_people: null,
    price_fds_night: null,
    price_week_more: null,
    price_month_more: null,
    price_night_additional_person: null,
    price_holiday: null,
    price_animal: null,
    title_fr: '',
    title_en: '',
    not_show_tariff: false,
    not_show_dates_tariff: false,
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dataTwins, setDataTwins] = useState([]);
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isTwin = queryParams.get('isTwin');
  const type = queryParams.get('type');
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */

  const navigateBack = () => {
    history.goBack();
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { ranges, periods, not_show_tariff, not_show_dates_tariff, ...rest } = items;
      const values = {
        ...rest,
        not_show_tariff: not_show_tariff ? 1 : 0,
        not_show_dates_tariff: not_show_dates_tariff ? 1 : 0,
        type: 'basic',
      };
      const dataToSend = Object.fromEntries(
        Object.entries(values)
          .filter(([_, v]) => v != null)
          .filter(([_, v]) => v !== '')
      );

      if (Number(type) === 0) {
        create(`tariffs/${id}`, dataToSend).then((res) => {
          setLoading(false);
          if (res.status === 201) {
            navigateBack();
            Toast(t('common.successAddM', { title: 'Le tarif' }));
          }
        });
      } else {
        update(`tariffs`, `${id}/${items.id}`, dataToSend).then((res) => {
          setLoading(false);
          if (res.status === 200) {
            navigateBack();
            Toast(t('common.successUpdateM', { title: 'Le tarif' }));
          }
        });
      }
    },
    [items]
  );
  useEffect(() => {
    if (Number(type) === 1) {
      getAll(`tariffs/${id}/basic`).then((res) => {
        if (res.data.data[0]) {
          setItems(res.data.data[0] ?? {});
        }
      });
    }
    if (JSON.parse(isTwin)) {
      getAll(`twins/${id}`).then((res) => {
        setDataTwins(res.data.data);
      });
    }
  }, [type, JSON.parse(isTwin)]);

  const formFieldDataChanged = (e) => {
    const dataForm = e.component.option('formData');
    setItems({ ...items, ...dataForm });
  };
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="dx-card responsive-paddings-widget fullWidth">
      <div className="gallery_item_title">
        <i className="fa fa-tag" />
        {Number(type) === 0 ? t('residance.addPrice') : t('residance.editPrice')}
      </div>
      <div>
        <form onSubmit={onSubmit}>
          <Form formData={items} showValidationSummary onFieldDataChanged={formFieldDataChanged}>
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              <Tab title={t('price.prices')}>
                {JSON.parse(isTwin) && (
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="twins_id"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(dataTwins)}
                    >
                      <RequiredRule message={t('errors.required_jumele')} />
                      <Label text={t('jumele.jumeles')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                )}
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem
                    dataField="basic_nbr_people"
                    editorType="dxNumberBox"
                    editorOptions={NumberIntEditorOptions()}
                  >
                    <RequiredRule message={t('errors.required_numPersons')} />
                    <Label text={t('price.numPersons')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="max_nbr_animals"
                    editorType="dxNumberBox"
                    editorOptions={NumberIntEditorOptions()}
                  >
                    <RequiredRule message={t('errors.required_numAnimals')} />
                    <Label text={t('price.numAnimals')} visible={false} />
                  </SimpleItem>
                </GroupItem>
              </Tab>
              {/** pricesAllTimes */}
              <Tab title={t('price.pricesAllTimes')}>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="title_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message={t('errors.required_titleFr')} />
                    <Label text={t('price.TitleFr')} visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="title_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message={t('errors.required_titleEn')} />
                    <Label text={t('price.TitleEn')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="min_nbr_nights"
                    editorType="dxNumberBox"
                    editorOptions={NumberIntEditorOptions({ max: items?.max_nbr_nights })}
                  >
                    <RequiredRule message={t('errors.required_minReservation')} />
                    <Label text={t('price.minReservation')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="max_nbr_nights"
                    editorType="dxNumberBox"
                    editorOptions={NumberIntEditorOptions({ min: items?.max_nbr_nights })}
                  >
                    <RequiredRule message={t('errors.required_maxReservation')} />
                    <Label text={t('price.maxReservation')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_basic_night"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <RequiredRule message={t('errors.required_priceByNigth')} />
                    <Label text={t('price.priceByNigthLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_fds_night"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <RequiredRule message={t('errors.required_fds')} />
                    <Label text={t('price.priceFdsByNigthLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_week_more"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={`${t('price.weekPrice')} $`} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_month_more"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.mounthPriceLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_night_additional_person"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.priceExtraPersonLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_holiday"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.priceHolidayLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_animal"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.priceAnimalLabel')} visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="notes_fr" editorType="dxTextArea" editorOptions={textEditorOptions}>
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="notes_en" editorType="dxTextArea" editorOptions={textEditorOptions}>
                    <Label visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={1}>
                  <SimpleItem
                    dataField="not_show_tariff"
                    editorType="dxCheckBox"
                    editorOptions={checkBoxOptions(t('price.dontShowPrice'))}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="not_show_dates_tariff"
                    editorType="dxCheckBox"
                    editorOptions={checkBoxOptions(t('price.dontShowDates'))}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                </GroupItem>
              </Tab>
            </TabbedItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.saveBtn')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={navigateBack}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

FormPrices.propTypes = {
  t: PropTypes.func.isRequired,
};
