/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import PropTypes from 'prop-types';

export default function CheckBoxCustom({ handelStatus }) {
    const [value, setValue] = useState(false);

    return (
        <CheckBox
            value={value}
            onValueChanged={(e) => {
                setValue(e.value);
                handelStatus(e.value)
            }}
        />
    );
}
CheckBoxCustom.propTypes = {
    handelStatus: PropTypes.func.isRequired,
};