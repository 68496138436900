import { withNavigationWatcher } from './contexts/navigation';
import {
  HomePage,
  TasksPage,
  ResidencesPage,
  ReservationsPage,
  ClientsPage,
  PagesPage,
  ExtraitsPage,
  ActivitesPage,
  VedettesPage,
  MembresPage,
  SauvegardePage,
  StatistiquesPage,
  MembresCorbeillePage,
  ClientPage,
  AgenciesPage,
  TaxesPage,
  VillesPage,
  GroupesVillePage,
  DeverouillagesPage,
  CleApiPage,
  ParametresPage,
  SpecialDaysPage,
  PeriodesPage,
  InvoicesTypePage,
  CategoriesPage,
  PhotosDescriptionPage,
  TypesUnitePage,
  EquipmentsPage,
  ResidenceDetails,
  MembrePage,
  ResidenceUnitiesPage,
  ResidencesCorbeillePage,
  JumelesPage,
  PromotionPage,
  SearchFacturesPage,
  SearchTransactionsPage,
  PaymentReceivedPage,
  RapportPaymentPage,
  PendingPaymentsPage,
  FacturesAdditionPage,
  FacturesDetailsPage,
  RapportFacturesAdditionPage,
  RapportFacturesPage,
  RapportTransactionsPage,
  ReservationsCorbeillePage,
  ReservationsCrediteesPage,
  CompteGeneralPage,
  DepensesPage,
  CreditsPage,
  RapportFormPage,
  GenerateFacturePage,
  FacturationsPage,
  FacturationDetailsPage,
  EtatsComptePage,
  EtatsCompteDetails,
  EtatsCompteGenerator,
  categorieActivites,
  HeaderImages,
} from './pages';
import {
  CreateClientForm,
  UpdateTaxeForm,
  FusionClients,
  CreateVilleForm,
  GroupeVilleForm,
  DeverouillageForm,
  CleApiForm,
  ParametreForm,
  PeriodeForm,
  InvoicesTypeForm,
  CategoryForm,
  AgencyForm,
  PhotoDescriptionForm,
  TypeUniteForm,
  EquipmentForm,
  ResidenceForm,
  ResidenceCreateForm,
  HolidaysForm,
  MemberForm,
  JumeleCreateForm,
  JumeleUpdateForm,
  FactureForm,
  LigneForm,
  PaymentMethodeManuel,
  PaymentMethodeManuelEdit,
  DepenseForm,
  CreditForm,
  ActivityForm,
  ActivityCreateForm,
  CreateCategorieActivitesForm,
  PageForm,
  HeaderImagesForm,
} from './components';
import UpdateImages from './pages/residenceDetails/updateImages';
import UpdateVideos from './pages/residenceDetails/updateVideos';
import UpdatePrice from './pages/prices';
import AddAndUpdatePrice from './pages/prices/addPrice';
import AddExtractForm from './components/form/addExracts';
import ReservationDetails from './pages/reservations/ReservationDetails';
import PromotionForm from './components/form/pormotion-form';
import ResidencePromotions from './pages/promotions';
import PromotionResidanceForm from './components/form/pormotion-form-residance';
import UpdateFormReservation from './components/form/update-reservation-form';
import BankAccount from './pages/bankAccount';
import BalanceScreen from './pages/comptabilite/balance';
// j(-j(-j(-j(-))))
const routes = [
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/tasks',
    component: TasksPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/home',
    component: HomePage,
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    path: '/residences/:parameter?',
    component: ResidencesPage,
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    path: '/corbeille/residences/:parameter?',
    component: ResidencesCorbeillePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residence/ajouter',
    component: ResidenceCreateForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residence/modifier/:id',
    component: ResidenceForm,
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    path: '/reservations/:parameter?',
    component: ReservationsPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/corbeille/reservations/:parameter?',
    component: ReservationsCorbeillePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/clients',
    component: ClientsPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/client',
    component: CreateClientForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/client/:id',
    component: CreateClientForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/pages',
    component: PagesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/page/modifier/:id',
    component: PageForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/page/ajouter',
    component: PageForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/extraits',
    component: ExtraitsPage,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/activites',
    component: ActivitesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/activite/modifier/:id',
    component: ActivityForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/activite/ajouter',
    component: ActivityCreateForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/membres',
    component: MembresPage,
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    path: '/membres/:id',
    component: MembrePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/membre',
    component: MemberForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/membre/:id',
    component: MemberForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/sauvegarde',
    component: SauvegardePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/statistiques',
    component: StatistiquesPage,
  },
  {
    roles: ['super_admin'],
    path: '/factures_rechercher/:parameter?',
    component: SearchFacturesPage,
  },
  {
    roles: ['super_admin'],
    path: '/transactions_rechercher/:parameter?',
    component: SearchTransactionsPage,
  },
  {
    roles: ['super_admin'],
    path: '/bank_account',
    component: BankAccount,
  },
  {
    roles: ['super_admin'],
    path: '/balance',
    component: BalanceScreen,
  },
  {
    roles: ['super_admin'],
    path: '/paiement_recu',
    component: PaymentReceivedPage,
  },
  {
    roles: ['super_admin'],
    path: '/rapport_paiements/:parameter?',
    component: RapportPaymentPage,
  },
  {
    roles: ['super_admin'],
    path: '/paiements',
    component: PendingPaymentsPage,
  },
  {
    roles: ['super_admin'],
    path: '/rapport_factures',
    component: RapportFacturesAdditionPage,
  },
  {
    roles: ['super_admin'],
    path: '/compte_rendu_promotion/:parameter?',
    component: RapportFacturesPage,
  },
  {
    roles: ['super_admin'],
    path: '/rapport_transactions/:parameter?',
    component: RapportTransactionsPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/corbeille/membres',
    component: MembresCorbeillePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/agences',
    component: AgenciesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/agence',
    component: AgencyForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/agence/:id',
    component: AgencyForm,
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    path: '/clients/:id',
    component: ClientPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/taxes',
    component: TaxesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/taxes/:id',
    component: UpdateTaxeForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/clients/fusion/:id',
    component: FusionClients,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/villes',
    component: VillesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/ville',
    component: CreateVilleForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/ville/:id',
    component: CreateVilleForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/categ_activites',
    component: categorieActivites,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/categ_activity',
    component: CreateCategorieActivitesForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/categ_activity/:id',
    component: CreateCategorieActivitesForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/extrait',
    component: AddExtractForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/extrait/:id',
    component: AddExtractForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/groupes-ville',
    component: GroupesVillePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/groupe-ville',
    component: GroupeVilleForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/groupe-ville/:id',
    component: GroupeVilleForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/deverouillages',
    component: DeverouillagesPage,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/entetes',
    component: HeaderImages,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/entetes/entete/:id',
    component: HeaderImagesForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/entetes/entete',
    component: HeaderImagesForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/deverouillage',
    component: DeverouillageForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/deverouillage/:id',
    component: DeverouillageForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/cles-api',
    component: CleApiPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/cle-api',
    component: CleApiForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/cle-api/:id',
    component: CleApiForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/parametres/:type',
    component: ParametresPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/parametre/:type',
    component: ParametreForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/parametre/:type/:id',
    component: ParametreForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/jours-special',
    component: SpecialDaysPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/jour-special',
    component: HolidaysForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/jour-special/:id',
    component: HolidaysForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/periodes',
    component: PeriodesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/periode',
    component: PeriodeForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/periode/:id',
    component: PeriodeForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/types-factures',
    component: InvoicesTypePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/type-facture',
    component: InvoicesTypeForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/type-facture/:id',
    component: InvoicesTypeForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/categories',
    component: CategoriesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/categorie',
    component: CategoryForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/categorie/:id',
    component: CategoryForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/description-photos',
    component: PhotosDescriptionPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/description-photo',
    component: PhotoDescriptionForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/description-photo/:id',
    component: PhotoDescriptionForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/types-unites',
    component: TypesUnitePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/type-unite',
    component: TypeUniteForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/type-unite/:id',
    component: TypeUniteForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/equipements',
    component: EquipmentsPage,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/promotions',
    component: PromotionPage,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/promotion',
    component: PromotionForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/promotion/:id',
    component: PromotionForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/update-promotion/:id',
    component: PromotionForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/equipement',
    component: EquipmentForm,
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    path: '/equipement/:id',
    component: EquipmentForm,
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    path: '/residences/details/:id',
    component: ResidenceDetails,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/details/picture-gallery/:id',
    component: UpdateImages,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/details/videos-gallery/:id',
    component: UpdateVideos,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/promotions/:id',
    component: ResidencePromotions,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/unites/:id',
    component: ResidenceUnitiesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/prices/:id',
    component: UpdatePrice,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/prices/price/:id',
    component: AddAndUpdatePrice,
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    path: '/reservation/:id',
    component: ReservationDetails,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/jumeles/:id',
    component: JumelesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/jumeles/ajouter/:id',
    component: JumeleCreateForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/jumeles/modifier/:id/:itemId',
    component: JumeleUpdateForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/promotion/add/:id',
    component: PromotionResidanceForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/promotion/update/:id/:itemId',
    component: PromotionResidanceForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/reservation/update/:id',
    component: UpdateFormReservation,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/reservation/factures/:id',
    component: FacturesAdditionPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/reservation/factures/ajouter/:id',
    component: FactureForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/reservation/factures/modifier/:id/:itemId',
    component: FactureForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/reservation/factures/details/:id/:itemId',
    component: FacturesDetailsPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/factures/lignes/ajouter/:reservationId/:factureId',
    component: LigneForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/factures/lignes/modifier/:reservationId/:factureId/:id',
    component: LigneForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/reservation/paiement/ajouter/:id',
    component: PaymentMethodeManuel,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/reservation/paiement/modifier/:id/:itemId',
    component: PaymentMethodeManuelEdit,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/credits/reservations/:parameter?',
    component: ReservationsCrediteesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/etat_compte_general/:parameter?',
    component: CompteGeneralPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/depenses/:id',
    component: DepensesPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/depenses/ajouter/:id',
    component: DepenseForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/depenses/modifier/:id/:itemId',
    component: DepenseForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/credits/:id',
    component: CreditsPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/credits/ajouter/:id',
    component: CreditForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/credits/modifier/:id/:itemId',
    component: CreditForm,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/generer_rapport',
    component: RapportFormPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/generer_facture',
    component: GenerateFacturePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/factures/:id',
    component: FacturationsPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/facture/:id/:itemId',
    component: FacturationDetailsPage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/etats_comptes/:id',
    component: EtatsComptePage,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/etats_comptes/:id/:itemId',
    component: EtatsCompteDetails,
  },
  {
    roles: ['super_admin', 'admin'],
    path: '/residences/:id/etats_comptes/0',
    component: EtatsCompteGenerator,
  },
];

export default routes.map((route) => ({
  ...route,
  component: withNavigationWatcher(route.component),
}));
