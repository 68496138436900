import CustomStore from 'devextreme/data/custom_store';
import { getParams } from '../request-helper';
import { errorsHandlerCustomStore } from '../errors-handler';
import ApiClient from '../../api/config/api-client';

/**
 * get clients list with pagination
 * @returns {CustomStore<any, any>|Promise<{summary: *, data: *, groupCount: *, totalCount: *}>}
 * @private
 */
export const initCustomStore = (resourceName) =>
  new CustomStore({
    key: 'id',
    cacheRawData: false, // Specifies whether raw data should be saved in the cache. Applies only if loadMode is "raw".
    load: async (loadOptions) => {
      const params = getParams(loadOptions);
      const res = await ApiClient.get(resourceName, { params });
      return {
        data: res.data.data,
        totalCount: res.data.totalCount,
        summary: res.data.summary,
        groupCount: res.data.groupCount,
      };
    },
    errorHandler: errorsHandlerCustomStore,
  });
