/* eslint-disable prettier/prettier */
import { Accordion, Button } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles/style.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import WidgetActions from '../../components/widgets/actions';
import WidgetImage from '../../components/widgets/mainImage';
import WidgetPrices from '../../components/widgets/prices';
import WidgetPromtions from '../../components/widgets/promtions';
import WidgetCalendar from '../../components/widgets/calander';
import { create, get, pathApi, remove, update, getWithPdf } from '../../api';
import RenderHeaderPage from '../../components/widgets/headerResidance';
import { Toast, getPermissionVisibility } from '../../utils';
import DeletePopup from '../../components/delete-popup/delete-popup';
import AddPopup from '../../components/add-popup/add-popup';
import FormReservation from '../../components/reservationPopup';
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function ResidenceDetails() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [details, setDetails] = useState({ multi_unit: 0 });
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupReservationVisible, setPopupReservationVisible] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const PermissionsList = auth.permissions;
  const hasPerm = getPermissionVisibility(PermissionsList, 'updateResidence');

  const popupDetailsList = [
    {
      title: `${t('common.deletion')} ${details.name}`,
      msg: t('residance.deletion_msg'),
      deleteActivity: () => deleteResidence(details.id),
      textBtn: t('common.delete'),
      typeBtn: 'danger',
    },
    {
      title: `${t('common.deletion_definitive')} ${details.name}`,
      msg: t('residance.deletion_definitive_msg'),
      deleteActivity: () => forceDeleteResidence(details.id),
      textBtn: t('common.delete_definitive'),
      typeBtn: 'danger',
    },
    {
      title: `${t('common.reactivation')} ${details.name}`,
      msg: t('residance.reactivation_msg'),
      deleteActivity: () => reactifResidence(details.id),
      textBtn: t('common.enable'),
      typeBtn: 'success',
    },
    {
      title: `${t('common.activation')} ${details.name}`,
      msg: t('residance.activation_msg'),
      deleteActivity: () => actifResidence(details.id),
      textBtn: t('common.activate'),
      typeBtn: 'success',
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                   EVENTS                                   */
  /* -------------------------------------------------------------------------- */

  const handleClickOpenLink = (path) => {
    window.open(path);
  };

  function navigateToUpdateResidance() {
    history.push(`/residence/modifier/${id}`);
  }

  function navigateToUniteResidance() {
    history.push(`/residences/unites/${id}`);
  }

  function navigateToJumeles() {
    history.push(`/residences/jumeles/${id}`);
  }

  function navigateToUpdateImages() {
    history.push({
      pathname: `/residences/details/picture-gallery/${id}`,
    });
  }

  function navigateToUpdateVideos() {
    history.push({
      pathname: `/residences/details/videos-gallery/${id}`,
    });
  }
  function navigateToUpdatePromotions() {
    history.push({
      pathname: `/residences/prices/${id}`,
    });
  }

  function duplicateResidance() {
    create(`/residences/${id}`).then((res) => {
      if (res.data) {
        history.push(`/residences/details/${res.data.data.id}`);
        Toast(t('residance.duplicated_msg'));
        document.location.reload();
      }
    });
  }

  const showModal = (index) => {
    setPopupDetail(popupDetailsList[index]);
    setPopupVisible(true);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const hideReservationModal = () => {
    setPopupReservationVisible(false);
  };
  const setPopupVisibleReservation = () => {
    setPopupReservationVisible(true);
  };
  const deleteResidence = (idRes) => {
    remove('residences', idRes).then((e) => {
      if (!e.message) {
        history.push('/residences');
        Toast(t('residance.delete_fiche_msg'));
      }
      hideModal();
    });
  };

  const forceDeleteResidence = (idRes) => {
    remove('residences', `${idRes}/forceDelete`)
      .then(() => {
        hideModal();
        history.push('/residences');
        Toast(t('residance.delete_fiche_msg'));
      })
      .catch(() => hideModal());
  };

  const reactifResidence = (idRes) => {
    create(`residences/${idRes}/restore`)
      .then((res) => {
        res.data && hideModal();
        res.data && history.push('/residences');
        res.data && Toast(t('residance.reactive_residance_msg'));
      })
      .catch(() => hideModal());
  };

  const actifResidence = (idRes) => {
    update('residences', `${idRes}/activate`)
      .then((res) => {
        res.data && hideModal();
        res.data && history.push('/residences');
        res.data && Toast(t('residance.active_residance_msg'));
      })
      .catch(() => hideModal());
  };

  const importContarct = () => {
    getWithPdf(`residences/${id}/export`, { 'Content-Type': 'application/pdf' }).then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      res.data && Toast(t('reservation.export_contract_msg'));
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const accordionData = [
    {
      title: t('residance.introduction'),
      data: details.introduction_fr,
    },
    {
      title: t('residance.description'),
      data: details.descriptions_fr,
    },

    {
      title: t('residance.equipment'),
      data: details.equipments_fr,
    },
    {
      title: t('residance.location'),
      data: details.localization_fr,
    },
    {
      title: t('residance.near'),
      data: details.proximity_fr,
    },
    {
      title: t('residance.unlock'),
      data: details.unlocking_details_fr,
    },
    {
      title: t('residance.arrival_departure'),
      data: details.arrival_departure_fr,
    },
    {
      title: t('residance.note'),
      data: details.internal_notes,
    },
  ];
  const listActions = [
    {
      id: 1,
      name: t('residance.Book_residence'),
      icon: 'fa fa-asterisk',
      onPress: setPopupVisibleReservation,
      styles: 'greenBtnBackground actionBtn btnForWithBackGrounColor',
      display: ['active', 'draft'].includes(details.status),
    },
    {
      id: 2,
      name: t('residance.visit_website'),
      icon: 'fa fa-globe',
      onPress: () =>
        handleClickOpenLink(
          'https://staging.hebergement-charlevoix.com/fr/chalet-a-louer/petite-riviere-saint-francois/voir/AAA-39'
        ),
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 3,
      name: t('residance.consult_member'),
      icon: 'fa fa-user',
      onPress: () => history.push(`/membres/${details.member.id}`),
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 4,
      name: t('residance.consult_history'),
      icon: 'clock',
      onPress: () => history.push(`/reservations/?residence_id=${id}`),
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 5,
      name: t('residance.edit_information'),
      icon: 'edit',
      onPress: navigateToUpdateResidance,
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: hasPerm,
    },
    {
      id: 6,
      name: t('residance.Unit_management'),
      icon: 'fa fa-home',
      onPress: navigateToUniteResidance,
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: !!details.multi_unit && hasPerm,
    },
    {
      id: 7,
      name: t('residance.jumeles_managment'),
      icon: 'fa fa-eye',
      onPress: navigateToJumeles,
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: !!details.twin && ['active'].includes(details.status) && hasPerm,
    },
    {
      id: 8,
      name: t('residance.edit_emails_for_residence_note'),
      icon: 'fa fa-file-text',
      onPress: () => console.log(),
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: hasPerm,
    },
    {
      id: 9,
      name: t('residance.edit_photo_gallery'),
      icon: 'fa fa-picture-o',
      onPress: navigateToUpdateImages,
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: hasPerm,
    },
    {
      id: 10,
      name: t('residance.edit_videos'),
      icon: 'fa fa-video-camera',
      onPress: navigateToUpdateVideos,
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: hasPerm,
    },
    {
      id: 11,
      name: t('residance.edit_prices'),
      icon: 'fa fa-tags',
      onPress: navigateToUpdatePromotions,
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: hasPerm,
    },
    {
      id: 12,
      name: t('residance.duplicate_residance'),
      icon: 'fa fa-files-o',
      onPress: duplicateResidance,
      styles: 'actionBtn btnForWithBackGrounColor orangeBtnBackground',
      display: getPermissionVisibility(PermissionsList, 'updateResidence'),
    },
    {
      id: 13,
      name: t('residance.reactive_residance'),
      icon: 'repeat',
      onPress: () => showModal(2),
      styles: 'actionBtn btnForWithBackGrounColor greenBtnBackground hide',
      display: ['archived'].includes(details.status) && getPermissionVisibility(PermissionsList, 'updateResidence'),
    },
    {
      id: 14,
      name: t('residance.delete_residance'),
      icon: 'fa fa-trash',
      onPress: () => showModal(1),
      styles: 'actionBtn btnForWithBackGrounColor redBtnBackground',
      display: ['archived'].includes(details.status) && getPermissionVisibility(PermissionsList, 'updateResidence'),
    },
    {
      id: 15,
      name: t('residance.archive_residance'),
      icon: 'fa fa-archive',
      onPress: () => showModal(0),
      styles: 'actionBtn btnForWithBackGrounColor greyBtnBackground',
      display: ['active', 'draft'].includes(details.status) && getPermissionVisibility(PermissionsList, 'updateResidence'),
    },
    {
      id: 16,
      name: t('residance.active_residance'),
      icon: 'fa fa-eye',
      onPress: () => showModal(3),
      styles: 'actionBtn btnForWithBackGrounColor greenBtnBackground hide',
      display: ['draft'].includes(details.status) && getPermissionVisibility(PermissionsList, 'updateResidence'),
    },
  ];

  const listAccounting = [
    {
      id: 1,
      name: t('residance.generate_report'),
      icon: 'fa fa-bar-chart',
      onPress: () => console.log(),
      styles: 'actionBtn',
      display: true,
    },
    {
      id: 2,
      name: t('residance.expenses'),
      icon: 'fa fa-usd',
      onPress: () => history.push(`/residences/depenses/${id}`),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateResidence')
    },
    {
      id: 3,
      name: t('residance.billings'),
      icon: 'fa fa-file-text',
      onPress: () => history.push(`/residences/factures/${id}`),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateResidence'),
    },
    {
      id: 4,
      name: t('residance.credits'),
      icon: 'fa fa-usd',
      onPress: () => history.push(`/residences/credits/${id}`),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateResidence')
    },
    {
      id: 5,
      name: t('residance.account_status'),
      icon: 'fa fa-exchange',
      onPress: () => history.push(`/residences/etats_comptes/${id}`),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateResidence'),
    },
    {
      id: 6,
      name: t('residance.service_contract'),
      icon: 'fa fa-file',
      onPress: () => importContarct(),
      styles: 'actionBtn',
      display: getPermissionVisibility(PermissionsList, 'updateResidence'),
    },
  ];
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  function renderItem(itemData) {
    return <div dangerouslySetInnerHTML={{ __html: itemData.data }} />;
  }

  /* -------------------------------------------------------------------------- */
  /*                                 USEEFFECTS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    get(pathApi.residences, id).then((response) => {
      const { data } = response.data;
      setDetails(data);
      setLoading(false);
    });
    return () => { };
  }, []);


  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="content-block ">
        <Button
          className="goback-btn"
          icon="chevronprev"
          text="Retour à la page Résidences"
          type="default"
          stylingMode="text"
          onClick={() => history.push('/residences')}
        />
        <RenderHeaderPage
          t={t}
          id={details.id}
          name={details.name}
          officialName={details.official_name}
          rate={details.commission_rate}
          auth={getPermissionVisibility(PermissionsList, 'updateResidence')}
        />
        <div className="mainContainer">
          <div className="sideLeft">
            <WidgetImage t={t} src={details.main_photo} />
            <WidgetActions ListAction={listActions} t={t} dviderIndexs={hasPerm ? [1, 4, 11, 16] : []} />
          </div>
          <div className="main">
            <Accordion collapsible dataSource={accordionData} itemRender={(item) => renderItem(item)} />
            <WidgetPromtions t={t} residenceId={id} />
          </div>
          <div className="sideRight">
            <WidgetCalendar t={t} multiUnits={details.multi_unit === 1} loading={loading} />
            <WidgetActions
              title={t('residance.accountingAndReports')}
              ListAction={listAccounting}
              t={t}
              dviderIndexs={[]}
            />
            <WidgetPrices t={t} textButton="1 - 11 persone" />
          </div>
        </div>
      </div>
      {['active', 'draft'].includes(details.status) && (
        <AddPopup
          popupVisible={popupReservationVisible}
          hideModal={() => {
            hideReservationModal();
          }}
          title={t('reservation.titleAddReservation', { name: details.name, officialName: details.official_name })}
          component={
            <FormReservation
              loadingDetails={loading}
              residenceId={id}
              residenceInfo={details}
              hideModal={hideReservationModal}
              multiUnits={details.multi_unit === 1}
              t={t}
            />
          }
          width="70%"
          height="90%"
        />
      )}
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={popupDetail.title}
        msg={popupDetail.msg}
        deleteActivity={popupDetail.deleteActivity}
        textBtn={popupDetail.textBtn}
        typeBtn={popupDetail.typeBtn}
      />
    </>
  );
}
