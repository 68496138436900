import React from 'react';
import './PriceListing.scss';

export default function PriceListing() {
  const PriceList = [
    { text: "Taxe d'hébergement", value: '3,50$' },
    { text: 'Frais de service', value: '3,11$' },
    { text: 'TPS (#848340436)', value: '5,33$' },
    { text: 'TVQ (#1220482011)', value: '10,63$' },
    { text: 'Total', value: '122,57$' },
  ];
  return (
    <table className="table sum">
      <tbody>
        {PriceList.map(({ text, value }) => (
          <tr>
            <th>{text}</th>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
