import React from 'react';
import { Button } from 'devextreme-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'moment/locale/fr-ca';

import '../style.scss';

export default function CustomDatesRender({ dates, key, onDeleteDate }) {
  return (
    <div key={key} className="containerDate">
      <span>
        {`Du ${moment(dates?.start_date).format('DD MMMM')} au ${moment(dates?.end_date).format('DD MMMM YYYY')}`}
      </span>
      <Button icon="trash" hint="Supprimer" onClick={() => onDeleteDate(dates)} />
    </div>
  );
}

CustomDatesRender.propTypes = {
  dates: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
  onDeleteDate: PropTypes.func.isRequired,
};
