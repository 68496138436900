import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import './delete-popup.scss';

export default function DeletePopup({
  popupVisible,
  hideModal,
  title,
  msg,
  deleteActivity,
  src,
  textBtn,
  typeBtn,
  width,
}) {
  const deleteButtonOptions = {
    text: textBtn,
    stylingMode: 'contained',
    type: typeBtn,
    onClick: deleteActivity,
  };
  const closeButtonOptions = {
    text: 'Annuler',
    stylingMode: 'contained',
    type: 'normal',
    onClick: hideModal,
  };
  return (
    <Popup
      visible={popupVisible}
      onHiding={hideModal}
      dragEnabled={false}
      closeOnOutsideClick
      showCloseButton
      showTitle
      title={title}
      container=".dx-viewport"
      width={width}
      height="auto"
    >
      <Position at="center" my="center" />
      <ToolbarItem widget="dxButton" toolbar="bottom" location="after" options={deleteButtonOptions} />
      <ToolbarItem widget="dxButton" toolbar="bottom" location="after" options={closeButtonOptions} />
      <p>{msg}</p>
      {src && (
        <div className="imageContainer">
          <img alt="" src={src} width="150px" height="100px" />
        </div>
      )}
    </Popup>
  );
}

DeletePopup.propTypes = {
  popupVisible: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  deleteActivity: PropTypes.func.isRequired,
  src: PropTypes.string,
  textBtn: PropTypes.string,
  typeBtn: PropTypes.oneOf(['success', 'default', 'normal', 'danger']),
  width: PropTypes.number,
};

DeletePopup.defaultProps = {
  popupVisible: false,
  src: '',
  textBtn: 'Supprimer',
  typeBtn: 'danger',
  width: 400,
};
