import React, { useState, useRef } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { permalinkEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import useSubmitForm from '../../../hooks/useSubmitForm/useSubmitForm';
import { checkBoxOptions, FileEditorOptions } from './input-options';
import { useAuth } from '../../../contexts/auth';
import { getPermissionVisibility } from '../../../utils';

export default function EquipmentForm() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const history = useHistory();

  const { auth } = useAuth();
  const { role } = auth;

  const { id } = useParams();
  const isAddMode = !id;
  const { t } = useTranslation();
  const [isIcon, setIsIcon] = useState(false);
  const refForm = useRef('equipmentForm');
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const formatDataBeforeSubmit = (data) => {
    const { seo_fr, seo_en, published, icon, ...rest } = data;
    const seo =
      seo_fr || seo_en
        ? [
            { ...seo_fr, language: 'fr' },
            { ...seo_en, permalink: null, language: 'en' },
          ]
        : [];
    const values = {
      ...rest,
      seo: JSON.stringify(seo),
      published: published ? +published : 0,
    };
    if (icon.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      values.icon = icon[0];
    }
    const formData = new FormData();
    Object.keys(values).map((key) => formData.append(key, values[key]));
    if (!isAddMode) {
      formData.append('_method', 'PUT');
    }
    return formData;
  };

  const formatDataForUpdate = (data) => {
    const { icon, ...rest } = data;
    const items = { ...rest, icon: [] };
    return { items, icon };
  };

  const [items, loading, onSubmit, icon] = useSubmitForm(
    'equipments',
    'equipements',
    "L'équipement",
    formatDataBeforeSubmit,
    formatDataForUpdate,
    { 'Content-Type': 'multipart/form-data' },
    true
  );
  const title = isAddMode ? 'Ajouter un équipement' : 'Modifier un équipement';

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Options et équipements"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/equipements')}
      />
      <h2 className="content-block">{title}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form ref={refForm} formData={items} disabled={loading} showValidationSummary>
              <TabbedItem>
                <TabPanelOptions
                  deferRendering={false}
                  selectedIndex={getPermissionVisibility(['webmaster'], role) ? 1 : 0}
                />
                <Tab title="Général" disabled={getPermissionVisibility(['webmaster'], role)}>
                  <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom est requis" />
                    <Label text="Nom" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="icon" editorType="dxFileUploader" editorOptions={FileEditorOptions(setIsIcon)}>
                    {(!!icon && isIcon) || <RequiredRule message={t('errors.required_icon')} />}
                    <Label text="Icône" visible={false} />
                  </SimpleItem>
                  {!!icon && isIcon && (
                    <GroupItem>
                      <div className="content-block">
                        <img src={icon} alt="icon" width="100" height="100" />
                      </div>
                    </GroupItem>
                  )}
                  <SimpleItem dataField="published" editorType="dxCheckBox" editorOptions={checkBoxOptions}>
                    <Label visible={false} />
                  </SimpleItem>
                </Tab>
                <Tab title="SEO">
                  <SimpleItem
                    dataField="seo_fr.permalink"
                    editorType="dxTextBox"
                    editorOptions={Object.assign(permalinkEditorOptions(refForm), textEditorOptions)}
                  >
                    <Label text="Permalien" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_fr.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Titre Français" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_fr.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Description Français" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_en.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Titre Anglais" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_en.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Description Anglais" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/equipements')}>
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
