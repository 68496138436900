import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { get, getAll } from '../../../api';
import WidgetImage from '../../../components/widgets/mainImage';
import AddPopup from '../../../components/add-popup/add-popup';
import FacturetionsListing from './facturationsListing';
import GenerateFacturePopupForm from '../../../components/form/generate-facture';

const FakeDAta = [
  {
    id: 1,
    payee: true,
    transf: true,
    number: '20210303-5',
    titre: 'Frais pour annulation Diane Tremblay 17-31 juillet 2021',
    brut: 731,
    tps: 30,
    tvq: 61,
    total: 824,
  },
  {
    id: 2,
    payee: false,
    transf: false,
    number: '20210303-5',
    titre: 'Frais pour annulation Diane Tremblay 17-31 juillet 2021',
    brut: 731,
    tps: 30,
    tvq: 61,
    total: 824,
  },
  {
    id: 3,
    payee: false,
    transf: false,
    number: '20210303-5',
    titre: 'Frais pour annulation Diane Tremblay 17-31 juillet 2021',
    brut: 731,
    tps: 30,
    tvq: 61,
    total: 824,
  },
  {
    id: 4,
    payee: false,
    transf: false,
    number: '20210303-5',
    titre: 'Frais pour annulation Diane Tremblay 17-31 juillet 2021',
    brut: 731,
    tps: 30,
    tvq: 61,
    total: 824,
  },
  {
    id: 5,
    payee: false,
    transf: false,
    number: '20210303-5',
    titre: 'Frais pour annulation Diane Tremblay 17-31 juillet 2021',
    brut: 731,
    tps: 30,
    tvq: 61,
    total: 824,
  },
  {
    id: 6,
    payee: false,
    transf: false,
    number: '20210303-5',
    titre: 'Frais pour annulation Diane Tremblay 17-31 juillet 2021',
    brut: 731,
    tps: 30,
    tvq: 61,
    total: 824,
  },
];

export default function Facturations() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [residenceDetails, setResidenceDetails] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);

  const showModal = () => {
    setPopupVisible(true);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    get('residences', id).then((response) => {
      setResidenceDetails(response.data.data);
    });
    // getAll(`facturations/${id}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    // });
    setCustomDataSource(FakeDAta);
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('common.goback_residences')}
        type="default"
        stylingMode="text"
        onClick={() => history.push('/residences')}
      />
      <h2 className="content-block">
        {residenceDetails.name}, {residenceDetails.official_name}
      </h2>
      <div className="content-block client">
        <div className="client_item client_item-actions">
          <WidgetImage t={t} src={residenceDetails.main_photo} />
          <div className="dx-card responsive-paddings-widget widget">
            <div className="widget_item_title">
              <i className="fa fa-edit" />
              {t('common.actions')}
            </div>
            <div className="client_btn containerBtnsActions">
              <Button icon="file" type="normal" text={t('facturations.facture_generate')} onClick={showModal} />
              <hr className="barre" />
              <Button
                icon="fa fa-exchange"
                type="normal"
                text={t('facturations.etat_compte')}
                onClick={() => history.push(`/residences/etats_comptes/${id}`)}
              />
              <Button
                icon="chevronprev"
                type="default"
                text={t('residance.backToResidance')}
                onClick={() => history.push(`/residences/details/${id}`)}
              />
            </div>
          </div>
        </div>
        <div className="dx-card responsive-paddings-widget widget client_item client_item-listing">
          <div className="widget_item_title">
            <i className="fa fa-file" />
            {t('facturations.title')}
          </div>
          <FacturetionsListing
            customDataSource={customDataSource}
            setCustomDataSource={setCustomDataSource}
            residenceId={id}
          />
        </div>
      </div>
      <AddPopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={t('facturations.facture_generate')}
        component={<GenerateFacturePopupForm hideModal={hideModal} />}
        width="60%"
      />
    </>
  );
}
