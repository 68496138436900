import React, { useEffect, useState } from 'react';
import '../styles/style.scss';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Button } from 'devextreme-react/data-grid';
import { Button as MainButton } from 'devextreme-react';
import PropTypes from 'prop-types';
import DeletePopup from '../../delete-popup/delete-popup';
import AddPopup from '../../add-popup/add-popup';
import { remove } from '../../../api';
import { Toast } from '../../../utils';
import './prices-details.scss';
import FormMultiplePrices from '../../form/multiplePrice-form';

export default function WidgetMultiplePrices({ idResidence, isTwin, dataListPrices, addTarif }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation();
  const [popupVisible, setPopupVisible] = useState({ status: false, idPrice: null });
  const [popupAddVisible, setPopupAddVisible] = useState({ status: false, idPrice: null, initial: false });
  const [dataPrices, setDataPrices] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const hideModal = () => {
    setPopupVisible({ status: false, idPrice: null });
  };
  const hideAddModal = () => {
    setPopupAddVisible({ status: false, idPrice: null, initial: true });
  };
  const handleDelete = () => {
    remove('tariffs', `${idResidence}/${popupVisible.priceInfo.id}`).then(() => {
      hideModal();
      setDataPrices((prevState) => {
        const newState = prevState.filter((obj) => obj.id !== popupVisible.priceInfo?.id);
        return newState;
      });
      Toast(t('common.successDeleteM', { title: 'Le tarif' }));
    });
  };
  const onPopupAddVisible = (data) => {
    setPopupAddVisible(data);
  };

  const renderAddressHeader = (data) => {
    return <p className="caption">{data.column.caption}</p>;
  };

  const handleAdd = (data) => {
    setDataPrices((prevState) => {
      setDataPrices([...(prevState.length ? prevState : []), data]);
    });
  };
  const handleUpdate = (data) => {
    setDataPrices((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === data.id) {
          return data;
        }
        return obj;
      });
      return newState;
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                                  USEEEFECT                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (dataListPrices != null) {
      setDataPrices(dataListPrices);
    }
  }, [dataListPrices, addTarif]);
  return (
    <div className="dx-card responsive-paddings-widget widget">
      <div>
        <MainButton
          text={t('residance.addPrice')}
          type="success"
          className="add_tarif"
          disabled={!addTarif}
          onClick={() => onPopupAddVisible({ status: true, idPrice: null })}
        />
        <DataGrid
          className="dx-card wide-card"
          dataSource={dataPrices}
          showBorders
          focusedRowEnabled={false}
          keyExpr="id"
          noDataText={t('reservation.no_prices')}
        >
          <Column allowSorting={false} dataField="title_fr" caption={t('price.TitleFr').toUpperCase()} />
          <Column
            dataField="basic_nbr_people"
            caption={t('price.numPersons').toUpperCase()}
            allowSorting={false}
            hidingPriority={7}
          />
          <Column allowSorting={false} dataField="price_basic_night" caption={t('price.priceByNigth').toUpperCase()} />
          <Column allowSorting={false} dataField="price_fds_night" caption={t('price.priceFdsByNigth').toUpperCase()} />
          <Column
            allowSorting={false}
            dataField="price_night_additional_person"
            caption={t('price.priceExtraPerson').toUpperCase()}
            headerCellRender={renderAddressHeader}
          />
          <Column type="buttons">
            <Button
              hint="Modifier"
              icon="edit"
              onClick={(e) => onPopupAddVisible({ status: true, priceInfo: e.row.data })}
            />
            <Button
              hint="Supprimer"
              icon="fa fa-trash"
              onClick={(e) => setPopupVisible({ status: true, priceInfo: e.row.data })}
            />
          </Column>
        </DataGrid>
        <AddPopup
          popupVisible={popupAddVisible.status}
          hideModal={hideAddModal}
          height="80%"
          width="60%"
          title={popupAddVisible?.priceInfo?.id > 0 ? t('residance.editPrice') : t('residance.addPrice')}
          component={
            <FormMultiplePrices
              dataPrice={popupAddVisible.priceInfo}
              isTwin={isTwin}
              hideAddModal={hideAddModal}
              initialVal={popupAddVisible.initial}
              t={t}
              handleAdd={handleAdd}
              handleUpdate={handleUpdate}
            />
          }
        />
        <DeletePopup
          popupVisible={popupVisible.status}
          hideModal={hideModal}
          title={`Suppression de tarif ${popupVisible.priceInfo?.title_fr}`}
          msg={`Êtes-vous sûr de vouloir supprimer ce tarif: ${popupVisible.priceInfo?.title_fr} ?`}
          deleteActivity={handleDelete}
        />
      </div>
    </div>
  );
}

WidgetMultiplePrices.propTypes = {
  idResidence: PropTypes.string.isRequired,
  isTwin: PropTypes.bool.isRequired,
  dataListPrices: PropTypes.array.isRequired,
  addTarif: PropTypes.bool.isRequired,
};
