import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './etats_compte.scss';
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  LoadPanel,
  Sorting,
  Summary,
  Button,
  TotalItem,
} from 'devextreme-react/data-grid';
import { useAuth } from '../../../contexts/auth';
import RenderHeaderPage from '../../../components/widgets/headerResidance';
import WidgetImage from '../../../components/widgets/mainImage';
import WidgetActions from '../../../components/widgets/actions';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import NotePopup from '../../../components/notePopup/note_popup';
import { getAll } from '../../../api';
import { priceColumn_customizeText } from '../../../utils';

export default function EtatsCompte() {
  const { t } = useTranslation();
  const { id, itemId } = useParams();
  const history = useHistory();
  const { auth } = useAuth();
  const [dataList, setDataList] = useState({});
  const [popupVisible, setPopupVisible] = useState({ status: false, id: null });
  const [popupEmailVisible, setPopupEmailVisible] = useState({ status: false, id: null });
  const [isVisible, setIsVisible] = useState(false);
  const [noteMsg, setNoteMsg] = useState(null);

  const navigateBack = () => {
    history.push(`/residences/etats_comptes/${id}`);
  };

  const navigateBackResidence = () => {
    history.push(`/residences/details/${id}`);
  };

  const hideModal = () => {
    setPopupVisible({ status: false, id: null });
  };
  const onDelete = () => {
    hideModal();
  };

  const onHiding = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    getAll(`tariffs/${id}/basic`).then((res) => {
      setDataList(res.data);
    });
  }, []);

  const listActions = [
    {
      id: 1,
      name: t('residance.exporter_en_pdf'),
      icon: 'fa fa-magic',
      onPress: console.log('press'),
      styles: 'actionBtn',
      display: true,
    },
    {
      id: 2,
      name: t('residance.expedier_etat_compte'),
      icon: 'fa fa-trophy',
      onPress: () => {
        setIsVisible(true);
        setNoteMsg('Sélectionner le ou les destinataires');
      },
      styles: 'actionBtn',
      display: true,
    },
    {
      id: 3,
      name: t('common.delete'),
      icon: 'fa fa-file',
      onPress: (e) => setPopupVisible({ status: true, id: itemId }),
      styles: 'actionBtn orangeBtnBackground btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 4,
      name: t('residance.retour_etats_compte'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBack,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
    {
      id: 5,
      name: t('residance.backToResidance'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBackResidence,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
  ];

  const customDataSource = [
    {
      id: 1,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 2,
      date: '2021-01-31',
      type: 'CR',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 3,
      date: '2021-01-31',
      type: 'CR',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 4,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 5,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 6,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 7,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 8,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 9,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 10,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
    {
      id: 11,
      date: '2021-01-31',
      type: 'FA',
      num: '20210208-4',
      ref: 'Commission janvier 2021',
      mont: 402,
    },
  ];
  const PermissionsList = auth.permissions;
  let total_fact = 0;

  const calculateFacAmount = (rowData) => {
    if (rowData.type === 'FA') {
      total_fact += rowData.mont;
    }
    return total_fact.toFixed(3);
  };

  function isCancelButtonVisible(e) {
    return !e.row.isEditing && !e.row.data.isCompleted;
  }

  return (
    <div className="content-block">
      <RenderHeaderPage
        t={t}
        id=""
        name={dataList ? `${t('residance.account_status')}, ${dataList.name}` : 'page'}
        officialName={dataList.official_name}
      />
      <div className="mainContainer">
        <div className="side">
          <WidgetImage t={t} src={dataList?.main_photo ?? ''} />
          <WidgetActions ListAction={listActions} title={t('common.actions')} t={t} dviderIndexs={[]} />
        </div>
        <DataGrid
          className="dx-card wide-card"
          dataSource={customDataSource}
          showBorders
          defaultFocusedRowIndex={0}
          columnAutoWidth
          columnHidingEnabled
        >
          <Sorting mode="none" />
          <LoadPanel enabled />
          <Paging defaultPageSize={10} />
          <Editing mode="row" useIcons />
          <Column dataField="date" caption="Date" />
          <Column dataField="type" caption="Type" />
          <Column dataField="num" caption="NUMÉRO" />
          <Column dataField="ref" caption="RÉFÉRENCE" />
          <Column dataField="mont" caption="Montant" customizeText={priceColumn_customizeText} />
          <Column type="buttons">
            <Button
              hint="Voir"
              icon="fa fa-eye"
              onClick={(e) => history.push(`/${e.row.data.id}`)}
              className="actionBtn"
              visible={(e) => e.row.data.type === 'CR'}
            />
          </Column>
          <Summary>
            <TotalItem column="mont" valueFormat="decimal" displayFormat="Crédit: 2587 $" />
            <TotalItem column="mont" valueFormat="decimal" displayFormat="Facture: 2587 $" />
            <TotalItem column="mont" summaryType="sum" valueFormat="decimal" displayFormat="Total: {0} $" />
          </Summary>
        </DataGrid>
        <DeletePopup
          popupVisible={popupVisible.status}
          hideModal={hideModal}
          msg="Supprimer l'état de compte"
          title="Êtes-vous sûr de vouloir supprimer cette état de compte?"
          deleteActivity={() => {
            onDelete();
            navigateBack();
          }}
        />
        <NotePopup isVisible={isVisible} onHiding={onHiding} title="Note de paiement" msg={noteMsg} width={800} />
      </div>
    </div>
  );
}
