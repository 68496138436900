import { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../contexts/auth';
import { getAll } from '../api';

export default function useGetMergTags() {
  const [tags, setDataTags] = useState(null);
  const { auth } = useAuth();
  const owner_permission = auth.role === 'residence_owner';
  if (owner_permission) return false;

  useEffect(() => {
    const array = [
      {
        id: '1',
        text: 'Tags',
        items: [],
      },
    ];
    if (tags == null) {
      getAll('merge_tags').then((res) => {
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const key in res.data) {
          array[0].items.push({
            text: key,
            items: res.data[key],
          });
        }
        setDataTags(array);
      });
    }
  }, []);

  return useMemo(
    () => ({
      tags,
    }),
    [tags]
  );
}
