import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DeletePopup from '../../../../../../components/delete-popup/delete-popup';
import { getAll, remove } from '../../../../../../api';
import { Toast } from '../../../../../../utils';
import Hero from '../../../../../../components/hero/hero';

const lignes = [
  { id: 1, description: 'Ajout de la nuit du 2 juillet 2021', montant: '100.00$' },
  { id: 2, description: 'Ajout de la nuit du 2 juillet 2021', montant: '100.00$' },
  { id: 3, description: 'Ajout de la nuit du 2 juillet 2021', montant: '100.00$' },
  { id: 4, description: 'Ajout de la nuit du 2 juillet 2021', montant: '100.00$' },
  { id: 5, description: 'Ajout de la nuit du 2 juillet 2021', montant: '100.00$' },
];

export default function MontantListing({ factureId, reservationId }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [customDataSource, setCustomDataSource] = useState([]);

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteLigne = (idLigne) => {
    setCustomDataSource(customDataSource.filter((data) => data.id !== idLigne));
    hideModal();
    Toast(t('common.successDelete', { title: 'La ligne' }));
    // remove('lignes', idLigne).then(() => {
    //   setCustomDataSource(customDataSource.filter((data) => data.id !== idLigne));
    //   hideModal();
    //   Toast(t('common.successDelete', { title: 'La ligne' }));
    // });
  };

  useEffect(() => {
    setCustomDataSource(lignes);
    // getAll(`lignes/${factureId}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    // });
  }, []);

  return (
    <>
      <div className="btn_ajout">
        <Hero
          title=""
          btnText={t('factures.ligne_add')}
          url={`/factures/lignes/ajouter/${reservationId}/${factureId}`}
        />
      </div>
      <DataGrid
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} />
        <Column dataField="description" caption="DESCRIPTION" />
        <Column dataField="montant" caption="MONTANT" />
        <Column type="buttons" width={110}>
          <Button
            hint={t('common.update')}
            icon="edit"
            onClick={(e) => history.push(`/factures/lignes/modifier/${reservationId}/${factureId}/${e.row.data.id}`)}
          />
          <Button icon="trash" hint={t('common.delete')} onClick={showModal} />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={t('factures.ligne_delete')}
        msg={t('factures.ligne_delete_msg')}
        deleteActivity={() => deleteLigne(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];

MontantListing.propTypes = {
  factureId: PropTypes.string.isRequired,
  reservationId: PropTypes.string.isRequired,
};
