import React from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';

export default function RenderHeaderPage({ t, id, name, officialName, rate, auth }) {
  return (
    <div>
      <div className="headerTitle">
        <div>
          <h2 className="noMargin">
            {name} {officialName !== null ? `, ${officialName}` : ''}
          </h2>
        </div>
        {auth && <h2 className="noMargin">{id ? `${t('common.id').toUpperCase()} : ${id} | ${rate ?? '0'}%` : ''}</h2>}
      </div>
    </div>
  );
}

RenderHeaderPage.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  officialName: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
  auth: PropTypes.bool,
};

RenderHeaderPage.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  auth: true,
};
