import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { Button } from 'devextreme-react/button';
import DataSource from 'devextreme/data/data_source';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useTranslation } from 'react-i18next';
import caImg from '../../../assets/ca.gif';
import euImg from '../../../assets/eu.gif';
import { create } from '../../../api';
import EditorInput from '../../editor-input/editor-input';
import {
  textEditorOptions,
  emailEditorOptions,
  AutoCompleteEditorOptions,
  salutationEditorOptions,
  langueEditorOptions,
} from '../../../utils/editorOptions';
import { canadaProvince, countriesList, Toast } from '../../../utils';
import useGetMergTags from '../../../hooks/useGetTags';

export default function PopupClientForm({ hideModal }) {
  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANS                                  */
  /* -------------------------------------------------------------------------- */
  const phoneCaEditorOptions = {
    mask: '0 (000) 000-0000',
    maskInvalidMessage: 'Le téléphone doit avoir un format de téléphone CA correct',
    labelMode: 'floating',
  };

  const phoneEuEditorOptions = {
    mask: '00 0 00 00 00 00',
    maskInvalidMessage: 'Le téléphone doit avoir un format de téléphone EU correct',
    labelMode: 'floating',
  };
  const cacheKey = 'upa-country-restricted';
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [valueContent, setValueContent] = useState('');
  const [phoneEditorOptions, setPhoneEditorOptions] = useState(phoneCaEditorOptions);
  const [phoneSecondEditorOptions, setPhoneSecondEditorOptions] = useState(phoneCaEditorOptions);
  const [isSelect, setIsSelect] = useState(true);
  const [items, setItems] = useState({});
  const refFormRAddClient = useRef('AddCleintForm');
  const dataTags = useGetMergTags();

  const { suggestions, setValue } = usePlacesAutocomplete({
    cacheKey,
    requestOptions: {
      componentRestrictions: {
        country: items.country ? countriesList.find((element) => element.name === items.country)?.code : 'ca',
      },
    },
  });
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const countryEditorOptions = {
    dataSource: new DataSource({
      store: {
        data: countriesList,
        type: 'array',
        key: 'code',
      },
    }),
    displayExpr: 'name',
    valueExpr: 'name',
    showClearButton: true,
    searchEnabled: true,
    labelMode: 'floating',
    onValueChanged: (e) => {
      if (e.value === 'Canada') {
        setIsSelect(true);
      } else {
        setIsSelect(false);
        setItems({ ...items, province: '' });
      }
    },
  };

  const provinceEditorOptions = {
    dataSource: canadaProvince,
    showClearButton: true,
    searchEnabled: true,
    labelMode: 'floating',
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const notes = valueContent;
      const values = { ...items, notes };
      values.phone = JSON.stringify(values.phone);
      if (values.second_phone && values.second_phone.number) {
        values.second_phone = JSON.stringify(values.second_phone);
      } else {
        delete values.second_phone;
      }
      setLoading(true);
      return addClient(values);
    },
    [items, valueContent]
  );

  const addClient = (values) => {
    create('clients', values)
      .then((res) => {
        setLoading(false);
        res.data && hideModal();
        res.data && Toast(t('client.add_msg'));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 USEEFFECTS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setItems({ ...items, country: 'Canada', province: 'Québec' });
  }, []);

  useEffect(() => {
    if (items?.country) {
      // clear saved suggestions from cache google api when change country
      sessionStorage.removeItem(cacheKey);
      // clear inputs country  state  zipCode
      if (items?.address) {
        setTimeout(() => {
          setItems((state) => {
            const newState = { ...state, address: '', city: '', postal_code: '', province: '' };
            return newState;
          });
        }, 100);
      }
    }
  }, [items?.country]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block">
      <div className="dx-card responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form ref={refFormRAddClient} formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
            <TabbedItem>
              <TabPanelOptions deferRendering />
              <Tab title="Informations de base">
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="gender" editorType="dxSelectBox" editorOptions={salutationEditorOptions}>
                    <RequiredRule message="La Salutation est requise" />
                    <Label text="Salutation" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="language" editorType="dxSelectBox" editorOptions={langueEditorOptions}>
                    <RequiredRule message="La Langue est requise" />
                    <Label text="Langue" visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={3}>
                  <SimpleItem dataField="first_name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le Prénom est requis" />
                    <Label text="Prénom" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="last_name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom est requis" />
                    <Label text="Nom" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="surname" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Nom (autre)" visible={false} />
                  </SimpleItem>
                </GroupItem>
                <SimpleItem dataField="company" editorType="dxTextBox" editorOptions={textEditorOptions}>
                  <Label text="Compagnie" visible={false} />
                </SimpleItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="email" editorType="dxTextBox" editorOptions={emailEditorOptions}>
                    <RequiredRule message="Le courriel est requis" />
                    <EmailRule message="Le courriel est invalide" />
                    <Label text="Courriel" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="second_email" editorType="dxTextBox" editorOptions={emailEditorOptions}>
                    <EmailRule message="Le courriel est invalide" />
                    <Label text="Courriel (autre)" visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="phone.number" editorType="dxTextBox" editorOptions={phoneEditorOptions}>
                    <RequiredRule message="Le téléphone est requis" />
                    <Label text="Téléphone" visible={false} />
                  </SimpleItem>
                  <GroupItem colCount={2}>
                    <SimpleItem dataField="phone.extension" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <Label text="Ext." visible={false} />
                    </SimpleItem>
                    <SimpleItem>
                      <Button
                        className="btn-icon"
                        icon={caImg}
                        onClick={() => setPhoneEditorOptions(phoneCaEditorOptions)}
                      />
                      <Button
                        className="btn-icon"
                        icon={euImg}
                        onClick={() => setPhoneEditorOptions(phoneEuEditorOptions)}
                      />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem
                    dataField="second_phone.number"
                    editorType="dxTextBox"
                    editorOptions={phoneSecondEditorOptions}
                  >
                    <Label text="Téléphone (autre)" visible={false} />
                  </SimpleItem>
                  <GroupItem colCount={2}>
                    <SimpleItem
                      dataField="second_phone.extension"
                      editorType="dxTextBox"
                      editorOptions={textEditorOptions}
                    >
                      <Label text="Ext. (autre)" visible={false} />
                    </SimpleItem>
                    <SimpleItem>
                      <Button
                        className="btn-icon"
                        icon={caImg}
                        onClick={() => setPhoneSecondEditorOptions(phoneCaEditorOptions)}
                      />
                      <Button
                        className="btn-icon"
                        icon={euImg}
                        onClick={() => setPhoneSecondEditorOptions(phoneEuEditorOptions)}
                      />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={1}>
                  <SimpleItem dataField="country" editorType="dxSelectBox" editorOptions={countryEditorOptions}>
                    <RequiredRule message="Le Pays est requis" />
                    <Label text="Pays" visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem
                    dataField="address"
                    editorType="dxAutocomplete"
                    editorOptions={AutoCompleteEditorOptions(setValue, suggestions, refFormRAddClient)}
                  >
                    <RequiredRule message="L'adresse est requise" />
                    <Label text="Adresse" visible={false} />
                  </SimpleItem>
                  {isSelect ? (
                    <SimpleItem dataField="province" editorType="dxSelectBox" editorOptions={provinceEditorOptions}>
                      <RequiredRule message="Le province est requis" />
                      <Label text="province" visible={false} />
                    </SimpleItem>
                  ) : (
                    <SimpleItem dataField="province" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message="La province est requise" />
                      <Label text="Province" visible={false} />
                    </SimpleItem>
                  )}
                </GroupItem>

                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="city" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="La ville est requise" />
                    <Label text="Ville" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="postal_code" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le code postal est requis" />
                    <Label text="Code postal" visible={false} />
                  </SimpleItem>
                </GroupItem>
              </Tab>
              <Tab title="Remarques">
                <EditorInput dataSource={dataTags} valueContent={valueContent} valueChanged={setValueContent} />
              </Tab>
            </TabbedItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={() => hideModal()}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

PopupClientForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
};
