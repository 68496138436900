import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

export default function NotePopup({ isVisible, onHiding, title, msg, width }) {
  const closeButtonOptions = {
    text: 'Annuler',
    stylingMode: 'contained',
    type: 'normal',
    onClick: onHiding,
  };
  return (
    <Popup
      visible={isVisible}
      onHiding={onHiding}
      dragEnabled={false}
      closeOnOutsideClick
      showCloseButton
      showTitle
      title={title}
      container=".dx-viewport"
      width={width}
      height="auto"
    >
      <Position at="center" my="center" />
      <ToolbarItem widget="dxButton" toolbar="bottom" location="after" options={closeButtonOptions} />
      <p>{msg}</p>
    </Popup>
  );
}

NotePopup.propTypes = {
  isVisible: PropTypes.bool,
  onHiding: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  width: PropTypes.number,
};

NotePopup.defaultProps = {
  isVisible: false,
  width: 800,
};
