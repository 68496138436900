import React, { useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'react-pagination-bar';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.scss';
import { getAll } from '../../api';
import 'react-pagination-bar/dist/index.css';
import ReservationSearchForm from '../form/reservation-search-form/reservation-search-form';
import ReservationsListing from '../../pages/reservations/ReservationsListing/ReservationsListing';

export default function ReservationsComponent({ isTrashed, isCredit }) {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [isSearch, setIsSearch] = useState(false);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchObject, setSearchObject] = useState({});
  const { t } = useTranslation();

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  const generateURLPath = (searchValues) => {
    const archivePram = isTrashed ? 'archive=1&' : '';
    let url = `?${archivePram}page=${currentPage}`;
    Object.keys(searchValues).forEach(function (key) {
      if (searchValues[key] === null || searchValues[key].length === 0) {
        url += '';
      } else if (typeof searchValues[key] === 'object') {
        url += `&${key}=[${searchValues[key]}]`;
      } else {
        url += `&${key}=${searchValues[key]}`;
      }
    });
    return url;
  };

  useEffect(() => {
    const formValues = {
      reservation_number: queryParams.get('reservation_number'),
      residence_id: queryParams.get('residence_id'),
      client_id: queryParams.get('client_id'),
      start_at: queryParams.get('start_at'),
      end_at: queryParams.get('end_at'),
      origin: queryParams.get('origin'),
      first_name: queryParams.get('first_name'),
      last_name: queryParams.get('last_name'),
      email: queryParams.get('email'),
      phone: queryParams.get('phone'),
      company: queryParams.get('company'),
    };

    const url = generateURLPath(formValues);

    getAll(`reservations/${url}`).then((response) => {
      setPaginationMeta(response.data.meta);
      setCustomDataSource(response.data.data);
    });
    const { residence_id, client_id, ...rest } = formValues;
    const searchData = { ...rest };
    if (client_id) {
      getAll(`search/client/?filed=id&value=${client_id}`).then((response) => {
        searchData.client_id = response.data.data;
      });
    }
    if (residence_id) {
      getAll(`search/residence/?filed=id&value=${residence_id}`).then((response) => {
        searchData.residence_id = response.data.data;
      });
    }
    setSearchObject(searchData);
  }, [currentPage]);

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTANTS                                 */
  /* -------------------------------------------------------------------------- */
  const handleSearchValues = (values) => {
    const url = generateURLPath(values);
    // eslint-disable-next-line no-nested-ternary
    const archiveUrl = isCredit ? '/credits' : isTrashed ? '/corbeille' : '';

    history.push(`${archiveUrl}/reservations/${url}`);

    getAll(`reservations/${url}`).then((response) => {
      setPaginationMeta(response.data.meta);
      setCustomDataSource(response.data.data);
    });
    setSearchObject(values);
  };
  /* -------------------------------------------------------------------------- */
  /*                                   RANDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <h2 className="content-block">
        {isCredit ? 'Réservation annulé avec crédit' : `Historique des réservations${isTrashed ? ' - Corbeille' : ''}`}
      </h2>
      <div className="btn_ajout">
        <div className="dx-field">
          <div className="dx-field-label" />
          <div className="dx-field-value content-block">
            <Button className="btn-icon" hint="Rechercher" icon="find" onClick={() => setIsSearch(!isSearch)} />
          </div>
        </div>
      </div>
      {isSearch && <ReservationSearchForm handleSearchValues={handleSearchValues} formValues={searchObject} />}
      <ReservationsListing customDataSource={customDataSource} />
      {paginationMeta?.total > 9 && (
        <div className="containerPginationBar">
          <Pagination
            initialPage={1}
            itemsPerPage={paginationMeta?.per_page ?? 1}
            onPageСhange={(pageNumber) => setCurrentPage(pageNumber)}
            totalItems={paginationMeta?.total ?? 1}
            pageNeighbours={5}
            endLabel=">>"
            nextLabel=">"
            startLabel="<<"
            prevLabel="<"
          />
        </div>
      )}
    </>
  );
}

ReservationsComponent.propTypes = {
  isTrashed: PropTypes.bool,
  isCredit: PropTypes.bool,
};

ReservationsComponent.defaultProps = {
  isTrashed: false,
  isCredit: false,
};
