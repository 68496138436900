import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import './update-taxe-form.scss';
import { Tab } from 'devextreme-react/diagram';
import Button from 'devextreme-react/button';
import { get, update } from '../../../api';
import { textEditorOptions } from '../../../utils/editorOptions';
import { Toast } from '../../../utils';

export default function UpdateTaxeForm() {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { type, ...rest } = items;
      const values = { ...rest };
      update('taxes', id, values)
        .then((res) => {
          setLoading(false);
          res.data && history.push('/taxes');
          res.data && Toast('Le taxe a été bien modifié');
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  useEffect(() => {
    get('taxes', id).then((response) => {
      setItems(response.data.data);
    });
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Taxes"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/taxes')}
      />
      <h2 className="content-block">Modifier un paramètre: {items.type}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Paramètres">
                  <SimpleItem dataField="value" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="La valeur du taxe est requis" />
                    <Label text="Valeur %" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() => {
                      history.push('/taxes');
                    }}
                  >
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
