import React, { useCallback, useEffect, useState } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { create, get, update } from '../../../api';
import { textEditorOptions } from '../../../utils/editorOptions';
import { Toast } from '../../../utils';

export default function CleApiForm() {
  const history = useHistory();
  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return isAddMode ? addKey(items) : updateKeyById(id, items);
    },
    [items]
  );

  const addKey = (values) => {
    create('tokens', values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/cles-api');
        res.data && Toast("Le clé d'api a été bien ajouté");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateKeyById = (keyId, values) => {
    // eslint-disable-next-line no-param-reassign
    delete values.key;
    update('tokens', keyId, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/cles-api');
        res.data && Toast("Le clé d'api a été bien modifié");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  useEffect(() => {
    if (!isAddMode) {
      get('tokens', id).then((response) => {
        setItems(response.data.data);
      });
    } else {
      setItems({ ...items, key: uuidv4() });
    }
  }, []);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Clé d'Api"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/cles-api')}
      />
      <h2 className="content-block">{isAddMode ? "Ajouter une clé d'api" : "Modifier une clé d'api"}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Informations">
                  {!isAddMode && (
                    <SimpleItem dataField="key" editorType="dxTextBox" editorOptions={apiEditorOptions}>
                      <Label text="API" visible={false} />
                    </SimpleItem>
                  )}
                  <SimpleItem dataField="gateway" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le client est requis" />
                    <Label text="Client" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/cles-api')}>
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}

const apiEditorOptions = {
  stylingMode: 'filled',
  labelMode: 'floating',
  disabled: true,
};
