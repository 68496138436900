import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import RapportListing from './RapportListing/RapportListing';
import RapportTotalListing from './RapportTotalListing/RapportTotalListing';
import PromotionReportForm from './promotionReportForm';

const paymentTotal = [
  {
    remb: '0.00 $',
    no_remb: '167 538,90 $',
  },
];
const paymentRapport = [
  {
    id: 1,
    remb: true,
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    type: '146',
    total: 2282558,
    tps: 0,
    tvq: 0,
    th: 0,
    total_ttc: 228789,
  },
  {
    id: 2,
    remb: true,
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    type: '146',
    total: 2282558,
    tps: 0,
    tvq: 0,
    th: 0,
    total_ttc: 228789,
  },
  {
    id: 3,
    remb: false,
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    type: '146',
    total: 2282558,
    tps: 0,
    tvq: 0,
    th: 0,
    total_ttc: 228789,
  },
  {
    id: 4,
    remb: false,
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    type: '146',
    total: 2282558,
    tps: 0,
    tvq: 0,
    th: 0,
    total_ttc: 228789,
  },
  {
    id: 5,
    remb: true,
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    type: '146',
    total: 2282558,
    tps: 0,
    tvq: 0,
    th: 0,
    total_ttc: 228789,
  },
];

export default function RapportFactures() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [dates, setDates] = useState({});
  const [searchObject, setSearchObject] = useState({});

  useEffect(() => {
    const formValues = {
      debut: queryParams.get('debut'),
      fin: queryParams.get('fin'),
      type: queryParams.get('type'),
    };
    const url = `?debut=${formValues.debut}&fin=${formValues.fin}&type=${formValues.type}`;

    setCustomDataSource(paymentRapport);
    setDates(formValues);
    setSearchObject(formValues);
    // getAll(`transactions/${url}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    // });
  }, []);

  return (
    <>
      <h2 className="content-block">{`${t('factures.compte_rendu_promotion')}: Du ${moment(dates?.debut).format(
        'll'
      )} au ${moment(dates?.fin).format('ll')}`}</h2>
      <div className="factures">
        <div className="factures_listing">
          <RapportListing customDataSource={customDataSource} />
        </div>
        <div className="factures_search">
          <RapportTotalListing customDataSource={paymentTotal} />
          <PromotionReportForm formValues={searchObject} />
        </div>
      </div>
    </>
  );
}
