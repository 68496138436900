/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, SelectBox } from 'devextreme-react';
import moment from 'moment';
import { addDays, subDays } from 'date-fns';
import { useParams, Link } from 'react-router-dom';
import { getAll } from '../../../api';
import { getReservationDatesCalander } from '../../../utils';
import '../styles/style.scss';

/* -------------------------------------------------------------------------- */
/*                                  HELLPERS                                  */
/* -------------------------------------------------------------------------- */
function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('-').toString();
}

function getCellCssClass(date, view, listDates, listInter) {
  let cellInfo = {};
  let cssClass = '';
  (listInter !== null) && listDates.forEach((item, index) => {
    if (view === 'month') {
      if (item.res_date.includes(formatDate(date))) {
        if (item.res_date.indexOf(formatDate(date)) === 0 && !listInter.includes(formatDate(date))) {
          cssClass = 'startCellReservation';
        } else if (
          item.res_date.indexOf(formatDate(date)) === item.res_date.length - 1
        ) {
          cssClass = 'endCellReservation';
        } else if (listInter && item.res_date.indexOf(formatDate(date)) === 0
          && listInter.includes(formatDate(date))) {
          cssClass = 'intersectionCellReservation';
        } else {
          cssClass = 'cellReservation';
        }
        cellInfo = { cssClass, id_res: item.res_id }
      }
    }
  });

  return cellInfo;
}
/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */
export default function WidgetCalendar({ t, multiUnits, loading }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [reservationTable, setReservationTable] = useState([{ res_date: [], res_id: null }]);
  const [listInter, setIistInter] = useState([]);
  const { id } = useParams();
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState(null);

  /* -------------------------------------------------------------------------- */
  /*                                  CONSTATNS                                 */
  /* -------------------------------------------------------------------------- */

  const checkReservationDates = async (date) => {
    setLoadPanelVisible(true);
    const pickerDate = date;
    const res = await getAll(
      multiUnits
        ? `residences/${id}/reservation-date?unit_id=${unit}&start_at=${moment(subDays(pickerDate, 30)).format(
          'YYYY-MM-DD'
        )}&end_at=${moment(addDays(pickerDate, 60)).format('YYYY-MM-DD')}`
        : `residences/${id}/reservation-date?start_at=${moment(subDays(pickerDate, 30)).format(
          'YYYY-MM-DD'
        )}&end_at=${moment(addDays(pickerDate, 60)).format('YYYY-MM-DD')}`
    );
    const array2 = [];
    const array3 = [];
    res.data.data.map((el) =>
      array2.push({
        res_date: getReservationDatesCalander([el]),
        res_id: el.reservation_id ? el.reservation_id : null
      })
    );
    if (array2.length > 0) {
      setReservationTable(array2);
      if (array2) {
        array2.forEach((item) => {
          array3.push(item.res_date[item.res_date.length - 1]);
          setIistInter(array3)
        });
      }

    }

    setLoadPanelVisible(false);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  CALLBACKS                                 */
  /* -------------------------------------------------------------------------- */
  const CustomCell = useCallback(

    (cell, listDates, listIntersec) => {
      const { text, date, view } = cell;
      let isRes;
      let cellCustomued;
      if (listIntersec && listDates) {
        isRes = getCellCssClass(date, view, listDates, listIntersec);
      };

      if (isRes && isRes.id_res) {
        cellCustomued = <Link to={`/reservation/${isRes.id_res}`} className={`${isRes.cssClass} calandar`}>{text}</Link>;
      }
      else { cellCustomued = <span className='calandar'>{text}</span>; }
      return cellCustomued;
    },
    [reservationTable, listInter]
  );

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (multiUnits) {
      getAll(`residence_units/${id}`).then((response) => {
        setUnits(response.data.data);
        if (response.data.data.length > 0) {
          setUnit(response.data.data[0].id);
        }
      });
    }
  }, [id, multiUnits]);

  useEffect(() => {
    if (!loading && multiUnits === false) {
      checkReservationDates(new Date());
    } else if (!loading && unit !== null) {
      checkReservationDates(new Date());
    }
  }, [unit, loading]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="dx-card responsive-paddings widget">
      <div className="widget_item_title">
        <i className="fa fa-calendar" />
        {t('residance.calendar')}
      </div>
      {multiUnits && (
        <SelectBox
          items={units}
          valueExpr="id"
          value={unit}
          onValueChanged={(e) => setUnit(e.value)}
          displayExpr="code"
          label={t('units.units')}
        />
      )}

      <Calendar
        width="100%"
        id="calendar-container"
        cellRender={(cell) => CustomCell(cell, reservationTable, listInter)}
        zoomLevel="month"
        onOptionChanged={(e) =>
          Object.prototype.toString.call(e.value) === '[object Date]' && checkReservationDates(e.value)
        }
        readOnly={loadPanelVisible}
      />
    </div>
  );
}

WidgetCalendar.propTypes = {
  t: PropTypes.func.isRequired,
  multiUnits: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};