import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';

import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react';
import { create, getAll, remove } from '../../../api';
import { Toast } from '../../../utils';
import PriceDetails from './priceDetails';
import WidgetSpecilaPeriode from './specialPeriod';
import DeletePopup from '../../delete-popup/delete-popup';
import WidgetMultiplePrices from './multiplePrices';

/* -------------------------------------------------------------------------- */
/*                                RENDER HELPER                               */
/* -------------------------------------------------------------------------- */
export default function PricesList({ t, dataBasePrice, checkboxValue, isTwin, onDelete, can_add_basic_tariff }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const initialstate = {
    title_fr: '-',
    title_en: '-',
    min_nbr_nights: '-',
    required_maxReservation: '-',
    price_basic_night: '-',
    price_fds_night: '-',
    price_week_more: '-',
    price_month_more: '-',
    price_night_additional_person: '-',
    price_holiday: '-',
    price_animal: '-',
    not_show_tariff: '-',
    not_show_dates_tariff: '-',
    basic_nbr_people: '-',
    max_nbr_animals: '-',
    allow_fill_holes: '-',
    day_delay_between_reservations: '-',
  };
  const [dataPrices, setDataPrices] = useState(initialstate);
  const [popupVisiblDelete, setPopupVisibleDelete] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */

  const hideModalDelete = () => {
    setPopupVisibleDelete(false);
  };

  const showModal = (e) => {
    setPopupVisibleDelete(true);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (!isTwin) {
      setDataPrices({ ...dataPrices, ...checkboxValue });
    }
  }, [checkboxValue]);

  useEffect(() => {
    if (dataBasePrice && dataBasePrice.length > 0) {
      setDataPrices((state) => (isTwin ? dataBasePrice : { ...state, ...dataBasePrice[0] }) ?? {});
    }
  }, [dataBasePrice.length]);

  const handleDelete = () => {
    remove('tariffs', `${id}/${dataPrices.id}`).then(() => {
      hideModalDelete();
      setDataPrices(initialstate);
      onDelete();
      Toast(t('common.successDeleteM', { title: 'Le tarif' }));
    });
  };

  const navigateToUpdate = () => {
    history.push({
      pathname: `/residences/prices/price/${id}`,
      search: `?${new URLSearchParams({ type: 1, isTwin }).toString()}`,
    });
  };

  const keysAllTime = [
    { key: 'title_fr', name: t('price.TitleFr') },
    { key: 'title_en', name: t('price.TitleEn') },
    { key: 'min_nbr_nights', name: t('price.minReservation') },
    { key: 'max_nbr_nights', name: t('price.maxReservation') },
    { key: 'price_basic_night', name: t('price.priceByNigth') },
    { key: 'price_fds_night', name: t('price.priceFdsByNigth') },
    { key: 'price_week_more', name: t('price.weekPrice') },
    { key: 'price_month_more', name: t('price.mounthPrice') },
    { key: 'price_night_additional_person', name: t('price.priceExtraPerson') },
    { key: 'price_holiday', name: t('price.priceHoliday') },
    { key: 'price_animal', name: t('price.priceAnimal') },
    { key: 'not_show_tariff', name: t('price.dontShowPrice') },
    { key: 'not_show_dates_tariff', name: t('price.dontShowDates') },
  ];
  const keysBasic = [
    { key: 'twin', name: t('jumele.jumeles') },
    { key: 'basic_nbr_people', name: t('price.numPersons') },
    { key: 'max_nbr_animals', name: t('price.numAnimals') },
    { key: 'allow_fill_holes', name: t('price.allowToFillGaps') },
    { key: 'day_delay_between_reservations', name: t('price.delay24Hour') },
  ];

  const onDeletePrice = (idPrice) => {
    setDataPrices(dataPrices.filter((data) => data.id !== idPrice));
  };
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="dx-card responsive-paddings-widget fullWidth">
      <div className="gallery_item_title">
        <i className="fa fa-tags" />
        {t('residance.price_list')}
        {!isTwin && (
          <>
            <Button
              disabled={initialstate.basic_nbr_people === dataPrices.basic_nbr_people}
              icon="edit"
              hint={t('common.update')}
              onClick={navigateToUpdate}
            />
            <Button
              disabled={initialstate.basic_nbr_people === dataPrices.basic_nbr_people}
              icon="trash"
              hint={t('common.delete')}
              onClick={showModal}
            />
          </>
        )}
      </div>
      {!isTwin ? (
        <div className="containerPriceDetails">
          <PriceDetails details={dataPrices} keysList={keysBasic} title={t('price.prices')} />
          <PriceDetails details={dataPrices} keysList={keysAllTime} title={t('price.pricesAllTimes')} />
        </div>
      ) : (
        <WidgetMultiplePrices
          dataListPrices={dataPrices}
          addTarif={can_add_basic_tariff}
          idResidence={id}
          isTwin={isTwin}
          onDelete={onDeletePrice}
        />
      )}
      <WidgetSpecilaPeriode idResidence={id} isTwin={isTwin} />
      <DeletePopup
        popupVisible={popupVisiblDelete}
        hideModal={hideModalDelete}
        title={t('price.delete-price-title')}
        msg={t('price.delete-price')}
        deleteActivity={() => {
          handleDelete();
        }}
      />
    </div>
  );
}

PricesList.propTypes = {
  t: PropTypes.func.isRequired,
  dataBasePrice: PropTypes.array.isRequired,
  isTwin: PropTypes.bool.isRequired,
  can_add_basic_tariff: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  checkboxValue: PropTypes.object.isRequired,
};
