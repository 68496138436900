import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule, GroupItem } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useTranslation } from 'react-i18next';
import { textEditorOptions } from '../../../utils/editorOptions';
import { dateBoxOptions } from '../residence-form/input-options';

export default function GenerateFacturePopupForm({ hideModal }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return items;
    },
    [items]
  );

  return (
    <div className="content-block">
      <div className="dx-card responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form formData={items} disabled={loading}>
            <SimpleItem
              dataField="title"
              editorType="dxTextBox"
              editorOptions={{
                ...textEditorOptions,
                placeholder: 'Laisser vide pour "Commission du mois de _____"',
              }}
            >
              <Label text="Titre" visible={false} />
            </SimpleItem>
            <SimpleItem dataField="date" editorType="dxDateBox" editorOptions={dateBoxOptions}>
              <RequiredRule message="la date est requise" />
              <Label text="Date" visible={false} />
            </SimpleItem>
            <GroupItem colCount={3}>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={() => hideModal()}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Générer une facture vierge'}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="success" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Générer'}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

GenerateFacturePopupForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
};
