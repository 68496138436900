/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import './home.scss';
import List from 'devextreme-react/list';
import { useTranslation } from 'react-i18next';
import RequestItem from '../../components/list/request/RequestItem';
import { requests } from '../../data-layer/fake-data/requests';

export default function Home() {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="content-block">{t('common.dashboard')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <div className="list-container">
            <div className="list-col">
              <div className="list-header">
                <h2>Mes demandes</h2>
              </div>
              <div className="list-content">
                <List
                  keyExpr="id"
                  dataSource={requests}
                  height="100%"
                  itemRender={(item) => <RequestItem item={item} t={t} />}
                  noDataText={t('common.noRequests')}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  hoverStateEnabled={false}
                />
              </div>
            </div>

            <div className="list-col">
              <div className="list-header">
                <h2>Toutes les demandes en cours</h2>
              </div>
              <div className="list-content">
                <List
                  dataSource={requests}
                  height="100%"
                  itemRender={(item) => <RequestItem item={item} t={t} />}
                  noDataText={t('common.noRequests')}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  hoverStateEnabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
