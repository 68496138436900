const navigationWithPermission = [
  {
    roles: ['super_admin', 'admin'],
    text: 'Tableau de bord',
    path: '/home',
    icon: 'mediumiconslayout',
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    text: 'Résidences',
    path: '/residences',
    icon: 'home',
  },
  {
    roles: ['super_admin', 'admin', 'residence_owner'],
    text: 'Réservations',
    path: '/reservations',
    icon: 'clock',
  },
  {
    roles: ['super_admin', 'admin'],
    text: 'Clients',
    path: '/clients',
    icon: 'user',
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    text: 'Pages',
    path: '/pages',
    icon: 'file',
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    text: 'Extraits',
    path: '/extraits',
    icon: 'alignleft',
  },
  {
    roles: ['super_admin', 'admin', 'webmaster'],
    text: 'Activités',
    path: '/activites',
    icon: 'pin',
  },
  {
    roles: ['super_admin', 'admin'],
    text: 'Membres',
    path: '/membres',
    icon: 'user',
  },
  {
    roles: ['super_admin', 'admin'],
    text: 'Sauvegarde',
    path: '/sauvegarde',
    icon: 'save',
  },
  {
    text: 'Statistiques',
    icon: 'splitcells',
    items: [
      {
        roles: ['super_admin', 'admin'],
        text: 'Sub Item 1',
        path: '/statistiques',
      },
    ],
  },
  {
    text: 'Comptabilite',
    icon: 'file',
    items: [
      {
        roles: ['super_admin'],
        text: 'Générer un rapport',
        path: '/generer_rapport',
      },
      {
        roles: ['super_admin'],
        text: 'Rapport des factures add.',
        path: '/rapport_factures',
      },
      {
        roles: ['super_admin'],
        text: 'Recherche de transactions',
        path: '/transactions_rechercher',
      },
      {
        roles: ['super_admin'],
        text: 'Recherche de factures',
        path: '/factures_rechercher',
      },
      {
        roles: ['super_admin'],
        text: 'Paiements reçu',
        path: '/paiement_recu',
      },
      {
        roles: ['super_admin'],
        text: 'Paiements en attente',
        path: '/paiements',
      },
      {
        roles: ['super_admin'],
        text: 'Rapport des transactions',
        path: '/rapport_transactions',
      },
      {
        roles: ['super_admin'],
        text: 'Réservations créditées',
        path: '/credits/reservations',
      },
      {
        roles: ['super_admin'],
        text: 'Compte bancaire',
        path: '/bank_account',
      },
      {
        roles: ['super_admin'],
        text: 'Balance de vérification',
        path: '/balance',
      },
      {
        roles: ['super_admin'],
        text: 'État de compte général',
        path: '/etat_compte_general',
      },
    ],
  },
  {
    text: 'Corbeille',
    icon: 'trash',
    items: [
      {
        roles: ['super_admin', 'admin'],
        text: 'Résidences',
        path: '/corbeille/residences',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Réservations',
        path: '/corbeille/reservations',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Membres',
        path: '/corbeille/membres',
      },
    ],
  },
  {
    text: 'Paramètres',
    icon: 'preferences',
    items: [
      {
        roles: ['super_admin', 'admin'],
        text: 'Types de facture additionnelle',
        path: '/types-factures',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Agences de ménage',
        path: '/agences',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Taxes',
        path: '/taxes',
      },
      {
        roles: ['super_admin', 'admin', 'webmaster'],
        text: 'Villes',
        path: '/villes',
      },
      {
        roles: ['super_admin', 'admin', 'webmaster'],
        text: 'Groupes de Ville',
        path: '/groupes-ville',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Déverrouillages',
        path: '/deverouillages',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Clé api',
        path: '/cles-api',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Contrat de location',
        path: '/parametres/contrat-location',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Contrat de service',
        path: '/parametres/contrat-service',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Courriel',
        path: '/parametres/courriel',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Jours fériés',
        path: '/jours-special',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Périodes',
        path: '/periodes',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Catégories',
        path: '/categories',
      },
      {
        roles: ['super_admin', 'admin'],
        text: 'Descriptifs des photos',
        path: '/description-photos',
      },
      {
        roles: ['super_admin', 'admin'],
        text: "Types d'unités",
        path: '/types-unites',
      },
      {
        roles: ['super_admin', 'admin', 'webmaster'],
        text: 'Options et équipements',
        path: '/equipements',
      },
      {
        roles: ['super_admin', 'admin', 'webmaster'],
        text: 'Promotions',
        path: '/promotions',
      },
      {
        roles: ['super_admin', 'admin', 'webmaster'],
        text: 'Catégories des activités',
        path: '/categ_activites',
      },
      {
        roles: ['super_admin', 'admin', 'webmaster'],
        text: "Images d'Entête",
        path: '/entetes',
      },
    ],
  },
];

const getPermissionItem = (item, role) => {
  const { items, ...rest } = item;
  const itemsList = items.map((elt) => {
    return elt.roles.includes(role) && elt;
  });
  const itemsPermissions = itemsList.filter((e) => Object.keys(e).length !== 0);
  return itemsPermissions.length > 0 && { items: itemsPermissions, ...rest };
};

const getNavigation = (navigationPermission, role) => {
  const navigationList = navigationPermission.map((elt) => {
    if (Object.prototype.hasOwnProperty.call(elt, 'roles')) {
      return elt.roles.includes(role) && elt;
    }
    return getPermissionItem(elt, role);
  });
  return navigationList.filter((e) => Object.keys(e).length !== 0);
};

const navigation = (role) => {
  return getNavigation(navigationWithPermission, role);
};

export { navigation };
