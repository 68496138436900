import React, { useState, useCallback, useEffect } from 'react';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function CompteGeneralForm({ handleSearchValues, formValues }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({});

  const startDateBoxOptions = {
    stylingMode: 'filled',
    labelMode: 'floating',
    invalidDateMessage: 'La date doit avoir le format suivant: MM/dd/yyyy',
    openOnFieldClick: true,
    showClearButton: true,
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { date, ...rest } = items;
      const values = {
        ...rest,
      };
      if (date) {
        values.date = moment(date).format('YYYY-MM-DD');
      }
      return handleSearchValues(values);
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  useEffect(() => {
    setItems(formValues);
  }, [formValues]);

  return (
    <div className="dx-card responsive-paddings">
      <div className="client_item_title search-res">
        <i className="fa fa-search" />
        {t('common.searching')}
      </div>
      <form onSubmit={onSubmit}>
        <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
          <SimpleItem dataField="date" editorType="dxDateBox" editorOptions={startDateBoxOptions}>
            <Label text="Date" visible={false} />
          </SimpleItem>
          <GroupItem cssClass="search-btn">
            <ButtonItem>
              <ButtonOptions width="100%" type="default" useSubmitBehavior>
                <span className="dx-button-text">
                  {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.search')}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
}

CompteGeneralForm.propTypes = {
  handleSearchValues: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};
