/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './editor-input.scss';
import { Menu } from 'devextreme-react';

function Editor({ dataSource, valueContent, valueChanged }) {
  let inputTimeout;
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const CKEDITOR = useRef();

  /* -------------------------------------------------------------------------- */
  /*                                   HELPRES                                  */
  /* -------------------------------------------------------------------------- */
  const pasteOnPostion = (e) => {
    if (e.itemData.name) {
      CKEDITOR.current.editor.model.change((writer) => {
        writer.insertText(
          `{{${e.itemData.name}}}`,
          CKEDITOR.current.editor.model.document.selection.getFirstPosition()
        );
        CKEDITOR.current.editor.focus();
      });
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <div className="containerMenu">
        <Menu
          dataSource={dataSource?.tags ?? []}
          displayExpr="text"
          onItemClick={(e) => {
            pasteOnPostion(e);
          }}
        />
      </div>
      <CKEditor
        ref={CKEDITOR}
        editor={ClassicEditor}
        config={{
          removePlugins: ['EasyImage'],
        }}
        data={valueContent}
        onChange={(event, editor) => {
          if (inputTimeout) {
            clearTimeout(inputTimeout);
          }
          setTimeout(() => {
            valueChanged(editor.getData());
          }, 300);
        }}
      />
    </div>
  );
}
const EditorInput = memo(Editor);
export default EditorInput;
Editor.propTypes = {
  valueChanged: PropTypes.func.isRequired,
  valueContent: PropTypes.string.isRequired,
  dataSource: PropTypes.object.isRequired,
};
