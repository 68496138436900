import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useTranslation } from 'react-i18next';
import { checkBoxOptions } from '../residence-form/input-options';

export default function SendFacturePopupForm({ hideModal }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({ proprio: true, agency: false });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return items;
    },
    [items]
  );

  return (
    <div className="content-block">
      <div className="dx-card responsive-paddings">
        <p>Sélectionner le ou les destinataires:</p>
        <form onSubmit={onSubmit}>
          <Form formData={items} disabled={loading}>
            <SimpleItem dataField="proprio" editorType="dxCheckBox" editorOptions={checkBoxOptions('Propriétaire(s)')}>
              <Label visible={false} />
            </SimpleItem>
            <SimpleItem dataField="agency" editorType="dxCheckBox" editorOptions={checkBoxOptions('Agence')}>
              <Label visible={false} />
            </SimpleItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="success" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Envoyer'}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={() => hideModal()}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

SendFacturePopupForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
};
