import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import './photos-description.scss';
import Hero from '../../../components/hero/hero';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { getAll, remove } from '../../../api';
import { Toast } from '../../../utils';

export default function PhotosDescription() {
  const history = useHistory();
  const [popupVisible, setPopupVisible] = useState(false);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [deleteID, setDeleteID] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
    setDeleteName(e.row.data.description);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteDescription = (id) => {
    remove('photo-descriptions', id).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== id));
      hideModal();
      Toast('La description a été bien supprimée');
    });
  };

  useEffect(() => {
    getAll('photo-descriptions').then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, []);

  return (
    <>
      <Hero title="Descriptifs des photos" btnText="Ajouter une description" url="/description-photo" />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} />
        <Column dataField="description" caption="Description" />
        <Column type="buttons" width={110}>
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/description-photo/${e.row.data.id}`)} />
          <Button icon="trash" hint="Supprimer" onClick={showModal} />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={"Suppression d'une description"}
        msg={`Êtes-vous sûr de vouloir supprimer cette description ${deleteName}?`}
        deleteActivity={() => deleteDescription(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
