import React, { useCallback, useEffect, useState } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { create, get, update } from '../../../api';
import { NumberEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { Toast } from '../../../utils';
import { dateBoxOptions } from '../residence-form/input-options';

export default function CreditForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { itemId, id } = useParams();
  const [loading, setLoading] = useState(false);
  const isAddMode = !itemId;
  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return isAddMode ? addCredit(items) : updateCredit(items);
    },
    [items]
  );

  const addCredit = (values) => {
    create(`credits/${id}`, values)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Toast(t('common.successAddM', { title: 'Le crédit' }));
          history.push(`/residences/credits/${id}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateCredit = (values) => {
    update(`credits/${id}`, itemId, values)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          Toast(t('common.successUpdateM', { title: 'Le crédit' }));
          history.push(`/residences/credits/${id}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isAddMode) {
      // get(`credits/${id}`, itemId).then((response) => {
      //   const { data } = response.data;
      //   setItems(data);
      // });
    }
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('credits.go_back_credit')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/residences/credits/${id}`)}
      />
      <h2 className="content-block">{isAddMode ? t('credits.credit_add') : t('credits.credit_edit')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Détails">
                  <SimpleItem dataField="date" editorType="dxDateBox" editorOptions={dateBoxOptions}>
                    <RequiredRule message="la date est requise" />
                    <Label text="Date" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="titre" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="le titre est requis" />
                    <Label text="Titre" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="montant" editorType="dxNumberBox" editorOptions={NumberEditorOptions}>
                    <RequiredRule message="le montant est requis" />
                    <Label text="Montant" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push(`/residences/credits/${id}`)}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
