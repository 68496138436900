import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/style.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'devextreme-react';
import { useHistory } from 'react-router-dom';
import DeletePopup from '../../delete-popup/delete-popup';
import { getAll, remove } from '../../../api';
import PaymentListing from './paymentListing/PaymentListing';
import { Toast } from '../../../utils';

export default function WidgetPayment({ reservationId }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteID, setDeleteID] = useState('');

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deletePayment = (idPayment) => {
    remove('payments', idPayment).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== idPayment));
      hideModal();
      Toast(t('common.successDeleteM', { title: 'Le paiement manuel' }));
    });
  };

  const onPress = () => {
    history.push({
      pathname: `/reservation/paiement/ajouter/${reservationId}`,
    });
  };

  useEffect(() => {
    getAll(`payments/${reservationId}`).then(({ data }) => setCustomDataSource(data?.data));
  }, []);

  return (
    <div className="dx-card responsive-paddings-widget widget">
      <div className="widget_item_title">
        <i className="fa fa-tags" />
        {t('reservation.payments')}
      </div>
      <div>
        <Button className="btn_payment" text={t('reservation.save_manuel_payment')} type="success" onClick={onPress} />
        <PaymentListing customDataSource={customDataSource} reservationId={reservationId} showModal={showModal} />
        <DeletePopup
          popupVisible={popupVisible}
          hideModal={hideModal}
          title={t('payment.payment_delete_title')}
          msg={t('payment.payment_delete_msg')}
          deleteActivity={() => deletePayment(deleteID)}
          width={600}
        />
      </div>
    </div>
  );
}

WidgetPayment.propTypes = {
  reservationId: PropTypes.number.isRequired,
};
