import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Form, { SimpleItem, Label, RequiredRule, GroupItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { LoadIndicator } from 'devextreme-react';
import { ImageEditorOptions, selectEditorOptions } from '../../utils/editorOptions';
import './styles/index.scss';
import { Toast } from '../../utils';
import { create, getAll } from '../../api';

export default function MediaAddFormPopup({ hideAddModal, t, restList }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({ list: {} });
  const [descriptionsDataSource, setDescriptionsDataSource] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const { id } = useParams();
  const formRef = useRef('formMedia');
  const addPicture = (values) => {
    const formData = new FormData();
    Object.values(values.list).map((obj, index) => {
      formData.append(`photos[${index}][residence_id]`, id);
      formData.append(`photos[${index}][photo_description_id]`, obj.descriptifs);
      formData.append(`photos[${index}][photo]`, obj.photo[0]);
      return true;
    });
    create(`residence_photos/${id}`, formData, { 'Content-Type': 'multipart/form-data' })
      .then((res) => {
        setLoading(false);
        hideAddModal('update');
        Toast(t('common.successAdd', { title: 'La photo' }));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const addButtonOptions = {
    icon: 'add',
    text: 'Ajouter une Photo',
    onClick: () => {
      addPeriodeData();
      setMediaList([...mediaList, '']);
    },
  };

  const addPeriodeData = () => {
    const { list } = items;
    list[Object.keys(list).length] = { photo: undefined, descriptifs: undefined, residence_id: id };
    setItems({ ...items, list });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      return addPicture(items);
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAll('photo-descriptions').then((response) => {
      setDescriptionsDataSource(response.data.data);
    });
  }, []);

  useEffect(() => {
    setMediaList([]);
    setItems({ list: {} });
  }, [restList]);

  const deletePeriode = (index) => {
    const periodeData = deletePeriodeData(index);
    items.list = periodeData;
    setItems(items);
    setMediaList([...mediaList.filter((_, i) => i !== index)]);
  };

  const deletePeriodeData = (index) => {
    const data = items.list && Object.values(items.list).filter((_, i) => i !== index);
    return { ...data };
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="content-block">
      <div className="responsive-paddings">
        <form onSubmit={onSubmit}>
          <Form ref={formRef} formData={items} disabled={false} onFieldDataChanged={formFieldDataChanged}>
            <GroupItem cssClass="group-item" colCount={1}>
              {mediaList?.map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <GroupItem itemType="group" key={index} colCount={3}>
                  <SimpleItem
                    dataField={`list[${index}].photo`}
                    editorType="dxFileUploader"
                    editorOptions={ImageEditorOptions}
                  >
                    <RequiredRule message={t('errors.required_photo')} />
                    <Label text={t('common.photo')} visible={false} />
                  </SimpleItem>

                  <SimpleItem
                    dataField={`list[${index}].descriptifs`}
                    editorType="dxSelectBox"
                    editorOptions={selectEditorOptions(descriptionsDataSource, 'description')}
                  >
                    <RequiredRule message={t('errors.required_descriptif')} />
                    <Label text={t('residance.descriptifs')} visible={false} />
                  </SimpleItem>
                  <ButtonItem>
                    <ButtonOptions
                      icon="trash"
                      type="normal"
                      hint="Supprimer"
                      onClick={() => {
                        deletePeriode(index);
                      }}
                    />
                  </ButtonItem>
                </GroupItem>
              ))}
              <SimpleItem
                itemType="button"
                cssClass="add-button"
                horizontalAlignment="left"
                buttonOptions={addButtonOptions}
              />
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={hideAddModal}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

MediaAddFormPopup.propTypes = {
  t: PropTypes.func.isRequired,
  hideAddModal: PropTypes.func.isRequired,
  restList: PropTypes.bool.isRequired,
};
