import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  Button,
  LoadPanel,
  Sorting,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { priceColumn_customizeText } from '../../../../utils';

export default function FacturetionsListing({ customDataSource }) {
  const history = useHistory();

  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
    >
      <Sorting mode="none" />
      <LoadPanel enabled />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
      <Editing mode="row" useIcons />

      <Column dataField="origin" caption="ORIGINE" />
      <Column dataField="detail" caption="DÉTAILS" />
      <Column dataField="fee" caption="FRAIS" alignment="left" customizeText={priceColumn_customizeText} />
      <Column
        dataField="service_fee"
        caption="FRAIS SERVICE"
        alignment="left"
        customizeText={priceColumn_customizeText}
      />
      <Column dataField="tx_hc" caption="TX D'HÉB." alignment="left" customizeText={priceColumn_customizeText} />
      <Column dataField="total" caption="TOTAL" alignment="left" customizeText={priceColumn_customizeText} />
      <Column type="buttons">
        <Button
          hint="Consulter"
          icon="fa fa-eye"
          onClick={(e) => history.push(`/reservation/${e.row.data.reservationId}`)}
        />
      </Column>
      <Summary>
        <TotalItem column="detail" displayFormat="Sous-total:" />
        <TotalItem column="fee" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        <TotalItem column="service_fee" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        <TotalItem column="tx_hc" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        <TotalItem column="total" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        <TotalItem column="detail" displayFormat="_" />
        <TotalItem column="detail" displayFormat="_" />
        <TotalItem column="detail" displayFormat="_" />
        <TotalItem column="fee" displayFormat="_" />
        <TotalItem column="fee" displayFormat="_" />
        <TotalItem column="fee" displayFormat="_" />
        <TotalItem column="service_fee" displayFormat="_" />
        <TotalItem column="service_fee" displayFormat="_" />
        <TotalItem column="service_fee" displayFormat="_" />
        <TotalItem column="tx_hc" displayFormat="TPS:" />
        <TotalItem column="tx_hc" displayFormat="TVQ:" />
        <TotalItem column="tx_hc" displayFormat="Total:" />
        <TotalItem column="total" displayFormat="30$" />
        <TotalItem column="total" displayFormat="61$" />
        <TotalItem column="total" displayFormat="800$" />
      </Summary>
    </DataGrid>
  );
}

const allowedPageSizes = [5, 10, 20];

FacturetionsListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
