import React from 'react';
import PropTypes from 'prop-types';
import './client-info.scss';

export default function ClientInfo({ clientDetails }) {
  const infoData = [
    { key: 'email', name: 'Courriel' },
    { key: 'second_email', name: 'Courriel(autre)' },
    { key: 'phone', name: 'Téléphone' },
    { key: 'second_phone', name: 'Téléphone(autre)' },
    { key: 'company', name: 'Compagnie' },
    { key: 'address', name: 'Adresse' },
    { key: 'city', name: 'Ville' },
    { key: 'postal_code', name: 'Code postal' },
    { key: 'province', name: 'Province' },
    { key: 'country', name: 'Pays' },
    { key: 'actif', name: 'Actif' },
  ];

  function renderComponent(item) {
    return (
      <div key={item.key} className="element">
        <span className="label">{item.name}</span>
        {['phone', 'second_phone'].includes(item.key) ? (
          <span className="value">
            {`${clientDetails[item.key].number}  ${
              clientDetails[item.key].extension ? `#${clientDetails[item.key].extension}` : ''
            }`}
          </span>
        ) : (
          <span className="value">
            {item.key === 'second_email' && typeof clientDetails[item.key] === 'object'
              ? clientDetails[item.key].email
              : clientDetails[item.key]}
          </span>
        )}
      </div>
    );
  }

  function InfoComponent(item) {
    return clientDetails[item.key] && renderComponent(item);
  }

  return <>{infoData.map(InfoComponent)}</>;
}

ClientInfo.propTypes = {
  clientDetails: PropTypes.object.isRequired,
};
