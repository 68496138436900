import { subDays } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAll } from '../../../api';
import TransactionsListing from './TransactionsListing/TransactionsListing';
import TransactionsSearchForm from './TransactionsSearchForm/TransactionsSearchForm';

const transactions = [
  {
    id: 1,
    residenceId: 3,
    paye: false,
    date: '7 mars 2019',
    residence: 'AAA-39',
    titre: 'Remb.: Balance',
    montant: '228,21',
  },
  {
    id: 2,
    residenceId: 1,
    paye: false,
    date: '7 mars 2019',
    residence: 'AAA-39',
    titre: 'Remb.: Balance',
    montant: '228,21',
  },
  {
    id: 3,
    residenceId: 7,
    paye: true,
    date: '7 mars 2019',
    residence: 'AAA-39',
    titre: 'Remb.: Balance',
    montant: '228,21',
  },
  {
    id: 4,
    residenceId: 3,
    paye: true,
    date: '7 mars 2019',
    residence: 'AAA-39',
    titre: 'Remb.: Balance',
    montant: '228,21',
  },
  {
    id: 5,
    residenceId: 1,
    paye: false,
    date: '7 mars 2019',
    residence: 'AAA-39',
    titre: 'Remb.: Balance',
    montant: '228,21',
  },
];

export default function RapportTransactions() {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [searchObject, setSearchObject] = useState({});

  useEffect(() => {
    const formValues = {
      debut: queryParams.get('debut') ?? subDays(new Date(), 1),
      fin: queryParams.get('fin') ?? new Date(),
    };
    const url = `${formValues.debut || formValues.fin ? '?' : ''}${
      formValues.debut ? `debut=${formValues.debut}` : ''
    }${formValues.debut && formValues.fin ? '&' : ''}${formValues.fin ? `fin=${formValues.fin}` : ''}`;

    setCustomDataSource(transactions);
    setSearchObject(formValues);
    // getAll(`transactions/${url}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    //   setSearchObject(formValues);
    // });
  }, []);

  const handleSearchValues = (values) => {
    const url = `${values.debut || values.fin ? '?' : ''}${values.debut ? `debut=${values.debut}` : ''}${
      values.debut && values.fin ? '&' : ''
    }${values.fin ? `fin=${values.fin}` : ''}`;

    history.push(`/rapport_transactions/${url}`);

    // getAll(`transactions/${url}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    //   setSearchObject(values);
    // });
  };

  return (
    <>
      <h2 className="content-block">{`Rapport des transactions: Du ${moment(searchObject?.debut).format(
        'll'
      )} au ${moment(searchObject?.fin).format('ll')}`}</h2>
      <div className="factures">
        <div className="factures_listing">
          <TransactionsListing customDataSource={customDataSource} />
        </div>
        <div className="factures_search">
          <TransactionsSearchForm handleSearchValues={handleSearchValues} formValues={searchObject} />
        </div>
      </div>
    </>
  );
}
