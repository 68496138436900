import React, { useState, useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import './fusion-clients.scss';
import { Tab } from 'devextreme-react/diagram';
import Button from 'devextreme-react/button';
import { textEditorOptions } from '../../../utils/editorOptions';

export default function FusionClients() {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const formData = useRef({});

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const values = formData.current;
      setLoading(true);
      setLoading(false);
    },
    [history, id]
  );

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Clients"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/clients')}
      />
      <h2 className="content-block">Fusion de clients avec {id}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={formData.current} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Fusion">
                  <SimpleItem
                    dataField="value"
                    editorType="dxTextBox"
                    editorOptions={textEditorOptions}
                    helpText={`Séparer les ids par une virgule. ex: 34,12,23.  L'exemple précédent va fusionner les clients 34, 12 et 23 avec ${id}`}
                  >
                    <RequiredRule message="Les ID des clients sont requises" />
                    <Label text="ID des clients" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Fusion'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() => {
                      history.push(`/clients/${id}`);
                    }}
                  >
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
