import { SelectBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import RapportListing from './RapportListing/RapportListing';
import RapportTotalListing from './RapportTotalListing/RapportTotalListing';
import './RapportPayment.scss';

const paymentTotal = [
  {
    visa: '136 764,90 $',
    mastercard: '167 538,90 $',
    visa_online: '978 753,58 $',
    mastercard_online: '957 818,90 $',
    certificat: '0,00 $',
    frais_cc: '602,44 $',
    other: '231 347,60 $',
    total: '2 472 826,32 $',
  },
];
const paymentRapport = [
  {
    id: 1,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt En ligne (MC)',
    montant: '228,21 $',
    frais_cc: '0,00 $',
    total: '228,21 $',
  },
  {
    id: 2,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt En ligne (MC)',
    montant: '228,21 $',
    frais_cc: '0,00 $',
    total: '228,21 $',
  },
  {
    id: 3,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt En ligne (MC)',
    montant: '228,21 $',
    frais_cc: '0,00 $',
    total: '228,21 $',
  },
  {
    id: 4,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt En ligne (MC)',
    montant: '228,21 $',
    frais_cc: '0,00 $',
    total: '228,21 $',
  },
  {
    id: 5,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt En ligne (MC)',
    montant: '228,21 $',
    frais_cc: '0,00 $',
    total: '228,21 $',
  },
];

const paymentFilter = [
  {
    id: 'agency',
    name: "Carte de crédit à l'agence",
  },
  {
    id: 'online',
    name: 'Carte de crédit en ligne',
  },
  {
    id: 'others',
    name: 'Autres',
  },
];

export default function RapportPayment() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [dates, setDates] = useState({});
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    const formValues = {
      debut: queryParams.get('debut'),
      fin: queryParams.get('fin'),
    };
    const url = `?debut=${formValues.debut}&fin=${formValues.fin}`;

    setCustomDataSource(paymentRapport);
    setDates(formValues);
    // getAll(`transactions/${url}`).then((response) => {
    //   setCustomDataSource(response.data.data);
    //   setSearchObject(formValues);
    // });
  }, []);

  return (
    <div className="content-block">
      <RapportTotalListing customDataSource={paymentTotal} />
      <div className="dx-field filter-content">
        <div className="dx-field-label cssClassLabel">
          <h6 className="content-block transaction-title">{`${t('payment.payment_rapport')}: Du ${moment(
            dates?.debut
          ).format('ll')} au ${moment(dates?.fin).format('ll')}`}</h6>
        </div>
        <div className="dx-field-value content-block">
          {t('payment.filter')}
          <SelectBox
            items={paymentFilter}
            valueExpr="id"
            value={filter}
            onValueChanged={(e) => setFilter(e.value)}
            displayExpr="name"
            label={t('payment.filter_label')}
            showClearButton
          />
        </div>
      </div>
      <RapportListing customDataSource={customDataSource} />
    </div>
  );
}
