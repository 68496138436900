/**
 * check for non empty value
 * @param value
 * @returns {boolean}
 */
function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

/**
 * Prepare request URL parameters
 * @param loadOptions
 * @returns {*}
 */
export function getParams(loadOptions) {
  const params = {};
  [
    'skip',
    'take',
    'requireTotalCount',
    'requireGroupCount',
    'sort',
    'filter',
    'totalSummary',
    'group',
    'groupSummary',
  ].forEach((i) => {
    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
      params[i] = JSON.stringify(loadOptions[i]);
    }
  });
  return params;
}
