/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, LoadIndicator } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  SimpleItem,
  TabbedItem,
  TabPanelOptions,
  GroupItem,
  RequiredRule,
} from 'devextreme-react/form';
import { Tab } from 'devextreme-react/diagram';
import { Label } from 'devextreme-react/bar-gauge';
import { useHistory, useParams } from 'react-router-dom';

import { Toast } from '../../../utils';
import {
  NumberDecimalEditorOptions,
  NumberIntEditorOptions,
  selectEditorOptions,
  textEditorOptions,
} from '../../../utils/editorOptions';
import { checkBoxOptions } from '../residence-form/input-options';
import { create, getAll, update } from '../../../api';

/* -------------------------------------------------------------------------- */
/*                                RENDER HELPER                               */
/* -------------------------------------------------------------------------- */

export default function FormMultiplePrices({
  t,
  isTwin,
  hideAddModal,
  initialVal,
  handleUpdate,
  handleAdd,
  dataPrice,
}) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const initData = {
    max_nbr_nights: 7,
    min_nbr_nights: 2,
    price_basic_night: null,
    max_nbr_animals: null,
    basic_nbr_people: null,
    price_fds_night: null,
    price_week_more: null,
    price_month_more: null,
    price_night_additional_person: null,
    price_holiday: null,
    price_animal: null,
    title_fr: '',
    title_en: '',
    not_show_tariff: false,
    not_show_dates_tariff: false,
  };
  const form = useRef('form');
  const [items, setItems] = useState(initData);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dataTwins, setDataTwins] = useState([]);
  const history = useHistory();
  const isAddMode = !dataPrice?.id;
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */

  const resetForm = () => {
    form.current.instance.resetValues();
    setItems(initData);
  };
  const navigateBack = () => {
    // history.goBack();
    hideAddModal();
    resetForm();
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData();
      const dataToSend = items;
      dataToSend.not_show_tariff = dataToSend.not_show_tariff ? 1 : 0;
      dataToSend.not_show_dates_tariff = dataToSend.not_show_dates_tariff ? 1 : 0;
      dataToSend.type = 'basic';
      Object.keys(dataToSend).map((key) => formData.append(key, items[key]));

      if (isAddMode) {
        create(`tariffs/${id}`, formData).then((res) => {
          setLoading(false);
          if (res.status === 201) {
            hideAddModal();
            handleAdd(res.data.data);
            form.current.instance.resetValues();
            Toast(t('common.successAddM', { title: 'Le tarif' }));
            resetForm();
          }
        });
      } else {
        const { not_show_tariff, not_show_dates_taiff, twin_id, periods, ranges, ...rest } = dataToSend;
        let values = {
          ...rest,
          not_show_tariff: not_show_tariff ? 1 : 0,
          not_show_dates_tariff: not_show_dates_taiff ? 1 : 0,
          type: 'basic',
        };
        if (twin_id) {
          values = { ...values, twin_id };
        }
        update(`tariffs`, `${id}/${items.id}`, values).then((res) => {
          setLoading(false);
          if (res.status === 200) {
            hideAddModal();
            handleUpdate(res.data.data);
            form.current.instance.resetValues();
            Toast(t('common.successUpdateM', { title: 'Le tarif' }));
          }
        });
      }
    },
    [items]
  );
  const formFieldDataChanged = (e) => {
    const dataForm = e.component.option('formData');
    setItems({ ...items, ...dataForm });
  };
  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (JSON.parse(isTwin)) {
      getAll(`twins/${id}`).then((res) => {
        setDataTwins(res.data.data);
      });
    }
  }, [isAddMode, JSON.parse(isTwin)]);

  useEffect(() => {
    if (dataPrice?.id > 0) {
      const { twin, ...rest } = dataPrice;
      const values = { twin_id: twin?.id, ...rest };
      setItems(values);
    } else {
      setItems(initData);
    }
  }, [dataPrice]);

  useEffect(() => {
    if (initialVal) {
      resetForm();
    }
  }, [initialVal]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="dx-card responsive-paddings-widget fullWidth">
      <div>
        <form onSubmit={onSubmit}>
          <Form ref={form} formData={items} showValidationSummary onFieldDataChanged={formFieldDataChanged}>
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              <Tab title={t('price.prices')}>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem
                    dataField="twin_id"
                    editorType="dxSelectBox"
                    editorOptions={selectEditorOptions(dataTwins)}
                  >
                    <Label text={t('jumele.jumeles')} visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem
                    dataField="basic_nbr_people"
                    editorType="dxNumberBox"
                    editorOptions={NumberIntEditorOptions()}
                  >
                    <RequiredRule message={t('errors.required_numPersons')} />
                    <Label text={t('price.numPersons')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="max_nbr_animals"
                    editorType="dxNumberBox"
                    editorOptions={NumberIntEditorOptions()}
                  >
                    <RequiredRule message={t('errors.required_numAnimals')} />
                    <Label text={t('price.numAnimals')} visible={false} />
                  </SimpleItem>
                </GroupItem>
              </Tab>
              {/** pricesAllTimes */}
              <Tab title={t('price.pricesAllTimes')}>
                <GroupItem cssClass="group-item" colCount={2}>
                  <SimpleItem dataField="title_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message={t('errors.required_titleFr')} />
                    <Label text={t('price.TitleFr')} visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="title_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message={t('errors.required_titleEn')} />
                    <Label text={t('price.TitleEn')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="min_nbr_nights"
                    editorType="dxNumberBox"
                    editorOptions={NumberIntEditorOptions({ max: items?.max_nbr_nights })}
                  >
                    <RequiredRule message={t('errors.required_minReservation')} />
                    <Label text={t('price.minReservation')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="max_nbr_nights"
                    editorType="dxNumberBox"
                    editorOptions={NumberIntEditorOptions({ min: items?.max_nbr_nights })}
                  >
                    <RequiredRule message={t('errors.required_maxReservation')} />
                    <Label text={t('price.maxReservation')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_basic_night"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <RequiredRule message={t('errors.required_priceByNigth')} />
                    <Label text={t('price.priceByNigthLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_fds_night"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <RequiredRule message={t('errors.required_fds')} />
                    <Label text={t('price.priceFdsByNigthLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_week_more"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.weekPriceLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_month_more"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.mounthPriceLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_night_additional_person"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.priceExtraPersonLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_holiday"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.priceHolidayLabel')} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="price_animal"
                    editorType="dxNumberBox"
                    editorOptions={NumberDecimalEditorOptions}
                  >
                    <Label text={t('price.priceAnimalLabel')} visible={false} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem cssClass="group-item" colCount={1}>
                  <SimpleItem
                    dataField="not_show_tariff"
                    editorType="dxCheckBox"
                    editorOptions={checkBoxOptions(t('price.dontShowPrice'))}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    dataField="not_show_dates_tariff"
                    editorType="dxCheckBox"
                    editorOptions={checkBoxOptions(t('price.dontShowDates'))}
                  >
                    <Label visible={false} />
                  </SimpleItem>
                </GroupItem>
              </Tab>
            </TabbedItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width="100%" type="default" useSubmitBehavior>
                  <span className="dx-button-text">
                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.saveBtn')}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem>
                <ButtonOptions width="100%" type="normal" onClick={navigateBack}>
                  <span className="dx-button-text">{t('common.cancel')}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}

FormMultiplePrices.propTypes = {
  t: PropTypes.func.isRequired,
  isTwin: PropTypes.bool.isRequired,
  hideAddModal: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  dataPrice: PropTypes.object.isRequired,
  initialVal: PropTypes.bool,
};
FormMultiplePrices.defaultProps = {
  initialVal: false,
};
