import Button from 'devextreme-react/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function GenerateFacture() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="content-block">
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('rapports.Go_back_home')}
        type="default"
        stylingMode="text"
        onClick={() => history.push('/')}
      />
      <h2>{t('rapports.generate_title')}</h2>
      <h6>{t('rapports.generate_completed')}</h6>
      <Button
        text={t('rapports.download_rapport')}
        type="default"
        stylingMode="contained"
        onClick={() => console.log('rapport')}
      />
    </div>
  );
}
