/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import Form, { ButtonItem, ButtonOptions, GroupItem, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './residence-form.scss';
import { LoadPanel } from 'devextreme-react';
import { get, updateWithMedia } from '../../../api';
import { bedList, Toast } from '../../../utils';
import AgencyFormPopup from '../agency-form-popup/agency-form-popup';
import MemberFormPopup from '../member-form-popup/member-form-popup';
import useGetMergTags from '../../../hooks/useGetTags';

const AddPopup = React.lazy(() => import('../../add-popup/add-popup'));
const NoteTab = React.lazy(() => import('./tabs/note'));
const GeneralInformation = React.lazy(() => import('./tabs/generalInformation'));
const ServiceContract = React.lazy(() => import('./tabs/serviceContract'));
const CharacteristicTab = React.lazy(() => import('./tabs/characteristic'));
const FrenchTab = React.lazy(() => import('./tabs/french'));
const EnglishTab = React.lazy(() => import('./tabs/english'));
const LocationContract = React.lazy(() => import('./tabs/locationContract'));
const EnVedette = React.lazy(() => import('./tabs/enVedette'));

export default function ResidenceForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: chaletId } = useParams();
  const [loadPanelVisible, setLoadPanelVisible] = useState(true);

  const [loading, setLoading] = useState(false);
  /** ********** Popup Form create ********** */
  const [popupVisibleAgence, setPopupVisibleAgence] = useState(false);
  const [popupVisibleMember, setPopupVisibleMember] = useState(false);

  const [items, setItems] = useState({});

  /** ** select dropdown data *** */

  const [agencydata, setAgencydata] = useState([]);
  const dataTags = useGetMergTags();
  const [selectsData, setSelectsData] = useState({
    unitiesdata: [],
    conditiondata: [],
    contartLocationdata: [],
    typedevrrdata: [],
    equipmentdata: [],
    categorydata: [],
    villedata: [],
    contartServicedata: [],
    photo: '',
    contrat: '',
    Membersdata: [],
    IsPreauthorization: [],
    unit_twin: false,
  });

  const refFormResidence = useRef('ResidenceupdateForm');

  /** ***** texts editor states ****** */
  const [notes, setNotes] = useState([
    {
      type: 'reservations_notes',
      value: '',
    },
    {
      type: 'fees_notes',
      value: '',
    },
    {
      type: 'internal_notes',
      value: '',
    },
  ]);
  const [introduction, setIntroduction] = useState([
    {
      type: 'introduction',
      language: 'fr',
      value: '',
    },
    {
      type: 'introduction',
      language: 'en',
      value: '',
    },
  ]);
  const [texts, setTexts] = useState([
    {
      type: 'unlocking_details',
      language: 'fr',
      value: '',
    },
    {
      type: 'unlocking_details',
      language: 'en',
      value: '',
    },
    {
      type: 'short_description',
      language: 'fr',
      value: '',
    },
    {
      type: 'short_description',
      language: 'en',
      value: '',
    },
    {
      type: 'descriptive_title',
      language: 'fr',
      value: '',
    },
    {
      type: 'descriptive_title',
      language: 'en',
      value: '',
    },
    {
      type: 'descriptions',
      language: 'fr',
      value: '',
    },
    {
      type: 'descriptions',
      language: 'en',
      value: '',
    },
    {
      type: 'equipments',
      language: 'fr',
      value: '',
    },
    {
      type: 'equipments',
      language: 'en',
      value: '',
    },
    {
      type: 'localization',
      language: 'fr',
      value: '',
    },
    {
      type: 'localization',
      language: 'en',
      value: '',
    },
    {
      type: 'proximity',
      language: 'fr',
      value: '',
    },
    {
      type: 'proximity',
      language: 'en',
      value: '',
    },
    {
      type: 'arrival_departure',
      language: 'fr',
      value: '',
    },
    {
      type: 'arrival_departure',
      language: 'en',
      value: '',
    },
    {
      type: 'rental_modality',
      language: 'fr',
      value: '',
    },
    {
      type: 'rental_modality',
      language: 'en',
      value: '',
    },
    {
      type: 'special_clauses',
      language: 'fr',
      value: '',
    },
    {
      type: 'special_clauses',
      language: 'en',
      value: '',
    },
    {
      type: 'other_name',
      language: 'fr',
      value: '',
    },
    {
      type: 'other_name',
      language: 'en',
      value: '',
    },
  ]);
  /** **** handle change notes value **** */
  const setChangeNotes = (data, index) => {
    notes[index].value = data;
    setNotes(notes);
  };

  /** **** handle change introduction value **** */
  const setChangeIntroduction = (data, index) => {
    introduction[index].value = data;
    setIntroduction(introduction);
  };

  /** **** handle change texts value **** */
  const setChangeTexts = (data, index) => {
    texts[index].value = data;
    setTexts(texts);
  };

  /** ********* format data before submit ******** */
  const formatDataBeforeSubmit = () => {
    const {
      descriptive_title_fr,
      descriptive_title_en,
      accommodation_tax,
      other_name_fr,
      other_name_en,
      short_description_fr,
      short_description_en,
      owner_cleaning,
      preauthorization,
      taxes,
      multi_unit,
      twin,
      main_photo,
      start_date_news,
      no_allowing_reservation_date,
      archive_date,
      signed_contract,
      conditions,
      peculiarities,
      service_level,
      preauthorization_amount,
      agencies,
      unit_twin,
      allow_fill_holes,
      day_delay_between_reservations,
      units,
      featured_start_date,
      featured_end_date,
      featured_displayed_regardless_of_date,
      ...rest
    } = items;
    texts[4].value = descriptive_title_fr;
    texts[5].value = descriptive_title_en;
    texts[20].value = other_name_fr;
    texts[21].value = other_name_en;
    texts[3].value = short_description_en;
    texts[2].value = short_description_fr;
    const values = {
      ...rest,
      allow_fill_holes: allow_fill_holes ? 1 : 0,
      day_delay_between_reservations: day_delay_between_reservations ? 1 : 0,
      start_date_news: start_date_news && moment(start_date_news).format('YYYY-MM-DD'),
      featured_start_date: featured_start_date && moment(featured_start_date).format('YYYY-MM-DD'),
      featured_end_date: featured_end_date && moment(featured_end_date).format('YYYY-MM-DD'),
      no_allowing_reservation_date:
        no_allowing_reservation_date && moment(no_allowing_reservation_date).format('YYYY-MM-DD'),
      archive_date: archive_date && moment(archive_date).format('YYYY-MM-DD'),
      notes: JSON.stringify(notes),
      introduction: JSON.stringify(introduction),
      texts: JSON.stringify(texts),
      conditions: JSON.stringify(conditions),
      peculiarities: JSON.stringify(peculiarities),
      agencies: JSON.stringify(agencies),
      accommodation_tax: +accommodation_tax,
      owner_cleaning: +owner_cleaning,
      preauthorization_amount: preauthorization ? preauthorization_amount : null,
      preauthorization: +preauthorization,
      multi_unit: unit_twin === 'multi_unit' ? 1 : 0,
      twin: unit_twin === 'twin' ? 1 : 0,
      featured_displayed_regardless_of_date: featured_displayed_regardless_of_date ? 1 : 0,
      taxes: +taxes,
      service_level: +service_level,
      units: JSON.stringify(units),
    };
    if (main_photo?.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      values.main_photo = main_photo[0];
    }
    if (signed_contract?.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      values.signed_contract = signed_contract[0];
    }
    const formData = new FormData();
    Object.keys(values).map((key) => values[key] != null && formData.append(key, values[key]));
    formData.append('_method', 'PUT');
    return formData;
  };

  /** ******* Submit data ********* */
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const updateData = formatDataBeforeSubmit();
      updateWithMedia('residences', chaletId, updateData, { 'Content-Type': 'multipart/form-data' })
        .then((res) => {
          setLoading(false);
          res.data && Toast(`La résidence a été bien modifiée`);
          res.data && history.push(`/residences/details/${chaletId}`);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [items, agencydata, notes, introduction, texts]
  );

  /** Form Field Data changed */
  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setSelectsData((state) => {
      return { ...state, IsPreauthorization: data.preauthorization };
    });
  };

  /** ******** useEffect ********* */

  useEffect(() => {
    get('residences', chaletId).then((response) => {
      const { data } = response.data;

      const {
        introduction_fr,
        introduction_en,
        unlocking_details_fr,
        unlocking_details_en,
        descriptions_fr,
        descriptions_en,
        equipments_fr,
        equipments_en,
        localization_fr,
        localization_en,
        proximity_fr,
        proximity_en,
        arrival_departure_fr,
        arrival_departure_en,
        rental_modality_fr,
        rental_modality_en,
        special_clauses_fr,
        special_clauses_en,
        category,
        city,
        reservations_notes,
        fees_notes,
        internal_notes,
        accommodation_tax,
        preauthorization,
        multi_unit,
        twin,
        main_photo,
        taxes,
        signed_contract,
        member,
        service_level,
        cities_list,
        categories_list,
        equipments_list,
        unlockings_list,
        service_contracts_list,
        rental_contracts_list,
        units_list,
        agencies_list,
        conditions_list,
        units,
        ...rest
      } = data;
      const dataList = {
        ...rest,
        user_id: member.id,
        category_id: category?.id,
        city_id: city?.id,
        accommodation_tax: accommodation_tax !== 0,
        preauthorization: preauthorization !== 0,
        taxes: taxes !== 0,
        service_level: service_level !== 0,
        unit_twin: multi_unit ? 'multi_unit' : twin ? 'twin' : null,
        units: units.map((e) => {
          return e.id;
        }),
      };
      setSelectsData({
        unitiesdata: units_list,
        conditiondata: conditions_list,
        contartLocationdata: rental_contracts_list,
        typedevrrdata: unlockings_list,
        equipmentdata: equipments_list,
        categorydata: categories_list,
        villedata: cities_list,
        contartServicedata: service_contracts_list,
        photo: main_photo,
        contrat: signed_contract,
        Membersdata: [member],
        IsPreauthorization: dataList.preauthorization,
        unit_twin: multi_unit ? true : !!twin,
      });

      setAgencydata(agencies_list);
      setItems(dataList);
      refFormResidence.current.instance.updateData('user_id', member.id);
      setIntroduction([
        {
          type: 'introduction',
          language: 'fr',
          value: introduction_fr ?? '',
        },
        {
          type: 'introduction',
          language: 'en',
          value: introduction_en ?? '',
        },
      ]);
      setNotes([
        {
          type: 'reservations_notes',
          value: reservations_notes ?? '',
        },
        {
          type: 'fees_notes',
          value: fees_notes ?? '',
        },
        {
          type: 'internal_notes',
          value: internal_notes ?? '',
        },
      ]);
      setTexts([
        {
          type: 'unlocking_details',
          language: 'fr',
          value: unlocking_details_fr ?? '',
        },
        {
          type: 'unlocking_details',
          language: 'en',
          value: unlocking_details_en ?? '',
        },
        {
          type: 'short_description',
          language: 'fr',
          value: '',
        },
        {
          type: 'short_description',
          language: 'en',
          value: '',
        },
        {
          type: 'descriptive_title',
          language: 'fr',
          value: '',
        },
        {
          type: 'descriptive_title',
          language: 'en',
          value: '',
        },
        {
          type: 'descriptions',
          language: 'fr',
          value: descriptions_fr ?? '',
        },
        {
          type: 'descriptions',
          language: 'en',
          value: descriptions_en ?? '',
        },
        {
          type: 'equipments',
          language: 'fr',
          value: equipments_fr ?? '',
        },
        {
          type: 'equipments',
          language: 'en',
          value: equipments_en ?? '',
        },
        {
          type: 'localization',
          language: 'fr',
          value: localization_fr ?? '',
        },
        {
          type: 'localization',
          language: 'en',
          value: localization_en ?? '',
        },
        {
          type: 'proximity',
          language: 'fr',
          value: proximity_fr ?? '',
        },
        {
          type: 'proximity',
          language: 'en',
          value: proximity_en ?? '',
        },
        {
          type: 'arrival_departure',
          language: 'fr',
          value: arrival_departure_fr ?? '',
        },
        {
          type: 'arrival_departure',
          language: 'en',
          value: arrival_departure_en ?? '',
        },
        {
          type: 'rental_modality',
          language: 'fr',
          value: rental_modality_fr ?? '',
        },
        {
          type: 'rental_modality',
          language: 'en',
          value: rental_modality_en ?? '',
        },
        {
          type: 'special_clauses',
          language: 'fr',
          value: special_clauses_fr ?? '',
        },
        {
          type: 'special_clauses',
          language: 'en',
          value: special_clauses_en ?? '',
        },
        {
          type: 'other_name',
          language: 'fr',
          value: '',
        },
        {
          type: 'other_name',
          language: 'en',
          value: '',
        },
      ]);
      setLoadPanelVisible(false);
    });
  }, []);

  return (
    <Suspense
      fallback={
        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible showIndicator shading showPane hideOnOutsideClick={false} />
      }
    >
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('residance.backToResidance')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/residences/details/${chaletId}`)}
      />
      <h2 className="content-block">
        {t('residance.edit_residence')} {items.name} {items.official_name && `(${items.official_name})`}
      </h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form
              formData={items}
              ref={refFormResidence}
              labelLocation="left"
              onFieldDataChanged={formFieldDataChanged}
              disabled={loading}
              showValidationSummary
              className="residence_form"
            >
              <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                onHiding={() => setLoadPanelVisible(false)}
                visible={loadPanelVisible}
                showIndicator
                shading
                showPane
                hideOnOutsideClick={false}
              />
              <TabbedItem>
                <TabPanelOptions deferRendering />
                <Tab title="Informations de base">
                  <GeneralInformation
                    photo={selectsData.photo}
                    categorydata={selectsData.categorydata}
                    villedata={selectsData.villedata}
                    unitiesdata={selectsData.unitiesdata}
                    setPopupVisibleMember={setPopupVisibleMember}
                    formData={items}
                    refForm={refFormResidence}
                    onFieldDataChanged={formFieldDataChanged}
                    membersdata={selectsData.Membersdata}
                    unit_twin={selectsData.unit_twin}
                    t={t}
                  />
                </Tab>
                <Tab title="Caractéristiques">
                  <CharacteristicTab
                    dataTags={dataTags}
                    bedList={bedList}
                    equipmentdata={selectsData.equipmentdata}
                    agencydata={agencydata}
                    setChangeTexts={setChangeTexts}
                    setPopupVisibleAgence={setPopupVisibleAgence}
                    texts={texts}
                    typedevrrdata={selectsData.typedevrrdata}
                    formData={items}
                    ref={refFormResidence}
                    onFieldDataChanged={formFieldDataChanged}
                    t={t}
                  />
                </Tab>
                <Tab title="Français">
                  <FrenchTab
                    dataTags={dataTags}
                    setChangeTexts={setChangeTexts}
                    setChangeIntroduction={setChangeIntroduction}
                    texts={texts}
                    introduction={introduction}
                    formData={items}
                    ref={refFormResidence}
                    onFieldDataChanged={formFieldDataChanged}
                  />
                </Tab>
                <Tab title="Anglais">
                  <EnglishTab
                    dataTags={dataTags}
                    setChangeTexts={setChangeTexts}
                    setChangeIntroduction={setChangeIntroduction}
                    texts={texts}
                    introduction={introduction}
                    formData={items}
                    ref={refFormResidence}
                    onFieldDataChanged={formFieldDataChanged}
                  />
                </Tab>
                <Tab title="Contrat de location">
                  <LocationContract
                    dataTags={dataTags}
                    t={t}
                    conditiondata={selectsData.conditiondata}
                    contartLocationdata={selectsData.contartLocationdata}
                    setChangeTexts={setChangeTexts}
                    isPreauthorization={selectsData.IsPreauthorization}
                    texts={texts}
                    formData={items}
                    ref={refFormResidence}
                    onFieldDataChanged={formFieldDataChanged}
                  />
                </Tab>
                <Tab title="Contrat de service">
                  <ServiceContract
                    Contrat={selectsData.contrat}
                    contartServicedata={selectsData.contartServicedata}
                    formData={items}
                    ref={refFormResidence}
                    onFieldDataChanged={formFieldDataChanged}
                  />
                </Tab>
                <Tab title="Notes">
                  <NoteTab dataTags={dataTags} notes={notes} setChangeNotes={setChangeNotes} />
                </Tab>
                <Tab title="Vedette">
                  <EnVedette formData={items} ref={refFormResidence} onFieldDataChanged={formFieldDataChanged} />
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() => history.push(`/residences/details/${chaletId}`)}
                  >
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>

      {popupVisibleAgence && (
        <AddPopup
          popupVisible={popupVisibleAgence}
          hideModal={() => {
            setPopupVisibleAgence(false);
          }}
          title={t('agency.add_agency')}
          component={
            <AgencyFormPopup
              hideModal={() => {
                setPopupVisibleAgence(false);
              }}
              setAgencydata={(data) => {
                setAgencydata((state) => [...state, data]);
              }}
            />
          }
          width="60%"
        />
      )}
      {popupVisibleMember && (
        <AddPopup
          popupVisible={popupVisibleMember}
          hideModal={() => {
            setPopupVisibleMember(false);
          }}
          title={t('members.add_member')}
          component={
            <MemberFormPopup
              hideModal={() => {
                setPopupVisibleMember(false);
              }}
              residence
            />
          }
          width="60%"
          height="90%"
        />
      )}
    </Suspense>
  );
}
