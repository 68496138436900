/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule, GroupItem } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import moment from 'moment';
import { selectEditorOptions } from '../../../../../utils/editorOptions';
import { promotionTypes } from '../../../../../utils';

export default function PromotionReportForm({ formValues }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startValue, setStartValue] = useState(new Date());
  const [endValue, setEndValue] = useState(new Date());
  const [items, setItems] = useState({ start_date: new Date(), end_date: new Date() });

  const minChangeHandler = function (e) {
    setStartValue(e.value);
  };

  const maxChangeHandler = function (e) {
    setEndValue(e.value);
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const { debut, fin, ...rest } = items;
      const values = {
        ...rest,
        debut: moment(debut).format('YYYY-MM-DD'),
        fin: moment(fin).format('YYYY-MM-DD'),
      };
      return addPeriode(values);
    },
    [items]
  );

  const addPeriode = (values) => {
    setLoading(false);
    history.push(`/compte_rendu_promotion?debut=${values.debut}&fin=${values.fin}&type=${values.type}`);
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  const startDateBoxOptions = () => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: t('errors.date_format'),
      openOnFieldClick: true,
      showClearButton: true,
      value: startValue,
      onValueChanged: (e) => minChangeHandler(e),
      max: endValue,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  const endDateBoxOptions = () => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: t('errors.date_format'),
      openOnFieldClick: true,
      showClearButton: true,
      value: endValue,
      onValueChanged: (e) => maxChangeHandler(e),
      min: startValue,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  useEffect(() => {
    setItems({ ...formValues, type: parseInt(formValues.type, 10) });
  }, [formValues]);

  return (
    <div className="dx-card responsive-paddings">
      <div className="client_item_title search-res">
        <i className="fa fa-search" />
        {t('common.searching')}
      </div>
      <form onSubmit={onSubmit}>
        <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
          <SimpleItem dataField="debut" editorType="dxDateBox" editorOptions={startDateBoxOptions()}>
            <Label text="Début de la période" visible={false} />
            <RequiredRule message={t('errors.required_start_date')} />
          </SimpleItem>
          <SimpleItem dataField="fin" editorType="dxDateBox" editorOptions={endDateBoxOptions()}>
            <Label text="Fin de la période" visible={false} />
            <RequiredRule message={t('errors.required_end_date')} />
          </SimpleItem>
          <SimpleItem dataField="type" editorType="dxSelectBox" editorOptions={selectEditorOptions(promotionTypes)}>
            <RequiredRule message="le type est requis" />
            <Label text="Type" visible={false} />
          </SimpleItem>
          <GroupItem>
            <ButtonItem>
              <ButtonOptions width="100%" type="default" useSubmitBehavior>
                <span className="dx-button-text">
                  {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.search')}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
}

PromotionReportForm.propTypes = {
  formValues: PropTypes.object.isRequired,
};
