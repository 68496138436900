import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './etats_compte.scss';
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  LoadPanel,
  Sorting,
  Summary,
  Button,
  TotalItem,
} from 'devextreme-react/data-grid';
import { useAuth } from '../../../contexts/auth';
import RenderHeaderPage from '../../../components/widgets/headerResidance';
import WidgetImage from '../../../components/widgets/mainImage';
import WidgetActions from '../../../components/widgets/actions';
import { getAll } from '../../../api';
import { getPermissionVisibility } from '../../../utils';

export default function EtatsCompte() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { auth } = useAuth();
  const [dataList, setDataList] = useState({});

  const navigateBack = () => {
    // eslint-disable-next-line no-restricted-globals
    history.push(`/residences/details/${id}`);
  };

  useEffect(() => {
    getAll(`tariffs/${id}/basic`).then((res) => {
      setDataList(res.data);
    });
  }, []);

  const listActions = [
    {
      id: 1,
      name: t('residance.generer_etat_compte'),
      icon: 'fa fa-magic',
      onPress: console.log('press'),
      styles: 'actionBtn',
      display: true,
    },
    {
      id: 2,
      name: t('residance.etat_de_compte_complet'),
      icon: 'fa fa-trophy',
      onPress: () => history.push(`/residences/${id}/etats_comptes/0`),
      styles: 'actionBtn',
      display: true,
    },
    {
      id: 3,
      name: t('residance.facturations'),
      icon: 'fa fa-file',
      onPress: () => history.push(`/residences/factures/${id}`),
      styles: 'actionBtn',
      display: true,
    },
    {
      id: 4,
      name: t('residance.backToResidance'),
      icon: 'fa fa-arrow-left',
      onPress: navigateBack,
      styles: 'blueBtnBackground actionBtn btnForWithBackGrounColor',
      display: true,
    },
  ];

  const customDataSource = [
    {
      id: 1,
      date: '28 février 2021',
      fact: '1,454.07',
      credit: '-4,038.66',
    },
    {
      id: 2,
      date: '28 Juin 2021',
      fact: '1,454.07',
      credit: '0',
    },
    {
      id: 3,
      date: '28 July 2021',
      fact: '5,454.07',
      credit: '0',
    },
    {
      id: 4,
      date: '31 janvier 2021',
      fact: '1,454.07',
      credit: '2,038.66',
    },
    {
      id: 5,
      date: '6 janvier 2021',
      fact: '1,454.07',
      credit: '-4,038.66',
    },
    {
      id: 6,
      date: '7 décembre 2020',
      fact: '1,454.07',
      credit: '-4,038.66',
    },
    {
      id: 7,
      date: '28 février 2021',
      fact: '1,454.07',
      credit: '-4,038.66',
    },
    {
      id: 8,
      date: '28 février 2021',
      fact: '1,454.07',
      credit: '-4,038.66',
    },
    {
      id: 9,
      date: '28 février 2021',
      fact: '1,454.07',
      credit: '-4,038.66',
    },
    {
      id: 10,
      date: '28 février 2021',
      fact: '1,454.07',
      credit: '-4,038.66',
    },
    {
      id: 11,
      date: '28 février 2021',
      fact: '1,454.07',
      credit: '-4,038.66',
    },
  ];
  const PermissionsList = auth.permissions;

  const calculateSalesAmount = (rowData) => {
    return (parseFloat(rowData.fact.replace(',', '')) + parseFloat(rowData.credit.replace(',', ''))).toFixed(3);
  };
  function isCancelButtonVisible(e) {
    return !e.row.isEditing && !e.row.data.isCompleted;
  }

  function myCommand() {
    return console.log('clicked button');
  }

  return (
    <div className="content-block ">
      <RenderHeaderPage
        t={t}
        id=""
        name={dataList ? `${t('residance.account_status')}, ${dataList.name}` : 'page'}
        officialName={dataList.official_name}
      />
      <div className="mainContainer">
        <div className="side">
          <WidgetImage t={t} src={dataList?.main_photo ?? ''} />
          <WidgetActions ListAction={listActions} title={t('common.actions')} t={t} dviderIndexs={[]} />
        </div>
        <DataGrid
          className="dx-card wide-card"
          dataSource={customDataSource}
          showBorders
          defaultFocusedRowIndex={1}
          columnAutoWidth
          columnHidingEnabled
          remoteOperations
        >
          <Sorting mode="none" />
          <LoadPanel enabled />
          <Paging defaultPageSize={10} />
          <Editing mode="row" useIcons />
          <Column dataField="date" caption="DATE" />
          <Column dataField="fact" caption="FACTURE" />
          <Column dataField="credit" caption="CRÉDIT" />
          <Column dataField="total" caption="TOTAL DÛ" calculateCellValue={calculateSalesAmount} />
          <Column type="buttons">
            <Button
              hint="Voir"
              icon="fa fa-eye"
              onClick={(e) => history.push(`/residences/etats_comptes/${id}/${e.row.data.id}`)}
              className="actionBtn"
            />
          </Column>
        </DataGrid>
      </div>
    </div>
  );
}
