/* eslint-disable prettier/prettier */
/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, LoadIndicator } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  SimpleItem,
  TabbedItem,
  TabPanelOptions,
  GroupItem,
  RequiredRule,
} from 'devextreme-react/form';
import { Tab } from 'devextreme-react/diagram';
import { Label } from 'devextreme-react/bar-gauge';
import { addDays, subDays } from 'date-fns';
import { frCA } from 'date-fns/locale';
import { Button } from 'devextreme-react/button';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import {
  AutoCompleteSelectEditorOptionsWithAddBtn,
  emailEditorOptions,
  langueEditorOptions,
  NumberEditorOptions,
  phoneCaEditorOptions,
  salutationEditorOptions,
  selectEditorOptions,
  textEditorOptions,
} from '../../utils/editorOptions';
import './style.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import EditorInput from '../editor-input/editor-input';
import useGetMergTags from '../../hooks/useGetTags';
import { checkBoxOptions } from '../form/residence-form/input-options';
import {
  getReservationDates,
  getReservationDatesCalander,
  ModePaimentdata,
  occupeProprioData,
  Toast,
  getDateTimeZone
} from '../../utils';
import { create, get, getAll } from '../../api';
import AddPopup from '../add-popup/add-popup';
import PopupClientForm from '../form/popup-client-form/popup-client-form';
/* -------------------------------------------------------------------------- */
/*                                RENDER HELPER                               */
/* -------------------------------------------------------------------------- */

export default function FormReservation({ t, hideModal, residenceId, residenceInfo, loadingDetails, multiUnits }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const history = useHistory();
  const [items, setItems] = useState({ client: [], child: [] });
  const [loading, setLoading] = useState(false);
  const [valueContent, setValueContent] = useState('');
  const [nbrBabies, setNbrBabies] = useState([]);
  const [units, setUnits] = useState([]);
  const [isAnimal, setIsAnimal] = useState(false);
  const [isOtherProvenance, setIsOtherProvenance] = useState(false);
  const [isAnimalList, setIsAnimalList] = useState({
    dog: false,
    cat: false,
    other: false,
  });

  const [Membersdata, setMembersdata] = useState([]);
  const [memberValue, setMemberValue] = useState('');
  const [popupVisibleClient, setPopupVisibleClient] = useState(false);

  const [reservationPeriods, setReservationPeriods] = useState([]);
  const [reservationdelay, setReservationdelay] = useState([]);


  const [statePeriode, setStatePeriode] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const dataTags = useGetMergTags();

  const showModalClient = () => {
    setPopupVisibleClient(true);
  };

  const hideModalClient = () => {
    setPopupVisibleClient(false);
  };

  const addAgeButtonOptions = {
    icon: 'add',
    text: 'Ajouter un enfant',
    onClick: () => {
      setItems((state) => {
        const { child, ...rest } = state;
        return { ...rest, child: [...child, null] };
      });
      setNbrBabies([...nbrBabies, '']);
    },
  };

  const generateAgesBabiesOptions = (index) => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      step: 0,
      min: 0,
      buttons: [
        {
          name: 'trash',
          location: 'after',
          options: {
            stylingMode: 'text',
            icon: 'trash',
            onClick: () => {
              setItems((state) => {
                const { child, ...rest } = state;
                child.splice(index, 1);
                return { ...rest, child };
              });
              setNbrBabies([...nbrBabies.filter((_, i) => i !== index)]);
            },
          },
        },
      ],
    };
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const values = dataBeforeSubmit(items);
      return reserverResidence(values);
    },
    [items, valueContent]
  );

  const reserverResidence = (values) => {
    const { residence_id, ...rest } = values;
    create(`reservations/${residence_id}`, rest)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          hideModal();
          history.push('/reservations');
          Toast(res.data.message);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const dataForm = e.component.option('formData');
    setItems({ ...items, ...dataForm });
    setIsAnimal(dataForm.animals);
    setIsOtherProvenance(dataForm.origins === 'other_provenance');
    setIsAnimalList({
      dog: dataForm.dog,
      cat: dataForm.cat,
      other: dataForm.other,
    });
  };

  const setPrice = () => {
    setItems({
      ...items,
      price_ht: null,
      tps_cost: null,
      tvq_cost: null,
      cleaning_fee: null,
      service_cost: null,
      lodging_cost: null,
      total_price: null,
    });
  };
  const [serviceInitCost, setServiceInitCost] = useState();
  const [recalcActive, setRecalcActive] = useState(false);



  const dataBeforeSubmit = (data) => {
    const { dog, cat, other, dogs, cats, others, client, smoker, animals, origins, ...rest }
      = data;
    const animal = [];
    if (dog) {
      animal.push({
        type: 'dog',
        ...dogs,
      });
    }
    if (cat) {
      animal.push({
        type: 'cat',
        ...cats,
      });
    }
    if (other) {
      animal.push({
        type: 'other',
        ...others,
      });
    }
    const values = {
      ...rest,
      note: valueContent,
      occupied_by_owner: origins === 'occupied_by_owner' ? 1 : 0,
      occupied_by_friend_or_family: origins === 'occupied_by_friend_or_family' ? 1 : 0,
      renovations: origins === 'renovations' ? 1 : 0,
      agency: origins === 'agency' ? 1 : 0,
      airbnb: origins === 'airbnb' ? 1 : 0,
      lodgify: origins === 'lodgify' ? 1 : 0,
      quebec_location_de_chalets: origins === 'quebec_location_de_chalets' ? 1 : 0,
      rent_by_owner: origins === 'rent_by_owner' ? 1 : 0,
      reservit: origins === 'reservit' ? 1 : 0,
      smoker: smoker ? 1 : 0,
      vrbo: origins === 'vrbo' ? 1 : 0,
      start_at: moment(statePeriode[0].startDate).format('YYYY-MM-DD'),
      end_at: moment(statePeriode[0].endDate).format('YYYY-MM-DD'),
      current_residence_id: residenceId,
    };
    if (animals) {
      values.animal = animal;
    }
    return values;
  };

  const checkReservationDates = async (date) => {
    const pickerDate = date;
    const res = await getAll(
      multiUnits && items.unit_id
        ? `residences/${residenceId}/reservation-date?unit_id=${Number(items.unit_id)}&start_at=${moment(
          subDays(pickerDate, 30)
        ).format('YYYY-MM-DD')}&end_at=${moment(addDays(pickerDate, 60)).format('YYYY-MM-DD')}`
        : `residences/${residenceId}/reservation-date?start_at=${moment(subDays(pickerDate, 30)).format(
          'YYYY-MM-DD'
        )}&end_at=${moment(addDays(pickerDate, 60)).format('YYYY-MM-DD')}`
    );
    // eslint-disable-next-line no-unneeded-ternary
    if (res.data && res.data.day_delay_between_reservations === 0) {
      /* eslint-disable no-param-reassign */
      res.data?.data.forEach((item) => {
        item.end_at = moment(item.end_at).subtract(24, 'h');
      });
    }
    const reservationsDates = res.data?.data?.filter((el) => {
      return (
        moment(getDateTimeZone(new Date(el.start_at))).format('YYYY-MM-DD') !==
        moment(new Date(statePeriode[0]?.startDate)).format('YYYY-MM-DD')
      );
    });
    /* eslint-disable no-param-reassign */
    // const isWithDelay = !!(res.data.day_delay_between_reservations && res.data.day_delay_between_reservations === 1);
    const dataPeriodsFormat = getReservationDates(reservationsDates ?? []);
    setReservationPeriods(dataPeriodsFormat);

  };

  const showDateChange = (DateRange, Calendar) => {
    checkReservationDates(DateRange);
  };

  const calculPrice = () => {
    const { adult, child, dogs, cats, others, ...rest } = items;

    const dogsNumber = items.animals && items.dog && dogs?.number ? parseInt(dogs?.number) : 0;
    const catsNumber = items.animals && items.cat && cats?.number ? parseInt(cats?.number) : 0;
    const othersNumber = items.animals && items.other && others?.number ? parseInt(others?.number) : 0;
    const animalsNumber = dogsNumber + catsNumber + othersNumber;
    const values = {
      adult,
      children: child,
      animal: animalsNumber,
      start_at: moment(statePeriode[0].startDate).format('YYYY-MM-DD'),
      end_at: moment(statePeriode[0].endDate).format('YYYY-MM-DD'),
    };
    create(`reservations/${residenceId}/tariff`, values).then((res) => {
      if (res.data) {
        const {
          cleaning_fee,
          lodging_cost,
          service_cost,
          total_HT,
          tps_cost,
          tvq_cost,
          total_TTC,
          residence_id,
          service_fee,
          twin_id,
        } = res.data;
        const residenceIdTwin = residence_id || residenceId;
        if (twin_id) {
          setItems({
            ...items,
            price_ht: total_HT,
            tps_cost,
            tvq_cost,
            cleaning_fee: cleaning_fee || 0,
            service_cost,
            lodging_cost,
            total_price: total_TTC,
            twin_id,
            residence_id: residenceIdTwin,
          });
        } else {
          setItems({
            ...items,
            price_ht: total_HT,
            tps_cost,
            tvq_cost,
            cleaning_fee: cleaning_fee || 0,
            service_cost,
            lodging_cost,
            total_price: total_TTC,
            residence_id: residenceIdTwin,
          });
        }
        setServiceInitCost(service_fee);
        setRecalcActive(true);
      }
    });
  };
  const reCalculPrice = () => {
    const { cleaning_fee: clean_fe, service_cost: serv_ct, price_ht, ...rest } = items;
    const values = {
      total_ht: price_ht,
      service_fee: serviceInitCost,
      cleaning_fee: clean_fe,
    };

    create(`reservations/${residenceId}/tariff/recalculate`, values).then((res) => {
      if (res.data) {
        const { cleaning_fee, lodging_cost, service_cost, total_HT, tps_cost, tvq_cost, total_TTC } = res.data;
        setItems({
          ...items,
          price_ht: total_HT,
          tps_cost,
          tvq_cost,
          cleaning_fee: cleaning_fee || 0,
          service_cost,
          lodging_cost,
          total_price: total_TTC,
        });
      }
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (multiUnits) {
      getAll(`residence_units/${residenceId}`).then((response) => {
        setUnits(response.data.data);
        if (response.data.data.length > 0) {
          setItems((state) => {
            return { ...state, unit_id: response.data?.data[0]?.id ?? '' };
          });
        }
      });
    }
  }, [residenceId, multiUnits]);

  useEffect(() => {
    if (items.client_id) {
      get('clients', items.client_id).then((response) => {
        const { data } = response.data;
        setItems({ ...items, client: data });
      });
    } else {
      setItems({ ...items, client: [] });
    }
  }, [items.client_id]);

  useEffect(() => {
    if (!loadingDetails) {
      if (items.unit_id) {
        checkReservationDates(new Date());
        setStatePeriode([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          },
        ]);
      } else if (!multiUnits) {
        checkReservationDates(new Date());
      }
    }
  }, [items.unit_id, loadingDetails]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (memberValue?.length >= 3) {
        getAll(`search/client/?filed=full_name&value=${memberValue}`).then((response) => {
          setMembersdata(response.data.data);
        });
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [memberValue]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="dx-card responsive-paddings-widget fullWidth">
        {residenceInfo.reservations_notes && (
          <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: residenceInfo.reservations_notes }} />
        )}
        <div>
          <form onSubmit={onSubmit}>
            <Form formData={items} showValidationSummary onFieldDataChanged={formFieldDataChanged}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title={t('residance.arrival_departure')}>
                  {!!multiUnits && (
                    <SimpleItem
                      dataField="unit_id"
                      editorType="dxSelectBox"
                      editorOptions={{
                        ...selectEditorOptions(units, 'code'),
                        onValueChanged: () => setPrice(),
                      }}
                    >
                      <RequiredRule message="L'unité est requise" />
                      <Label text={t('units.units')} visible={false} />
                    </SimpleItem>
                  )}
                  <GroupItem cssClass="group-item" colCount={2}>
                    <DateRangePicker
                      className="date_calander"
                      onChange={(item) => {
                        setStatePeriode([item.selection]);
                        setPrice();
                      }}
                      onShownDateChange={showDateChange}
                      showSelectionPreview={false}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={statePeriode}
                      direction="horizontal"
                      locale={frCA}
                      staticRanges={[]}
                      inputRanges={[]}
                      disabledDates={reservationPeriods}
                    />
                  </GroupItem>
                </Tab>
                <Tab title={t('residance.nbr_people')}>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <GroupItem cssClass="group-item">
                      <SimpleItem
                        dataField="adult"
                        editorType="dxNumberBox"
                        editorOptions={{ ...NumberEditorOptions, onValueChanged: () => setPrice() }}
                      >
                        <RequiredRule message="Le nombre d'adultes est requis" />
                        <Label text="Nombre d'adultes" visible={false} />
                      </SimpleItem>
                      <GroupItem caption="Enfants" name="enfants-container">
                        <GroupItem name="enfants">
                          {nbrBabies.map((_, index) => (
                            <SimpleItem
                              key={`child${index}`}
                              dataField={`child[${index}]`}
                              editorType="dxNumberBox"
                              editorOptions={{
                                ...generateAgesBabiesOptions(index),
                                onValueChanged: () => setPrice(),
                              }}
                            >
                              <RequiredRule message={`L'age d'enfant ${index + 1} est requis`} />
                              <Label text={`Age d'enfant ${index + 1}`} visible={false} />
                            </SimpleItem>
                          ))}
                        </GroupItem>
                        <SimpleItem
                          itemType="button"
                          horizontalAlignment="left"
                          cssClass="add-button"
                          buttonOptions={addAgeButtonOptions}
                        />
                      </GroupItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item">
                      <SimpleItem
                        dataField="smoker"
                        editorType="dxCheckBox"
                        editorOptions={checkBoxOptions('Présence de fumeur')}
                      >
                        <Label visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        dataField="animals"
                        editorType="dxCheckBox"
                        editorOptions={{
                          ...checkBoxOptions("Présence d'animaux"),
                          onValueChanged: () => setPrice(),
                        }}
                      >
                        <Label visible={false} />
                      </SimpleItem>
                      {isAnimal && (
                        <GroupItem cssClass="group-item">
                          <GroupItem cssClass="group-item" colCount={2}>
                            <SimpleItem
                              dataField="dog"
                              editorType="dxCheckBox"
                              editorOptions={{
                                ...checkBoxOptions('Chien'),
                                onValueChanged: () => setPrice(),
                              }}
                            >
                              <Label visible={false} />
                            </SimpleItem>
                            {isAnimalList.dog && (
                              <GroupItem cssClass="group-item">
                                <SimpleItem
                                  dataField="dogs.breed"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Râce" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="dogs.number"
                                  editorType="dxTextBox"
                                  editorOptions={{ ...textEditorOptions, onValueChanged: () => setPrice() }}
                                >
                                  <Label text="Nombre" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="dogs.weight"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Poids approximatif" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="dogs.age"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Âge approximatif" visible={false} />
                                </SimpleItem>
                              </GroupItem>
                            )}
                          </GroupItem>
                          <GroupItem cssClass="group-item" colCount={2}>
                            <SimpleItem
                              dataField="cat"
                              editorType="dxCheckBox"
                              editorOptions={{
                                ...checkBoxOptions('Chat'),
                                onValueChanged: () => setPrice(),
                              }}
                            >
                              <Label visible={false} />
                            </SimpleItem>
                            {isAnimalList.cat && (
                              <GroupItem cssClass="group-item">
                                <SimpleItem
                                  dataField="cats.breed"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Râce" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="cats.number"
                                  editorType="dxTextBox"
                                  editorOptions={{ ...textEditorOptions, onValueChanged: () => setPrice() }}
                                >
                                  <Label text="Nombre" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="cats.weight"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Poids approximatif" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="cats.age"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Âge approximatif" visible={false} />
                                </SimpleItem>
                              </GroupItem>
                            )}
                          </GroupItem>
                          <GroupItem cssClass="group-item" colCount={2}>
                            <SimpleItem
                              dataField="other"
                              editorType="dxCheckBox"
                              editorOptions={{
                                ...checkBoxOptions('Autre'),
                                onValueChanged: () => setPrice(),
                              }}
                            >
                              <Label visible={false} />
                            </SimpleItem>
                            {isAnimalList.other && (
                              <GroupItem cssClass="group-item">
                                <SimpleItem
                                  dataField="others.breed"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Râce" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="others.number"
                                  editorType="dxTextBox"
                                  editorOptions={{ ...textEditorOptions, onValueChanged: () => setPrice() }}
                                >
                                  <Label text="Nombre" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="others.weight"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Poids approximatif" visible={false} />
                                </SimpleItem>
                                <SimpleItem
                                  dataField="others.age"
                                  editorType="dxTextBox"
                                  editorOptions={textEditorOptions}
                                >
                                  <Label text="Âge approximatif" visible={false} />
                                </SimpleItem>
                              </GroupItem>
                            )}
                          </GroupItem>
                        </GroupItem>
                      )}
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title={t('residance.prix')}>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="payment_method"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(ModePaimentdata)}
                    >
                      <RequiredRule message="Le mode de paiement est requis" />
                      <Label text="Mode de paiement" visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="confirm_mails"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions([{ id: 'default', name: 'Défaut' }])}
                    >
                      <Label text="Courriel de confirmation" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" caption="Calcul du Prix">
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem dataField="price_ht" editorType="dxNumberBox" editorOptions={NumberEditorOptions}>
                        <RequiredRule message="Le prix est requis" />
                        <Label text="PRIX $" visible={false} />
                      </SimpleItem>
                      <GroupItem colCount={2}>
                        <SimpleItem>
                          <Button type="success" text="Calculer le prix" onClick={calculPrice} />
                        </SimpleItem>
                        <SimpleItem>
                          <Button text="Recalculer le prix" onClick={reCalculPrice} disabled={!recalcActive} />
                        </SimpleItem>
                      </GroupItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item" colCount={3}>
                      <SimpleItem
                        dataField="lodging_cost"
                        disabled
                        editorType="dxNumberBox"
                        editorOptions={NumberEditorOptions}
                      >
                        <Label text="TAXE HÉBERGEMENT $" visible={false} />
                        <RequiredRule message="Le taxe hébergement est requis" />
                      </SimpleItem>
                      <SimpleItem
                        dataField="service_cost"
                        disabled
                        editorType="dxNumberBox"
                        editorOptions={NumberEditorOptions}
                      >
                        <Label text="FRAIS DE SERVICE $" visible={false} />
                        <RequiredRule message="Le frais de service est requis" />
                      </SimpleItem>
                      <SimpleItem
                        dataField="cleaning_fee"
                        disabled
                        editorType="dxNumberBox"
                        editorOptions={NumberEditorOptions}
                      >
                        <Label text="Frais de ménage sans commission $" visible={false} />
                        <RequiredRule message="Le frais de ménage sans commission est requis" />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="tps_cost"
                        disabled
                        editorType="dxNumberBox"
                        editorOptions={NumberEditorOptions}
                      >
                        <Label text="TPS $" visible={false} />
                        <RequiredRule message="Le TPS est requis" />
                      </SimpleItem>
                      <SimpleItem
                        dataField="tvq_cost"
                        disabled
                        editorType="dxNumberBox"
                        editorOptions={NumberEditorOptions}
                      >
                        <Label text="TVQ $" visible={false} />
                        <RequiredRule message="Le TVQ est requis" />
                      </SimpleItem>
                    </GroupItem>
                    <SimpleItem
                      dataField="total_price"
                      disabled
                      editorType="dxNumberBox"
                      editorOptions={NumberEditorOptions}
                    >
                      <RequiredRule message="Le prix total est requis" />
                      <Label text="TOTAL $" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                </Tab>
                <Tab title={t('residance.client')}>
                  <SimpleItem
                    dataField="client_id"
                    editorType="dxSelectBox"
                    editorOptions={AutoCompleteSelectEditorOptionsWithAddBtn(
                      Membersdata,
                      setMemberValue,
                      showModalClient
                    )}
                  >
                    <RequiredRule message="Le client est requis" />
                    <Label text="Client" visible={false} />
                  </SimpleItem>
                  <GroupItem cssClass="group-item">
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="client.gender"
                        disabled
                        editorType="dxSelectBox"
                        editorOptions={salutationEditorOptions}
                      >
                        <Label text="Salutation" visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        dataField="client.language"
                        disabled
                        editorType="dxSelectBox"
                        editorOptions={langueEditorOptions}
                      >
                        <Label text="Langue" visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item" colCount={3}>
                      <SimpleItem
                        dataField="client.first_name"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={textEditorOptions}
                      >
                        <Label text="Prénom" visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        dataField="client.last_name"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={textEditorOptions}
                      >
                        <Label text="Nom" visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        dataField="client.surname"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={textEditorOptions}
                      >
                        <Label text="Nom (autre)" visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <SimpleItem
                      dataField="client.company"
                      disabled
                      editorType="dxTextBox"
                      editorOptions={textEditorOptions}
                    >
                      <Label text="Compagnie" visible={false} />
                    </SimpleItem>
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="client.email"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={emailEditorOptions}
                      >
                        <Label text="Courriel" visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        dataField="client.second_email"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={emailEditorOptions}
                      >
                        <Label text="Courriel (autre)" visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="client.phone.number"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={phoneCaEditorOptions}
                      >
                        <Label text="Téléphone" visible={false} />
                      </SimpleItem>
                      <GroupItem colCount={2}>
                        <SimpleItem
                          dataField="client.phone.extension"
                          disabled
                          editorType="dxTextBox"
                          editorOptions={textEditorOptions}
                        >
                          <Label text="Ext." visible={false} />
                        </SimpleItem>
                      </GroupItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="client.second_phone.number"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={phoneCaEditorOptions}
                      >
                        <Label text="Téléphone (autre)" visible={false} />
                      </SimpleItem>
                      <GroupItem colCount={2}>
                        <SimpleItem
                          dataField="client.second_phone.extension"
                          disabled
                          editorType="dxTextBox"
                          editorOptions={textEditorOptions}
                        >
                          <Label text="Ext. (autre)" visible={false} />
                        </SimpleItem>
                      </GroupItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item" colCount={1}>
                      <SimpleItem
                        dataField="client.country"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={textEditorOptions}
                      >
                        <Label text="Pays" visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="client.address"
                        disabled
                        editorType="dxAutocomplete"
                        editorOptions={textEditorOptions}
                      >
                        <Label text="Adresse" visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        dataField="client.province"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={textEditorOptions}
                      >
                        <Label text="Province" visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="client.city"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={textEditorOptions}
                      >
                        <Label text="Ville" visible={false} />
                      </SimpleItem>
                      <SimpleItem
                        dataField="client.postal_code"
                        disabled
                        editorType="dxTextBox"
                        editorOptions={textEditorOptions}
                      >
                        <Label text="Code postal" visible={false} />
                      </SimpleItem>
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title={t('residance.proprio')}>
                  <GroupItem cssClass="group-item">
                    <GroupItem cssClass="group-item" colCount={2}>
                      <SimpleItem
                        dataField="origins"
                        editorType="dxSelectBox"
                        editorOptions={selectEditorOptions(occupeProprioData)}
                      >
                        <RequiredRule message="Le choix du propriétaire est requis" />
                        <Label text="Choix du propriétaire" visible={false} />
                      </SimpleItem>
                      {isOtherProvenance && (
                        <SimpleItem dataField="other_origin" editorType="dxTextBox" editorOptions={textEditorOptions}>
                          <RequiredRule message="Le provenance est requis" />
                          <Label text="Provenance" visible={false} />
                        </SimpleItem>
                      )}
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title={t('residance.note')}>
                  <GroupItem>
                    <div className="dx-field-label">Note supplémentaire</div>
                    <div className="dx-field-value editor_width">
                      <EditorInput dataSource={dataTags} valueContent={valueContent} valueChanged={setValueContent} />
                    </div>
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.saveBtn')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => hideModal()}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
      <AddPopup
        popupVisible={popupVisibleClient}
        hideModal={hideModalClient}
        title={t('client.add_client')}
        component={
          <PopupClientForm
            hideModal={() => {
              setPopupVisibleClient(false);
            }}
          />
        }
        width="60%"
        height="90%"
      />
    </>
  );
}

FormReservation.propTypes = {
  t: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  residenceId: PropTypes.number.isRequired,
  residenceInfo: PropTypes.object.isRequired,
  loadingDetails: PropTypes.bool.isRequired,
  multiUnits: PropTypes.bool.isRequired,
};
