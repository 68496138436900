import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Sorting } from 'devextreme-react/data-grid';
import '../styles.scss';

export default function BalanceDetailsFees({ dataSource }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation();
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const renderGridCell = (data) => {
    const color = Number(data.value) >= 0 ? 'green' : 'red';
    return <div style={{ color }}>{data.value} $</div>;
  };
  const handleClick = () => {};

  const renderGridCellLink = (data) => {
    return (
      <div className="linkDetails" onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={0}>
        {data.value}
      </div>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="detailsItem">
      <DataGrid
        className="dx-card wide-card"
        dataSource={dataSource}
        showBorders
        defaultFocusedRowIndex={0}
        columnHidingEnabled
      >
        <Sorting mode="none" />

        <Column dataField="date" caption="Date" cellRender={renderGridCellLink} />
        <Column dataField="client" caption="Client" />
        <Column dataField="residence" caption="Résidence" />
        <Column dataField="card_fees" caption="Frais de carte de crédit" cellRender={renderGridCell} />
      </DataGrid>
    </div>
  );
}

BalanceDetailsFees.propTypes = {
  dataSource: PropTypes.array.isRequired,
};
