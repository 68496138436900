import React, { useState, createContext, useContext, useEffect } from 'react';
/* eslint-disable react/jsx-no-constructed-context-values, react/prop-types , react/destructuring-assignment */
const NavigationContext = createContext({});
const useNavigation = () => useContext(NavigationContext);

function NavigationProvider(props) {
  const [navigationData, setNavigationData] = useState({});

  return <NavigationContext.Provider value={{ navigationData, setNavigationData }} {...props} />;
}

function withNavigationWatcher(Component) {
  // eslint-disable-next-line func-names
  return function (props) {
    const { pathname } = props.location;
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      setNavigationData({ currentPath: pathname });
    }, [pathname, setNavigationData]);

    return React.createElement(Component, props);
  };
}

export { NavigationProvider, useNavigation, withNavigationWatcher };
