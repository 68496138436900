const FileEditorOptions = (setIconStatus) => {
  return {
    stylingMode: 'filled',
    labelMode: 'floating',
    selectButtonText: "sélectionner l'icône",
    accept: '.svg',
    uploadMode: 'useForm',
    labelText: 'ou déposez le fichier ici',
    readyToUploadMessage: 'Prêt à télécharger',
    onValueChanged: (e) => {
      setIconStatus(!e.value.length);
    },
  };
};
const checkBoxOptions = {
  text: "ajouter une page publique relative à l'option / équipement",
};

export { FileEditorOptions, checkBoxOptions };
