import React from 'react';
import PropTypes from 'prop-types';
import './member-form-popup.scss';
import MemberFormComponent from '../member-form-component/member-form-component';

export default function MemberFormPopup({ hideModal, residence }) {
  const resultAddMember = async (data) => {
    data && hideModal();
  };

  const closeFormAction = () => {
    return hideModal();
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <MemberFormComponent resultAddMember={resultAddMember} closeFormAction={closeFormAction} residence={residence} />
  );
}

MemberFormPopup.propTypes = {
  hideModal: PropTypes.func.isRequired,
  residence: PropTypes.bool,
};

MemberFormPopup.defaultProps = {
  residence: false,
};
