import React, { useCallback, useEffect, useState, useRef } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { create, get, update } from '../../../api';
import { permalinkEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { getPermissionVisibility, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';

export default function GroupeVilleForm() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const history = useHistory();

  const { auth } = useAuth();
  const { role } = auth;

  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const refForm = useRef('groupCityForm');
  const [items, setItems] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                                  HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { seo_fr, seo_en, ...rest } = items;
      const seo =
        seo_fr || seo_en
          ? [
              { ...seo_fr, language: 'fr' },
              { ...seo_en, permalink: null, language: 'en' },
            ]
          : [];
      const values = {
        ...rest,
        seo: JSON.stringify(seo),
        published: +items.published,
      };
      setLoading(true);
      return isAddMode ? addGroupeVille(values) : updateGroupeVilleById(id, values);
    },
    [items]
  );

  const addGroupeVille = (values) => {
    create('cities-groups', values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/groupes-ville');
        res.data && Toast('Le groupe de ville a été bien ajouté');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateGroupeVilleById = (villeId, values) => {
    update('cities-groups', villeId, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/groupes-ville');
        res.data && Toast('Le groupe de ville a été bien modifié');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (!isAddMode) {
      get('cities-groups', id).then((response) => {
        const { data } = response.data;
        data.published = data.published !== 0;
        setItems(data);
      });
    }
  }, [id]);
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Groupes de Ville"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/groupes-ville')}
      />
      <h2 className="content-block">{isAddMode ? 'Ajouter un groupe de ville' : 'Modifier un groupe de ville'}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form ref={refForm} formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions
                  deferRendering={false}
                  selectedIndex={getPermissionVisibility(['webmaster'], role) ? 1 : 0}
                />
                <Tab title="Groupe de ville" disabled={getPermissionVisibility(['webmaster'], role)}>
                  <SimpleItem dataField="name" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message="Le nom est requis" />
                    <Label text="Nom" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="published" editorType="dxCheckBox" editorOptions={checkBoxOptions}>
                    <Label visible={false} />
                  </SimpleItem>
                </Tab>
                <Tab title="SEO">
                  <SimpleItem
                    dataField="seo_fr.permalink"
                    editorType="dxTextBox"
                    editorOptions={Object.assign(permalinkEditorOptions(refForm), textEditorOptions)}
                  >
                    <Label text="Permalien" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_fr.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Titre Français" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_fr.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Description Français" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_en.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Titre Anglais" visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_en.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text="Description Anglais" visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/groupes-ville')}>
                    <span className="dx-button-text">Annuler</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}

const checkBoxOptions = {
  text: 'Ajouter une page publique pour le groupe de ville',
  value: false,
};
