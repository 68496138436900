/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  RangeRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import './periode-form.scss';
import moment from 'moment';
import { Tab } from 'devextreme-react/diagram';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import {
  FnDecimalNumberEditorOptions,
  FnNumberEditorOptions,
  ImageEditorOptions,
  NumberDecimalEditorOptions,
  NumberIntEditorOptions,
  selectEditorOptions,
  tagEditorOptions,
  textEditorOptions,
} from '../../../utils/editorOptions';
import { create, get, getAll, updateWithMedia } from '../../../api';
import { promotionType, Toast, weekDays } from '../../../utils';
import EditorInput from '../../editor-input/editor-input';
import useGetMergTags from '../../../hooks/useGetTags';
import { checkBoxOptions, dateBoxOptions } from '../residence-form/input-options';
import CustomDatesRender from '../../widgets/addPrice/helper';
import AddPopup from '../../add-popup/add-popup';
import SelectMultipleDates from '../select-multiple-dates';

export default function PromotionResidanceForm() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const history = useHistory();
  const { id, itemId } = useParams();
  const isAddMode = !itemId;
  const [loading, setLoading] = useState(false);
  const [periodesList, setPeriodesList] = useState([]);
  const [valueContentFr, setValueContentFr] = useState('');
  const [valueContentEn, setValueContentEn] = useState('');
  const [isPhoto, setIsPhoto] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupVisibleNotAvailable, setPopupVisibleNotAvailable] = useState(false);
  const [listCustomDate, setListCustomDates] = useState([]);
  const [listCustomDateNotAvailable, setListCustomDatesNotAvailable] = useState([]);
  const [discountsList, setDiscountsList] = useState([]);
  const [pricesList, setPricesList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [items, setItems] = useState({ not_available_periods: [], available_periods: [], date_added_website: null });
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { t } = useTranslation();
  const dataTags = useGetMergTags();
  /* -------------------------------------------------------------------------- */
  /*                                  HELEPERS                                  */
  /* -------------------------------------------------------------------------- */
  const onDeleteDate = (selectedDate) => {
    const array = listCustomDate;
    const filtredArray = array.filter(
      (el) => !(el.start_date === selectedDate.start_date && el.end_date === selectedDate.end_date)
    );
    setListCustomDates(filtredArray);
  };
  const onDeleteDateNotAvailable = (selectedDate) => {
    const array = listCustomDateNotAvailable;
    const filtredArray = array.filter(
      (el) => !(el.start_date === selectedDate.start_date && el.end_date === selectedDate.end_date)
    );
    setListCustomDatesNotAvailable(filtredArray);
  };

  const onHandleCustomDate = (date) => {
    const data = date.map((e) => {
      return { ...e, availability: 'available' };
    });
    setListCustomDates(data);
    hideDateModal();
  };
  const onHandleCustomDateNotAvailbale = (date) => {
    const data = date.map((e) => {
      return { ...e, availability: 'not_available' };
    });
    setListCustomDatesNotAvailable(data);
    hideDateModalNotAvailable(false);
  };

  const showModal = () => {
    setPopupVisible(true);
  };

  const showModalNotAvailable = () => {
    setPopupVisibleNotAvailable(true);
  };
  const hideDateModal = () => {
    setPopupVisible(false);
  };
  const hideDateModalNotAvailable = () => {
    setPopupVisibleNotAvailable(false);
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const {
        changes_tariff,
        not_available_periods,
        available_week_days,
        available_periods,
        valid_for_holidays,
        icon,
        date_added_website,
        last_minute_discount,
        applicable_tariffs,
        select_discount,
        ...rest
      } = items;
      const values = {
        ...rest,
        type: 'residence',
        residence_id: id,
        changes_tariff: changes_tariff ? 1 : 0,
        valid_for_holidays: valid_for_holidays ? 1 : 0,
        last_minute_discount: last_minute_discount ? 1 : 0,
        not_available_ranges: JSON.stringify(listCustomDateNotAvailable),
        available_ranges: JSON.stringify(listCustomDate),
        not_available_periods: JSON.stringify(not_available_periods),
        available_periods: JSON.stringify(available_periods),
        applicable_tariffs: JSON.stringify(applicable_tariffs),
        available_week_days: available_week_days ? JSON.stringify(available_week_days) : '[]',
        date_added_website: date_added_website ? moment(date_added_website).format('l') : null,
        description_fr: valueContentFr,
        description_en: valueContentEn,
      };
      const dataTosend = Object.fromEntries(Object.entries(values).filter(([_, v]) => v != null));
      if (icon?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        dataTosend.icon = icon[0];
      } else {
        dataTosend.duplicata = select_discount;
      }
      const form_data = new FormData();
      for (const key in dataTosend) {
        form_data.append(key, dataTosend[key]);
      }
      return isAddMode ? addDiscount(form_data) : updateDiscount(itemId, dataTosend);
    },
    [items, valueContentFr, valueContentEn, listCustomDateNotAvailable, listCustomDate]
  );

  const addDiscount = (values) => {
    create('discounts', values, { 'Content-Type': 'multipart/form-data' })
      .then((res) => {
        setLoading(false);
        if (res.status === 201) {
          history.goBack();
          Toast('La promotion a été bien ajoutée');
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateDiscount = (discountId, values) => {
    const formData = new FormData();
    Object.keys(values).map((key) => values[key] != null && formData.append(key, values[key]));
    formData.append('_method', 'PUT');
    updateWithMedia('discounts', discountId, formData, { 'Content-Type': 'multipart/form-data' })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          history.goBack();
          Toast(t('common.successUpdate', { title: 'La promotion' }));
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
    forceUpdate();
  };

  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (!items.changes_tariff) {
      setListCustomDatesNotAvailable([]);
      setListCustomDates([]);
      setItems(
        ({
          min_nbr_nights,
          max_nbr_nights,
          discount_type,
          discount_percentage,
          discount_amount,
          nbr_free_nights,
          available_week_days,
          valid_for_holidays,
          available_periods,
          not_available_periods,
          applicable_tariffs,
          ...prevState
        }) => {
          return { ...prevState, applicable_tariffs: [], available_periods: [], not_available_periods: [] };
        }
      );
    }
  }, [items.changes_tariff]);

  useEffect(() => {
    if (!items.changes_tariff && isAddMode) {
      setItems({ min_nbr_nights: 1, max_nbr_nights: 1 });
    }
  }, [items.changes_tariff]);

  useEffect(() => {
    getAll('periods').then((response) => {
      setPeriodesList(response.data.data);
    });
    getAll('discounts/all/parameter/').then((response) => {
      setDiscountsList(response.data.data);
    });
    getAll(`discounts/all/residence/${id}`).then(({ data }) => {
      if (data?.data.length > 0) {
        setDataSource(data?.data?.filter((el) => el.id !== Number(itemId)));
      }
    });
    getAll(`/tariffs/${id}/all`).then((response) => {
      setPricesList(response.data.data);
    });

    if (!isAddMode) {
      get('discounts', itemId).then((response) => {
        const { icon, not_available_ranges, available_ranges, description_fr, description_en, ...rest } =
          response.data.data;
        setValueContentFr(description_fr);
        setValueContentEn(description_en);
        setItems(rest);
        setIsPhoto(icon);
        setListCustomDates(
          available_ranges.map(({ start_date, end_date }) => {
            return { start_date, end_date, availability: 'not_available' };
          })
        );
        setListCustomDatesNotAvailable(
          not_available_ranges.map(({ start_date, end_date }) => {
            return { start_date, end_date, availability: 'available' };
          })
        );
      });
    }
  }, []);

  useEffect(() => {
    if (items?.select_discount) {
      get('discounts', items.select_discount).then((response) => {
        const { icon, available_ranges, not_available_ranges, description_fr, description_en, ...rest } =
          response.data.data;
        setValueContentFr(description_fr);
        setValueContentEn(description_en);
        setIsPhoto(icon);
        setListCustomDates(
          available_ranges.map(({ start_date, end_date }) => {
            return { start_date, end_date, availability: 'not_available' };
          })
        );
        setListCustomDatesNotAvailable(
          not_available_ranges.map(({ start_date, end_date }) => {
            return { start_date, end_date, availability: 'available' };
          })
        );
        setItems((preventState) => {
          return { ...preventState, ...rest, last_minute_discount: false };
        });
      });
    }
  }, [items.select_discount]);

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('discount.back_promo')}
        type="default"
        stylingMode="text"
        onClick={() => history.goBack()}
      />
      <h2 className="content-block">{isAddMode ? t('discount.add_promo') : t('discount.update_promo')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Description">
                  <GroupItem colCount={1}>
                    <SimpleItem
                      dataField="select_discount"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(discountsList, 'title_fr')}
                    >
                      <Label text={t('discount.charge_promo')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem colCount={2}>
                    <SimpleItem dataField="title_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message={t('errors.required_titleFr')} />
                      <Label text={t('price.TitleFr')} visible={false} />
                    </SimpleItem>
                    <SimpleItem dataField="title_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message={t('errors.required_titleEn')} />
                      <Label text={t('price.TitleEn')} visible={false} />
                    </SimpleItem>
                    <GroupItem>
                      <div className="dx-field-label">{t('residance.description_Fr')}</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput
                          dataSource={dataTags}
                          valueContent={valueContentFr}
                          valueChanged={(data) => setValueContentFr(data)}
                        />
                      </div>
                    </GroupItem>
                    <GroupItem>
                      <div className="dx-field-label">{t('residance.description_En')}</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput
                          dataSource={dataTags}
                          valueContent={valueContentEn}
                          valueChanged={(data) => setValueContentEn(data)}
                        />
                      </div>
                    </GroupItem>
                    <GroupItem cssClass="group-item" caption="Icône pour le site">
                      <SimpleItem dataField="icon" editorType="dxFileUploader" editorOptions={ImageEditorOptions}>
                        <Label text="Icône pour le site" visible={false} />
                        {!isPhoto && <RequiredRule message={t('errors.required_icon')} />}
                      </SimpleItem>
                      {!!isPhoto && (
                        <GroupItem>
                          <div className="content-block">
                            <img src={isPhoto} alt="icon" width="150" height="150" />
                          </div>
                        </GroupItem>
                      )}
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title="Paramètres">
                  <GroupItem colCount={2}>
                    <SimpleItem dataField="date_added_website" editorType="dxDateBox" editorOptions={dateBoxOptions}>
                      <Label text="Date d'ajout sur le site" visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="changes_tariff"
                      editorType="dxCheckBox"
                      editorOptions={checkBoxOptions('Promotion modifie les tarifs')}
                    >
                      <Label visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="min_nbr_nights"
                      editorType="dxNumberBox"
                      editorOptions={FnDecimalNumberEditorOptions(NumberDecimalEditorOptions, !items.changes_tariff)}
                    >
                      <RangeRule
                        message={t('errors.error_min_reservation')}
                        min={1}
                        max={Number(items?.max_nbr_nights)}
                        ignoreEmptyValue
                      />

                      {items.changes_tariff && <RequiredRule message={t('errors.required_minReservation')} />}

                      <Label text={t('price.minReservation')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="max_nbr_nights"
                      editorType="dxNumberBox"
                      editorOptions={FnDecimalNumberEditorOptions(NumberDecimalEditorOptions, !items.changes_tariff)}
                    >
                      <RangeRule
                        message={t('errors.error_max_reservation')}
                        min={Number(items?.min_nbr_nights)}
                        ignoreEmptyValue
                      />
                      {items.changes_tariff && <RequiredRule message={t('errors.required_maxReservation')} />}

                      <Label text={t('price.maxReservation')} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="discount_type"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(promotionType, 'name', !items.changes_tariff)}
                    >
                      <RequiredRule message="Type de promotion" />
                      <Label text="Type de promotion" visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="discount_percentage"
                      editorType="dxNumberBox"
                      editorOptions={FnNumberEditorOptions(!items.changes_tariff)}
                    >
                      {items.discount_type === 'percentage' && (
                        <RequiredRule message={t('error.required_percentage_discount')} />
                      )}

                      <Label text={`${t('discount.percentage_discount')} %`} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="discount_amount"
                      editorType="dxNumberBox"
                      editorOptions={FnNumberEditorOptions(!items.changes_tariff)}
                    >
                      {items.discount_type === 'amount' && (
                        <RequiredRule message={t('error.required_discount_amount')} />
                      )}
                      <Label text={`${t('discount.discount_amount')} $`} visible={false} />
                    </SimpleItem>
                    <SimpleItem
                      dataField="nbr_free_nights"
                      editorType="dxNumberBox"
                      editorOptions={FnNumberEditorOptions(!items.changes_tariff)}
                    >
                      {items.discount_type === 'free_nights' && <RequiredRule message="Nb nuit gratuite est requis" />}
                      <Label text="Nb nuit gratuite" visible={false} />
                    </SimpleItem>
                    {dataSource.length > 0 && (
                      <SimpleItem
                        dataField="discount_twin_id"
                        editorType="dxSelectBox"
                        editorOptions={selectEditorOptions(dataSource, 'title_fr')}
                      >
                        <Label text="Promotion jumelable" visible={false} />
                      </SimpleItem>
                    )}
                    <SimpleItem
                      dataField="last_minute_discount"
                      editorType="dxCheckBox"
                      editorOptions={checkBoxOptions('Promotion de dernière minute')}
                    >
                      <Label visible={false} />
                    </SimpleItem>
                  </GroupItem>
                </Tab>
                <Tab disabled={!items.changes_tariff} title={t('discount.add_parms_availability')}>
                  <GroupItem colCount={2}>
                    <SimpleItem
                      dataField="available_periods"
                      cssClass="tag-box-input"
                      editorType="dxTagBox"
                      editorOptions={tagEditorOptions(periodesList, 'label', true)}
                    >
                      <Label text={t('price.periods')} visible={false} />
                    </SimpleItem>
                    <GroupItem cssClass="group-item centerItem" colCount={1}>
                      <div className="customDates">
                        <Button text="Ajouter une date" hint="Add" icon="plus" onClick={showModal} />
                        {listCustomDate.map((el, index) => (
                          <CustomDatesRender key={index?.toString()} dates={el} onDeleteDate={onDeleteDate} />
                        ))}
                      </div>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem
                        dataField="applicable_tariffs"
                        cssClass="tag-box-input"
                        editorType="dxTagBox"
                        editorOptions={tagEditorOptions(pricesList, 'title_fr', true)}
                      >
                        <Label text={t('discount.applicable_special_rates')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem
                        dataField="available_week_days"
                        cssClass="tag-box-input"
                        editorType="dxTagBox"
                        editorOptions={tagEditorOptions(weekDays, 'name', true)}
                      >
                        <Label text={t('price.valid_date')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem
                        dataField="valid_for_holidays"
                        editorType="dxCheckBox"
                        editorOptions={checkBoxOptions(t('discount.valid_holidays'))}
                      >
                        <Label visible={false} />
                      </SimpleItem>
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab disabled={!items.changes_tariff} title={t('discount.add_parms_unavailability')}>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="not_available_periods"
                      cssClass="tag-box-input"
                      editorType="dxTagBox"
                      editorOptions={tagEditorOptions(periodesList, 'label', true)}
                    >
                      <Label text={t('price.periods')} visible={false} />
                    </SimpleItem>
                    <GroupItem cssClass="group-item centerItem" colCount={1}>
                      <div className="customDates">
                        <Button text={t('discount.add_date')} hint="Add" icon="plus" onClick={showModalNotAvailable} />
                        {listCustomDateNotAvailable.map((el, index) => (
                          <CustomDatesRender
                            key={index?.toString()}
                            dates={el}
                            onDeleteDate={onDeleteDateNotAvailable}
                          />
                        ))}
                      </div>
                    </GroupItem>
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Enregistrer'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
        <AddPopup
          popupVisible={popupVisible}
          hideModal={hideDateModal}
          title={t('discount.add_special_date')}
          component={
            <SelectMultipleDates
              listCustomDate={listCustomDate}
              onHandleCustomDate={onHandleCustomDate}
              hideModal={hideDateModal}
              t={t}
            />
          }
          width="60%"
        />
        <AddPopup
          popupVisible={popupVisibleNotAvailable}
          hideModal={hideDateModalNotAvailable}
          title={t('discount.add_special_date')}
          component={
            <SelectMultipleDates
              listCustomDate={listCustomDateNotAvailable}
              onHandleCustomDate={onHandleCustomDateNotAvailbale}
              hideModal={hideDateModalNotAvailable}
              t={t}
            />
          }
          width="60%"
        />
      </div>
    </>
  );
}
