import React, { useEffect, useState } from 'react';
import DataGrid, { Column, Pager, Paging, LoadPanel, Sorting, Editing, Button } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const paymentRapport = [
  {
    id: 1,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Balance',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: 'Paiement en ligne',
  },
  {
    id: 2,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Balance',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: 'Paiement en ligne',
  },
  {
    id: 3,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Balance',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: '',
  },
  {
    id: 4,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Total',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: 'Paiement en ligne',
  },
  {
    id: 5,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: '',
  },
  {
    id: 6,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: '',
  },
  {
    id: 7,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: '',
  },
  {
    id: 8,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: '',
  },
  {
    id: 9,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: '',
  },
  {
    id: 10,
    date: '7 septembre 2020',
    residence: 'MER-314',
    client: 'Josée St-Arneault',
    sejour: '23 juillet 2021(7 nuits)',
    type: 'Dépôt',
    montant: '2 228,21 $ (Frais: 0.00 $)',
    creation: '8 mars 2021',
    online_payment: '',
  },
];

export default function PendingPayments() {
  const history = useHistory();
  const { t } = useTranslation();
  const [customDataSource, setCustomDataSource] = useState([]);

  const onCellPrepared = (e) => {
    if (e.rowType === 'data' && e.column.dataField === 'color') {
      if (e.data.id === 1 || e.data.id === 2 || e.data.id === 4 || e.data.id === 5) {
        e.cellElement.style.backgroundColor = '#da4f49';
      } else if (e.data.id === 3 || e.data.id === 7 || e.data.id === 8) {
        e.cellElement.style.backgroundColor = '#faa732';
      } else {
        e.cellElement.style.backgroundColor = '#eee';
      }
    }
  };

  useEffect(() => {
    setCustomDataSource(paymentRapport);
    // getAll('payment_pending').then((response) => {
    //   setCustomDataSource(response.data.data);
    // });
  }, []);

  return (
    <>
      <h2 className="content-block">{t('payment.pending_payment')}</h2>
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
        onCellPrepared={(e) => onCellPrepared(e)}
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="color" alignment="left" width={10} />
        <Column dataField="residence" caption="RÉSIDENCE" />
        <Column dataField="client" caption="CLIENT" />
        <Column dataField="sejour" caption="SÉJOUR" />
        <Column dataField="montant" caption="MONTANT" />
        <Column dataField="date" caption="DÛ LE" />
        <Column dataField="creation" caption="CRÉATION" />
        <Column dataField="type" caption="TYPE" />
        <Column dataField="online_payment" caption="MODE DE PAIEMENT" />
        <Column type="buttons">
          <Button hint={t('payment.payment_hint_4')} icon="check" onClick={(e) => console.log('check')} />
          <Button hint={t('payment.payment_hint_5')} icon="email" onClick={(e) => console.log('mail')} />
          <Button
            hint={t('payment.payment_hint_3')}
            icon="fa fa-eye"
            onClick={(e) => history.push(`/reservation/${e.row.data.id}`)}
          />
          <Button text="Note" onClick={(e) => console.log('note')} />
        </Column>
      </DataGrid>
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
