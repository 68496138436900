import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  Button,
  LoadPanel,
  Sorting,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import DeletePopup from '../../../../components/delete-popup/delete-popup';
import { priceColumn_customizeText } from '../../../../utils';
import AddPopup from '../../../../components/add-popup/add-popup';
import PayeeFacturePopupForm from '../../../../components/form/facture-payee';

export default function FacturetionsListing({ customDataSource, setCustomDataSource, residenceId }) {
  const history = useHistory();
  const { t } = useTranslation();

  const [popupVisible, setPopupVisible] = useState(false);

  const [popupAppVisible, setPopupAppVisible] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [appData, setappData] = useState({ id: null, app: false });

  const popupDetailsList = [
    {
      title: t('facturations.transfer_title'),
      msg: t('facturations.transfer_msg'),
      deleteActivity: () => applicredit(),
      textBtn: t('facturations.transfer_btn'),
      typeBtn: 'success',
    },
    {
      title: t('facturations.no_transfer_title'),
      msg: t('facturations.no_transfer_msg'),
      deleteActivity: () => applicredit(),
      textBtn: t('facturations.no_transfer_btn'),
      typeBtn: 'default',
    },
    {
      title: t('facturations.no_payee_title'),
      msg: t('facturations.no_payee_msg'),
      deleteActivity: () => console.log('impayé'),
      textBtn: t('facturations.no_payee_btn'),
      typeBtn: 'default',
    },
  ];

  const showAppModal = (e) => {
    if (e.rowType === 'data' && e.column.dataField === 'transf') {
      setPopupAppVisible(true);
      setappData({ id: e.row.data.id, app: e.row.data.transf });
      setPopupDetail(e.row.data.transf ? popupDetailsList[1] : popupDetailsList[0]);
    }
    if (e.rowType === 'data' && e.column.dataField === 'payee') {
      if (e.row.data.payee) {
        setPopupAppVisible(true);
        setPopupDetail(popupDetailsList[2]);
      } else {
        setPopupVisible(true);
      }
    }
  };

  const applicredit = () => {
    // @ts-ignore
    setCustomDataSource((state) =>
      state.map((item) => {
        if (item.id === appData.id) {
          return { ...item, transf: !appData.app };
        }
        return item;
      })
    );
    setPopupAppVisible(false);
  };

  return (
    <>
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
        onCellClick={showAppModal}
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="payee" caption="PAY." />
        <Column dataField="transf" caption="TRANSF." />
        <Column dataField="number" caption="# FACTURE" />
        <Column dataField="titre" caption="TITRE" />
        <Column dataField="brut" caption="BRUT" alignment="left" customizeText={priceColumn_customizeText} />
        <Column dataField="tps" caption="TPS" alignment="left" customizeText={priceColumn_customizeText} />
        <Column dataField="tvq" caption="TVQ" alignment="left" customizeText={priceColumn_customizeText} />
        <Column dataField="total" caption="TOTAL" alignment="left" customizeText={priceColumn_customizeText} />
        <Column type="buttons">
          <Button
            hint="Consulter"
            icon="fa fa-eye"
            onClick={(e) => history.push(`/residences/facture/${residenceId}/${e.row.data.id}`)}
          />
        </Column>
        <Summary>
          <TotalItem column="titre" displayFormat="Total:" />
          <TotalItem column="brut" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
          <TotalItem column="tps" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
          <TotalItem column="tvq" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
          <TotalItem column="total" summaryType="sum" valueFormat="decimal" displayFormat="{0} $" />
        </Summary>
      </DataGrid>
      <DeletePopup
        popupVisible={popupAppVisible}
        hideModal={() => setPopupAppVisible(false)}
        title={popupDetail.title}
        msg={popupDetail.msg}
        deleteActivity={popupDetail.deleteActivity}
        textBtn={popupDetail.textBtn}
        typeBtn={popupDetail.typeBtn}
      />
      <AddPopup
        popupVisible={popupVisible}
        hideModal={() => setPopupVisible(false)}
        title={t('facturations.payee_title')}
        component={<PayeeFacturePopupForm hideModal={() => setPopupVisible(false)} />}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];

FacturetionsListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
  residenceId: PropTypes.number.isRequired,
  setCustomDataSource: PropTypes.func.isRequired,
};
