import React, { useEffect, useState } from 'react';
import './pages.scss';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataGrid, { Sorting, Column, Editing, Button } from 'devextreme-react/data-grid';
import PermissionComponent from '../../permissions/permissionComponent';
import { getAll, remove } from '../../api';
import Hero from '../../components/hero/hero';
import { useAuth } from '../../contexts/auth';
import { getPermissionVisibility, Toast } from '../../utils';
import DeletePopup from '../../components/delete-popup/delete-popup';

export default function () {
  const { t } = useTranslation();
  const history = useHistory();
  const [customDataSource, setCustomDataSource] = useState([]);
  const [popupVisible, setPopupVisible] = useState({ status: false, id: null });
  const { auth } = useAuth();
  const PermissionsList = auth.permissions;
  const data = [
    {
      id: 1,
      name_fr: 'Page 1',
    },
    {
      id: 2,
      name_fr: 'Page 2',
    },
    {
      id: 3,
      name_fr: 'Page 3',
    },
  ];
  const onDelete = () => {
    hideModal();
    remove('pages', popupVisible.id).then((res) => {
      if (res.status === 204) {
        Toast(t('pages.successDeletePage'));
        setCustomDataSource((state) => state.filter((el) => el.id !== popupVisible.id));
        hideModal();
      }
    });
  };
  const hideModal = () => {
    setPopupVisible({ status: false, id: null });
  };

  useEffect(() => {
    /*   getAll(`activities`).then((response) => {
      setCustomDataSource(response.data.data);
    });
    */
    setCustomDataSource(data);
  }, []);

  return (
    <>
      <PermissionComponent
        scope="createActivity"
        rightchildren={<Hero title={t('pages.pages')} btnText={t('pages.add_page')} url="/page/ajouter" />}
        nonrightchildren={<h2 className="content-block">{t('pages.pages')}</h2>}
      />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
        loadPanel={{ enabled: true }}
      >
        <Editing mode="row" useIcons />
        <Sorting disabled={false} />
        <Column dataField="name_fr" caption="Nom" />
        <Column type="buttons" width={110}>
          <Button
            hint="Modifier"
            icon="edit"
            visible={getPermissionVisibility(PermissionsList, 'updateActivity')}
            onClick={(e) => history.push(`/page/modifier/${e.row.data.id}`)}
          />
          <Button
            hint={t('common.delete')}
            icon="fa fa-trash"
            onClick={(e) => setPopupVisible({ status: true, id: e.row.data.id })}
          />
        </Column>
      </DataGrid>

      <DeletePopup
        popupVisible={popupVisible.status}
        hideModal={hideModal}
        msg={t('pages.deletion_msg')}
        title={t('pages.deletion_title')}
        width={500}
        deleteActivity={() => {
          onDelete();
        }}
      />
    </>
  );
}
