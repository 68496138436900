import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

export default function ProtectedRoute({ roles, redirectPath, routeChildren }) {
  const { auth } = useAuth();
  const { role } = auth;
  const permission = roles.includes(role);
  if (!permission) {
    return <Redirect to={redirectPath} />;
  }

  return routeChildren;
}

ProtectedRoute.propTypes = {
  roles: PropTypes.array.isRequired,
  redirectPath: PropTypes.string,
  routeChildren: PropTypes.element.isRequired,
};

ProtectedRoute.defaultProps = {
  redirectPath: '/home',
};
