export const requests = [
  {
    ID: 1,
    time: 'Il y a 13 heures',
    title: 'Demande de quebeclocationschalets.com',
    description: 'Hanierh aimerait réserver MON-71 pour 5 nuits à partir du 8 aout 2022',
    client: 'Marie Lou Giard ',
    residanceName: 'FRE-18',
    residanceSubName: '(Chalet de Mon Frère)',
    nights: '13 nuits',
    start_at: '23 juillet 2023',
  },
  {
    ID: 2,
    time: 'Il y a 3 jours',
    title: "Demande d'information",
    description: 'John aimerait réserver ANC-71-2 pour 7 nuits à partir du 25 septembre 2022',
    client: 'Marie Lou Giard ',
    residanceName: 'FRE-18',
    residanceSubName: '(Chalet de Mon Frère)',
    nights: '13 nuits',
    start_at: '23 juillet 2023',
  },
];
