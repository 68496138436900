/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Form, {
    SimpleItem,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    GroupItem,
    TabbedItem,
    TabPanelOptions,
    Tab,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import RenderHeaderPage from '../../widgets/headerResidance';
import WidgetImage from '../../widgets/mainImage';
import WidgetActions from '../../widgets/actions';
import { create, get, updateWithMedia, remove } from '../../../api';
import { ImageWithEventEditorOptions } from './input-options';
import { permalinkEditorOptions, textEditorOptions, ImageEditorOptions } from '../../../utils/editorOptions';
import DeletePopup from '../../delete-popup/delete-popup';
import { getPermissionVisibility, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';
import './header_image.scss'

export default function HeaderImagesForm() {
    /* -------------------------------------------------------------------------- */
    /*                                    HOOKS                                   */
    /* -------------------------------------------------------------------------- */
    const history = useHistory();
    const { t } = useTranslation();
    const { auth } = useAuth();
    const { role } = auth;

    const { id } = useParams();
    const isAddMode = !id;
    const [loading, setLoading] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const refForm = useRef('groupCityForm');
    const [items, setItems] = useState({});
    const [isPhoto, setIsPhoto] = useState(false);
    const [deleteID, setDeleteID] = useState('');
    const [deleteName, setDeleteName] = useState('');
    const [selectPhoto, setSelectPhoto] = useState('');
    /* -------------------------------------------------------------------------- */
    /*                                  HELPERS                                  */
    /* -------------------------------------------------------------------------- */

    const hideModal = () => {
        setDeleteID(id);
        setPopupVisible(false);
    };
    const deletePhoto = (idPhoto) => {
        remove('header_pictures', idPhoto).then(() => {
            hideModal();
            Toast(t('headerImg.successDeletePage'));
            history.push('/entetes');
        });
    };

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            const { photo, ...rest } = items;
            const values = {
                ...rest,
            };
            // eslint-disable-next-line prefer-destructuring
            if (photo?.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                values.photo = photo[0];
            }

            setLoading(true);
            const formData = new FormData();
            Object.keys(values).map((key) => values[key] != null && formData.append(key, values[key]));
            return isAddMode ? addEntete(formData) : updateEntete(id, formData);
        },
        [items]
    );

    const addEntete = (values) => {
        create('header_pictures', values)
            .then((res) => {
                setLoading(false);
                res.data && history.push('/entetes');
                res.data && Toast('La photo a été bien ajoutée');
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const updateEntete = (photoId, values) => {
        values.append('_method', 'PUT');
        updateWithMedia('header_pictures', photoId, values, { 'Content-Type': 'multipart/form-data' })
            .then((res) => {
                setLoading(false);
                res.data && history.push('/entetes');
                res.data && Toast(t('headerImg.successEditPage'));
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const formFieldDataChanged = (e) => {
        const data = e.component.option('formData');
        setItems(data);
    };

    /* -------------------------------------------------------------------------- */
    /*                                  USEEFFECT                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        if (!isAddMode) {
            (async () => {
                get('header_pictures', id).then((response) => {
                    const { data } = response.data;
                    const {
                        photo,
                        ...rest
                    } = data;
                    if (data) {
                        setItems(rest);
                        setSelectPhoto(

                            photo

                        );
                    };
                });
            })()
        }
    }, [id]);
    const dataList = {
        official_name: 'Nom title',
        name: 'Nom'
    };
    const listActions = [
        {
            id: 1,
            name: t('headerImg.backBtn'),
            icon: 'fa fa-arrow-left',
            onPress: () => history.push('/entetes'),
            styles: ' blueBtnBackground actionBtn btnForWithBackGrounColor',
            display: true,
        },
        {
            id: 2,
            name: t('headerImg.deletion_title'),
            icon: 'fa fa-trash',
            onPress: () => setPopupVisible(true),
            styles: 'redBtnBackground actionBtn btnForWithBackGrounColor',
            display: !isAddMode,
        },
    ]
    /* -------------------------------------------------------------------------- */
    /*                                   RENDER                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <>
            <Button
                className="goback-btn"
                icon="chevronprev"
                text={t('headerImg.backBtn')}
                type="default"
                stylingMode="text"
                onClick={() => history.push('/entetes')}
            />
            <div className="content-block header_image">
                <RenderHeaderPage t={t} id="" name={isAddMode ? t('headerImg.add_page') : t('headerImg.edit_page')} officialName={null} />
                <div className="mainContainer">
                    <div className="side">
                        <div className="dx-card responsive-paddings-widget widget">

                            {selectPhoto ?
                                (
                                    <>
                                        <div className="widget_item_title">
                                            <span>Photo Actuelle</span>
                                        </div>
                                        <img
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                            }}
                                            alt={items.title}
                                            src={selectPhoto ?? ''}
                                        />
                                    </>
                                ) :
                                (<div className="widget_item_title header_image_icon">
                                    <i className="fa fa-image" />
                                </div>)
                            }
                        </div>
                        <WidgetActions title="Actions" ListAction={listActions} t={t} dviderIndexs={[]} />
                    </div>
                    <div className="content-block">
                        <div className="dx-card responsive-paddings">
                            <form onSubmit={onSubmit}>
                                <Form formData={items} labelLocation="left" disabled={loading}>
                                    <TabbedItem>
                                        <TabPanelOptions deferRendering={false} />
                                        <Tab title="Information">
                                            <GroupItem cssClass="group-item" colCount={2}>
                                                <SimpleItem dataField="title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                                                    <RequiredRule message={t('headerImg.require_title')} />
                                                    <Label text={t('headerImg.title')} visible={false} />
                                                </SimpleItem>
                                            </GroupItem>
                                            {
                                                isAddMode ? (
                                                    <GroupItem cssClass="group-item" caption="Photo">
                                                        <SimpleItem dataField="photo" editorType="dxFileUploader" editorOptions={ImageEditorOptions}>
                                                            <RequiredRule message={t('headerImg.require_img')} />
                                                            <Label text={t('headerImg.photo')} visible={false} />
                                                        </SimpleItem>
                                                    </GroupItem>
                                                ) : (
                                                    <SimpleItem dataField="photo" editorType="dxFileUploader" editorOptions={ImageWithEventEditorOptions(setIsPhoto)}>
                                                        {!selectPhoto && (
                                                            <RequiredRule message={t('headerImg.require_img')} />
                                                        )}
                                                        <Label text={t('headerImg.photo')} visible={false} />
                                                    </SimpleItem>
                                                )
                                            }
                                            {isPhoto && !!selectPhoto && (
                                                <GroupItem>
                                                    <div className="content-block">
                                                        <img src={selectPhoto} alt={t('activity.main_photo')} width="150" height="150" />
                                                    </div>
                                                </GroupItem>
                                            )}
                                        </Tab>
                                    </TabbedItem>
                                    <GroupItem colCount={3}>
                                        <ButtonItem>
                                            <ButtonOptions width="100%" type="default" useSubmitBehavior>
                                                <span className="dx-button-text">
                                                    {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                                                </span>
                                            </ButtonOptions>
                                        </ButtonItem>
                                        <ButtonItem>
                                            <ButtonOptions width="100%" type="normal" onClick={() => history.push('/entetes')}>
                                                <span className="dx-button-text">{t('common.cancel')}</span>
                                            </ButtonOptions>
                                        </ButtonItem>
                                    </GroupItem>
                                </Form>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <DeletePopup
                popupVisible={popupVisible}
                hideModal={hideModal}
                title={t('headerImg.deletion_title')}
                msg={`${t('headerImg.deletion_msg')} ?`}
                deleteActivity={() => deletePhoto(id)}
            />

        </>
    );
}
