import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Button } from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/auth';
import { getPermissionVisibility, Toast } from '../../utils';
import { getAll, remove } from '../../api';
import DeletePopup from '../delete-popup/delete-popup';

export default function ExtractsListing() {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const history = useHistory();
  const { auth } = useAuth();
  const [customDataSource, setCustomDataSource] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const PermissionsList = auth.permissions;
  const { t } = useTranslation();
  const [popupVisible, setPopupVisible] = useState({ status: false, id: null });

  const onDelete = () => {
    remove('snippets', popupVisible.id).then((res) => {
      if (res.status === 204) {
        Toast(t('common.successDeleteExtact'));
        setCustomDataSource((state) => state.filter((el) => el.id !== popupVisible.id));
        hideModal();
      }
    });
  };

  const hideModal = () => {
    setPopupVisible({ status: false, id: null });
  };
  /* -------------------------------------------------------------------------- */
  /*                                  USEEFFECT                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAll('snippets').then(({ data: { data } }) => setCustomDataSource(data));
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
        loadPanel={{ enabled: true }}
      >
        <Editing mode="row" useIcons />
        <Column dataField="title_fr" caption="Titre" />
        <Column type="buttons" width={110}>
          <Button
            hint="Modifier"
            icon="edit"
            visible={getPermissionVisibility(PermissionsList, 'updateResidence')}
            onClick={(e) => history.push(`/extrait/${e.row.data.id}`)}
          />
          <Button
            hint="Supprimer"
            icon="fa fa-trash"
            onClick={(e) => setPopupVisible({ status: true, id: e.row.data.id })}
          />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible.status}
        hideModal={hideModal}
        msg={t('residance.deletion_msg_extct')}
        title={t('residance.deletion_Title_extct')}
        deleteActivity={() => {
          onDelete();
        }}
      />
    </div>
  );
}
