import React, { useState, createContext, useContext } from 'react';
import { useCookie } from 'react-use';
import { getUserAuth, getTokenFromCookie } from '../token/Auth';
/* eslint-disable react/jsx-no-constructed-context-values */
function AuthProvider(props) {
  const [auth, setAuth] = useState({
    userId: getUserAuth().userId,
    userName: getUserAuth().userName,
    role: getUserAuth().role,
    permissions: getUserAuth().permissions,
    getAuth: !!getTokenFromCookie(),
  });
  const [value, updateCookie, deleteCookie] = useCookie('authentification-cookie');

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        updateCookie,
        deleteCookie,
      }}
      {...props}
    />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
