import React from 'react';
import PropTypes from 'prop-types';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Button } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import { priceColumn_customizeText, getPermissionVisibility } from '../../../utils';

export default function ReservationsListing({ customDataSource }) {
  const history = useHistory();
  const { auth } = useAuth();
  const PermissionsList = auth.permissions;
  return (
    <DataGrid
      className="dx-card wide-card"
      dataSource={customDataSource}
      showBorders
      remoteOperations
      defaultFocusedRowIndex={0}
      columnAutoWidth
      columnHidingEnabled
    >
      <Editing mode="row" useIcons />

      <Column dataField="id" caption="ID" width={90} cssClass="column_ID" />
      <Column dataField="reservation_number" caption="N° RÉSERVATION" />
      <Column dataField="residence_name" caption="RÉSIDENCE" />
      <Column dataField="client_name" caption="CLIENT" />
      <Column dataField="start_at" caption="ARRIVÉE" />
      <Column dataField="nights" caption="DURÉE" cssClass="column_ID" />
      <Column dataField="total_price" caption="PRIX" customizeText={priceColumn_customizeText} cssClass="column_ID" />
      <Column type="buttons" width={110}>
        <Button hint="Consulter" icon="fa fa-eye" onClick={(e) => history.push(`/reservation/${e.row.data.id}`)} />
        {getPermissionVisibility(PermissionsList, 'updateReservation') && (
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/reservation/update/${e.row.data.id}`)} />
        )}
      </Column>
    </DataGrid>
  );
}

ReservationsListing.propTypes = {
  customDataSource: PropTypes.array.isRequired,
};
