import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel, RowDragging } from 'devextreme-react/data-grid';
import './categorieActivites.scss';
import Hero from '../../../components/hero/hero';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { getAll, remove, create } from '../../../api';
import { getPermissionVisibility, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';
import PermissionComponent from '../../../permissions/permissionComponent';

export default function categorieActivites() {
  const history = useHistory();

  const { auth } = useAuth();
  const PermissionsList = auth.permissions;

  const [popupVisible, setPopupVisible] = useState(false);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [deleteID, setDeleteID] = useState('');
  const [deleteName, setDeleteName] = useState('');
  const ref = useRef(null);

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
    setDeleteName(e.row.data.name_fr);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteCateg = (idAct) => {
    remove('activity_categories', idAct).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== idAct));
      hideModal();
      Toast('La catégorie a été bien supprimée');
    });

    hideModal();
    Toast('La catégorie a été bien supprimée');
  };

  useEffect(() => {
    getAll('activity_categories').then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            DRAG AND DROP METHODS                           */
  /* -------------------------------------------------------------------------- */
  const onDragStart = (e) => {
    const tasks = customDataSource;
    e.itemData = tasks[e.fromIndex];
  };
  const onAdd = (e) => {
    setCustomDataSource((state) => [...state.slice(0, e.toIndex), e.itemData, ...state.slice(e.toIndex)]);
    handleUpdateOrder();
  };

  const onRemove = (e) => {
    setCustomDataSource((state) => [...state.slice(0, e.fromIndex), ...state.slice(e.fromIndex + 1)]);
  };

  const onReorder = (e) => {
    onRemove(e);
    onAdd(e);
  };
  const handleUpdateOrder = () => {
    const newOrderdList = ref.current?.props?.dataSource;
    const odredData = newOrderdList.map((el, index) => {
      return { id: el.id, order: index + 1 };
    });
    const formData = new FormData();
    formData.append('list', JSON.stringify(odredData));
    formData.append('type', 'activityCategory');
    create('/order', formData);
  };

  return (
    <>
      <PermissionComponent
        scope="createActivityCategory"
        rightchildren={<Hero title="Catégories des activités" btnText="Ajouter une catégorie" url="/categ_activity" />}
        nonrightchildren={<h2 className="content-block">Catégories des activités</h2>}
      />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        keyExpr="id"
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
        ref={ref}
      >
        <RowDragging
          allowReordering
          keyExpr="id"
          onDragStart={onDragStart}
          onAdd={onAdd}
          onRemove={onRemove}
          onReorder={onReorder}
          group="data"
          data="customDataSource"
        />
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} />
        <Column dataField="name_fr" caption="Nom" />
        <Column type="buttons" width={110}>
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/categ_activity/${e.row.data.id}`)} />
          <Button
            icon="trash"
            hint="Supprimer"
            visible={getPermissionVisibility(PermissionsList, 'deleteActivityCategory')}
            onClick={showModal}
          />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={"Suppression d'une catégorie"}
        msg={`Êtes-vous sûr de vouloir supprimer cette catégorie "${deleteName && !!deleteName ? deleteName : ''}"?`}
        deleteActivity={() => deleteCateg(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
