/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { get } from '../../../../../api';
import DeletePopup from '../../../../../components/delete-popup/delete-popup';
import { Toast } from '../../../../../utils';
import MontantListing from './MontantListing';
import PriceListing from './PriceListing/PriceListing';

export default function FacturesDetails() {
  const { t } = useTranslation();
  const { id: reservationId, itemId: factureId } = useParams();
  const history = useHistory();
  const [factureDetails, setFactureDetails] = useState({});
  const [reservationDetails, setReservationDetails] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  const [isGenerate, setIsGenerate] = useState(false);

  const showModal = () => {
    setPopupVisible(true);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteFacture = (idFacture) => {
    hideModal();
    Toast(t('common.successDelete', { title: 'La facture' }));
    history.push(`/reservation/factures/${reservationId}`);
    // remove('factures', idFacture).then(() => {
    //   hideModal();
    //   Toast(t('common.successDelete', { title: 'La facture' }));
    //   history.push(`/reservation/factures/${reservationId}`)
    // });
  };

  useEffect(() => {
    // get(`factures/${reservationId}`, factureId).then((response) => {
    //   setFactureDetails(response.data.data);
    // });
    get('reservations', reservationId).then((response) => {
      setReservationDetails(response.data.data);
    });
  }, [factureId, reservationId]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('factures.go_back_factures')}
        type="default"
        stylingMode="text"
        onClick={() => history.push(`/reservation/factures/${reservationId}`)}
      />
      <h2 className="content-block">
        {t('reservation.header', {
          residanceName: reservationDetails?.residence_name,
          client: `${reservationDetails?.client?.first_name} ${reservationDetails?.client?.last_name}`,
        })}
      </h2>
      <div className="content-block client">
        <div className="client_item client_item-actions">
          <div className="dx-card responsive-paddings-widget widget">
            <div className="widget_item_title">
              <i className="fa fa-edit" />
              {t('common.actions')}
            </div>
            <div className="client_btn containerBtnsActions">
              <Button
                icon="clock"
                type="default"
                text={t('factures.see_reservation')}
                onClick={() => {
                  history.push(`/reservation/${reservationId}`);
                }}
              />
              <Button
                icon="file"
                type="default"
                text={t('factures.see_factures')}
                onClick={() => {
                  history.push(`/reservation/factures/${reservationId}`);
                }}
              />
              <hr className="barre" />
              <Button
                icon="fa fa-magic"
                type="normal"
                text={t('factures.manage_facture')}
                onClick={() => setIsGenerate(true)}
              />
              {isGenerate && (
                <>
                  <Button icon="fa fa-eye" type="normal" text={t('factures.consulter_facture')} onClick={() => {}} />
                  <Button icon="email" type="normal" text={t('factures.send_facture')} onClick={() => {}} />
                </>
              )}
              <hr className="barre" />
              <Button
                className="btn-edit"
                icon="edit"
                type="default"
                text={t('factures.edit_facture')}
                onClick={() => {
                  history.push(`/reservation/factures/modifier/${reservationId}/${factureId}`);
                }}
              />
              <hr className="barre" />
              <Button icon="trash" type="danger" text={t('factures.delete_facture')} onClick={showModal} />
            </div>
          </div>
        </div>
        <div className="dx-card responsive-paddings-widget widget client_item client_item-info">
          <div className="widget_item_title">
            <i className="fa fa-file" />
            {t('factures.facture_title')} - Normale
          </div>
          <div className="facture_client_details">
            <p>
              {reservationDetails?.client?.gender === 'm'
                ? 'M.'
                : reservationDetails?.client?.gender === 'mme'
                ? 'Mme.'
                : ''}{' '}
              {reservationDetails?.client?.first_name} {reservationDetails?.client?.last_name}
            </p>
            <p>{reservationDetails?.client?.address}</p>
            <p>
              {reservationDetails?.client?.city}, {reservationDetails?.client?.province}
            </p>
            <p>{reservationDetails?.client?.postal_code}</p>
          </div>
          <div>
            <MontantListing factureId={factureId} reservationId={reservationId} />
          </div>
          <PriceListing />
        </div>
      </div>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={`${t('factures.additional_invoices_delete')}${factureDetails?.number}`}
        msg={t('factures.additional_invoices_delete_msg')}
        deleteActivity={() => deleteFacture(factureId)}
        width={500}
      />
    </>
  );
}
