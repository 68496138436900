import React from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Pager, Paging, FilterRow, Button, Editing } from 'devextreme-react/data-grid';
import { initCustomStore } from '../../data-layer/custom-store/client';
import './clients.scss';
import Hero from '../../components/hero/hero';

export default function Clients() {
  const history = useHistory();
  return (
    <>
      <Hero title="Clients" btnText="Ajouter un client" url="/client" />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <FilterRow visible />
        <Editing mode="row" useIcons />

        <Column
          dataField="id"
          caption="ID"
          defaultSortOrder="desc"
          cssClass="column_ID"
          filterOperations={filterOperations}
        />
        <Column dataField="first_name" caption="Prénom" filterOperations={filterOperations} />
        <Column dataField="last_name" caption="Nom" filterOperations={filterOperations} />
        <Column dataField="email" caption="Courriel" filterOperations={filterOperations} />
        <Column dataField="number" caption="Téléphone" filterOperations={filterOperations} />
        <Column type="buttons" width={110}>
          <Button hint="Consulter" icon="fa fa-eye" onClick={(e) => history.push(`/clients/${e.row.data.id}`)} />
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/client/${e.row.data.id}`)} />
        </Column>
      </DataGrid>
    </>
  );
}

const customDataSource = initCustomStore('clients');

const filterOperations = ['contains'];

const allowedPageSizes = [5, 10, 20];
