import React, { useState, useRef, useCallback } from 'react';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, EmailRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import './login-form.scss';
import { signIn } from '../../../api/auth';
import { getUserAuth, getTokenFromCookie } from '../../../token/Auth';

export default function LoginForm() {
  const { setAuth, updateCookie } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { email, password } = formData.current;
      setLoading(true);

      signIn(email, password)
        .then((res) => {
          setLoading(false);
          if (res.data.token) {
            const userAuth = {
              userId: res.data.user.id,
              userName: `${res.data.user.first_name} ${res.data.user.last_name}`,
              role: res.data.user.role,
              permissions: res.data.user.permissions,
            };
            localStorage.setItem('userAuth', JSON.stringify(userAuth));
            updateCookie(res.data.token);
            setAuth({
              userId: getUserAuth().userId,
              userName: getUserAuth().userName,
              role: getUserAuth().role,
              permissions: getUserAuth().permissions,
              getAuth: !!getTokenFromCookie(),
            });
            history.push('/home');
          }
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [signIn]
  );

  return (
    <form className="login-form" onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item dataField="email" editorType="dxTextBox" editorOptions={emailEditorOptions}>
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <Item dataField="password" editorType="dxTextBox" editorOptions={passwordEditorOptions}>
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width="100%" type="default" useSubmitBehavior>
            <span className="dx-button-text">
              {loading ? <LoadIndicator width="24px" height="24px" visible /> : 'Sign In'}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
