/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import DataSource from 'devextreme/data/data_source';
import EditorInput from '../../editor-input/editor-input';
import './create-ville-form.scss';
import { create, get, getAll, update } from '../../../api';
import { permalinkEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import { getPermissionVisibility, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';
import useGetMergTags from '../../../hooks/useGetTags';
import { checkBoxOptions } from '../residence-form/input-options';

export default function CreateCategorieActivitesForm() {
  const history = useHistory();

  const { auth } = useAuth();
  const { role } = auth;
  const { t } = useTranslation();
  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [valueContentFR, setValueContentFR] = useState('');
  const [valueContentEN, setValueContentEN] = useState('');
  const [listVersion, setListVersion] = useState('false');
  const refForm = useRef('createCategAcyivForm');
  const [items, setItems] = useState({});


  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (valueContentFR === '' || valueContentEN === '') {
        return notify('Tous les champs français et anglais sont requis', 'error', 4000);
      }
      const { name_fr, name_en, seo_fr, seo_en, ...rest } = items;
      const seo =
        seo_fr || seo_en
          ? [
            { ...seo_fr, language: 'fr' },
            { ...seo_en, language: 'en' },
          ]
          : [];
      const values = {
        ...rest,
        texts: JSON.stringify([
          {
            language: 'fr',
            type: 'name',
            value: name_fr
          },
          {
            language: 'en',
            type: 'name',
            value: name_en
          },
          {
            language: 'fr',
            type: 'text',
            value: valueContentFR,
          },
          {
            language: 'en',
            type: 'text',
            value: valueContentEN,
          },
        ]),
        seo: JSON.stringify(seo),
      };
      setLoading(true);
      return isAddMode ? addCateg(values) : updateCategById(id, values);
    },
    [items, valueContentEN, valueContentFR, listVersion]
  );

  const addCateg = (values) => {
    create('activity_categories', values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/categ_activites');
        res.data && Toast('La catégorie a été bien ajoutée');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateCategById = (actId, values) => {
    update('activity_categories', actId, values)
      .then((res) => {
        setLoading(false);
        res.data && history.push('/categ_activites');
        res.data && Toast('La catégorie a été bien modifiée');
        Toast('La catégorie a été bien modifiée');
      })
      .catch(() => {
        setLoading(false);
      })
  };

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  useEffect(() => {
    if (!isAddMode) {
      get('activity_categories', id).then((response) => {
        const { data } = response.data;
        const { text_fr, text_en } = data;
        setItems(data);
        setValueContentFR(text_fr ?? '');
        setValueContentEN(text_en ?? '');
      });
    }
  }, [id]);

  return (
    <>
      <Button
        className="goback-btn"
        icon="chevronprev"
        text="Retour à la page Catégories"
        type="default"
        stylingMode="text"
        onClick={() => history.push('/categ_activites')}
      />
      <h2 className="content-block">{isAddMode ? t('activity.ajout_cat') : t('activity.mofif_cat')}</h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form ref={refForm} formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}
              showValidationSummary>
              <TabbedItem>
                <TabPanelOptions
                  deferRendering={false}
                  selectedIndex={getPermissionVisibility(['webmaster'], role)}
                />
                <Tab title="Français" disabled={getPermissionVisibility(['webmaster'], role)}>
                  <SimpleItem dataField="name_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message={t('activity.activity_cat_name_fr')} />
                    <Label text={t('forms.labels.last_name')} visible={false} />
                  </SimpleItem>
                  <GroupItem>
                    <div className="dx-field-label">Description *</div>
                    <div className="dx-field-value editor_width">
                      <EditorInput valueContent={valueContentFR} valueChanged={setValueContentFR} />
                    </div>
                  </GroupItem>
                </Tab>
                <Tab title="Anglais" disabled={getPermissionVisibility(['webmaster'], role)}>
                  <SimpleItem dataField="name_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <RequiredRule message={t('activity.activity_cat_name_en')} />
                    <Label text={t('forms.labels.last_name')} visible={false} />
                  </SimpleItem>
                  <GroupItem>
                    <div className="dx-field-label">Description *</div>
                    <div className="dx-field-value editor_width">
                      <EditorInput valueContent={valueContentEN} valueChanged={setValueContentEN} />
                    </div>
                  </GroupItem>
                </Tab>
                <Tab title="SEO et options">
                  <SimpleItem dataField="seo_fr.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text={t('forms.labels.titre_fr')} visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_fr.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text={t('forms.labels.desc_fr')} visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_en.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text={t('forms.labels.titre_en')} visible={false} />
                  </SimpleItem>
                  <SimpleItem dataField="seo_en.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                    <Label text={t('forms.labels.desc_en')} visible={false} />
                  </SimpleItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/categ_activites')}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}
