import React, { useState, useCallback, useEffect } from 'react';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, GroupItem, RequiredRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { subDays } from 'date-fns';

export default function TransactionsSearchForm({ handleSearchValues, formValues }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({ debut: subDays(new Date(), 1), fin: new Date() });
  const [startValue, setStartValue] = useState(subDays(new Date(), 1));
  const [endValue, setEndValue] = useState(new Date());

  const minChangeHandler = function (e) {
    setStartValue(e.value);
  };

  const maxChangeHandler = function (e) {
    setEndValue(e.value);
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { debut, fin, ...rest } = items;
      const values = {
        ...rest,
      };
      if (debut) {
        values.debut = moment(debut).format('YYYY-MM-DD');
      }
      if (fin) {
        values.fin = moment(fin).format('YYYY-MM-DD');
      }
      return handleSearchValues(values);
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  const startDateBoxOptions = () => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: t('errors.date_format'),
      openOnFieldClick: true,
      showClearButton: true,
      value: startValue,
      onValueChanged: (e) => minChangeHandler(e),
      max: endValue,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  const endDateBoxOptions = () => {
    return {
      stylingMode: 'filled',
      labelMode: 'floating',
      invalidDateMessage: t('errors.date_format'),
      openOnFieldClick: true,
      showClearButton: true,
      value: endValue,
      onValueChanged: (e) => maxChangeHandler(e),
      min: startValue,
      displayFormat: 'yyyy-MM-dd',
    };
  };

  useEffect(() => {
    setItems(formValues);
  }, [formValues]);

  return (
    <div className="dx-card responsive-paddings">
      <div className="client_item_title search-res">
        <i className="fa fa-search" />
        {t('common.searching')}
      </div>
      <form onSubmit={onSubmit}>
        <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
          <SimpleItem dataField="debut" editorType="dxDateBox" editorOptions={startDateBoxOptions()}>
            <Label text="Début de la période" visible={false} />
            <RequiredRule message={t('errors.required_start_date')} />
          </SimpleItem>
          <SimpleItem dataField="fin" editorType="dxDateBox" editorOptions={endDateBoxOptions()}>
            <Label text="Fin de la période" visible={false} />
            <RequiredRule message={t('errors.required_end_date')} />
          </SimpleItem>
          <GroupItem cssClass="search-btn">
            <ButtonItem>
              <ButtonOptions width="100%" type="default" useSubmitBehavior>
                <span className="dx-button-text">
                  {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.search')}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
}

TransactionsSearchForm.propTypes = {
  handleSearchValues: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};
