import React from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Pager, Paging, FilterRow, Button, Editing, Sorting } from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import { initCustomStore } from '../../../data-layer/custom-store/client';
import './membres.scss';
import Hero from '../../../components/hero/hero';
import { getAll } from '../../../api';
import { Toast } from '../../../utils';

export default function MembresCorbeille() {
  const history = useHistory();
  const { t } = useTranslation();

  const downloadMembers = () => {
    getAll('users/csv-only-trashed', { 'Content-Type': 'text/csv' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'membres.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      res.data && Toast(t('members.export_member_msg'));
    });
  };

  return (
    <>
      <h2 className="content-block">Membres - Corbeille</h2>
      <div className="btn_ajout">
        <Hero
          title=""
          btnText={t('members.export_member')}
          type="normal"
          icon="download"
          onClickBtn={downloadMembers}
        />
      </div>
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <Sorting mode="none" />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <FilterRow visible />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" cssClass="column_ID" filterOperations={filterOperations} />
        <Column dataField="first_name" caption="Prénom" filterOperations={filterOperations} />
        <Column dataField="last_name" caption="Nom" filterOperations={filterOperations} />
        <Column dataField="email" caption="Courriel" filterOperations={filterOperations} />
        <Column dataField="number" caption="Téléphone" filterOperations={filterOperations} />
        <Column dataField="residences" caption="Residences" allowFiltering={false} />
        <Column type="buttons" width={110}>
          <Button hint="Consulter" icon="fa fa-eye" onClick={(e) => history.push(`/membres/${e.row.data.id}`)} />
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/membre/${e.row.data.id}`)} />
        </Column>
      </DataGrid>
    </>
  );
}

const customDataSource = initCustomStore('users?archive=1');

const filterOperations = ['contains'];

const allowedPageSizes = [5, 10, 20];
