import React, { useState, useCallback, useEffect } from 'react';
import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { textEditorOptions } from '../../../../utils/editorOptions';

export default function FacturesSearchForm({ handleSearchValues, formValues }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({});

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      return handleSearchValues(items);
    },
    [items]
  );

  const formFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  useEffect(() => {
    setItems(formValues);
  }, [formValues]);

  return (
    <div className="dx-card responsive-paddings">
      <div className="client_item_title search-res">
        <i className="fa fa-search" />
        {t('common.searching')}
      </div>
      <form onSubmit={onSubmit}>
        <Form formData={items} onFieldDataChanged={formFieldDataChanged} disabled={loading}>
          <SimpleItem dataField="number" editorType="dxTextBox" editorOptions={textEditorOptions}>
            <Label text="Numéro du Facture" visible={false} />
          </SimpleItem>
          <GroupItem cssClass="search-btn">
            <ButtonItem>
              <ButtonOptions width="100%" type="default" useSubmitBehavior>
                <span className="dx-button-text">
                  {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.search')}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
}

FacturesSearchForm.propTypes = {
  handleSearchValues: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};
