import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, LoadPanel, Sorting, Summary, TotalItem } from 'devextreme-react/data-grid';
import '../styles.scss';

export default function BalanceVerification({ dataSource }) {
  /* -------------------------------------------------------------------------- */
  /*                                    HOOKS                                   */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation();
  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */
  const renderGridCell = (data) => {
    const color = Number(data.value) >= 0 ? 'green' : 'red';
    return typeof data.value === 'number' ? <div style={{ color }}>{data.value} $</div> : data.value;
  };

  const renderTitleCell = (data) => {
    return t(`balance.${data.value}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  FAKEDATA                                  */
  /* -------------------------------------------------------------------------- */
  const refactoringData = () => {
    const newarray = [];

    dataSource?.map((el) => {
      const objectArray = Object.entries(el);
      return objectArray.forEach(([key, value]) => {
        newarray.push({ title: key, price: value });
      });
    });
    return newarray;
  };

  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="detailsItem">
      <DataGrid
        className="dx-card wide-card"
        dataSource={refactoringData()}
        showBorders
        defaultFocusedRowIndex={0}
        columnHidingEnabled
        showColumnHeaders={false}
      >
        <Sorting mode="none" />
        <LoadPanel enabled />
        <Column dataField="title" cellRender={renderTitleCell} />
        <Column dataField="price" cellRender={renderGridCell} alignment="right" />
        <Summary>
          <TotalItem cssClass="sum_balance" column="price" summaryType="sum" displayFormat="Total: {0} $" />
        </Summary>
      </DataGrid>
    </div>
  );
}

BalanceVerification.propTypes = {
  dataSource: PropTypes.array.isRequired,
};
