import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Pager, Paging, Button, LoadPanel } from 'devextreme-react/data-grid';
import './equipments.scss';
import Hero from '../../../components/hero/hero';
import DeletePopup from '../../../components/delete-popup/delete-popup';
import { getAll, remove } from '../../../api';
import { getPermissionVisibility, Toast } from '../../../utils';
import { useAuth } from '../../../contexts/auth';
import PermissionComponent from '../../../permissions/permissionComponent';

export default function Equipments() {
  const history = useHistory();

  const { auth } = useAuth();
  const PermissionsList = auth.permissions;

  const [popupVisible, setPopupVisible] = useState(false);
  const [customDataSource, setCustomDataSource] = useState([]);
  const [deleteID, setDeleteID] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const showModal = (e) => {
    setPopupVisible(true);
    setDeleteID(e.row.data.id);
    setDeleteName(e.row.data.name);
  };

  const hideModal = () => {
    setPopupVisible(false);
  };

  const deleteEquipment = (id) => {
    remove('equipments', id).then(() => {
      setCustomDataSource(customDataSource.filter((data) => data.id !== id));
      hideModal();
      Toast("L'équipement a été bien supprimé");
    });
  };

  useEffect(() => {
    getAll('equipments').then((response) => {
      setCustomDataSource(response.data.data);
    });
  }, []);

  return (
    <>
      <PermissionComponent
        scope="createEquipment"
        rightchildren={<Hero title="Options et équipements" btnText="Ajouter un équipement" url="/equipement" />}
        nonrightchildren={<h2 className="content-block">Options et équipements</h2>}
      />
      <DataGrid
        className="dx-card wide-card"
        dataSource={customDataSource}
        showBorders
        remoteOperations
        defaultFocusedRowIndex={0}
        columnAutoWidth
        columnHidingEnabled
      >
        <LoadPanel enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector showInfo allowedPageSizes={allowedPageSizes} />
        <Editing mode="row" useIcons />

        <Column dataField="id" caption="ID" width={90} />
        <Column dataField="name" caption="Nom" />
        <Column type="buttons" width={110}>
          <Button hint="Modifier" icon="edit" onClick={(e) => history.push(`/equipement/${e.row.data.id}`)} />
          <Button
            icon="trash"
            hint="Supprimer"
            visible={getPermissionVisibility(PermissionsList, 'deleteEquipment')}
            onClick={showModal}
          />
        </Column>
      </DataGrid>
      <DeletePopup
        popupVisible={popupVisible}
        hideModal={hideModal}
        title={"Suppression d'un équipement"}
        msg={`Êtes-vous sûr de vouloir supprimer cet équipement ${deleteName}?`}
        deleteActivity={() => deleteEquipment(deleteID)}
      />
    </>
  );
}

const allowedPageSizes = [5, 10, 20];
