/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import Form, { ButtonItem, ButtonOptions, GroupItem, TabbedItem, TabPanelOptions, Tab, SimpleItem, RequiredRule, Label } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './residence-form.scss';
import { LoadPanel } from 'devextreme-react';
import { CommonSeriesSettingsHoverStyle } from 'devextreme-react/chart';
import { get, updateWithMedia, update, getAll } from '../../../api';
import { saisonList, Toast, categList } from '../../../utils';
import AgencyFormPopup from '../agency-form-popup/agency-form-popup';
import MemberFormPopup from '../member-form-popup/member-form-popup';
import useGetMergTags from '../../../hooks/useGetTags';
import { checkBoxOptions, ImageWithEventEditorOptions } from './input-options';
import { selectEditorOptions, ImageEditorOptions, textEditorOptions } from '../../../utils/editorOptions';
import EditorInput from '../../editor-input/editor-input';


const AddPopup = React.lazy(() => import('../../add-popup/add-popup'));
const GeneralInformation = React.lazy(() => import('./tabs/generalInformation'));
const FrenchTab = React.lazy(() => import('./tabs/french'));
const EnglishTab = React.lazy(() => import('./tabs/english'));
const Seo = React.lazy(() => import('./tabs/seo'));

export default function ActivityForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: activityId } = useParams();
  const [loadPanelVisible, setLoadPanelVisible] = useState(true);

  const [loading, setLoading] = useState(false);
  /** ********** Popup Form create ********** */
  const [items, setItems] = useState({});

  /** ** select dropdown data *** */

  const [groupCateg, setGroupCateg] = useState();
  const [selectsData, setSelectsData] = useState({
    photo: '',
    photoCover: '',
  });

  const refFormActivity = useRef('ActivityupdateForm');


  /** ***** texts editor states ****** */
  const [textsValues, setTextsValues] = useState([
    {
      language: 'fr',
      type: 'name',
      value: '',
    },
    {
      language: 'en',
      type: 'name',
      value: '',
    },
    {
      language: 'fr',
      type: 'text',
      value: '',
    },
    {
      language: 'en',
      type: 'text',
      value: '',
    },
    {
      language: 'fr',
      type: 'useful_links',
      value: '',
    },
    {
      language: 'en',
      type: 'useful_links',
      value: '',
    },
  ]);

  /** **** handle change texts value **** */
  const setChangeTexts = (data, index) => {
    textsValues[index].value = data;
    setTextsValues(textsValues);
  };

  const onFieldDataChanged = (e) => {
    const data = e.component.option('formData');
    setItems(data);
  };

  /** ********* format data before submit ******** */
  const formatDataBeforeSubmit = () => {
    let with_error = false;
    const { name_fr, name_en, main_photo, cover_photo, seo_fr, seo_en, activity_category, ...rest } = items;
    if (textsValues[3].value === '' || textsValues[3].value === null || textsValues[2].value === '' || textsValues[2].value === null ||
      textsValues[4].value === '' || textsValues[4].value === null || textsValues[5].value === null || textsValues[5].value === '') {
      with_error = true;
      return with_error;
    }
    textsValues[0].value = name_fr;
    textsValues[1].value = name_en;
    const seo =
      seo_fr || seo_en
        ? [
          { ...seo_fr, language: 'fr' },
          { ...seo_en, permalink: null, language: 'en' },
        ]
        : [];
    const values = {
      ...rest,
      texts: JSON.stringify(textsValues),
      seo: JSON.stringify(seo),
    };
    if (main_photo?.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      values.main_photo = main_photo[0];
    }
    if (cover_photo?.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      values.cover_photo = cover_photo[0];
    }
    values.activity_category_id = activity_category.id;
    const formData = new FormData();
    Object.keys(values).map((key) => values[key] != null && formData.append(key, values[key]));
    formData.append('_method', 'PUT');
    return formData;
  };

  /** ******* Submit data ********* */
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const dataToSend = formatDataBeforeSubmit();
      if (dataToSend === true) { return notify('Tous les champs français et anglais sont obligatoires', 'error', 4000); }
      updateWithMedia('activities', activityId, dataToSend, { 'Content-Type': 'multipart/form-data' }).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          Toast(t('activity.categ_successUpdateM', { title: items.name_fr }));
          history.push('/activites');
        }
      });
      return items;
    },
    [items, textsValues]
  );


  /** ******** useEffect ********* */

  /* useEffect(() => {
    get('residences', activityId).then((response) => {
      const { data } = response.data;

      const {
        introduction_fr,
        introduction_en,
        descriptions_fr,
        descriptions_en,
        category,
        preauthorization,
        main_photooto,
        member,
        short_description_fr,
        short_description_en,
        other_name_fr,
        other_name_en,
        ...rest
      } = data;
      const dataList = {
        ...rest,
        user_id: member.id,
        category_id: category?.id,
      };
      setSelectsData({
        photo: main_photo,
      });

      setItems(dataList);
      refFormActivity.current.instance.updateData('user_id', member.id);
      setIntroduction([
        {
          type: 'introduction',
          language: 'fr',
          value: introduction_fr ?? '',
        },
        {
          type: 'introduction',
          language: 'en',
          value: introduction_en ?? '',
        },
      ]);
      setTexts([
        {
          type: 'short_description',
          language: 'fr',
          value: short_description_fr ?? '',
        },
        {
          type: 'short_description',
          language: 'en',
          value: short_description_en ?? '',
        },
        {
          type: 'descriptive_title',
          language: 'fr',
          value: '',
        },
        {
          type: 'descriptive_title',
          language: 'en',
          value: '',
        },
        {
          type: 'descriptions',
          language: 'fr',
          value: descriptions_fr ?? '',
        },
        {
          type: 'descriptions',
          language: 'en',
          value: descriptions_en ?? '',
        },
        {
          type: 'other_name',
          language: 'fr',
          value: other_name_fr ?? '',
        },
        {
          type: 'other_name',
          language: 'en',
          value: other_name_en ?? '',
        },
      ]);
      setLoadPanelVisible(false);
    });
  }, []);
  */
  const [seoRef, setSeoRef] = useState([
    { language: 'fr', description: '' },
    { permalink: null, language: 'en', description: '' },
  ]);

  const setChangeSeo = (data, index) => {
    seoRef[index].value = data;
    setSeoRef(seoRef);
  };
  useEffect(() => {
    (async () => {
      get('activities', activityId)
        .then((response) => {
          const { data } = response.data;
          const {
            cover_photo,
            main_photo,
            text_en,
            text_fr,
            useful_link_fr,
            useful_link_en,
            ...rest
          } = data;
          if (data) {
            setItems(rest);
            setTextsValues([
              {
                language: 'fr',
                type: 'name',
                value: items.name_fr,
              },
              {
                language: 'en',
                type: 'name',
                value: items.name_en,
              },
              {
                language: 'fr',
                type: 'text',
                value: text_fr,
              },
              {
                language: 'en',
                type: 'text',
                value: text_en,
              },
              {
                language: 'fr',
                type: 'useful_links',
                value: useful_link_fr,
              },
              {
                language: 'en',
                type: 'useful_links',
                value: useful_link_en,
              },
            ]);
          }
          setSelectsData(
            {
              photo: main_photo,
              photoCover: cover_photo,
            }
          );
          setLoadPanelVisible(false);
        }).then(
          getAll('activity_categories').then((response) => {
            setGroupCateg(response.data.data);
            setLoadPanelVisible(false);
          })
        );
    })()
  }, []);


  const [isPhoto, setIsPhoto] = useState(false);
  const [isPhotoCouv, setIsPhotoCouv] = useState(false);

  return (
    <Suspense
      fallback={
        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible showIndicator shading showPane hideOnOutsideClick={false} />
      }
    >
      <Button
        className="goback-btn"
        icon="chevronprev"
        text={t('activity.goback')}
        type="default"
        stylingMode="text"
        onClick={() => history.push('/activites')}
      />
      <h2 className="content-block">
        {t('activity.edit_activity')} {items.name} {items.official_name && `(${items.official_name})`}
      </h2>
      <div className="content-block">
        <div className="dx-card responsive-paddings">
          <form onSubmit={onSubmit}>
            <Form
              formData={items}
              ref={refFormActivity}
              labelLocation="left"
              disabled={loading}
              showValidationSummary
              className="residence_form"
            >
              <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                onHiding={() => setLoadPanelVisible(false)}
                visible={loadPanelVisible}
                showIndicator
                shading
                showPane
                hideOnOutsideClick={false}
              />
              <TabbedItem>
                <TabPanelOptions deferRendering />
                <Tab title="Informations de base">
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem
                      dataField="activity_category.id"
                      editorType="dxSelectBox"
                      editorOptions={selectEditorOptions(groupCateg, 'name_fr')}
                    >
                      <RequiredRule message={t('activity.categ_error_msg')} />
                      <Label text={t('activity.categ')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" caption={t('activity.main_img')}>
                    <SimpleItem dataField="main_photo" editorType="dxFileUploader" editorOptions={ImageWithEventEditorOptions(setIsPhoto)}>
                      <Label text="Photo principale" visible={false} />
                    </SimpleItem>
                    {isPhoto && !!selectsData.photo && (
                      <GroupItem>
                        <div className="content-block">
                          <img src={selectsData.photo} alt={t('activity.main_photo')} width="150" height="150" />
                        </div>
                      </GroupItem>
                    )}
                  </GroupItem>

                  <GroupItem cssClass="group-item" caption={t('activity.cover_photo')}>
                    <SimpleItem dataField="cover_photo" editorType="dxFileUploader" editorOptions={ImageWithEventEditorOptions(setIsPhotoCouv)} >
                      {!selectsData.photoCover && (
                        <RequiredRule message="la photo de couverture est requise" />
                      )}
                      <Label text="Photo de couverture" visible={false} />
                    </SimpleItem>
                    {isPhotoCouv && !!selectsData.photoCover && (
                      <GroupItem>
                        <div className="content-block">
                          <img src={selectsData.photoCover} alt={t('activity.cover_photo')} width="150" height="150" />
                        </div>
                      </GroupItem>
                    )}
                  </GroupItem>
                </Tab>
                <Tab title="Français">
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem dataField="name_fr" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message="Le nom de l'activité' est requis" />
                      <Label text="Nom de l'activité" visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <GroupItem>
                      <div className="dx-field-label">{t('activity.text')}*</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput
                          valueContent={textsValues[2].value}
                          valueChanged={(data) => setChangeTexts(data, 2)} />
                      </div>
                    </GroupItem>
                    <GroupItem>
                      <div className="dx-field-label">{t('activity.link_utils')}*</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput
                          valueContent={textsValues[4].value}
                          valueChanged={(data) => setChangeTexts(data, 4)} />
                      </div>
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title="Anglais">
                  <GroupItem cssClass="group-item" colCount={2}>
                    <SimpleItem dataField="name_en" editorType="dxTextBox" editorOptions={textEditorOptions}>
                      <RequiredRule message={t('activity.activity_name_requise')} />
                      <Label text={t('activity.activity_name')} visible={false} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem cssClass="group-item" colCount={2}>
                    <GroupItem>
                      <div className="dx-field-label">{t('activity.text')}*</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput
                          valueContent={textsValues[3].value}
                          valueChanged={(data) => setChangeTexts(data, 3)} />
                      </div>
                    </GroupItem>
                    <GroupItem>
                      <div className="dx-field-label">{t('activity.link_utils')}*</div>
                      <div className="dx-field-value editor_width">
                        <EditorInput
                          valueContent={textsValues[5].value}
                          valueChanged={(data) => setChangeTexts(data, 5)} />
                      </div>
                    </GroupItem>
                  </GroupItem>
                </Tab>
                <Tab title="Seo">
                  <GroupItem cssClass="group-item">
                    <GroupItem>
                      <SimpleItem dataField="seo_fr.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                        <Label text={t('activity.title_fr')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem dataField="seo_fr.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                        <Label text={t('activity.desc_fr')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem dataField="seo_en.title" editorType="dxTextBox" editorOptions={textEditorOptions}>
                        <Label text={t('activity.title_en')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                      <SimpleItem dataField="seo_en.description" editorType="dxTextBox" editorOptions={textEditorOptions}>
                        <Label text={t('activity.desc_en')} visible={false} />
                      </SimpleItem>
                    </GroupItem>
                  </GroupItem>
                </Tab>
              </TabbedItem>
              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible /> : t('common.save_form')}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width="100%" type="normal" onClick={() => history.push('/activites/')}>
                    <span className="dx-button-text">{t('common.cancel')}</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </Suspense>
  );
}
